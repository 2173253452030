import React, { useState } from 'react';
import { HeaderComponent } from '../OrganizationPageGroupComponents/HeaderComponent';
import { TabsData } from '../../@types/ITabTypes';

interface TabsManagedProps extends TabsData {
  onTabClick: (tabIndex: number) => void;
}

const TabsManaged: React.FC<TabsManagedProps> = ({ tabsData, onTabClick }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = tabsData[activeTabIndex];

  const handleTabClick = (idx: number) => {
    setActiveTabIndex(idx);
    onTabClick(idx);
  };

  return (
    <>
      <div className="flex flex-row w-full h-full space-x-10 text-gray-300 font-semibold pl-10 justify-initial bg-gray-100 overflow-x-auto lg:overflow-x-hidden">
        {tabsData.map((tab, idx) => (
          <button
            key={idx}
            className={`disabled py-5 transition-colors duration-300 ${
              idx === activeTabIndex
                ? ' border-b-2 text-gray-500 border-gray-500'
                : 'text-gray-300'
            }`}
            onClick={() => handleTabClick(idx)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {activeTab.hasHeader && (
        <HeaderComponent
          icon={activeTab.icon}
          label={activeTab.label}
          headerButtons={activeTab.headerButtons}
        />
      )}

      <div className="flex-1">{tabsData[activeTabIndex].content}</div>
    </>
  );
};

export default TabsManaged;

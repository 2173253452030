import { createContext, useContext, useState } from 'react';

interface ISidebarContext {
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

interface ISidebarProviderProps {
  children: React.ReactNode;
}

export const SidebarContext = createContext<ISidebarContext>({
  sidebarOpen: false,
  setSidebarOpen: (sidebarOpen: boolean) => {},
});

export const SidebarProvider: React.FC<ISidebarProviderProps> = ({
  children,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <SidebarContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);

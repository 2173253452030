import React, { useState, useEffect } from "react";
import Button from "../Button/Button";

interface TodoListProps {
    options?: IFormQuestionOptions[];
    onItemsChange?: (items: IFormQuestionOptions[]) => void;
  }
  
  const TodoList: React.FC<TodoListProps> = ({ options, onItemsChange }) => {
    const [newItem, setNewItem] = useState<IFormQuestionOptions | null>(null);
  
    useEffect(() => {
      if (Array.isArray(options)) {
        const optionItems = options.map((option) => option);
        setItems(optionItems);
      } else if (options) {
        setItems([options]);
      }
    }, [options]);
  
    const [items, setItems] = useState<IFormQuestionOptions[]>([]);
  
    const addItem = () => {
      if (newItem && newItem.value.trim() !== "") {
        const updatedItems = [...items, newItem];
        if (onItemsChange) {
          onItemsChange(updatedItems);
        }
        setItems(updatedItems);
        setNewItem(null);
      }
    };
  
    const deleteItem = (index: number) => {
      if (index >= 0 && index < items.length) {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        if (onItemsChange) {
          onItemsChange(updatedItems);
        }
        setItems(updatedItems);
      }
    };
  
    return (
      <div className="w-full">
        <div className="flex mb-4 border-b border-gray-300 w-full">
          <input
            type="text"
            className="w-full border-none"
            placeholder="Adicionar uma opção"
            value={newItem ? newItem.value : ""}
            onChange={(e) =>
              setNewItem({ key: items.length.toString(), value: e.target.value })
            }
          />
  
          <div className="mr-6">
            <Button icon={"plus-light-blue-200"} style="none" onClick={addItem} />
          </div>
        </div>
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              className="flex justify-between items-center border-b border-gray-300 mb-2"
            >
              <p>{item.value}</p>
  
              <div className="mr-6">
                <Button
                  icon={"trash-red-200"}
                  style="none"
                  onClick={() => deleteItem(index)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  export default TodoList;

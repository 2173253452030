import React from 'react';
import Icon from '../Icon/Icon';

interface ToastProps {
  state: 'danger' | 'warning' | 'success' | 'primary';
  title?: string;
  label: string;
  onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({
  state,
  title,
  label,
  onClose,
}) => {
  const stateStyle = {
    danger: ['bg-red-300', 'red-200', 'circle-xmark-red-200'],
    warning: ['bg-yellow-300', 'gray-500', 'warn-gray-500'],
    success: ['bg-green-300', 'green-200', 'check-green-200'],
    primary: ['bg-blue-400', 'blue-200', 'info-blue-500'],
  };

  const closeIcon: string = 'close-' + stateStyle[state][1];
  const toastClasses = `w-full relative flex items-center gap-5 px-5 py-[26px] rounded-base  ${stateStyle[state][0]}`;

  return (
    <div className={toastClasses}>
      <Icon name={stateStyle[state][2]} />
      <span className="text-black-100">
        <span className="font-bold">{title}</span> {label}
      </span>
      <button
        onClick={onClose}
        className="absolute top-[19px] right-[19px] border-none bg-none"
      >
        <Icon name={closeIcon} size="xs" />
      </button>
    </div>
  );
};

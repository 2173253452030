import React from 'react';

interface RadioProps {
  hasError?: boolean;
  name: string;
  isChecked: boolean;
  onChange?: any;
  value: string;
}

const Radio: React.FC<RadioProps> = ({
  name,
  hasError = false,
  onChange,
  isChecked,
  value,
}) => {
  function radioState(): string {
    if (isChecked)
      return 'checked:border-white-200 checked:bg-blue-200 checked:text-blue-warm-100 checked:hover:border-blue-200 bg-no-repeat bg-auto bg-center hover:bg-blue-warm-100';

    if (hasError) return 'border-red-200 bg-white-100';

    return 'bg-white-100 border-gray-200 hover:bg-gray-100';
  }

  return (
    <input
      type="radio"
      className={`appearance-none h-6 w-6 rounded-full border-1 focus-within:none ${radioState()}`}
      checked={isChecked}
      value={value}
      name={name}
      onChange={onChange}
    />
  );
};

export default Radio;

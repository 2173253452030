/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    fontFamily: {
      body: ['"Rawline"'],
    },
    fontSize: {
      sm: '1rem', // 16px
      base: '1.25rem', //20px
      lg: '1.5rem', //24px
      xl: '1.625rem', //26px
    },
    colors: {
      'black-100': '#2D2E2F',
      'black-200': '#333333',
      'black-300': '#1C1D1F',
      'black-400': '#343F4E',
      'blue-100': '#15C6F4',
      'blue-200': '#4A77B4',
      'blue-300': '#345D96',
      'blue-warm-100': '#E1E7F1',
      'blue-warm-200': '#C5D4EB',
      'blue-400': '#D4E5FF',
      'blue-500': '#1351B4',
      'blue-600': '#2F4668',
      'gray-100': '#EDEFF0',
      'gray-200': '#DFE1E2',
      'gray-300': '#A9AEB1',
      'gray-400': '#71767A',
      'gray-500': '#3D4551',
      'gray-600': '#C6CACE',
      'green-100': '#197278',
      'green-200': '#168821',
      'green-300': '#DBEBDE',
      'orange-100': '#FFA058',
      'orange-200': '#E66C37',
      'pink-100': '#BE5DC9',
      'purple-100': '#744EC2',
      'purple-200': '#6B007B',
      'red-100': '#D64550',
      'red-200': '#CD425B',
      'red-300': '#F3E1E4',
      'white-100': '#FFFFFF',
      'yellow-100': '#D9B300',
      'yellow-200': '#FFBE2E',
      'yellow-300': '#FFE396',
    },
    borderRadius: {
      sm: '0.125rem',
      base: '0.25rem',
      md: '0.625rem',
      lg: '1.5rem',
      xl: '1.625rem',
      full: '9999px',
    },
    extend: {
      width: {
        13: '0.813rem', //13px
        65: '16.25rem', //260px
        '2xl': '90rem', //1440px
      },
      height: {
        12: '3.125rem',
        13: '0.813rem',
        22: '5.625rem',
        38: '9.375rem',
      },
      gap: {
        5: '20px',
      },
      borderRadius: {
        '3xl': '1.563rem',
      },
      borderWidth: {
        1: '0.063',
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
};

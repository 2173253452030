import { Dispatch, SetStateAction, useContext } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { InputDropdown } from '../Dropdown/InputDropdown';
import ModalAlert from '../Modal/ModalAlert';
import { Input } from '../Input/Input';
import Button from '../Button/Button';
import Label from '../Label/Label';

import { AuthContext } from '../../contexts/AuthContext';
import { userService } from '../../services/userService';
import { OrganizationContext } from '../../contexts/OrganizationContext';

const options: IOption[] = [
  { key: 'REPORTER', value: 'Relator' },
  { key: 'REPRESENTATIVE', value: 'Responsável' },
];

interface IFormInviteProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  onInviteChange: (response: Promise<any>) => void;
}

export function FormInviteUser({
  open,
  setOpen,
  onInviteChange,
}: IFormInviteProps) {
  const { session } = useContext(AuthContext);
  const { selectedOrgId } = useContext(OrganizationContext);

  const InviteSchema = z.object({
    toEmail: z
      .string()
      .nonempty('Esse campo é obrigatório')
      .regex(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, {
        message: 'E-mail inválido!',
      }),
    organization: z.string().nonempty('Esse campo é obrigatório'),
    assignment: z.object({
      key: z.string(),
      value: z.string()
    }).required(),
  });

  type InviteSchemaProps = z.infer<typeof InviteSchema>;

  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<InviteSchemaProps>({
    resolver: zodResolver(InviteSchema),
    defaultValues: {
      organization:
        session?.organizations.find((org) => org.id === selectedOrgId)
          ?.nickName ?? '',
    },
  });

  async function submitInvite(data: InviteSchemaProps) {
    const { assignment, toEmail } = data;
    if (session) {
      const payload = {
        toEmail,
        assignment: assignment.key,
        fromUserId: session?.id,
        companyId: selectedOrgId
      };
      const response = await userService.inviteNewUser(payload);
      onInviteChange && onInviteChange(response);
    }
    reset();
  }

  function validateEmail(): IStateClasses {
    if (errors['toEmail']?.type === 'invalid_string') return 'warning';

    if (errors['toEmail']?.type === 'too_small') return 'danger';

    return undefined;
  }

  return (
    <ModalAlert
      visible={open}
      onClose={() => {
        setOpen((open) => !open);

        reset();
      }}
      hasButtonClose
    >
      <form
        className="w-[610px] h-[693px] flex flex-col"
        onSubmit={handleSubmit(submitInvite)}
      >
        <header className="p-10 border-solid border-b-2 border-gray-200">
          <section>
            <Label color="text-black-10" size="xl" className="!font-bold">
              Convidar usuário
            </Label>
            <Label color="text-gray-400" size="sm">
              Preencha os campos e envie o convite por meio do botão abaixo.
            </Label>
          </section>
        </header>
        <main className="flex-1 overflow-auto p-10">
          <section>
            <div className="pb-10">
              <Label color="text-gray-400" size="sm">
                Informe o e-mail do destinatário do convite e defina a função
                que ele na plataforma para essa organização.
              </Label>
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className="flex flex-col gap-[10px]">
                <Label>E-mail do usuário</Label>
                <Input
                  placeholder="Insira o e-mail do usuário aqui"
                  registerInput={register('toEmail')}
                  state={validateEmail()}
                  errorLabel={errors['toEmail']?.message}
                />
              </div>

              <div className="flex flex-col gap-[10px]">
                <Label>Organização Inventariante</Label>
                <Input
                  placeholder="Pumpkin Tech"
                  registerInput={register('organization')}
                  state={errors['organization']?.message ? 'danger' : undefined}
                  readOnly
                />
              </div>

              <div className="flex flex-col gap-[10px]">
                <Label>Função do usuário</Label>
                <>
                  <InputDropdown
                    placeholder="Selecione a função do usuário"
                    control={control}
                    {...register('assignment', { required: true })}
                    state={errors['assignment']?.message ? 'danger' : undefined}
                    errorMessage={errors['assignment']?.message}
                    options={options}
                    getValues={getValues}
                  />
                </>
              </div>
            </div>
          </section>
        </main>
        <footer className="p-10 border-solid border-t-2 border-gray-200">
          <Button type="submit" style="filled" label="Enviar convite" />
        </footer>
      </form>
    </ModalAlert>
  );
}

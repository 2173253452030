import { useContext, useEffect, useState } from 'react';

import { NotificationComponent } from '../../components/OrganizationPageGroupComponents/NotificationComponent';
import { ListUserOrganization } from '../../components/OrganizationPageGroupComponents/ListUsersOrganization';
import { FormInviteUser } from '../../components/OrganizationPageGroupComponents/FormInviteUser';
import {
    IUserorganization,
    organizationService,
} from '../../services/organizationService';
import { AuthContext } from '../../contexts/AuthContext';
import { OrganizationInventories } from '../../components/OrganizationPageGroupComponents/OrganizationInventories';
import { InviteSuccess } from '../../components/OrganizationPageGroupComponents/InviteSuccess';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import Button from '../../components/Button/Button';
import { AddInventoryModal } from '../../components/Modal/AddInventoryModal';
import { formsService } from '../../services/formsService';
import ModalAlert from '../../components/Modal/ModalAlert';
import Label from '../../components/Label/Label';
import Tabs from '../../components/Tabs/Tabs';
import { LoadingForm } from '../../components/Loading/LoadingForm';

export function GenericOrganizationPage() {
    
    const { session } = useContext(AuthContext);
    const { selectedOrgId } = useContext(OrganizationContext);

    const [users, setUsers] = useState<IPagination<IUserorganization> | null>(
        null,
    );
    const [showInviteNotification, setShowInviteNotification] =
        useState<boolean>(false);
    const [showForminvite, setShowForminvite] = useState<boolean>(false);
    const [showInviteSuccess, setShowInviteSuccess] = useState<boolean>(false);
    const [showUsersTable, setShowUsersTable] = useState<boolean>(false);
    const [showAddInventory, setShowAddInventory] = useState<boolean>(false);
    const [inventoryList, setInventoryList] =
        useState<IPagination<IInventoryInfo>>();

    const [showModalHasInventory, setShowModalHasInventory] = useState(false);

    const handleClickUserInvite = () => setShowForminvite(true);

    const handleReturnInvite = (response: Promise<any>) => {
        setShowForminvite(false);
        setShowInviteSuccess(true);
        setTimeout(() => {
            setShowInviteNotification(true);
            updateUsers();
        }, 1000);
    };

    const updateUsers = () => {
        if (session === null) return;
        setIsLoading(true);
        organizationService.getGeneralUsers([selectedOrgId]).then((users) => {
            setUsers(users);
            setIsLoading(false);
        });
    };
    const updateInventories = () => {
        setIsLoading(true);
        formsService.getFormsByCompanyId([selectedOrgId]).then((response) => {
            setInventoryList(response);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setShowUsersTable(false);
        updateUsers();
        updateInventories();
    }, [selectedOrgId]);

    useEffect(() => {
        setShowUsersTable(true);
    }, [users]);

    const hasInventory = () => {
        if (inventoryList?.totalElements !== undefined && inventoryList?.content) {
            const inventoryDates = inventoryList.content.map(
                (inventory: IInventoryInfo) => inventory.createdDate,
            );
            if (inventoryDates.length > 0) {
                const lastInventoryDate = inventoryDates.reduce(
                    (maxDate, currentDate) =>
                        currentDate > maxDate ? currentDate : maxDate,
                );
                if (isYearPassed(lastInventoryDate)) {
                    setShowModalHasInventory(false);
                } else {
                    setShowModalHasInventory(true);
                }
            }
        }
    };

    const isYearPassed = (date: Date) => {
        const nextYearDate = new Date(date);
        nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

        return date >= nextYearDate;
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const tabsData = [
        {
            icon: 'document',
            label: 'Inventários',
            hasHeader: true,
            headerButtons: (
                <div className="w-60">
                    <Button
                        label={'Adicionar Inventário'}
                        icon={'plus-light'}
                        style="filled"
                        type="button"
                        onClick={() => {
                            hasInventory();
                            if (inventoryList?.totalElements === 0 ?? true)
                                setShowAddInventory(true);
                        }}
                    />
                </div>
            ),
            content: (
                <OrganizationInventories
                    inventories={inventoryList!}
                    updateInventories={updateInventories}
                />
            ),
        },
        {
            icon: 'people',
            label: 'Usuários',
            hasHeader: true,
            headerButtons: (
                <>
                    <div className="w-60">
                        <Button
                            onClick={handleClickUserInvite}
                            label={'Convidar usuário'}
                            icon={'plus-light'}
                            style="filled"
                        />
                    </div>
                    {/* <div>
            <ButtonCircle
              style="outlined"
              icon="filter"
              iconPosition="center"
            ></ButtonCircle>
          </div> */}
                </>
            ),
            content: (
                <>
                    <FormInviteUser
                        onInviteChange={handleReturnInvite}
                        setOpen={setShowForminvite}
                        open={showForminvite}
                    />
                    {showUsersTable && (
                        <div className="m-10 border border-gray-200 rounded-md overflow-hidden overflow-x-auto">
                            <ListUserOrganization users={users} updateUsers={updateUsers} />
                        </div>
                    )}
                    <InviteSuccess
                        open={showInviteSuccess}
                        setOpen={setShowInviteSuccess}
                    />
                    <NotificationComponent
                        showNotification={showInviteNotification}
                        onClose={setShowInviteNotification}
                    />
                </>
            ),
        },
        // {
        //   icon: 'gear',
        //   label: 'Configurações',
        //   content: 'Configurações.',
        // },
    ];

    return (
        <>
           {isLoading && <LoadingForm />}
            <Tabs tabsData={tabsData}></Tabs>

            <AddInventoryModal
                visible={showAddInventory}
                closeModal={() => setShowAddInventory(false)}
            />

            <ModalAlert
                visible={showModalHasInventory}
                onClose={() => setShowModalHasInventory(false)}
            >
                <div className="w-auto relative p-10">
                    <div className="text-center gap-2.5 flex flex-col">
                        <Label size="xl" className="font-bold">
                            Você já possui um inventário!
                        </Label>
                        <Label color="text-gray-400">
                            Você já enviou um inventário este ano, espere o próximo ano para
                            poder enviar outro.
                        </Label>
                    </div>
                    <div className="w-[260px] mt-10 mx-auto">
                        <Button
                            style="filled"
                            label="Fechar"
                            onClick={() => setShowModalHasInventory(false)}
                        />
                    </div>
                </div>
            </ModalAlert>
        </>
    );
}

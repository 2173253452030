import { api } from './api';

const getNotificationByUserId = (
  userId: string,
  page?: number,
): Promise<any> => {
  const params = `userId=${userId}&page=${page ?? 0}&size=10`;
  return api.get(`/notifications/search`, params, '');
};

const putNotificationRead = (
  notificationRead: INotificationRead,
): Promise<any> => {
  return api.put('/notifications/read', notificationRead);
};

const participationAnswer = (
  participationAnswer: IParticipationAnswer,
  id: string,
): Promise<any> => {
  return api.post(`/request-participation/${id}`, participationAnswer);
};

const notificationExist = (id: string): Promise<any> => {
  return api.get(`/notifications/exists/${id}`, '');
};

export const notificationService = {
  getNotificationByUserId,
  putNotificationRead,
  participationAnswer,
  notificationExist,
};

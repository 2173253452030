// PDFComponent.tsx
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  Text,
  View,
  pdf,
} from '@react-pdf/renderer';
import React from 'react';
import logoPath from '../../assets/png/logo-report.png';

//import aggregatedPreviewTable from '../InventoryResumePage/InventoryResumePage';

Font.register({
  family: 'Rawline',
  fonts: [
    {
      src: require('../../assets/fonts/rawline-400.ttf'),
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: require('../../assets/fonts/rawline-600.ttf'),
      fontStyle: 'bold',
      fontWeight: 700,
    },
  ],
});

const page3Data = [
  {
    question: 'Nome da organização',
    answer: 'iDefsoft',
  },
  {
    question:
      'Com base na receita, selecione o setor primário predominante de sua organização:',
    answer: '01113 - CULTIVO DE CEREAIS',
  },
  {
    question:
      'Forneça uma visão geral e apresentação de sua organização (detalhes institucionais, produção anual, atividade, participação no segmento, receita, entre outros).',
    answer: 'Visão',
  },
  {
    question: 'Indicar o ano inventariado',
    answer: '2023',
  },
  {
    question:
      'Para o ano relatado, o inventário dessa organização já foi submetido para outra iniciativa também?',
    answer: 'Não',
  },
  {
    question:
      'Selecione a(s) Unidade(s) Federativa(s) relacionada(s) a esse inventário relatado',
    answer: 'AC',
  },
  {
    question:
      'Selecione a opção que descreve o limite de relato utilizado para a contabilização de GEEs',
    answer: 'Controle operacional',
  },
  {
    question:
      'Este é o primeiro ano de reporte de dados de emissões da organização ao SIRENE?',
    answer: 'Sim',
  },
  {
    question:
      'Selecione o nome da norma, do protocolo ou da metodologia usado/a para coletar os dados das atividades e calcular as emissões.',
    answer:
      'American Petroleum Institute Compendium of Greenhouse Gas Emissions Methodologies for the Oil and Natural Gas Industry, 2009',
  },
];

const page4Data = [
  {
    question:
      'Existem fontes (por ex., instalações, GEEs específicos, atividades, regiões etc.) de emissões de Escopo 1 e Escopo 2 que estejam dentro dos limites de reporte selecionados, mas que não estão incluídas na divulgação?',
    answer: 'Sim',
  },
  {
    question:
      'Forneça detalhes sobre as fontes de emissões de Escopo 1 e Escopo 2 que se enquadram no seu escopo de divulgação selecionada, mas que não estão incluídas em sua divulgação e explique por que essas fontes foram excluídas.',
    answer: 'detalhes',
  },
  {
    question:
      'Por favor, forneça mais detalhes sobre tipo de verificação/garantia adotada para as emissões do Escopo 1 e 2',
    answer: 'Garantia limitada',
  },
];

const orgName = 'iDefsoft';
const inventoryYear = '2023';

const Header: React.FC = () => {
  return (
    <>
      <Text
        style={{
          fontSize: 11,
          color: '#A1B2D0',
          fontFamily: 'Rawline',
          fontWeight: 'bold',
          fontStyle: 'bold',
          alignSelf: 'center',
          textAlign: 'center',
          alignContent: 'center',
        }}
      >
        Inventário organizacional de GEE
      </Text>
      <Text
        style={{
          textAlign: 'center',
          marginTop: '5px',
          fontSize: 11,
          fontFamily: 'Rawline',
          fontWeight: 600,
          alignSelf: 'center',
          alignContent: 'center',
          color: '#A4B2CD',
        }}
      >
        {orgName}
      </Text>

      <Text
        style={{
          textAlign: 'center',
          fontSize: 11,
          marginTop: '5px',
          fontFamily: 'Rawline',
          fontWeight: 600,
          alignSelf: 'center',
          alignContent: 'center',
          color: '#A4B2CD',
        }}
      >
        {inventoryYear}
      </Text>
    </>
  );
};

const OrganizationReport: React.FC = () => {
  const getCurrentTime = (): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };

    const currentTime = new Date().toLocaleString('pt-BR', options);
    return currentTime;
  };

  return (
    <Document>
      <Page
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '30px',
        }}
      >
        <View style={{ flex: 1 }} />

        <Image
          src={logoPath}
          style={{ width: 457.212, height: 150, marginBottom: '40px' }}
        />

        <Text
          style={{
            fontSize: 18,
            color: '#2F5496',
            fontFamily: 'Rawline',
            fontWeight: 600,
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          Inventário organizacional de emissões e remoções de gases de efeito
          estufa (GEE)
        </Text>

        <View style={{ flex: 1 }} />

        <Text
          style={{
            textAlign: 'center',
            marginBottom: '10px',
            fontSize: 11,
            fontFamily: 'Rawline',
            fontWeight: 600,
          }}
        >
          {orgName}
        </Text>
        <Text
          style={{
            textAlign: 'center',
            marginBottom: '10px',
            fontSize: 11,
            fontFamily: 'Rawline',
            fontWeight: 600,
            color: '#254276',
          }}
        >
          Ano do Inventário:
        </Text>
        <Text
          style={{
            textAlign: 'center',
            fontSize: 11,
            fontFamily: 'Rawline',
            fontWeight: 600,
          }}
        >
          {inventoryYear}
        </Text>
      </Page>

      <Page
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '30px',
        }}
      >
        <Header />

        <View style={{ flex: 1 }} />

        <Text
          style={{
            fontSize: 12,
            marginVertical: '40px',
            fontFamily: 'Rawline',
            fontWeight: 'bold',
            fontStyle: 'bold',
          }}
        >
          Sirene Organizacionais
        </Text>
        <Text
          style={{
            marginHorizontal: '70px',
            fontSize: 12,
            marginVertical: '40px',
            fontFamily: 'Rawline',
            fontWeight: 600,
          }}
        >
          O SIRENE Organizacionais representa uma iniciativa pioneira do governo
          brasileiro dedicada a promover a transparência e a sustentabilidade
          nas organizações do país.
        </Text>
        <Text
          style={{
            marginHorizontal: '70px',
            fontSize: 12,
            marginVertical: '10px',
            fontFamily: 'Rawline',
            fontWeight: 600,
          }}
        >
          A plataforma de relato é o resultado do esforço do governo federal
          coordenado pelo Ministério da Ciência, Tecnologia e Inovações (MCTI),
          com colaboração de diversos parceiros, que desde 2018 reuniu entidades
          públicas e privadas para discutir inventários organizacionais de gases
          de efeito estufa (GEE).
        </Text>
        <Text
          style={{
            marginHorizontal: '150px',
            fontSize: 12,
            marginVertical: '10px',
            fontFamily: 'Rawline',
            fontWeight: 600,
          }}
        >
          Para saber mais acesse:
        </Text>

        <Text
          style={{
            marginHorizontal: '150px',
            fontSize: 12,
            marginVertical: '10px',
            fontFamily: 'Rawline',
            fontWeight: 600,
          }}
        >
          <Link src="www.sireneorganizacionais.mcti.gov.br">
            www.sireneorganizacionais.mcti.gov.br
          </Link>
        </Text>

        <View style={{ flex: 1 }} />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '20px',
            paddingLeft: '30px',
            paddingRight: '30px',
          }}
        >
          <Text
            style={{
              fontSize: 11,
              paddingTop: 10,
              color: '#8C8C8C',
              fontFamily: 'Rawline',
              fontWeight: 600,
            }}
          >
            Gerado em: {getCurrentTime()}
          </Text>

          <View style={{ width: '300px' }} />

          <Image src={logoPath} style={{ width: 110, height: 36.5 }} />
        </View>
      </Page>

      <Page
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '30px',
        }}
      >
        <Header />

        {page3Data.map((item, index) => (
          <View key={index} style={{ marginBottom: '10px', marginTop: 20 }}>
            <Text
              style={{
                fontSize: 11,
                fontFamily: 'Rawline',
                fontWeight: 'bold',
                fontStyle: 'bold',
              }}
            >
              {item.question}
            </Text>
            <Text
              style={{
                fontSize: 11,
                marginTop: '10px',
                fontFamily: 'Rawline',
                fontWeight: 600,
              }}
            >
              {item.answer}
            </Text>
          </View>
        ))}

        <View style={{ flex: 1 }} />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 20,
            marginTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <Text
            style={{
              fontSize: 11,
              marginLeft: -30,
              paddingTop: 10,
              color: '#8C8C8C',
              fontFamily: 'Rawline',
              fontWeight: 600,
            }}
          >
            Gerado em: {getCurrentTime()}
          </Text>

          <View style={{ width: '300px', marginLeft: -80 }} />

          <Image
            src={logoPath}
            style={{ width: 110, height: 36.5, marginLeft: 75 }}
          />
        </View>
      </Page>

      <Page
        style={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '30px',
        }}
      >
        <Header />

        {page4Data.map((item, index) => (
          <View key={index} style={{ marginBottom: '20px', marginTop: 30 }}>
            <Text
              style={{
                fontSize: 11,
                fontFamily: 'Rawline',
                fontWeight: 'bold',
                fontStyle: 'bold',
              }}
            >
              {item.question}
            </Text>
            <Text
              style={{
                fontSize: 11,
                marginTop: '10px',
                fontFamily: 'Rawline',
                fontWeight: 600,
              }}
            >
              {item.answer}
            </Text>
          </View>
        ))}

        <View style={{ flex: 1 }} />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 20,
            marginTop: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <Text
            style={{
              fontSize: 11,
              marginLeft: -30,
              paddingTop: 10,
              color: '#8C8C8C',
              fontFamily: 'Rawline',
              fontWeight: 600,
            }}
          >
            Gerado em: {getCurrentTime()}
          </Text>

          <View style={{ width: '300px', marginLeft: -80 }} />

          <Image
            src={logoPath}
            style={{ width: 110, height: 36.5, marginLeft: 75 }}
          />
        </View>
      </Page>
    </Document>
  );
};

export const generatePdfBlob = async (): Promise<Blob> => {
  const pdfBlob = await pdf(<OrganizationReport />).toBlob();
  return pdfBlob as Blob;
};

import { ReactNode, useEffect, useState } from 'react';

interface IBaseTableProps {
  headerTitlesComponents: any[];
  children?: ReactNode;
  childrensLength: number;
  emptyComponent?: ReactNode;
}

export function BaseTable({
  headerTitlesComponents,
  children,
  emptyComponent,
}: IBaseTableProps) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <>
      <table className="table-auto w-full">
        <thead className="h-14 bg-gray-100">
          <tr>
            {headerTitlesComponents.map((title) => (
              <>
                {title !== 'hide' && (
                  <th
                    key={title}
                    className={`${
                      isMobile
                        ? 'text-[14px] whitespace-nowrap second:pl-4'
                        : ''
                    } text-left w-min p-4 first:pl-8 first:pr-0 last:pl-0 last:pr-8`}
                  >
                    {title}
                  </th>
                )}
              </>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
      {!!emptyComponent && (
        <>
          <div className="flex-1">{emptyComponent}</div>
        </>
      )}
    </>
  );
}

import Icon from '../Icon/Icon';
import Label from '../Label/Label';

interface InputMessageProps {
  state?: IStateClasses;
  label?: string;
}

export const InputMessage: React.FC<InputMessageProps> = ({
  state = 'primary',
  label,
}) => {
  const stateStyle = {
    primary: ['bg-blue-200', 'text-white-100'],
    success: ['bg-green-200', 'text-white-100'],
    warning: ['bg-yellow-200', 'text-gray-500'],
    danger: ['bg-red-200', 'text-white-100'],
  };
  
  const stateIcon = {
    primary: 'info-white-100',
    success: 'check-white-100',
    warning: 'warn-gray-500',
    danger: 'circle-xmark-white-100',
  };

  const stateMessage = {
    primary: '',
    success: '',
    warning: 'Verifique os dados inseridos',
    danger: 'Esse campo é obrigatório',
  }

  const componentClasses = `w-full h-[50px] md:h-[30px] px-1.5 py-1 flex flex-row items-center gap-1.5 rounded-base text-sm italic font-bold ${stateStyle[state][0]}`;
  return (
    <div className={componentClasses}>
      <Icon name={stateIcon[state]} size='xs'/>
      <Label color={stateStyle[state][1]}>{ label ?? stateMessage[state]}</Label>
    </div>
  );
};

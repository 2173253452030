import { createContext, useContext, useEffect, useState } from 'react';
import { notificationService } from '../services/notificationService';
import { AuthContext } from './AuthContext';
import { useLocation } from 'react-router-dom';

interface NotificationContextProps {
  newNotifications: boolean;
  setNewNotifications: React.Dispatch<React.SetStateAction<boolean>>;
}

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationContext = createContext<NotificationContextProps>({
  newNotifications: false,
  setNewNotifications: () => {},
});

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [newNotifications, setNewNotifications] = useState(false);
  const { session } = useContext(AuthContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (session === null) return;
    const handleNotificationsExist = async () => {
      session?.id &&
        (await notificationService
          .notificationExist(session?.id)
          .then((response) => {
            setNewNotifications(response);
          }));
    };
    handleNotificationsExist();
  }, [pathname]);

  return (
    <NotificationContext.Provider
      value={{ newNotifications, setNewNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

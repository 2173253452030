import { useContext, useEffect, useState } from 'react';
import { HeaderComponent } from '../../components/OrganizationPageGroupComponents/HeaderComponent';
import Button from '../../components/Button/Button';
import ButtonCircle from '../../components/Button/ButtonCircle';
import { formsService } from '../../services/formsService';
import { BaseTable } from '../../components/Table/BaseTable/BaseTable';
import { CheckBoxHeaderTable } from '../../components/OrganizationPageGroupComponents/CheckBoxHeaderTable';
import { RowBody } from '../../components/Table/RowBody/RowBody';
import { RowCell } from '../../components/Table/RowCell/RowCell';
import { CheckBoxBodyTable } from '../../components/OrganizationPageGroupComponents/CheckBoxBodyTable';
import Label from '../../components/Label/Label';
import { formatDateDDMMYYYY } from '../../utils/dates/formatDateDDMMYYYY';
import { LabelFunction } from '../../components/LabelFunction/LabelFunction';
import Icon from '../../components/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/Index.routes';
import ItemLabel from '../../components/ItemDnD/ItemDnD';
import { DropResult } from 'react-beautiful-dnd';
import ModalAlert from '../../components/Modal/ModalAlert';
import { Input } from '../../components/Input/Input';
import DragDrop from '../../components/DragDrop/DragDop';
import Accordion from '../../components/Accordion/Accordion';
import Dropdown from '../../components/Dropdown/Dropdown';
import TodoList from '../../components/TodoList/TodoList';
import { FormDrawContext } from '../../contexts/FormDrawContext';
import { number } from 'zod';
import ListOption from '../../components/ListOption/ListOption';
import moment from 'moment';
import Tabs from '../../components/Tabs/Tabs';

interface IInventoryPageProps { }

export const InventoryPage: React.FC<IInventoryPageProps> = () => {
    const navigate = useNavigate();

    const openInventoryForm = (
        formId: string,
        companyId: string,
        status: string,
    ) => {
        navigate(ROUTES.FORM_REVIEW.path, { state: { formId, companyId, status } });
    };

    const { formsJSON, setFormsJSON, getForm } = useContext(FormDrawContext);
    const [inventories, setInventories] = useState<IPagination<IInventoryInfo>>();
    const [showModalNewSection, setShowModalNewSection] =
        useState<boolean>(false);
    const [showModalNewQuestion, setShowModalNewQuestion] =
        useState<boolean>(false);
    const [steps, setSteps] = useState<IFormStep[]>([]);
    const [selectedStep, setSelectedStep] = useState<IOption | null>(null);
    const [selectedQuestion, setSelectedQuestion] = useState<IOption | null>(
        null,
    );
    const [selectedOptional, setSelectedOptional] = useState<IOption | null>(
        null,
    );
    const [selectedFormat, setSelectedFormat] = useState<IOption | null>(null);
    const [newStepName, setNewStepName] = useState('');
    const [insertedQuestion, setInsertedQuestion] = useState('');
    const [decimalPlaces, setDecimalPlaces] = useState('');
    const [questionItems, setQuestionItems] = useState<IFormQuestionOptions[]>(
        [],
    );
    const [selectedItem, setSelectedItem] = useState<string | null>(null);

    const openNewSection = () => {
        setShowModalNewSection(true);
    };
    const [modalPage, setModalPage] = useState(0);
    const openAddQuestion = () => {
        setShowModalNewQuestion(true);
    };

    useEffect(() => {
        getForm();
    }, []);

    useEffect(() => {
        if (formsJSON && formsJSON.steps) {
            setSteps(formsJSON.steps);
        }
    }, [formsJSON]);

    const handleOnDragEndSteps = (result: DropResult) => {
        if (!result.destination) return;
        const reorderedsteps = [...steps];
        const [reorderedSection] = reorderedsteps.splice(result.source.index, 1);
        reorderedsteps.splice(result.destination.index, 0, reorderedSection);

        setSteps(reorderedsteps);

        reorderedStepsAndSave(reorderedsteps);
    };

    const reorderedStepsAndSave = (steps: IFormStep[]) => {
        const reordered = steps.map((step, index) => {
            return {
                ...step,
                number: index + 1,
            };
        });

        if (formsJSON) {
            formsJSON.steps = reordered;
            saveForm(formsJSON);
            setSteps(formsJSON.steps);
        }
    };

    const handleOnDragEndQuestions = (result: DropResult, step: IFormStep) => {
        if (!result.destination) return;
        const reorderedQuestions = [...step.questions];
        const [reorderedQuestion] = reorderedQuestions.splice(
            result.source.index,
            1,
        );
        reorderedQuestions.splice(result.destination.index, 0, reorderedQuestion);

        reorderedQuestionsAndSave(reorderedQuestions, step);
    };

    const reorderedQuestionsAndSave = (
        questions: IFormQuestion[],
        step: IFormStep,
    ) => {
        const reordered = questions.map((question, index) => ({
            ...question,
            number: index + 1,
        }));

        if (formsJSON) {
            formsJSON.steps.forEach((step2, index) => {
                if (step2.number === step.number) {
                    formsJSON.steps[index].questions = reordered;
                }
            });

            saveForm(formsJSON);

            setSteps(formsJSON.steps);
        }
    };

    const ddSteps = steps.map((step) => ({
        key: step.number.toString(),
        value: step.description,
    }));

    const selectedStepQuestions: IFormQuestion[] =
        steps.find(
            (step) =>
                step.number === (selectedStep?.key ? parseInt(selectedStep.key) : 0),
        )?.questions || [];

    const ddQuestions: IOption[] = selectedStepQuestions.map((question) => ({
        key: question.number.toString(),
        value: question.description,
    }));

    const required: IOption[] = [
        { key: 'SIM', value: 'Sim' },
        { key: 'NAO', value: 'Não' },
    ];

    const formats: IOption[] = [
        { key: 'TEXT', value: 'Texto' },
        { key: 'SELECT', value: 'Seleção' },
        { key: 'MULTIPLE_SELECT', value: 'Multípla seleção' },
        { key: 'DATE', value: 'Data' },
        { key: 'INTEGER', value: 'Número inteiro' },
        { key: 'DOUBLE', value: 'Número decimal' },
        { key: 'FILE_UPLOAD_URL', value: 'Arquivos a serem anexados' },
    ];

    const handleStepClick = (option: IOption) => {
        setSelectedStep(option);
    };

    const handleQuestionClick = (option: IOption) => {
        setSelectedQuestion(option);
    };
    const handleOptionalClick = (option: IOption) => {
        setSelectedOptional(option);
    };
    const handleFormatClick = (option: IOption) => {
        setSelectedFormat(option);
    };

    const generateName = (text: string) => {
        return text?.replace(/ /g, '_');
    };

    const saveForm = async (form: IFormJSON) => {
        try {
            const response = await formsService.createFormDraw(form);
            setFormsJSON(response);
        } catch (error) {
            console.log('Unable to save form');
        }
    };

    const publishForm = async () => {
        try {
            if (formsJSON && formsJSON.id && typeof formsJSON.id === 'string') {
                const response = await formsService.publishFormDraw(formsJSON.id);
                setFormsJSON(response);
            } else {
                console.log('Invalid formsJSON.id');
            }
        } catch (error) {
            console.log('Unable to publish form');
        }
    };

    const saveNewStep = () => {
        if (newStepName.trim() !== '') {
            if (formsJSON) {
                const stepsCopy = [...formsJSON.steps];
                const newStep: IFormStep = {
                    number: formsJSON.steps.length ? formsJSON.steps.length + 1 : 1,
                    name: generateName(newStepName),
                    description: newStepName,
                    questions: [],
                };

                stepsCopy.push(newStep);

                const updatedFormsJSON = { ...formsJSON, steps: stepsCopy };

                saveForm(updatedFormsJSON);
                setSteps(stepsCopy);
                setNewStepName('');
            }
        }
    };

    function chosenType(type: string) {
        switch (type) {
            case 'SELECT':
            case 'MULTIPLE_SELECT':
                return (
                    <>
                        <Label className="mb-2" color="font-bold">
                            Adicione Opções
                        </Label>
                        <TodoList
                            options={questionItems}
                            onItemsChange={handleQuestionItemsChange}
                        />
                    </>
                );
            case 'INTEGER':
            case 'DOUBLE':
                return (
                    <>
                        <Label className="mb-2" color="font-bold">
                            Insira quantas casas decimais
                        </Label>
                        <Input
                            placeholder="Exemplo: 1000000000."
                            value={decimalPlaces}
                            onChange={(e) => setDecimalPlaces(e.target.value)}
                        />
                    </>
                );
            case 'TEXT':
            case 'DATE':
            case 'FILE_UPLOAD_URL':
                return <></>;
        }
    }

    const handleQuestionItemsChange = (items: IFormQuestionOptions[]) => {
        setQuestionItems(items);
    };

    const typeValidation = (): IFormValidation => {
        switch (selectedFormat?.key) {
            case 'SELECT':
            case 'MULTIPLE_SELECT':
                return {
                    type: selectedFormat?.key,
                    required: selectedOptional?.key === 'SIM',
                    editable: true,
                    options: questionItems.map((item) => item.key),
                };
            case 'INTEGER':
            case 'DOUBLE':
                return {
                    type: selectedFormat?.key,
                    required: selectedOptional?.key === 'SIM',
                    editable: true,
                    minValue: 0,
                    maxValue: parseFloat(decimalPlaces) || 0,
                };
            case 'DATE':
                return {
                    type: selectedFormat?.key,
                    required: selectedOptional?.key === 'SIM',
                    editable: true,
                };
            case 'FILE_UPLOAD_URL':
                return {
                    type: 'FILE_UPLOAD_URL',
                    required: true,
                    editable: true,
                };
            default:
                return {
                    type: 'TEXT',
                    required: true,
                    editable: true,
                    size: 5000,
                };
        }
    };

    const saveNewQuestion = () => {
        if (selectedStep && insertedQuestion.trim() !== '') {
            formsJSON?.steps.map((step) => {
                if (step.number === parseInt(selectedStep.key)) {
                    const newQuestion: IFormQuestion = {
                        number: step.questions.length ? step.questions.length + 1 : 1,
                        name: `${step.number}_${step.questions.length ? step.questions.length + 1 : 1
                            }`,
                        description: insertedQuestion,
                        observations: '',
                        validation: typeValidation(),
                    };

                    if (
                        selectedFormat?.key === 'SELECT' ||
                        selectedFormat?.key === 'MULTIPLE_SELECT'
                    ) {
                        newQuestion.options = questionItems;
                    }

                    step.questions.push(newQuestion);
                }
                return step;
            });

            if (formsJSON) {
                setSteps(formsJSON?.steps);
                saveForm(formsJSON);
            }
            setInsertedQuestion('');
            setShowModalNewQuestion(false);
        }
    };

    const handleClick = (itemId: string) => {
        setSelectedItem(itemId === selectedItem ? null : itemId);
    };

    const handleApproveInventory = async (formId: string) => {
        try {
            const answersObject: { [key: string]: Object | null } = {};
            await formsService.newFinishInventoryReview(formId, "APPROVED", answersObject);
    
            formsService.getAllForms().then((response) => {
                setInventories(response);
            });
        } catch (error) {
            console.error('Unable to approve inventory:', error);
        }
    };
    

    const tabsData = [
        {
            label: 'Gestão de Solicitações',
            icon: 'document',
            hasHeader: true,
            headerButtons: (
                <div>
                    {/* <ButtonCircle
            style="outlined"
            icon="filter"
            iconPosition="center"
          ></ButtonCircle> */}
                </div>
            ),
            content: (
                <section className="m-6 md:m-10 border border-gray-200 rounded-md overflow-x-scroll md:overflow-hidden">
                    <BaseTable
                        headerTitlesComponents={[
                            <CheckBoxHeaderTable titleLabel={'Organização Inventariante'} />,
                            'Data de envio',
                            'Status',
                            '',
                        ]}
                        childrensLength={inventories?.totalElements ?? 0}
                    >
                        {inventories?.content
                            .filter((inventory) => inventory.status !== 'FILLING')
                            .map((inventory) => {
                                return (
                                    <RowBody key={inventory.formId}>
                                        <RowCell>
                                            <CheckBoxBodyTable
                                                name={inventory.companyName}
                                                cnpj={inventory.cnpj}
                                                onClick={() => {
                                                    openInventoryForm(
                                                        inventory.formId,
                                                        inventory.companyId,
                                                        inventory.status,
                                                    );
                                                }}
                                            />
                                        </RowCell>
                                        <RowCell>
                                            <Label className="!font-normal">
                                                {moment(inventory.createdDate).format('DD/MM/YYYY')}
                                            </Label>
                                        </RowCell>
                                        <RowCell>
                                            <div className="flex items-end justify-between h-10">
                                                <LabelFunction
                                                    type={setInventoryStatusBadge(inventory.status)}
                                                />
                                            </div>
                                        </RowCell>
                                        <RowCell>
                                            <div className="flex items-center space-x-4">
                                                <button
                                                    className="h-full"
                                                    onClick={() => handleClick(inventory.formId ?? '')}
                                                >
                                                    <Icon name="vertical-dot-gray-400" />
                                                </button>
                                                <ListOption visible={inventory.formId === selectedItem} onClose={() => setSelectedItem(null)}>
                                                    {(inventory.status === 'APPROVED' ||
                                                        inventory.status === 'REVISION') && (
                                                            <button
                                                                className="p-5 font-bold text-left justify-start"
                                                                onClick={() => {
                                                                    openInventoryForm(
                                                                        inventory.formId,
                                                                        inventory.companyId,
                                                                        inventory.status,
                                                                    );
                                                                }}
                                                            >
                                                                Visualizar
                                                            </button>
                                                        )}

                                                    {inventory.status === 'PENDING' && (
                                                        <div className="flex flex-col gap-5 p-5">
                                                            <button
                                                                className="p-5 font-bold text-left justify-start"
                                                                onClick={() => {
                                                                    openInventoryForm(
                                                                        inventory.formId,
                                                                        inventory.companyId,
                                                                        inventory.status,
                                                                    );
                                                                }}
                                                            >
                                                                Corrigir
                                                            </button>
                                                            <button
                                                                className="p-5 font-bold text-left justify-start"
                                                                onClick={() => {
                                                                    handleApproveInventory(inventory.formId)
                                                                }}
                                                            >
                                                                Aprovar
                                                            </button>
                                                        </div>
                                                    )}
                                                </ListOption>
                                            </div>
                                        </RowCell>
                                    </RowBody>
                                );
                            })}
                    </BaseTable>
                </section>
            ),
        },

        // {
        //   label: 'Editar Seções',
        //   icon: 'edit',
        //   hasHeader: true,
        //   headerButtons: (
        //     <>
        //       <div className="w-60">
        //         <Button
        //           label={'Adicionar seção'}
        //           icon={'plus-light-blue-200'}
        //           style="outlined"
        //           onClick={openNewSection}
        //         />
        //       </div>
        //       <div className="w-60">
        //         <Button
        //           label={'Salvar alterações'}
        //           icon={'save-disket-white-100'}
        //           style="filled"
        //           onClick={publishForm}
        //         />
        //       </div>
        //     </>
        //   ),
        //   content: (
        //     <>
        //       <DragDrop
        //         items={steps}
        //         handleOnDragEnd={handleOnDragEndSteps}
        //         renderItem={(item, index) => <ItemLabel step={item}></ItemLabel>}
        //       />

        //       <ModalAlert
        //         visible={showModalNewSection}
        //         hasButtonClose={true}
        //         onClose={() => setShowModalNewSection(false)}
        //       >
        //         <section className="w-[610px] p-10 flex flex-col steps-start">
        //           <div className="pb-2.5">
        //             <Label size="xl" className="font-bold">
        //               Inventários
        //             </Label>
        //           </div>
        //           <div className="pb-8 border-b border-gray-200 w-full">
        //             <Label color="text-gray-400">
        //               Seção{' '}
        //               {steps.length > 0 ? steps[steps.length - 1].number + 1 : 1}
        //             </Label>
        //           </div>
        //           <div className="pt-8 pb-5 border-b border-gray-100">
        //             <Label color="font-bold">Seção</Label>
        //           </div>
        //           <div className="w-full pb-8">
        //             <Input
        //               placeholder="Digite o nome da seção"
        //               value={newStepName}
        //               onChange={(e) => setNewStepName(e.target.value)}
        //             ></Input>
        //           </div>
        //           <div className="flex flex-row steps-center justify-center gap-2.5 w-full">
        //             <div className="w-full">
        //               <Button
        //                 style="filled"
        //                 label="Continuar"
        //                 type="button"
        //                 onClick={() => {
        //                   setShowModalNewSection(false);
        //                   saveNewStep();
        //                 }}
        //               />
        //             </div>
        //           </div>
        //         </section>
        //       </ModalAlert>
        //     </>
        //   ),
        // },

        // {
        //   label: 'Editar Perguntas',
        //   icon: 'edit',
        //   hasHeader: true,
        //   headerButtons: (
        //     <>
        //       <div className="w-60">
        //         <Button
        //           label={'Adicionar pergunta'}
        //           icon={'plus-light-blue-200'}
        //           style="outlined"
        //           onClick={() => {
        //             openAddQuestion();
        //             setModalPage(0);
        //           }}
        //         />
        //       </div>
        //       <div className="w-60">
        //         <Button
        //           label={'Salvar alterações'}
        //           icon={'save-disket-white-100'}
        //           style="filled"
        //           onClick={publishForm}
        //         />
        //       </div>
        //     </>
        //   ),
        //   content: (
        //     <>
        //       <div>
        //         <ul>
        //           {steps.map((section) => (
        //             <Accordion title={section.description}>
        //               <DragDrop
        //                 items={section.questions}
        //                 handleOnDragEnd={(result) =>
        //                   handleOnDragEndQuestions(result, section)
        //                 }
        //                 renderItem={(item, index) => (
        //                   <ItemLabel question={item} step={section}></ItemLabel>
        //                 )}
        //               />
        //             </Accordion>
        //           ))}
        //         </ul>
        //       </div>

        //       <ModalAlert
        //         visible={showModalNewQuestion}
        //         hasButtonClose={true}
        //         onClose={() => setShowModalNewQuestion(false)}
        //       >
        //         <section className="w-[610px] p-10 flex flex-col steps-start max-h-[600px] overflow-y-auto">
        //           {modalPage === 0 && (
        //             <>
        //               <div className="pb-2.5">
        //                 <Label
        //                   size="xl"
        //                   className="font-bold pb-8 border-b border-gray-200 w-full"
        //                 >
        //                   Adicionar Pergunta
        //                 </Label>
        //               </div>
        //               <div className="py-2">
        //                 <Label className="mb-2" color="font-bold">
        //                   Seção
        //                 </Label>
        //                 <Dropdown
        //                   options={ddSteps}
        //                   icon="search-gray-400"
        //                   label="Selecione a seção."
        //                   selectedOption={selectedStep}
        //                   handleOptionClick={handleStepClick}
        //                 ></Dropdown>
        //               </div>
        //               <div className="py-4">
        //                 <Label className="mb-2" color="font-bold">
        //                   Pergunta
        //                 </Label>
        //                 <Input
        //                   placeholder="Digite sua pergunta aqui."
        //                   value={insertedQuestion}
        //                   onChange={(e) => setInsertedQuestion(e.target.value)}
        //                 />
        //               </div>
        //               {/* <div className="py-2">
        //                             <Label className='mb-2' color="font-bold">
        //                                 Associar a outra pergunta (opcional)
        //                             </Label>
        //                             <Dropdown
        //                                 options={ddQuestions}
        //                                 icon="search-gray-400"
        //                                 label="Selecione a questão associada."
        //                                 selectedOption={selectedQuestion}
        //                                 handleOptionClick={handleQuestionClick}
        //                             ></Dropdown>
        //                         </div>*/}
        //               <div className="py-2">
        //                 <Label className="mb-2" color="font-bold">
        //                   Opcional
        //                 </Label>
        //                 <Dropdown
        //                   options={required}
        //                   icon="search-gray-400"
        //                   label="Selecione a obrigatoriedade da pergunta."
        //                   selectedOption={selectedOptional}
        //                   handleOptionClick={handleOptionalClick}
        //                 ></Dropdown>
        //               </div>
        //               <div className="flex flex-row steps-center justify-center gap-2.5 w-full pt-4">
        //                 <div className="w-full">
        //                   <Button
        //                     style="filled"
        //                     label="Continuar"
        //                     type="button"
        //                     onClick={() => {
        //                       setModalPage(1);
        //                     }}
        //                   />
        //                 </div>
        //               </div>
        //             </>
        //           )}{' '}
        //           {modalPage === 1 && (
        //             <>
        //               <div className="py-2">
        //                 <Label className="mb-2" color="font-bold">
        //                   Formato do dado
        //                 </Label>
        //                 <Dropdown
        //                   options={formats}
        //                   icon="search-gray-400"
        //                   label="Selecione o formato do dado."
        //                   selectedOption={selectedFormat}
        //                   handleOptionClick={handleFormatClick}
        //                 ></Dropdown>
        //               </div>
        //               <div className="py-2">
        //                 <div className="py-2">
        //                   {typeof selectedFormat?.key === 'string'
        //                     ? chosenType(selectedFormat.key)
        //                     : null}
        //                 </div>
        //               </div>
        //               <div className="flex flex-row steps-center justify-center gap-2.5 w-full">
        //                 <div className="w-full">
        //                   <Button
        //                     style="filled"
        //                     label="Finalizar"
        //                     type="button"
        //                     onClick={() => {
        //                       saveNewQuestion();
        //                     }}
        //                   />
        //                 </div>
        //               </div>
        //             </>
        //           )}
        //         </section>
        //       </ModalAlert>
        //     </>
        //   ),
        // },
    ];

    function setInventoryStatusBadge(status: TInventoryStatus): string {
        let currentStatus: string = status;

        if (status === 'PENDING') return 'PENDING_ADM';

        if (status === 'REVISION') return 'REVISION_ADM';

        return currentStatus;
    }

    useEffect(() => {
        formsService.getAllForms().then((response) => {
            setInventories(response);
        });
    }, []);

    return (
        <>
            <Tabs tabsData={tabsData}></Tabs>
        </>
    );
};

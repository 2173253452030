import moment from 'moment';

const getDiffDate = (date: string): string => {
  const currentDate = moment();
  const notificationDate = moment(date);

  let diffNumber = 0;
  let unitTime = 'pouco';

  const diffWeeks = currentDate.diff(notificationDate, 'weeks');
  const diffDays = currentDate.diff(notificationDate, 'days');
  const diffHours = currentDate.diff(notificationDate, 'hours');
  const diffMinutes = currentDate.diff(notificationDate, 'minutes');

  if (diffWeeks > 0) {
    diffNumber = diffWeeks;
    if (diffWeeks === 1) {
      unitTime = 'semana';
    } else {
      unitTime = 'semanas';
    }
  } else if (diffDays > 0) {
    diffNumber = diffDays;
    if (diffDays === 1) {
      unitTime = 'dia';
    } else {
      unitTime = 'dias';
    }
  } else if (diffHours > 0) {
    diffNumber = diffHours;
    if (diffHours === 1) {
      unitTime = 'hora';
    } else {
      unitTime = 'horas';
    }
  } else if (diffMinutes > 0) {
    diffNumber = diffMinutes;
    unitTime = 'min';
  } else {
    diffNumber = 0;
    unitTime = 'pouco';
  }

  return `Há ${diffNumber !== 0 ? diffNumber : ''} ${unitTime}`;
};

export default getDiffDate;

import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';
import { error } from 'console';

interface DropdownProps {
  options: IOption[];
  icon?: string;
  label: string;
  selectedOption: IOption | null;
  handleOptionClick: (option: IOption) => void;
  registerDropdown?: any;
  error?: boolean;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  icon,
  label,
  selectedOption,
  handleOptionClick,
  error,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const optionClick = (option: IOption) => {
    handleOptionClick(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        className={`w-full h-[60px] relative left-0 p-5 flex items-center justify-between text-gray-400 border-2 border-solid ${
          error ? 'border-red-200' : 'border-gray-200'
        } hover:bg-gray-100`}
        onClick={toggleDropdown}
        disabled={disabled}
      >
        {icon && <Icon name={icon}></Icon>}
        <Label
          color={`${selectedOption ? 'text-black-300' : 'text-gray-300'}`}
          className={`absolute flex left-0 ml-14 ${
            !selectedOption && '!font-normal !italic'
          }`}
        >
          {selectedOption ? selectedOption.value : label}
        </Label>
        <div className={`transition-transform ${isOpen ? 'rotate-180' : ''}`}>
          <Icon name={'arrow-down-blue-200'}></Icon>
        </div>
      </button>
      {isOpen && (
        <ul className="absolute z-50 top-16 left-0 right-0 shadow-lg">
          {options.map((option) => (
            <li key={option.key}>
              <button
                className={`relative w-full h-[50px] p-5 flex items-center z-50 font-semibold text-sm border-[1px] border-solid border-gray-200 ${
                  option.key === selectedOption?.key
                    ? 'bg-blue-200 text-white-100 hover:bg-blue-300'
                    : 'bg-white-100 text-gray-400 hover:bg-gray-200'
                }`}
                onClick={() => optionClick(option)}
              >
                {option.value}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

import React, { useState, ChangeEvent, TextareaHTMLAttributes } from 'react';
import { InputMessage } from '../InputMessage/InputMessage';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string;
  required?: boolean;
  registerTextArea?: any;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  updateHasInput?: (hasInput: boolean) => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  placeholder,
  required = false,
  registerTextArea,
  onChange,
  updateHasInput,
  ...rest
}) => {
  const [hasInput, setHasInput] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);

    if (required && updateHasInput) {
      setHasInput(event.target.value !== '');
      updateHasInput(event.target.value !== '');
    }
  };

  return (
    <>
      <textarea
        className="resize-none border-2 border-gray-200 focus:border-blue-200 hover:bg-gray-100 rounded-base w-full h-[126px] !text-black-300 placeholder:italic placeholder:text-gray-300 py-4 px-4 "
        placeholder={placeholder}
        onChange={handleInputChange}
        {...registerTextArea}
        {...rest}
      />
      <div className="mt-2.5 w-fit">
        <div className="mb-2.5">
          {required && !hasInput && <InputMessage state={'danger'} />}
        </div>
      </div>
    </>
  );
};

export default TextArea;

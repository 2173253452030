import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';
import Checkbox from '../Checkbox/Checkbox';

const stateStyle = {
  warning: 'border-yellow-200',
  danger: 'border-red-200',
  primary: '',
  success: '',
};

interface DropdownSingleSelectProps {
  options: IOption[];
  icon?: string;
  handleOptionClick: (option: IOption | null) => void;
  registerInput?: any;
  isValid?: boolean;
  errorLabel?: string;
  state?: IStateClasses;
  getValues?: any;
  name?: string;
  reviewLabel?: string;
  handleKeyPress?: (event: React.KeyboardEvent) => void;
  disabled?: boolean;
  labelCharLimit?: number;
  placeholder?: string;
  wrapText?: boolean;
}

const DropdownSingleSelect: React.FC<DropdownSingleSelectProps> = ({
  options,
  icon,
  handleOptionClick,
  registerInput,
  isValid,
  errorLabel,
  state,
  getValues,
  name,
  reviewLabel,
  handleKeyPress,
  disabled,
  labelCharLimit,
  placeholder,
  wrapText = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [typedValue, setTypedValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   if (isOpen) return;

  //   setTypedValue('');
  // }, [isOpen]);

  // useEffect(() => {
  //   setSelectedOption(selectedOption);
  // }, [setSelectedOption, selectedOption]);

  useEffect(() => {
    if (typeof getValues(name) === 'object') {
      if (getValues(name)) {
        let values = getValues(name) as IOption;
        if (
          values !== undefined &&
          values.key !== undefined &&
          values.key !== 'Todos'
        ) {
          setSelectedOption(values);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (getValues(name)) {
      const selectedKeysString = getValues(name);
      if (typeof selectedKeysString === 'string') {
        const selectedKeysArray = selectedKeysString.split(';');
        let updatedSelectedOptions = options.filter((selectedOption) =>
          selectedKeysArray.includes(selectedOption.key as string),
        );
        // setSelectedOption(updatedSelectedOptions);
      }
    }
  }, [getValues, name]);

  const handleOptionSelect = (option: IOption) => {
    setSelectedOption(option);
    handleOptionClick(option);
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTypedValue(e.target.value);
    setIsOpen(true);
  };

  const filteredOptions = options.filter((option) =>
    option.value.toLowerCase().includes(typedValue.toLowerCase()),
  );

  return (
    <div className="relative">
      <button
        className={`w-full h-[60px] relative left-0 p-5 flex items-center justify-between cursor-text text-gray-400 border-2 border-solid border-gray-200 hover:bg-gray-100 group
        ${state ? stateStyle[state] : 'border-gray-200 focus:border-blue-200'}
        ${
          selectedOption
            ? 'border-blue-200'
            : 'border-gray-200 focus:border-blue-200'
        }
        ${disabled ? 'bg-gray-200' : ''}
        `}
        onClick={() => {
          if (!isOpen) toggleDropdown();
        }}
        type="button"
        disabled={disabled}
      >
        <Icon name="search-gray-400" />
        {!isOpen ? (
          <Label
            color={selectedOption ? 'text-black-300' : 'text-gray-300'}
            className={`absolute flex left-0 ml-16 ${
              !selectedOption ? '!font-normal !italic' : ''
            }`}
          >
            {selectedOption
              ? selectedOption.value
              : placeholder || 'Select an option'}
          </Label>
        ) : (
          <input
            type="text"
            className="w-full p-2 border-0 focus:outline-none group-hover:bg-gray-100"
            placeholder={
              placeholder ? placeholder : 'Digite para filtrar as opções'
            }
            onChange={handleInputChange}
            value={typedValue}
          />
        )}
        <input type="text" className="hidden" {...registerInput} />
        <button
          type="button"
          onClick={toggleDropdown}
          className={`transition-transform ${isOpen ? 'rotate-180' : ''}`}
        >
          <Icon name={'arrow-down-blue-200'}></Icon>
        </button>
      </button>
      {isOpen && (
        <ul className="absolute z-50 top-16 left-0 right-0 max-h-60 overflow-x-auto bg-white shadow-lg">
          {filteredOptions.map((option) => (
            <li key={option.key}>
              <button
                className={`relative w-full h-[50px] p-5 flex items-center z-50 font-semibold text-sm border-[1px] border-solid border-gray-200 ${
                  selectedOption?.key === option.key
                    ? 'bg-blue-200 text-white-100 hover:bg-blue-300'
                    : 'bg-white-100 text-gray-400 hover:bg-gray-200'
                }`}
                onClick={() => handleOptionSelect(option)}
                type="button"
              >
                <Checkbox
                  isChecked={selectedOption?.key === option.key}
                  handleChange={() => handleOptionSelect(option)}
                />
                {option.value}
              </button>
            </li>
          ))}
        </ul>
      )}
      <input type="text" className="hidden" {...registerInput} />
    </div>
  );
};

export default DropdownSingleSelect;

const findCnae = async () => {
    return await fetch(
      `https://servicodados.ibge.gov.br/api/v2/cnae/classes`,
      {
        method: 'GET',
      },
    )
      .then((response) => response.json())
      .then((responseData) => responseData)
      .catch((error) => console.warn(error));
  };
  
  export const cnaeService = {
    findCnae
  }
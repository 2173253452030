import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";

interface OrganizationContextType {
    orgUsers: any[];
    setOrgUsers: (orgUsers: any[]) => void;
    selectedOrgId: string;
    setSelectedOrgId: (selectedOrgId: string) => void;
}

interface OrganizationProviderProps {
    children: React.ReactNode;
}

export const OrganizationContext = createContext<OrganizationContextType>({
    orgUsers: [],
    setOrgUsers: (orgUsers: any[]) => {},
    selectedOrgId: '',
    setSelectedOrgId: (selectedOrgId: string) => {}
})

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
}) => {
  const [orgUsers, setOrgUsers] = useState<any[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const { session } = useContext(AuthContext);

  useEffect(()=>{
    if(session !== null) return;
    setSelectedOrgId('');
    setOrgUsers([]);
  },[session])

  return (
    <OrganizationContext.Provider
      value={{ orgUsers, selectedOrgId, setOrgUsers, setSelectedOrgId }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
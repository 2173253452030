import React, { useState } from 'react';
import Button from '../Button/Button';
import Label from '../Label/Label';
import { AddInventoryModal } from '../Modal/AddInventoryModal';
import { BaseTable } from '../Table/BaseTable/BaseTable';
import { CheckBoxHeaderTable } from './CheckBoxHeaderTable';
import { RowBody } from '../Table/RowBody/RowBody';
import { RowCell } from '../Table/RowCell/RowCell';
import { CheckBoxBodyTable } from './CheckBoxBodyTable';
import { LabelFunction } from '../LabelFunction/LabelFunction';
import Icon from '../Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/Index.routes';
import ListOption from '../ListOption/ListOption';
import ModalAlert from '../Modal/ModalAlert';
import { formsService } from '../../services/formsService';
import moment from 'moment';
import FloatingButton from '../FloatingButton/FloatingButton';
import { LoadingForm } from '../Loading/LoadingForm';

interface OrganizationInventoriesProps {
    inventories: IPagination<IInventoryInfo>;
    updateInventories: () => void;
}

export const OrganizationInventories: React.FC<
    OrganizationInventoriesProps
> = ({
    inventories = { totalElements: 0, content: [] },
    updateInventories,
}) => {
        const [showAddInventory, setShowAddInventory] = useState<boolean>(false);

        const navigate = useNavigate();

        const [selectedItem, setSelectedItem] = useState<IInventoryInfo | null>(null);

        const [showModalHasInventory, setShowModalHasInventory] = useState(false);

        const handleClick = (itemId: IInventoryInfo) => {
            setSelectedItem(itemId === selectedItem ? null : itemId);
        };

        const [showConfirmationModal, setShowConfirmationModal] =
            useState<boolean>(false);

        const openInventoryForm = (formId: string, status: TInventoryStatus) => {
            navigate(ROUTES.INVENTORY_FORM.path, { state: { formId, status } });
        }

        const deleteForm = (id: string) => {
            formsService.deleteFormsById(id).then(() => {
                setShowConfirmationModal(false);
                updateInventories();
            });
        };

        const hasInventory = () => {
            if (inventories?.totalElements !== undefined && inventories?.content) {
                const inventoryDates = inventories.content.map(
                    (inventory: IInventoryInfo) => inventory.createdDate,
                );
                if (inventoryDates.length > 0) {
                    const lastInventoryDate = inventoryDates.reduce(
                        (maxDate, currentDate) =>
                            currentDate > maxDate ? currentDate : maxDate,
                    );
                    if (isYearPassed(lastInventoryDate)) {
                        setShowModalHasInventory(false);
                    } else {
                        setShowModalHasInventory(true);
                    }
                }
            }
        };

        const isYearPassed = (date: Date) => {
            const nextYearDate = new Date(date);
            nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

            return date >= nextYearDate;
        };

        const options = [
            {
                label: 'Adicionar inventário', onClick:

                    () => {
                        hasInventory();
                        if (inventories?.totalElements === 0 ?? true)
                            setShowAddInventory(true)
                    }, icon: 'plus-light-blue-200'
            },
        ];

        return (
            <>
                {inventories && inventories.totalElements === 0 ? (
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        <div className='mt-[260px] w-full h-full flex flex-col items-center justify-center'>
                            <Label size="xl" className="!font-bold" color="text-black-400">
                                Ainda não há inventários.
                            </Label>
                            <Label className="mt-2.5 text-center" color="text-gray-400">
                                Adicione inventários para visualizar essas informações.
                            </Label>
                        </div>
                        <div className="mt-10 w-[223px]">
                            <Button
                                style="filled"
                                label="Adicionar inventário"
                                icon="plus-light-white-100"
                                onClick={() => {
                                    setShowAddInventory(true)
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="m-10 border border-gray-200 rounded-md overflow-x-scroll md:overflow-hidden">
                        <BaseTable
                            headerTitlesComponents={[
                                <CheckBoxHeaderTable titleLabel={'Organização Inventariante'} />,
                                'Enviado por',
                                'Data de envio',
                                'Status',
                                '',
                            ]}
                            childrensLength={inventories.totalElements ?? 0}
                        >
                            {inventories.content.map((inventory) => {
                                return (
                                    <RowBody key={inventory.formId}>
                                        <RowCell>
                                            <CheckBoxBodyTable
                                                name={inventory.companyName}
                                                cnpj={inventory.cnpj}
                                                onClick={() => openInventoryForm(inventory.formId, inventory.status)}
                                            />
                                        </RowCell>
                                        <RowCell>
                                            <Label className="max-w-[150px] truncate !font-normal">
                                                {inventory.responsibleUser ?? '-'}
                                            </Label>
                                        </RowCell>
                                        <RowCell>
                                            <Label className="!font-normal">
                                                {moment(inventory.createdDate).format('DD/MM/YYYY')}
                                            </Label>
                                        </RowCell>
                                        <RowCell>
                                            <div className="flex items-end justify-between h-10">
                                                <LabelFunction type={inventory.status} />
                                            </div>
                                        </RowCell>
                                        <RowCell>
                                            <div className="flex items-center md:space-x-4">
                                                <button
                                                    className="h-full"
                                                    onClick={() => handleClick(inventory)}
                                                >
                                                    <Icon name="vertical-dot-gray-400" />
                                                </button>
                                                <ListOption
                                                    visible={inventory.formId === selectedItem?.formId}
                                                    onClose={() => setSelectedItem(null)}
                                                >
                                                    {(inventory.status === 'PENDING' ||
                                                        inventory.status === 'APPROVED') && (
                                                            <button
                                                                className="p-5 font-bold text-left justify-start"
                                                                onClick={() => {
                                                                    openInventoryForm(inventory.formId, inventory.status);
                                                                }}
                                                            >
                                                                Visualizar
                                                            </button>
                                                        )}

                                                    {inventory.status === 'REVISION' && (
                                                        <button
                                                            className="p-5 font-bold text-left justify-start"
                                                            onClick={() => {
                                                                openInventoryForm(inventory.formId, inventory.status);
                                                            }}
                                                        >
                                                            Editar
                                                        </button>
                                                    )}

                                                    {inventory.status === 'FILLING' && (
                                                        <div className="flex flex-col gap-5 p-5">
                                                            <button
                                                                className="font-bold text-left justify-start"
                                                                onClick={() => {
                                                                    openInventoryForm(inventory.formId, inventory.status);
                                                                }}
                                                            >
                                                                Editar
                                                            </button>
                                                            <button
                                                                className=" font-bold text-left justify-start"
                                                                onClick={() => {
                                                                    setShowConfirmationModal(true);
                                                                }}
                                                            >
                                                                Remover
                                                            </button>
                                                        </div>
                                                    )}
                                                </ListOption>
                                            </div>
                                        </RowCell>
                                    </RowBody>
                                );
                            })}
                        </BaseTable>
                    </div>
                )}
                <div className='block md:hidden'>
                    <FloatingButton style="filled" options={options} />
                </div>

                <AddInventoryModal
                    visible={showAddInventory}
                    closeModal={() => setShowAddInventory(false)}
                />

                <ModalAlert visible={showConfirmationModal}>
                    <section className="w-[610px] p-10 flex flex-col items-center">
                        <button
                            className="absolute top-[19px] right-[19px] border-none bg-none"
                            onClick={() => {
                                setShowConfirmationModal(false);
                            }}
                        >
                            <Icon name="close-blue-200" size="sm" />
                        </button>
                        <Label size="md" className="font-bold">
                            Tem certeza que deseja remover o inventário?
                        </Label>

                        <div className="w-65 pt-8">
                            <Button
                                style="filled"
                                label="Confirmar"
                                onClick={() => {
                                    deleteForm(selectedItem?.formId ?? '');
                                }}
                            />
                        </div>
                    </section>
                </ModalAlert>

                <ModalAlert
                    visible={showModalHasInventory}
                    onClose={() => setShowModalHasInventory(false)}
                >
                    <div className="w-auto relative p-10">
                        <div className="text-center gap-2.5 flex flex-col">
                            <Label size="xl" className="font-bold">
                                Você já possui um inventário!
                            </Label>
                            <Label color="text-gray-400">
                                Você já enviou um inventário este ano, espere o próximo ano para
                                poder enviar outro.
                            </Label>
                        </div>
                        <div className="w-[260px] mt-10 mx-auto">
                            <Button
                                style="filled"
                                label="Fechar"
                                onClick={() => setShowModalHasInventory(false)}
                            />
                        </div>
                    </div>
                </ModalAlert>
            </>
        );
    };

import React, { useContext, useEffect, useState } from 'react';
import Label from '../Label/Label';
import Button from '../Button/Button';
import HTMLParser from 'html-react-parser';
import getDiffDate from '../../utils/strings/date';
import { AuthContext } from '../../contexts/AuthContext';
import { notificationService } from '../../services/notificationService';

interface Props {
  notification: INotification;
  reloadNotifications: (page: number) => void;
}

const NotificationItem: React.FC<Props> = ({
  notification,
  reloadNotifications,
}) => {
  const { session } = useContext(AuthContext);
  enum TYPE {
    INVITE = 'INVITE',
    SOLICITATION = 'SOLICITATION',
    WARNING = 'WARNING',
  }

  enum STATUS {
    SENT = 'SENT',
    READ = 'READ',
    APPROVED = 'APPROVED',
    REFUSED = 'REFUSED',
  }

  const handleParticipationAnswer = async (approved: boolean) => {
    const participationAnswer: IParticipationAnswer = {
      notificationId: notification.id,
      approved: approved,
    };

    session?.id &&
      notification.status !== STATUS.APPROVED &&
      notification.status !== STATUS.REFUSED &&
      (await notificationService
        .participationAnswer(participationAnswer, session?.id)
        .then(() => {
          reloadNotifications(0);
        }));
  };

  const handleNotificationRead = async () => {
    if (session?.id == null || notification.status != STATUS.SENT) return;
    const notificationRead: INotificationRead = {
      id: notification.id,
      readerUserId: session.id,
    };

    await notificationService.putNotificationRead(notificationRead);
  };

  useEffect(() => {
    handleNotificationRead();
  }, []);

  return (
    <>
      <li key={notification.id} className="py-10">
        <div className="ml-[3rem] space-x-6">
          <div
            className={`w-13 h-13 relative rounded-full top-6 ${
              notification.status === STATUS.SENT && 'bg-blue-300'
            } `}
          />

          <Label size="md">{notification.title}</Label>
        </div>
        <div className="ml-[4.563rem] mb-[0.625rem]">
          <Label color="text-gray-400">
            {getDiffDate(notification.createdDate)}
          </Label>
        </div>
        <div className="ml-[4.563rem] mr-10">
          <Label>{HTMLParser(notification.content)}</Label>
        </div>
        {notification.type === TYPE.SOLICITATION && (
          <div className="flex ml-[4.563rem] mt-10 gap-2.5">
            <div className="w-[260px]">
              <Button
                style="filled"
                label="Aceitar"
                disabled={notification.status === STATUS.REFUSED}
                isSelected={notification.status === STATUS.APPROVED}
                icon={
                  notification.status === STATUS.APPROVED
                    ? 'check-without-background'
                    : undefined
                }
                onClick={() => {
                  handleParticipationAnswer(true);
                }}
              />
            </div>
            <div className="w-[260px]">
              <Button
                style="outlined"
                label="Recusar"
                disabled={notification.status === STATUS.APPROVED}
                isSelected={notification.status === STATUS.REFUSED}
                onClick={() => {
                  handleParticipationAnswer(false);
                }}
              />
            </div>
          </div>
        )}
      </li>
    </>
  );
};

export default NotificationItem;

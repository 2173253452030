import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GraphicPie } from '../../components/Graphics/GraphicPie';
import Icon from '../../components/Icon/Icon';
import Label from '../../components/Label/Label';
import { LabelFunction } from '../../components/LabelFunction/LabelFunction';
import { BaseTable } from '../../components/Table/BaseTable/BaseTable';
import { RowBody } from '../../components/Table/RowBody/RowBody';
import { RowCell } from '../../components/Table/RowCell/RowCell';
import { TableContainer } from '../../components/TableContainer/TableContainer';
import { ROUTES } from '../../routes/Index.routes';
import { formsService } from '../../services/formsService';
import { organizationService } from '../../services/organizationService';
import { formatDateDDMMYYYY } from '../../utils/dates/formatDateDDMMYYYY';
import { mask } from '../../utils/strings/mask';
import ListOption from '../../components/ListOption/ListOption';
import moment from 'moment';

const EmptyInventory = () => {
    return (
        <>
            <div className="w-full h-full flex items-center justify-center flex-col">
                <Label size="md" color="text-black-400" className="!font-bold">
                    Ainda não há inventários.
                </Label>
                <Label size="sm" color="text-gray-400">
                    Adicione inventários para visualizar essas informações.
                </Label>
            </div>
        </>
    );
};

const EmptyOrg = () => {
    return (
        <>
            <div className="w-full h-full flex items-center justify-center flex-col">
                <Label size="md" color="text-black-400" className="!font-bold">
                    Ainda não há cadastro de organizações.
                </Label>
            </div>
        </>
    );
};

interface GeneralVisionAdminProps { }
export const GeneralVisionAdmin: React.FC<GeneralVisionAdminProps> = () => {
    const [inventories, setInventories] = useState<IPagination<IInventoryInfo>>();
    const [orgs, setOrgs] = useState<IPagination<IOrganization> | null>(null);
    const [orgGraphic, setOrgGraphic] = useState<[IGraphic] | null>(null);
    const [formGraphic, setFormGraphic] = useState<[IGraphic] | null>(null);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);

    const handleClick = (itemId: string) => {
        setSelectedItem(itemId === selectedItem ? null : itemId);
    };

    const navigate = useNavigate();

    const listAllForms = (page: number) => {
        formsService.getAllForms(page).then((response) => {
            setInventories(response);
        });
    };

    const listAllCompanies = (page: number) => {
        organizationService.getCompaniesAdmin(page).then((org) => {
            setOrgs(org);
        });
    };

    function graphicAllCompanies() {
        organizationService.getQtdCompanies().then((graph) => {
            setOrgGraphic(graph);
        });
    }

    function graphicAllForms() {
        formsService.getQtdForms().then((graph) => {
            setFormGraphic(graph);
        });
    }

    const openInventoryForm = (
        status: TInventoryStatus,
        formId: string,
        companyId: string,
    ) => {
        navigate(ROUTES.FORM_REVIEW.path, { state: { status, formId, companyId } });
    };

    function setInventoryStatusBadge(status: TInventoryStatus): string {
        let currentStatus: string = status;

        if (status === 'PENDING') return 'PENDING_ADM';

        if (status === 'REVISION') return 'REVISION_ADM';

        return currentStatus;
    }

    useEffect(() => {
        listAllForms(0);
        listAllCompanies(0);
        graphicAllCompanies();
        graphicAllForms();
    }, []);


    const handleApproveInventory = async (formId: string) => {
        try {
            const answersObject: { [key: string]: Object | null } = {};
            await formsService.newFinishInventoryReview(formId, "APPROVED", answersObject);

            formsService.getAllForms().then((response) => {
                setInventories(response);
            });
        } catch (error) {
            console.error('Unable to approve inventory:', error);
        }
    };

    return (
        <>
            <section className="w-full h-auto flex flex-col flex-wrap items-start justify-start p-5 gap-5">
                <div className="flex md:flex-row flex-col gap-5 w-full">
                    {inventories && (
                        <TableContainer
                            setPagination={listAllForms}
                            title="Últimos inventários recebidos"
                            icon="document-black-200"
                            hasButton={false}
                            numberPages={inventories?.totalPages ?? 0}
                            pagination={1}
                        >
                            <BaseTable
                                headerTitlesComponents={['Organização', 'Data de envio', 'Status']}
                                childrensLength={inventories?.totalElements ?? 0}
                                emptyComponent={
                                    inventories?.totalElements === 0 && <EmptyInventory />
                                }
                            >
                                {inventories?.content
                                    .filter((inventory) => inventory.status !== 'FILLING')
                                    .map((inventory) => {
                                        return (
                                            <RowBody key={inventory.formId}>
                                                <RowCell>
                                                    <button
                                                        className="text-start"
                                                        onClick={() => {
                                                            openInventoryForm(
                                                                inventory.status,
                                                                inventory.formId,
                                                                inventory.companyId,
                                                            );
                                                        }}
                                                    >
                                                        {' '}
                                                        <p className="font-bold text-gray-500 max-w-full line-clamp-2 ">
                                                            {inventory.companyName}
                                                        </p>
                                                    </button>
                                                </RowCell>
                                                <RowCell>
                                                    <Label className="!font-normal max-w-full">
                                                        {moment(inventory.createdDate).format('DD/MM/YYYY')}
                                                    </Label>
                                                </RowCell>
                                                <RowCell>
                                                    <div className="flex items-end justify-between max-w-full">
                                                        <LabelFunction
                                                            type={setInventoryStatusBadge(inventory.status)}
                                                        />
                                                        <div className="flex items-center space-x-4">
                                                            <button
                                                                className="h-full ml-10 md:ml-0"
                                                                onClick={() =>
                                                                    handleClick(inventory.formId ?? '')
                                                                }
                                                            >
                                                                <Icon name="vertical-dot-gray-400" />
                                                            </button>
                                                            <ListOption
                                                                visible={inventory.formId === selectedItem}
                                                                onClose={() => setSelectedItem(null)}
                                                            >
                                                                {(inventory.status === 'APPROVED' ||
                                                                    inventory.status === 'REVISION') && (
                                                                        <button
                                                                            className="p-5 font-bold text-left justify-start"
                                                                            onClick={() => {
                                                                                openInventoryForm(
                                                                                    inventory.status,
                                                                                    inventory.formId,
                                                                                    inventory.companyId,
                                                                                );
                                                                            }}
                                                                        >
                                                                            Visualizar
                                                                        </button>
                                                                    )}

                                                                {inventory.status === 'PENDING' && (
                                                                    <div className="flex flex-col gap-5 p-5">
                                                                        <button
                                                                            className="font-bold text-left justify-start"
                                                                            onClick={() => {
                                                                                openInventoryForm(
                                                                                    inventory.status,
                                                                                    inventory.formId,
                                                                                    inventory.companyId,
                                                                                );
                                                                            }}
                                                                        >
                                                                            Corrigir
                                                                        </button>
                                                                        <button
                                                                            className=" font-bold text-left justify-start"
                                                                            onClick={() => {
                                                                                handleApproveInventory(inventory.formId)
                                                                            }}
                                                                        >
                                                                            Aprovar
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </ListOption>
                                                        </div>
                                                    </div>
                                                </RowCell>
                                            </RowBody>
                                        );
                                    })}
                            </BaseTable>
                        </TableContainer>
                    )}
                    <TableContainer
                        setPagination={listAllCompanies}
                        title="Últimos cadastros de organizações"
                        icon="folder-black-200"
                        hasButton={false}
                        numberPages={orgs?.totalPages ?? 0}
                        pagination={1}
                    >
                        <BaseTable
                            headerTitlesComponents={['Organização', 'CNPJ.']}
                            childrensLength={orgs?.totalElements ?? 0}
                            emptyComponent={orgs?.totalElements === 0 && <EmptyOrg />}
                        >
                            {orgs?.content.map((org) => {
                                return (
                                    <RowBody key={org.companyId}>
                                        <RowCell>
                                            <button className="text-start" onClick={() => { }}>
                                                {' '}
                                                <p className="font-bold text-gray-500 max-w-full line-clamp-2 ">
                                                    {org.fantasyName}
                                                </p>
                                            </button>
                                        </RowCell>

                                        <RowCell>
                                            <div className="flex items-end justify-between h-10 max-w-full">
                                                <Label className="!font-normal">
                                                    {mask(org.cnpj, '##.###.###/####-##')}
                                                </Label>
                                            </div>
                                        </RowCell>
                                    </RowBody>
                                );
                            })}
                        </BaseTable>
                    </TableContainer>
                </div>

                <div className="flex md:flex-row flex-col gap-5 w-full">
                    <GraphicPie
                        labelText={{ singular: 'Inventário', plural: 'Inventários' }}
                        data={
                            formGraphic
                                ?.filter((graph) => graph.status !== 'FILLING')
                                .map((graph) => {
                                    let graphColor = '#FFFFFF';
                                    let graphLegend = '';

                                    switch (graph.status) {
                                        case 'PENDING':
                                            graphColor = '#FFBE2E';
                                            graphLegend = 'Pendentes';
                                            break;
                                        case 'REVISION':
                                            graphColor = '#4A77B4';
                                            graphLegend = 'Revisar';
                                            break;
                                        case 'APPROVED':
                                            graphColor = '#168821';
                                            graphLegend = 'Aprovados';
                                            break;
                                    }
                                    return {
                                        name: graph.status ?? '',
                                        value: graph.quantity,
                                        color: graphColor,
                                        legend: graphLegend,
                                        isChecked: true,
                                    };
                                }) ?? []
                        }
                    />

                    <GraphicPie
                        labelText={{ singular: 'Organização', plural: 'Organizações' }}
                        data={
                            orgGraphic?.map((graph) => {
                                let graphColor = '#FFFFFF';
                                let graphLegend = '';

                                switch (graph.status) {
                                    case 'LINKED':
                                        graphColor = '#4A77B4';
                                        graphLegend = 'Sem vínculo';
                                        break;
                                    case 'IMPORTED':
                                        graphColor = '#C5D4EB';
                                        graphLegend = 'Vínculadas';
                                        break;
                                    case 'CREATED_SIRE':
                                        graphColor = '#2F4668';
                                        graphLegend = 'Cadastradas';
                                        break;
                                }

                                return {
                                    name: graph.status ?? '',
                                    value: graph.quantity,
                                    color: graphColor,
                                    legend: graphLegend,
                                    isChecked: true,
                                };
                            }) ?? []
                        }
                    />
                </div>
            </section>
        </>
    );
};

import React from 'react';
import tailwindConfig from '../../tailwind.config';
type IconProps = {
    name: string;
    size?: 'xs' | 'xm' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    margin?: boolean;
};

const Icon: React.FC<IconProps> = ({ name, size = 'sm', margin = true }) => {
    const sizeClasses = {
        xs: 'h-4 w-4',
        xm: 'h-5 w-5',
        sm: 'h-6 w-6',
        md: 'h-8 w-6',
        lg: 'h-12 w-10',
        xl: 'h-14 w-12',
        xxl: 'h-28 w-24',
    };

    const colorDict = tailwindConfig.theme?.colors as { [key: string]: string };

    const iconClasses = `${!!margin ? 'mt-1' : 'mt-0'} ${sizeClasses[size]}`;
    const [iconColor, iconName] = Object.entries(colorDict).reduce(
        ([c, n], [color, value]) =>
            name.includes(color) ? [value, name.replace(`-${color}`, '')] : [c, n],
        ['#E1E7F1', name],
    );

    switch (iconName) {
        case 'chevron-left':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        d="M.438 9.862a1.502 1.502 0 0 0 0 2.123l9 9a1.502 1.502 0 0 0 2.123-2.123l-7.94-7.941 7.936-7.94A1.502 1.502 0 0 0 9.433.856l-9 9 .005.005Z"
                    />
                </svg>
            );

        case 'arrow-right':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className={iconClasses}
                    data-testid="icon"
                >
                    <path
                        fill={iconColor}
                        d="M11.562 11.98a1.502 1.502 0 0 0 0-2.123l-9-9A1.502 1.502 0 0 0 .438 2.98l7.941 7.941-7.936 7.94a1.502 1.502 0 0 0 2.124 2.124l9-9-.005-.005Z"
                    />
                </svg>
            );
        case 'document':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 28"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M.656 4.156a2.784 2.784 0 0 1 2.781-2.781h7.704v6.063c0 1.183.957 2.14 2.14 2.14h6.063v14.266a2.784 2.784 0 0 1-2.782 2.781H3.438a2.784 2.784 0 0 1-2.78-2.781V4.156Zm17.98 2.782h-4.855V2.082l4.856 4.856ZM14.103 13.5H5.898c-.727 0-1.32.593-1.32 1.32 0 .728.593 1.32 1.32 1.32h8.204c.727 0 1.32-.592 1.32-1.32 0-.727-.593-1.32-1.32-1.32Zm0 3.281H5.898c-.727 0-1.32.593-1.32 1.32 0 .728.593 1.32 1.32 1.32h8.204c.727 0 1.32-.592 1.32-1.32 0-.727-.593-1.32-1.32-1.32Zm0 3.282H5.898c-.727 0-1.32.593-1.32 1.32 0 .727.593 1.32 1.32 1.32h8.204c.727 0 1.32-.593 1.32-1.32 0-.727-.593-1.32-1.32-1.32Z"
                    />
                </svg>
            );

        case 'folder':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M10.53 3.2h.002a3.126 3.126 0 0 0 2.211.914h6.132c1.172 0 2.125.953 2.125 2.125v10.5a2.127 2.127 0 0 1-2.125 2.125H3.125A2.127 2.127 0 0 1 1 16.74V3.614c0-1.172.953-2.125 2.125-2.125H7.94c.566 0 1.107.223 1.504.62L10.532 3.2Z"
                    />
                </svg>
            );
        case 'home':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="m23.497 10.5-.003.028v.028a.846.846 0 0 1-.834.837h-1.835l.002.503.029 6.673c0 .089-.007.182-.018.282l-.003.028V19.577c0 .645-.522 1.167-1.167 1.167h-.666c-.05 0-.073 0-.093-.002l-.04-.004-.04.003a1.998 1.998 0 0 1-.14.003h-2.354a1.166 1.166 0 0 1-1.167-1.167V15.91c0-1.013-.82-1.833-1.833-1.833h-2.667c-1.014 0-1.834.82-1.834 1.833v3.667c0 .645-.522 1.167-1.167 1.167H5.338c-.043 0-.089-.003-.154-.007l-.038-.002-.037.003a1.353 1.353 0 0 1-.109.006h-.666a1.166 1.166 0 0 1-1.167-1.167V14.91a1.774 1.774 0 0 1 .001-.061l.003-.028v-3.427H1.333a.818.818 0 0 1-.833-.838c0-.243.073-.444.26-.636L11.43.62l.012-.012.012-.012c.153-.153.323-.187.564-.187.207 0 .414.06.55.172l10.637 9.35.001.001c.251.22.31.403.29.569Z"
                    />
                </svg>
            );

        case 'people':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M12.7 5.377a4.3 4.3 0 1 1-8.6 0 4.3 4.3 0 0 1 8.6 0ZM.5 18.663a6.185 6.185 0 0 1 6.186-6.186h3.428a6.185 6.185 0 0 1 6.186 6.186.614.614 0 0 1-.614.614H1.114a.614.614 0 0 1-.614-.614Zm22.349.614h-4.435c.056-.224.086-.458.086-.7v-.3a7.973 7.973 0 0 0-1.92-5.2h1.371a5.548 5.548 0 0 1 5.549 5.548c0 .36-.293.652-.651.652Zm-6.649-9.6a3.684 3.684 0 0 1-2.313-.813A6.47 6.47 0 0 0 14.9 5.377c0-.926-.194-1.809-.545-2.608A3.698 3.698 0 0 1 19.9 5.977c0 2.045-1.655 3.7-3.7 3.7Z"
                    />
                </svg>
            );

        case 'bars':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M8.5 2.927c0-.966.784-1.75 1.75-1.75h1.5c.966 0 1.75.784 1.75 1.75v16.5a1.75 1.75 0 0 1-1.75 1.75h-1.5a1.75 1.75 0 0 1-1.75-1.75v-16.5Zm-7.5 9c0-.966.784-1.75 1.75-1.75h1.5c.966 0 1.75.784 1.75 1.75v7.5a1.75 1.75 0 0 1-1.75 1.75h-1.5A1.75 1.75 0 0 1 1 19.427v-7.5Zm16.75-7.75h1.5c.966 0 1.75.784 1.75 1.75v13.5a1.75 1.75 0 0 1-1.75 1.75h-1.5a1.75 1.75 0 0 1-1.75-1.75v-13.5c0-.966.784-1.75 1.75-1.75Z"
                    />
                </svg>
            );

        case 'mail':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M1 2.27C1 1.46 1.658.802 2.469.802H19.53a1.47 1.47 0 0 1 .881 2.644l-8.923 6.692a.817.817 0 0 1-.978 0L1.588 3.446A1.47 1.47 0 0 1 1 2.27Zm0 11.157V5.895l8.125 6.094a3.12 3.12 0 0 0 3.75 0L21 5.895v7.532a2.127 2.127 0 0 1-2.125 2.125H3.125A2.127 2.127 0 0 1 1 13.427Z"
                    />
                </svg>
            );

        case 'profile':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M8.5 9a4.375 4.375 0 1 0 0-8.75A4.375 4.375 0 0 0 8.5 9Zm-1.562 1.64a6.093 6.093 0 0 0-6.094 6.095c0 .56.454 1.015 1.015 1.015H15.14c.56 0 1.015-.455 1.015-1.015a6.093 6.093 0 0 0-6.094-6.094H6.938Z"
                    />
                </svg>
            );

        case 'external-link':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M13.125.342c-.726 0-1.313.587-1.313 1.313s.587 1.312 1.313 1.312h3.392l-8.256 8.26a1.314 1.314 0 0 0 1.858 1.859l8.256-8.26v3.391c0 .726.587 1.313 1.313 1.313S21 8.943 21 8.217V1.655c0-.726-.587-1.313-1.313-1.313h-6.562ZM3.281 1.655A3.28 3.28 0 0 0 0 4.935v13.126a3.28 3.28 0 0 0 3.281 3.281h13.125a3.28 3.28 0 0 0 3.282-3.281v-4.594c0-.726-.587-1.313-1.313-1.313s-1.313.587-1.313 1.313v4.594c0 .36-.295.656-.656.656H3.281a.658.658 0 0 1-.656-.656V4.936c0-.361.295-.656.656-.656h4.594c.726 0 1.313-.587 1.313-1.313S8.6 1.655 7.875 1.655H3.281Z"
                    />
                </svg>
            );

        case 'notification':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="m8.786 3.09.4-.081V1.812c0-.45.363-.812.813-.812.45 0 .813.363.813.813v1.196l.4.081a6.065 6.065 0 0 1 4.85 5.941v.771c0 2.055.755 4.03 2.115 5.566l.304.341a.815.815 0 0 1-.608 1.353H2.125a.81.81 0 0 1-.74-.477.817.817 0 0 1 .133-.876l.304-.34v-.001a8.394 8.394 0 0 0 2.116-5.566v-.77a6.065 6.065 0 0 1 4.85-5.942Zm3.28 16.285A2.12 2.12 0 0 1 9.998 21a2.119 2.119 0 0 1-2.066-1.625h4.132Z"
                    />
                </svg>
            );

        case 'overview':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M8.5 2.75c0-.966.784-1.75 1.75-1.75h1.5c.966 0 1.75.784 1.75 1.75v16.5A1.75 1.75 0 0 1 11.75 21h-1.5a1.75 1.75 0 0 1-1.75-1.75V2.75Zm-7.5 9c0-.966.784-1.75 1.75-1.75h1.5c.966 0 1.75.784 1.75 1.75v7.5A1.75 1.75 0 0 1 4.25 21h-1.5A1.75 1.75 0 0 1 1 19.25v-7.5ZM17.75 4h1.5c.966 0 1.75.784 1.75 1.75v13.5A1.75 1.75 0 0 1 19.25 21h-1.5A1.75 1.75 0 0 1 16 19.25V5.75c0-.966.784-1.75 1.75-1.75Z"
                    />
                </svg>
            );

        case 'gear':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="m19.844 14.67-.47-.174c-.17.462-.376.905-.613 1.334l-.189.325a9.948 9.948 0 0 1-.861 1.215l-.002.002a.43.43 0 0 1-.466.12l-2.286-.727-.249-.079-.207.16a7.39 7.39 0 0 1-1.69.975l-.242.1-.057.256L12 20.519a.428.428 0 0 1-.339.344h-.002a10.094 10.094 0 0 1-3.322 0h-.002a.428.428 0 0 1-.339-.344l-.512-2.342-.056-.256-.243-.1a7.391 7.391 0 0 1-1.69-.976l-.208-.16-.25.08-2.28.73a.437.437 0 0 1-.468-.121 9.943 9.943 0 0 1-.861-1.215l-.188-.325a9.667 9.667 0 0 1-.614-1.333.43.43 0 0 1 .127-.465l.002-.002 1.777-1.616.193-.176-.034-.259a7.44 7.44 0 0 1 0-1.957l.034-.26-.193-.176L.756 7.974l-.003-.002a.43.43 0 0 1-.127-.465c.17-.46.376-.904.614-1.333l.188-.325a9.94 9.94 0 0 1 .861-1.215l.002-.002a.43.43 0 0 1 .467-.12l2.285.727.249.079.207-.16a7.394 7.394 0 0 1 1.69-.975l.243-.1.056-.256L8 1.485a.428.428 0 0 1 .34-.344h.004a10.1 10.1 0 0 1 3.32-.004h.001a.428.428 0 0 1 .339.344l.513 2.342.056.256.242.1c.606.25 1.175.58 1.69.976l.207.159.25-.08 2.285-.725a.437.437 0 0 1 .468.121c.316.382.604.787.861 1.215l.189.325c.19.343.359.694.506 1.057l.103.28a.43.43 0 0 1-.127.465l-.002.002-1.777 1.616-.193.176.034.26a7.422 7.422 0 0 1 0 1.952l-.034.26.193.176 1.777 1.616.002.002a.43.43 0 0 1 .127.465l.47.173Zm0 0a.93.93 0 0 0-.263-1.01m.263 1.01c-.18.488-.398.955-.649 1.407l-.192.332c-.271.451-.575.878-.907 1.28l1.485-4.029m0 0-1.776-1.616a7.942 7.942 0 0 0 0-2.084l1.776 3.7Zm-9.579 1.124a3.781 3.781 0 0 0 3.782-3.782 3.781 3.781 0 0 0-3.782-3.782 3.781 3.781 0 0 0-3.782 3.782 3.781 3.781 0 0 0 3.782 3.782Z"
                    />
                </svg>
            );

        case 'close':
            return (
                <svg
                    className={iconClasses}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M12.882 2.883a1.252 1.252 0 0 0-1.77-1.77L7 5.23 2.882 1.117a1.252 1.252 0 0 0-1.77 1.77L5.23 7l-4.113 4.117a1.252 1.252 0 0 0 1.77 1.77L6.999 8.77l4.117 4.113a1.252 1.252 0 0 0 1.77-1.77L8.769 7l4.113-4.117Z"
                    />
                </svg>
            );

        case 'arrow-right-button':
            return (
                <svg
                    className={iconClasses}
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill={iconColor}
                        d="M17.3828 8.88281C17.8711 8.39453 17.8711 7.60156 17.3828 7.11328L11.1328 0.863281C10.6445 0.375 9.85156 0.375 9.36328 0.863281C8.875 1.35156 8.875 2.14453 9.36328 2.63281L13.4844 6.75H1.5C0.808594 6.75 0.25 7.30859 0.25 8C0.25 8.69141 0.808594 9.25 1.5 9.25H13.4805L9.36719 13.3672C8.87891 13.8555 8.87891 14.6484 9.36719 15.1367C9.85547 15.625 10.6484 15.625 11.1367 15.1367L17.3867 8.88672L17.3828 8.88281Z"
                    />
                </svg>
            );

        case 'arrow-left-button':
            return (
                <svg
                    className={iconClasses}
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill={iconColor}
                        d="M1.11621 7.11719C0.627932 7.60547 0.627932 8.39844 1.11621 8.88672L7.36621 15.1367C7.85449 15.625 8.64746 15.625 9.13574 15.1367C9.62402 14.6484 9.62402 13.8555 9.13574 13.3672L5.01465 9.25L16.999 9.25C17.6904 9.25 18.249 8.69141 18.249 8C18.249 7.30859 17.6904 6.75 16.999 6.75L5.01856 6.75L9.13184 2.63281C9.62012 2.14453 9.62012 1.35156 9.13184 0.863278C8.64356 0.374997 7.85059 0.374997 7.36231 0.863278L1.11231 7.11328L1.11621 7.11719Z"
                    />
                </svg>
            );

        case 'search':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M14.469 7.358a7.09 7.09 0 0 1-1.367 4.194l4.326 4.33a1.095 1.095 0 0 1-1.548 1.548l-4.327-4.33a7.068 7.068 0 0 1-4.193 1.367A7.107 7.107 0 0 1 .252 7.358 7.107 7.107 0 0 1 7.36.25a7.107 7.107 0 0 1 7.109 7.108ZM7.36 12.28a4.922 4.922 0 0 0 0-9.843 4.922 4.922 0 0 0-4.92 4.921 4.922 4.922 0 0 0 4.92 4.922Z"
                    />
                </svg>
            );

        case 'plus-light':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 26 26"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M14.875 2.688A1.873 1.873 0 0 0 13 .812a1.873 1.873 0 0 0-1.875 1.875v8.438H2.687A1.873 1.873 0 0 0 .813 13c0 1.037.837 1.875 1.875 1.875h8.437v8.438c0 1.037.838 1.875 1.875 1.875a1.873 1.873 0 0 0 1.875-1.875v-8.438h8.438A1.873 1.873 0 0 0 25.188 13a1.873 1.873 0 0 0-1.875-1.875h-8.438V2.687Z"
                    />
                </svg>
            );

        case 'info':
            return (
                <svg
                    className={iconClasses}
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M7.067 14.067a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM5.973 9.254h.656v-1.75h-.656a.655.655 0 0 1-.656-.656c0-.364.292-.656.656-.656h1.312c.364 0 .657.292.657.656v2.406h.218c.364 0 .657.293.657.656a.655.655 0 0 1-.657.657H5.973a.655.655 0 0 1-.656-.657c0-.363.292-.656.656-.656Zm1.094-5.687a.875.875 0 1 1 0 1.75.875.875 0 0 1 0-1.75Z"
                    />
                </svg>
            );

        case 'check':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M8 16a8 8 0 0 0 8-8 8 8 0 0 0-8-8 8 8 0 0 0-8 8 8 8 0 0 0 8 8Zm3.531-9.469-4 4a.747.747 0 0 1-1.06 0l-2-2a.75.75 0 0 1 1.06-1.06L7 8.942l3.469-3.472a.75.75 0 0 1 1.06 1.06l.002.002Z"
                    />
                </svg>
            );

        case 'check-without-background':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 16"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M17.885 1.115a1.252 1.252 0 0 1 0 1.77l-10 10a1.252 1.252 0 0 1-1.77 0l-5-5a1.252 1.252 0 0 1 1.77-1.77l4.117 4.114 9.117-9.114a1.252 1.252 0 0 1 1.77 0h-.004Z"
                    />
                </svg>
            );

        case 'warn':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 14"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M8 0c.443 0 .853.234 1.078.619l6.75 11.5A1.248 1.248 0 0 1 14.749 14H1.25c-.446 0-.862-.24-1.084-.628a1.255 1.255 0 0 1 .006-1.253l6.75-11.5A1.248 1.248 0 0 1 8 0Zm0 4a.748.748 0 0 0-.75.75v3.5c0 .416.334.75.75.75.415 0 .75-.334.75-.75v-3.5A.748.748 0 0 0 8 4Zm1 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
                    />
                </svg>
            );

        case 'circle-xmark':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M15 30c8.285 0 15-6.715 15-15S23.285 0 15 0 0 6.715 0 15s6.715 15 15 15Zm-4.746-19.746a1.4 1.4 0 0 1 1.986 0l2.754 2.754 2.754-2.754a1.406 1.406 0 0 1 1.986 1.986l-2.754 2.754 2.754 2.754a1.406 1.406 0 0 1-1.986 1.986l-2.754-2.754-2.754 2.754a1.4 1.4 0 0 1-1.986 0 1.406 1.406 0 0 1 0-1.986l2.754-2.754-2.754-2.754a1.4 1.4 0 0 1 0-1.986Z"
                    />
                </svg>
            );

        case 'arrow-left':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 24"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M1.425 10.676a1.878 1.878 0 0 0 0 2.654l9.375 9.375a1.878 1.878 0 0 0 2.654-2.654l-6.181-6.176h17.976A1.873 1.873 0 0 0 27.124 12a1.873 1.873 0 0 0-1.875-1.875H7.28l6.17-6.176a1.878 1.878 0 0 0-2.655-2.654L1.42 10.67l.006.006Z"
                    />
                </svg>
            );
        case 'arrow-down':
            return (
                <svg
                    viewBox="0 0 16 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconClasses}
                >
                    <path
                        fill={iconColor}
                        d="M7.29365 7.70774C7.68428 8.09837 8.31865 8.09837 8.70928 7.70774L14.7093 1.70774C15.0999 1.31712 15.0999 0.682742 14.7093 0.292117C14.3187 -0.0985084 13.6843 -0.0985085 13.2937 0.292116L7.9999 5.58587L2.70615 0.295239C2.31553 -0.0953858 1.68115 -0.0953859 1.29053 0.295239C0.899902 0.685864 0.899902 1.32024 1.29053 1.71086L7.29053 7.71087L7.29365 7.70774Z"
                    />
                </svg>
            );

        case 'vertical-dot':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                >
                    <path
                        d="M3.5 15.875C4.94844 15.875 6.125 17.0516 6.125 18.5C6.125 19.9484 4.94844 21.125 3.5 21.125C2.05156 21.125 0.875 19.9484 0.875 18.5C0.875 17.0516 2.05156 15.875 3.5 15.875ZM3.5 8.375C4.94844 8.375 6.125 9.55156 6.125 11C6.125 12.4484 4.94844 13.625 3.5 13.625C2.05156 13.625 0.875 12.4484 0.875 11C0.875 9.55156 2.05156 8.375 3.5 8.375ZM6.125 3.5C6.125 4.94844 4.94844 6.125 3.5 6.125C2.05156 6.125 0.875 4.94844 0.875 3.5C0.875 2.05156 2.05156 0.875 3.5 0.875C4.94844 0.875 6.125 2.05156 6.125 3.5Z"
                        fill={iconColor}
                    />
                </svg>
            );

        case 'horizontal-dot':
            return (
                <svg
                    className={iconClasses}
                    viewBox="0 0 18 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.813477 3.00003C0.813477 2.41992 1.04392 1.86358 1.45412 1.45338C1.86431 1.04319 2.42066 0.812744 3.00076 0.812744C3.58086 0.812744 4.13721 1.04319 4.5474 1.45338C4.9576 1.86358 5.18804 2.41992 5.18804 3.00003C5.18804 3.58013 4.9576 4.13648 4.5474 4.54667C4.13721 4.95687 3.58086 5.18731 3.00076 5.18731C2.42066 5.18731 1.86431 4.95687 1.45412 4.54667C1.04392 4.13648 0.813477 3.58013 0.813477 3.00003ZM7.06286 3.00003C7.06286 2.41992 7.2933 1.86358 7.7035 1.45338C8.1137 1.04319 8.67004 0.812744 9.25014 0.812744C9.83025 0.812744 10.3866 1.04319 10.7968 1.45338C11.207 1.86358 11.4374 2.41992 11.4374 3.00003C11.4374 3.58013 11.207 4.13648 10.7968 4.54667C10.3866 4.95687 9.83025 5.18731 9.25014 5.18731C8.67004 5.18731 8.1137 4.95687 7.7035 4.54667C7.2933 4.13648 7.06286 3.58013 7.06286 3.00003ZM15.4995 0.812744C16.0796 0.812744 16.636 1.04319 17.0462 1.45338C17.4564 1.86358 17.6868 2.41992 17.6868 3.00003C17.6868 3.58013 17.4564 4.13648 17.0462 4.54667C16.636 4.95687 16.0796 5.18731 15.4995 5.18731C14.9194 5.18731 14.3631 4.95687 13.9529 4.54667C13.5427 4.13648 13.3122 3.58013 13.3122 3.00003C13.3122 2.41992 13.5427 1.86358 13.9529 1.45338C14.3631 1.04319 14.9194 0.812744 15.4995 0.812744Z"
                        fill={iconColor}
                    />
                </svg>
            );

        case 'filter':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="16"
                    fill="none"
                >
                    <path
                        fill="#4A77B4"
                        d="M.383 1.126A1.362 1.362 0 0 1 1.617.343h14.768A1.37 1.37 0 0 1 17.44 2.58l-6.252 7.64v4.345a1.092 1.092 0 0 1-1.75.875L7.25 13.798a1.086 1.086 0 0 1-.438-.875v-2.704L.557 2.575a1.364 1.364 0 0 1-.174-1.45Z"
                    />
                </svg>
            );
        case 'refresh':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22 22"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M4.107 6.915c.301-.852.79-1.653 1.477-2.336a6.25 6.25 0 0 1 8.84 0l.668.671h-1.965c-.692 0-1.25.56-1.25 1.25 0 .692.558 1.25 1.25 1.25h4.996c.691 0 1.25-.558 1.25-1.25v-5c0-.69-.559-1.25-1.25-1.25s-1.25.56-1.25 1.25v2l-.684-.687A8.75 8.75 0 0 0 1.752 6.086a1.25 1.25 0 0 0 2.355.832v-.003ZM1.525 10.3a1.244 1.244 0 0 0-.882 1.016 1.141 1.141 0 0 0-.016.2V16.5c0 .692.559 1.25 1.25 1.25s1.25-.558 1.25-1.25v-1.996l.687.684c3.418 3.414 8.957 3.414 12.372 0a8.726 8.726 0 0 0 2.066-3.27 1.25 1.25 0 0 0-2.355-.832c-.301.852-.79 1.653-1.477 2.336a6.25 6.25 0 0 1-8.84 0l-.004-.004-.668-.667h1.969c.691 0 1.25-.56 1.25-1.25 0-.692-.559-1.25-1.25-1.25H1.893c-.063 0-.125.003-.188.011a.914.914 0 0 0-.18.04Z"
                    />
                </svg>
            );

        case 'download-file':
            return (
                <svg
                    className={iconClasses}
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.25 1.75C11.25 1.05859 10.6914 0.5 10 0.5C9.30859 0.5 8.75 1.05859 8.75 1.75V11.2305L5.88281 8.36328C5.39453 7.875 4.60156 7.875 4.11328 8.36328C3.625 8.85156 3.625 9.64453 4.11328 10.1328L9.11328 15.1328C9.60156 15.6211 10.3945 15.6211 10.8828 15.1328L15.8828 10.1328C16.3711 9.64453 16.3711 8.85156 15.8828 8.36328C15.3945 7.875 14.6016 7.875 14.1133 8.36328L11.25 11.2305V1.75ZM2.5 14.25C1.12109 14.25 0 15.3711 0 16.75V18C0 19.3789 1.12109 20.5 2.5 20.5H17.5C18.8789 20.5 20 19.3789 20 18V16.75C20 15.3711 18.8789 14.25 17.5 14.25H13.5352L11.7656 16.0195C10.7891 16.9961 9.20703 16.9961 8.23047 16.0195L6.46484 14.25H2.5ZM16.875 16.4375C17.1236 16.4375 17.3621 16.5363 17.5379 16.7121C17.7137 16.8879 17.8125 17.1264 17.8125 17.375C17.8125 17.6236 17.7137 17.8621 17.5379 18.0379C17.3621 18.2137 17.1236 18.3125 16.875 18.3125C16.6264 18.3125 16.3879 18.2137 16.2121 18.0379C16.0363 17.8621 15.9375 17.6236 15.9375 17.375C15.9375 17.1264 16.0363 16.8879 16.2121 16.7121C16.3879 16.5363 16.6264 16.4375 16.875 16.4375Z"
                        fill={iconColor}
                    />
                </svg>
            );

        case 'upload-file':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconClasses}
                    fill="none"
                    viewBox="0 0 20 20"
                >
                    <path
                        fill={iconColor}
                        d="M11.25 4.26953V13.75c0 .6914-.5586 1.25-1.25 1.25-.69141 0-1.25-.5586-1.25-1.25V4.26953L5.88281 7.13672c-.48828.48828-1.28125.48828-1.76953 0-.48828-.48828-.48828-1.28125 0-1.76953l5-5.000002c.48828-.488282 1.28122-.488282 1.76952 0l5 5.000002c.4883.48828.4883 1.28125 0 1.76953-.4883.48828-1.2812.48828-1.7695 0L11.25 4.26953ZM2.5 13.75h5c0 1.3789 1.12109 2.5 2.5 2.5 1.3789 0 2.5-1.1211 2.5-2.5h5c1.3789 0 2.5 1.1211 2.5 2.5v1.25c0 1.3789-1.1211 2.5-2.5 2.5h-15C1.12109 20 0 18.8789 0 17.5v-1.25c0-1.3789 1.12109-2.5 2.5-2.5Zm14.375 4.0625c.2486 0 .4871-.0988.6629-.2746.1758-.1758.2746-.4143.2746-.6629 0-.2486-.0988-.4871-.2746-.6629-.1758-.1758-.4143-.2746-.6629-.2746-.2486 0-.4871.0988-.6629.2746-.1758.1758-.2746.4143-.2746.6629 0 .2486.0988.4871.2746.6629.1758.1758.4143.2746.6629.2746Z"
                    />
                </svg>
            );

        case 'save-disket':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconClasses}
                    fill="none"
                    viewBox="0 0 18 18"
                >
                    <path
                        fill={iconColor}
                        d="M2.83337.333344C1.45447.333344.333374 1.45444.333374 2.83334V15.3333c0 1.3789 1.121096 2.5 2.499996 2.5H15.3334c1.3789 0 2.5-1.1211 2.5-2.5V5.85287c0-.66406-.2617-1.30078-.7305-1.76953l-3.0195-3.01953c-.4688-.468748-1.1055-.730466-1.7696-.730466H2.83337Zm0 3.749996c0-.6914.5586-1.25 1.25-1.25h7.50003c.6914 0 1.25.5586 1.25 1.25v2.5c0 .69141-.5586 1.25-1.25 1.25H4.08337c-.6914 0-1.25-.55859-1.25-1.25v-2.5Zm6.25 6.24996c.66305 0 1.29893.2634 1.76773.7323.4689.4688.7323 1.1047.7323 1.7677 0 .6631-.2634 1.299-.7323 1.7678-.4688.4689-1.10468.7322-1.76773.7322-.66304 0-1.29892-.2633-1.76776-.7322-.46884-.4688-.73224-1.1047-.73224-1.7678 0-.663.2634-1.2989.73224-1.7677.46884-.4689 1.10472-.7323 1.76776-.7323Z"
                    />
                </svg>
            );

        case 'calendar':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 27"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        d="M7.078.375c.908 0 1.64.733 1.64 1.64v1.641h6.563v-1.64c0-.908.733-1.641 1.64-1.641.908 0 1.642.733 1.642 1.64v1.641h2.46a2.462 2.462 0 0 1 2.461 2.461v2.461H.516v-2.46a2.462 2.462 0 0 1 2.46-2.462h2.462v-1.64c0-.908.733-1.641 1.64-1.641ZM.516 10.219h22.968v13.945a2.462 2.462 0 0 1-2.46 2.461H2.976a2.462 2.462 0 0 1-2.461-2.46V10.218Zm3.28 4.101v1.64c0 .452.37.821.821.821h1.64c.452 0 .821-.369.821-.82v-1.64c0-.452-.369-.821-.82-.821h-1.64c-.452 0-.821.37-.821.82Zm6.563 0v1.64c0 .452.37.821.82.821h1.641c.451 0 .82-.369.82-.82v-1.64c0-.452-.369-.821-.82-.821h-1.64c-.451 0-.82.37-.82.82Zm7.383-.82c-.451 0-.82.37-.82.82v1.64c0 .452.369.821.82.821h1.64c.452 0 .821-.369.821-.82v-1.64c0-.452-.369-.821-.82-.821h-1.64ZM3.797 20.883v1.64c0 .452.369.82.82.82h1.64c.452 0 .821-.368.821-.82v-1.64c0-.451-.369-.82-.82-.82h-1.64c-.452 0-.821.369-.821.82Zm7.383-.82c-.451 0-.82.369-.82.82v1.64c0 .452.369.82.82.82h1.64c.451 0 .82-.368.82-.82v-1.64c0-.451-.369-.82-.82-.82h-1.64Zm5.742.82v1.64c0 .452.369.82.82.82h1.64c.452 0 .821-.368.821-.82v-1.64c0-.451-.369-.82-.82-.82h-1.64c-.452 0-.821.369-.821.82Z"
                    />
                </svg>
            );

        case 'edit':
            return (
                <svg
                    className={iconClasses}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 28"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M25.808 4.218a2.783 2.783 0 0 1 0 3.938l-2.13 2.13-5.965-5.965 1.984-1.984h.005l.147-.146a2.783 2.783 0 0 1 3.937 0l2.022 2.027Zm-9.962 1.97 5.965 5.965-11.62 11.614a4.052 4.052 0 0 1-1.708 1.014l-6.17 1.815a.732.732 0 0 1-.906-.913l1.816-6.172a4.051 4.051 0 0 1 1.014-1.708l11.61-11.615Z"
                    />
                </svg>
            );

        case 'document-edit':
            return (
                <svg
                    className={iconClasses}
                    data-testid="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 28"
                    fill="none"
                >
                    <path
                        fill={iconColor}
                        stroke="#3D4551"
                        d="M14.5109 23.1744L15.2138 20.3579C15.2138 20.3579 15.2139 20.3578 15.2139 20.3578C15.2139 20.3578 15.2139 20.3578 15.2139 20.3577C15.2515 20.2075 15.3233 20.0671 15.422 19.9506L15.476 19.8966L21.1775 14.1951L23.7977 16.8153L18.0965 22.5122L18.0964 22.5123C17.9682 22.6404 17.81 22.7303 17.6323 22.7739L17.6305 22.7744L14.8139 23.4774L14.8116 23.478C14.7268 23.4996 14.6403 23.4763 14.5762 23.4121C14.512 23.348 14.4887 23.2615 14.5103 23.1767L14.5109 23.1744ZM1.00497 2.99941C1.00497 1.62119 2.12617 0.5 3.50439 0.5H10.5029V5.99883C10.5029 7.1045 11.397 7.99854 12.5026 7.99854H18.0015V13.1825L14.08 17.104C13.5326 17.6514 13.1498 18.332 12.964 19.0805L12.0879 22.5899C12.0128 22.8901 11.9897 23.198 12.0168 23.5H3.50439C2.12617 23.5 1.00497 22.3788 1.00497 21.0006V2.99941ZM13.0026 1.20711L17.2944 5.49883H13.0026V1.20711ZM25.9194 11.401L26.5931 12.0747C27.1282 12.6097 27.13 13.4793 26.5926 14.0208C26.5924 14.021 26.5922 14.0211 26.592 14.0213L25.5688 15.0445L22.9485 12.4241L23.9728 11.3998C24.5078 10.8648 25.3774 10.863 25.9188 11.4004C25.919 11.4006 25.9192 11.4008 25.9194 11.401Z"
                    />
                </svg>
            );

        case 'drag':
            return (
                <svg
                    width="21"
                    height="44"
                    viewBox="0 0 21 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9 13C9 14.6569 7.65685 16 6 16C4.34315 16 3 14.6569 3 13C3 11.3431 4.34315 10 6 10C7.65685 10 9 11.3431 9 13Z"
                        fill="#DFE1E2"
                    />
                    <path
                        d="M18 13C18 14.6569 16.6569 16 15 16C13.3431 16 12 14.6569 12 13C12 11.3431 13.3431 10 15 10C16.6569 10 18 11.3431 18 13Z"
                        fill="#DFE1E2"
                    />
                    <path
                        d="M9 22C9 23.6569 7.65685 25 6 25C4.34315 25 3 23.6569 3 22C3 20.3431 4.34315 19 6 19C7.65685 19 9 20.3431 9 22Z"
                        fill="#DFE1E2"
                    />
                    <path
                        d="M18 22C18 23.6569 16.6569 25 15 25C13.3431 25 12 23.6569 12 22C12 20.3431 13.3431 19 15 19C16.6569 19 18 20.3431 18 22Z"
                        fill="#DFE1E2"
                    />
                    <path
                        d="M9 31C9 32.6569 7.65685 34 6 34C4.34315 34 3 32.6569 3 31C3 29.3431 4.34315 28 6 28C7.65685 28 9 29.3431 9 31Z"
                        fill="#DFE1E2"
                    />
                    <path
                        d="M18 31C18 32.6569 16.6569 34 15 34C13.3431 34 12 32.6569 12 31C12 29.3431 13.3431 28 15 28C16.6569 28 18 29.3431 18 31Z"
                        fill="#DFE1E2"
                    />
                </svg>
            );

        case 'trash':
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.35781 2.22598C8.5793 1.77891 9.03457 1.5 9.53086 1.5H14.4691C14.9654 1.5 15.4207 1.77891 15.6422 2.22598L15.9375 2.8125H19.875C20.601 2.8125 21.1875 3.39902 21.1875 4.125C21.1875 4.85098 20.601 5.4375 19.875 5.4375H4.125C3.39902 5.4375 2.8125 4.85098 2.8125 4.125C2.8125 3.39902 3.39902 2.8125 4.125 2.8125H8.0625L8.35781 2.22598ZM4.125 6.75H19.875V19.875C19.875 21.3229 18.6979 22.5 17.25 22.5H6.75C5.30215 22.5 4.125 21.3229 4.125 19.875V6.75ZM8.0625 9.375C7.70156 9.375 7.40625 9.67031 7.40625 10.0312V19.2188C7.40625 19.5797 7.70156 19.875 8.0625 19.875C8.42344 19.875 8.71875 19.5797 8.71875 19.2188V10.0312C8.71875 9.67031 8.42344 9.375 8.0625 9.375ZM12 9.375C11.6391 9.375 11.3438 9.67031 11.3438 10.0312V19.2188C11.3438 19.5797 11.6391 19.875 12 19.875C12.3609 19.875 12.6562 19.5797 12.6562 19.2188V10.0312C12.6562 9.67031 12.3609 9.375 12 9.375ZM15.9375 9.375C15.5766 9.375 15.2812 9.67031 15.2812 10.0312V19.2188C15.2812 19.5797 15.5766 19.875 15.9375 19.875C16.2984 19.875 16.5938 19.5797 16.5938 19.2188V10.0312C16.5938 9.67031 16.2984 9.375 15.9375 9.375Z"
                        fill="#CD425B"
                    />
                </svg>
            );

        case 'pen':
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.3919 2.26982L14.4047 4.25698L19.7421 9.59441L21.7293 7.60724C22.7557 6.58081 22.7557 4.918 21.7293 3.89158L20.1116 2.26982C19.0852 1.24339 17.4224 1.24339 16.396 2.26982H16.3919ZM13.4768 5.18487L3.90639 14.7594C3.4794 15.1864 3.16737 15.716 2.99493 16.2949L1.5415 21.2341C1.43886 21.5831 1.53329 21.9567 1.78785 22.2113C2.0424 22.4658 2.41602 22.5602 2.7609 22.4617L7.70007 21.0083C8.27897 20.8358 8.80861 20.5238 9.2356 20.0968L18.8142 10.5223L13.4768 5.18487Z"
                        fill="#4A77B4"
                    />
                </svg>
            );

        case 'arrow-right-blue-button':
            return (
                <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21.06 11.0594C21.6459 10.4734 21.6459 9.52187 21.06 8.93594L13.56 1.43594C12.974 0.849998 12.0225 0.849998 11.4365 1.43594C10.8506 2.02187 10.8506 2.97344 11.4365 3.55937L16.3818 8.5H2.00059C1.1709 8.5 0.500587 9.17031 0.500587 10C0.500587 10.8297 1.1709 11.5 2.00059 11.5H16.3772L11.4412 16.4406C10.8553 17.0266 10.8553 17.9781 11.4412 18.5641C12.0271 19.15 12.9787 19.15 13.5647 18.5641L21.0647 11.0641L21.06 11.0594Z"
                        fill="#4A77B4"
                    />
                </svg>
            );

        case 'send':
            return (
                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M35 0L0 17.5L13.125 21.875L17.5 35L35 0Z" fill="white" />
                </svg>
            );

        case 'menu':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="none"
                    viewBox="0 0 30 30"
                >
                    <path
                        fill="#4A77B4"
                        d="M2 5.875C2 4.838 2.838 4 3.875 4h22.5c1.037 0 1.875.838 1.875 1.875a1.873 1.873 0 01-1.875 1.875h-22.5A1.873 1.873 0 012 5.875zm0 9.375c0-1.037.838-1.875 1.875-1.875h22.5c1.037 0 1.875.838 1.875 1.875a1.873 1.873 0 01-1.875 1.875h-22.5A1.873 1.873 0 012 15.25zm26.25 9.375a1.873 1.873 0 01-1.875 1.875h-22.5A1.873 1.873 0 012 24.625c0-1.037.838-1.875 1.875-1.875h22.5c1.037 0 1.875.838 1.875 1.875z"
                    />
                </svg>
            );

        case 'close-menu':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 30 30"
                >
                    <path
                        fill="#4A77B4"
                        d="M23.824 8.824A1.878 1.878 0 0021.17 6.17L15 12.346l-6.176-6.17A1.878 1.878 0 006.17 8.83L12.346 15l-6.17 6.176A1.878 1.878 0 008.83 23.83L15 17.654l6.176 6.17a1.878 1.878 0 002.654-2.654L17.654 15l6.17-6.176z"
                    />
                </svg>
            );

        case 'fb-open':
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 3.125C11.25 2.43359 10.6914 1.875 10 1.875C9.30859 1.875 8.75 2.43359 8.75 3.125V8.75H3.125C2.43359 8.75 1.875 9.30859 1.875 10C1.875 10.6914 2.43359 11.25 3.125 11.25H8.75V16.875C8.75 17.5664 9.30859 18.125 10 18.125C10.6914 18.125 11.25 17.5664 11.25 16.875V11.25H16.875C17.5664 11.25 18.125 10.6914 18.125 10C18.125 9.30859 17.5664 8.75 16.875 8.75H11.25V3.125Z" fill="white" />
                </svg>
            );

        case 'fb-close':
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8828 5.88281C16.3711 5.39453 16.3711 4.60156 15.8828 4.11328C15.3945 3.625 14.6016 3.625 14.1133 4.11328L10 8.23047L5.88281 4.11719C5.39453 3.62891 4.60156 3.62891 4.11328 4.11719C3.625 4.60547 3.625 5.39844 4.11328 5.88672L8.23047 10L4.11719 14.1172C3.62891 14.6055 3.62891 15.3984 4.11719 15.8867C4.60547 16.375 5.39844 16.375 5.88672 15.8867L10 11.7695L14.1172 15.8828C14.6055 16.3711 15.3984 16.3711 15.8867 15.8828C16.375 15.3945 16.375 14.6016 15.8867 14.1133L11.7695 10L15.8828 5.88281Z" fill="white" />
                </svg>
            );
        default:
            return null;
    }
};

export default Icon;

import { useEffect, useState } from 'react';
import { TableResume } from '../../components/TableResume/TableResume';
import Button from '../../components/Button/Button';
import ModalAlert from '../../components/Modal/ModalAlert';
import Label from '../../components/Label/Label';
import { formsService } from '../../services/formsService';
import Dropdown from '../../components/Dropdown/Dropdown';
import {
  GEEDataAggPreview,
  aggPreviewSubtitle,
  aggPreview,
  GEEDataDisPreviewScope1,
  disPreviewScope1Subtitle,
  disPreviewScope1,
  GEEDataDisPreviewScope2,
  disPreviewScope2Subtitle,
  disPreviewScope2,
  GEEDataDisPreviewScope3,
  disPreviewScope3Subtitle,
  disagPreviewScope3,
  aggResultGEEData,
  aggResultSubtitle,
  aggResult,
  disResultGEEDataScope1,
  disResultScope1Subtitle,
  disResultScope1,
  disResultGEEDataScope2,
  disResultScope2Subtitle,
  disagResultScope2,
  disResultGEEDataScope3,
  disResultScope3Subtitle,
  disResultScope3,
} from '../../mocks/tableData';
import Tabs from '../../components/Tabs/Tabs';

interface InventoryResumePageProps {
  formId: string;
  onlyTableReturn?: number;
}

export const InventoryResumePage: React.FC<InventoryResumePageProps> = ({
  formId,
  onlyTableReturn,
}) => {
  enum TableType {
    agPreviewByScope,
    disagPreviewScope1,
    disagPreviewScope2,
    disagPreviewScope3,
    agResultByScope,
    disagResultScope1,
    disagResultScope2,
    disagResultScope3,
  }

  const [showModalTable, setShowModalTable] = useState<boolean>(false);
  const [answerForm, setAnswerForm] = useState<any>(null);
  const [answerResultForm, setAnswerResultForm] = useState<any>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  const options: IOption[] = [
    { key: 'sar1995', value: 'GWP 100 anos SAR-1995' },
    { key: 'ar42007', value: 'GWP 100 anos AR4-2007' },
    { key: 'ar52014', value: 'GWP 100 anos AR5-2014' },
  ];

  const handleOptionClick = (option: IOption) => {
    setSelectedOption(option);
  };

  const getAnswerFormById = (formId: string) => {
    formsService.getAnswerFormById(formId).then((response) => {
      setAnswerForm(response);
    });
  };

  const getFactorGwpById = (formId: string) => {
    formsService.getFactorGwpById(formId).then((response) => {
      setAnswerResultForm(response);
    });
  };

  const getMatrix = (
    form: any,
    refData: string[],
    refParam: string[][],
    isResult: boolean,
  ): string[][] => {
    let matrix = [refData];
    refParam.map((params) => {
      matrix.push(getDataByParam(form, params, isResult));
    });

    return matrix;
  };

  const getDataByParam = (
    form: any,
    params: string[],
    isResult: boolean,
  ): string[] => {
    return params.map((param) => {
      if (!form) return '-';

      if (param === '-' || param === ' ' || param === '') {
        return param;
      }

      if (param.includes('+')) {
        const refs = param.split('+');
        refs.map((ref) => {
          const value = getData(form, ref, isResult);
          if (value === '0') {
            return '-';
          } else {
            return value;
          }
        });
      }
      const value = getData(form, param, isResult);

      if (value === '0') {
        return '-';
      } else if (value === undefined) {
        return '';
      } else {
        return value;
      }
    });
  };

  const getData = (form: any, param: string, isResult: boolean) => {
    if (!form || param === '-' || param === ' ' || param === '')
      return undefined;

    if (!isResult) {
      return form[param];
    } else if (selectedOption?.key) {
      const objectForm = (form as any[]).find((obj) => obj.name === param);
      return objectForm ? objectForm[selectedOption.key] : undefined;
    } else {
      return undefined;
    }
  };

  const thComponent = (
    text?: string,
    rowSpan?: number,
    colSpan?: number,
  ): React.ReactNode => {
    return text ? (
      <th
        className="border px-4 py-2 max-w-[280px] bg-blue-200 text-white-100 "
        rowSpan={rowSpan}
        colSpan={colSpan}
      >
        {text}
      </th>
    ) : (
      <th></th>
    );
  };

  const titleHeader = (type: TableType): React.ReactNode => {
    switch (type) {
      case TableType.agPreviewByScope:
        return (
          <tr>
            {thComponent()}
            {thComponent('Em toneladas métricas, por tipo de GEE', 1, 4)}
          </tr>
        );

      case TableType.agResultByScope:
        return (
          <tr>
            {thComponent()}
            {thComponent(
              addSubscritoString(
                'Emissões em toneladas métricas de CO2 equivalente (tCO2e)',
              ),
              1,
              4,
            )}
          </tr>
        );

      case TableType.disagPreviewScope2:
      case TableType.disagResultScope2:
        return (
          <tr>
            {thComponent()}
            {thComponent('Abordagem com base na localização', 1, 4)}
            {thComponent('Abordagem com base em escolha de compra', 1, 4)}
            {thComponent('Total de emissões Escopo 2', 2, 1)}
          </tr>
        );
      default:
        return null;
    }
  };

  const subtitleHeader = (
    subtitles: string[],
    type: TableType,
  ): React.ReactNode => {
    return (
      <thead>
        {titleHeader(type)}
        <tr>
          {subtitles.map((subtitle) => {
            return thComponent(subtitle);
          })}
        </tr>
      </thead>
    );
  };

  const baseTable = (isResult: boolean, tableChildren: React.ReactNode) => {
    return (
      <div className="relative mt-8">
        {isResult && (
          <div className="absolute w-80">
            <Dropdown
              options={options}
              icon="search-gray-400"
              label="Selecione a métrica"
              selectedOption={selectedOption}
              handleOptionClick={handleOptionClick}
            ></Dropdown>
          </div>
        )}
        <div className={`${isResult && 'pt-[100px]'}`}>
          {isResult && !selectedOption ? (
            <div className="flex justify-center items-center w-full py-10">
              <Label
                className="flex text-center w-[700px]"
                color="text-gray-400"
                size="md"
              >
                {
                  'Selecione a métrica para cálculo de emissões e remoções em toneladas métricas de CO2 equivalente (tCO2e) para visualizar os resultados.'
                }
              </Label>
            </div>
          ) : (
            tableChildren
          )}
        </div>
      </div>
    );
  };

  const aggregatedPreviewTable = (
    byScope: string[][],
    isResult: boolean,
  ): React.ReactNode => {
    return baseTable(
      isResult,
      <div className="flex flex-col gap-10 mt-8">
        <Label size="md" color="text-gray-400">
          Emissões totais por escopo e tipo de GEE
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(aggResultSubtitle),
                  TableType.agResultByScope,
                )
              : subtitleHeader(aggPreviewSubtitle, TableType.agPreviewByScope)
          }
          matrix={byScope}
        />
      </div>,
    );
  };

  const aggregatedResultsTable = (
    byScope: string[][],
    isResult: boolean,
  ): React.ReactNode => {
    return baseTable(
      isResult,
      <div className="flex flex-col gap-10">
        <Label size="md" color="text-gray-400">
          {addSubscritoString('Emissões totais por escopo e em CO2e')}
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(aggResultSubtitle),
                  TableType.agResultByScope,
                )
              : subtitleHeader(
                  addSubscritoList(aggPreviewSubtitle),
                  TableType.agPreviewByScope,
                )
          }
          matrix={byScope}
        />
      </div>,
    );
  };

  const disaggregatePreviewTable = (
    scope1: string[][],
    scope2: string[][],
    scope3: string[][],
    isResult: boolean,
  ): React.ReactNode => {
    return baseTable(
      isResult,
      <div className="flex flex-col gap-10 mt-8">
        <Label size="md" color="text-gray-400">
          Emissões totais por escopo, categoria e tipo de GEE
        </Label>
        <Label size="md" color="text-gray-400">
          Escopo 1
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(disResultScope1Subtitle),
                  TableType.disagResultScope1,
                )
              : subtitleHeader(
                  disPreviewScope1Subtitle,
                  TableType.disagPreviewScope1,
                )
          }
          matrix={scope1}
        />

        <Label size="md" color="text-gray-400">
          Escopo 2
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(disResultScope2Subtitle),
                  TableType.disagResultScope2,
                )
              : subtitleHeader(
                  disPreviewScope2Subtitle,
                  TableType.disagPreviewScope2,
                )
          }
          matrix={scope2}
        />

        <Label size="md" color="text-gray-400">
          Escopo 3
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(disResultScope3Subtitle),
                  TableType.disagResultScope3,
                )
              : subtitleHeader(
                  disPreviewScope3Subtitle,
                  TableType.disagPreviewScope3,
                )
          }
          matrix={scope3}
        />
      </div>,
    );
  };

  const disaggregateResultsTable = (
    scope1: string[][],
    scope2: string[][],
    scope3: string[][],
    isResult: boolean,
  ): React.ReactNode => {
    return baseTable(
      isResult,
      <div className="flex flex-col gap-10">
        <Label size="md" color="text-gray-400">
          {addSubscritoString(
            'Emissões totais por escopo, categoria e em CO2e',
          )}
        </Label>
        <Label size="md" color="text-gray-400">
          Escopo 1
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(disResultScope1Subtitle),
                  TableType.disagResultScope1,
                )
              : subtitleHeader(
                  addSubscritoList(disPreviewScope1Subtitle),
                  TableType.disagPreviewScope1,
                )
          }
          matrix={scope1}
        />

        <Label size="md" color="text-gray-400">
          Escopo 2
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(disResultScope2Subtitle),
                  TableType.disagResultScope2,
                )
              : subtitleHeader(
                  addSubscritoList(disPreviewScope2Subtitle),
                  TableType.disagPreviewScope2,
                )
          }
          matrix={scope2}
        />

        <Label size="md" color="text-gray-400">
          Escopo 3
        </Label>
        <TableResume
          headerChildren={
            isResult
              ? subtitleHeader(
                  addSubscritoList(disResultScope3Subtitle),
                  TableType.disagResultScope3,
                )
              : subtitleHeader(
                  addSubscritoList(disPreviewScope3Subtitle),
                  TableType.disagPreviewScope3,
                )
          }
          matrix={scope3}
        />
      </div>,
    );
  };

  function addSubscritoList(gasList: string[]): string[] {
    const listaConvertida = gasList.map((gas) => {
      if (!/^HFC|^PFC|Escopo/.test(gas)) {
        gas = gas.replace(/\d+/g, (match) => {
          return match
            .split('')
            .map((char) => {
              const code = char.charCodeAt(0);
              return String.fromCharCode(code + 8272);
            })
            .join('');
        });
      }

      return gas;
    });

    return listaConvertida;
  }

  function addSubscritoString(gas: string): string {
    const gasConvertido = gas.replace(/\d+/g, (match) => {
      return match
        .split('')
        .map((char) => {
          const code = char.charCodeAt(0);
          return String.fromCharCode(code + 8272);
        })
        .join('');
    });

    return gasConvertido;
  }

  const tabsData = [
    {
      label: 'Pré Visualização Agregada',
      icon: '',
      hasHeader: false,
      headerButtons: <></>,
      content: aggregatedPreviewTable(
        getMatrix(
          answerForm,
          addSubscritoList(GEEDataAggPreview),
          aggPreview,
          false,
        ),
        false,
      ),
    },
    {
      label: 'Pré Visualização Desagregada',
      icon: '',
      hasHeader: false,
      headerButtons: <></>,
      content: disaggregatePreviewTable(
        getMatrix(
          answerForm,
          addSubscritoList(GEEDataDisPreviewScope1),
          disPreviewScope1,
          false,
        ),
        getMatrix(
          answerForm,
          addSubscritoList(GEEDataDisPreviewScope2),
          disPreviewScope2,
          false,
        ),
        getMatrix(
          answerForm,
          addSubscritoList(GEEDataDisPreviewScope3),
          disagPreviewScope3,
          false,
        ),
        false,
      ),
    },
    {
      label: addSubscritoString('Resultados agregados – em CO2e'),
      icon: '',
      hasHeader: false,
      headerButtons: <></>,
      content: aggregatedResultsTable(
        getMatrix(
          answerResultForm,
          addSubscritoList(aggResultGEEData),
          aggResult,
          true,
        ),
        true,
      ),
    },
    {
      label: addSubscritoString('Resultados desagregados – em CO2e'),
      icon: '',
      hasHeader: false,
      headerButtons: <></>,
      content: disaggregateResultsTable(
        getMatrix(
          answerResultForm,
          addSubscritoList(disResultGEEDataScope1),
          disResultScope1,
          true,
        ),
        getMatrix(
          answerResultForm,
          addSubscritoList(disResultGEEDataScope2),
          disagResultScope2,
          true,
        ),
        getMatrix(
          answerResultForm,
          addSubscritoList(disResultGEEDataScope3),
          disResultScope3,
          true,
        ),
        true,
      ),
    },
  ];

  useEffect(() => {
    getAnswerFormById(formId);
    getFactorGwpById(formId);
  }, []);

  return (
    <>
      {onlyTableReturn !== undefined ? (
        onlyTableReturn === 1 ? (
          aggregatedPreviewTable(
            getMatrix(
              answerForm,
              addSubscritoList(GEEDataAggPreview),
              aggPreview,
              false,
            ),
            false,
          )
        ) : onlyTableReturn === 2 ? (
          disaggregatePreviewTable(
            getMatrix(
              answerForm,
              addSubscritoList(GEEDataDisPreviewScope1),
              disPreviewScope1,
              false,
            ),
            getMatrix(
              answerForm,
              addSubscritoList(GEEDataDisPreviewScope2),
              disPreviewScope2,
              false,
            ),
            getMatrix(
              answerForm,
              addSubscritoList(GEEDataDisPreviewScope3),
              disagPreviewScope3,
              false,
            ),
            false,
          )
        ) : onlyTableReturn === 3 ? (
          aggregatedResultsTable(
            getMatrix(
              answerResultForm,
              addSubscritoList(aggResultGEEData),
              aggResult,
              true,
            ),
            true,
          )
        ) : onlyTableReturn === 4 ? (
          disaggregateResultsTable(
            getMatrix(
              answerResultForm,
              addSubscritoList(disResultGEEDataScope1),
              disResultScope1,
              true,
            ),
            getMatrix(
              answerResultForm,
              addSubscritoList(disResultGEEDataScope2),
              disagResultScope2,
              true,
            ),
            getMatrix(
              answerResultForm,
              addSubscritoList(disResultGEEDataScope3),
              disResultScope3,
              true,
            ),
            true,
          )
        ) : null
      ) : (
        <div className="h-full w-full flex flex-col items-center justify-center px-16">
          <Label className="flex text-center" color="text-black-400" size="xl">
            Consulte a pré-visualização e os resultados.
          </Label>
          <Label className="flex text-center" color="text-gray-400">
            Acesse o resumo das emissões por escopo e a desagregação das
            emissões de GEE da organização por escopo e categoria.
          </Label>

          <div className="flex flex-row items-center justify-center gap-2.5 mt-6">
            <div className="w-[300px]">
              <Button
                style="filled"
                label="Consultar"
                type="button"
                onClick={() => {
                  setShowModalTable(true);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <ModalAlert
        visible={showModalTable}
        hasButtonClose={true}
        onClose={() => {
          setShowModalTable(false);
          setSelectedOption(null);
        }}
      >
        <section className="w-full h-full max-w-[calc(100vw-20px)] max-h-[calc(100vh-20px)] md:max-w-[calc(100vw-100px)] md:max-h-[calc(100vh-100px)] p-14 overflow-y-auto overflow-x-auto">
          <Tabs tabsData={tabsData}></Tabs>
        </section>
      </ModalAlert>
    </>
  );
};

import React from 'react';

interface LabelProps {
  size?: 'xs' | 'xm' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  children: React.ReactNode;
  className?: string;
}

const Label: React.FC<LabelProps> = ({
  children,
  size = 'sm',
  color = 'text-gray-500',
  className = '',
}) => {
  const fontSizeClasses = {
    xs: 'text-[11px]',
    xm: 'text-[13px]',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  const labelClasses = `inline-block font-semibold ${fontSizeClasses[size]} ${color} ${className}`;

  return <span className={labelClasses}>{children}</span>;
};

export default Label;

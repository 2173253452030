import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import Button from '../Button/Button';
import { Input } from '../Input/Input';
import Label from '../Label/Label';
import ModalAlert from '../Modal/ModalAlert';
import { AuthContext } from '../../contexts/AuthContext';
import { userService } from '../../services/userService';

interface IFormInviteAdmin {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  onInviteChange: (response: Promise<any>) => void;
}

export function FormInviteAdmin({
  open,
  setOpen,
  onInviteChange,
}: IFormInviteAdmin) {
  const { session } = useContext(AuthContext);

  const InviteSchema = z.object({
    toEmail: z
      .string()
      .nonempty('Esse campo é obrigatório')
      .regex(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/, {
        message: 'E-mail inválido!',
      }),
  });

  type InviteSchemaProps = z.infer<typeof InviteSchema>;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InviteSchemaProps>({
    resolver: zodResolver(InviteSchema),
  });

  async function submitInvite(data: InviteSchemaProps) {
    const { toEmail } = data;
    if (session) {
      const payload = {
        toEmail,
        fromUserId: session?.id,
      };
      const response = await userService.inviteNewAdmin(payload);
      onInviteChange && onInviteChange(response);
    }
    reset();
  }

  function validateEmail(): IStateClasses {
    if (errors['toEmail']?.type === 'invalid_string') return 'warning';

    if (errors['toEmail']?.type === 'too_small') return 'danger';

    return undefined;
  }

  return (
    <ModalAlert
      visible={open}
      onClose={() => {
        setOpen((open) => !open);
        reset();
      }}
      hasButtonClose
    >
      <form
        className="w-auto md:w-[610px] h-[693px] flex flex-col"
        onSubmit={handleSubmit(submitInvite)}
      >
        <header className="p-10 border-solid border-b-2 border-gray-200">
          <section>
            <Label color="text-black-10" size="xl" className="!font-bold">
              Adicionar administrador
            </Label>
            <Label color="text-gray-400" size="sm">
              Preencha os campos e envie o convite por meio do botão abaixo.
            </Label>
          </section>
        </header>
        <main className="flex-1 overflow-auto p-10">
          <section>
            <div className="pb-10">
              <Label color="text-gray-400" size="sm">
                Informe o e-mail do destinatário do convite.
              </Label>
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className="flex flex-col gap-[10px]">
                <Label>E-mail do usuário</Label>
                <Input
                  placeholder="Insira o e-mail do usuário aqui"
                  registerInput={register('toEmail')}
                  state={validateEmail()}
                  errorLabel={errors['toEmail']?.message}
                />
              </div>
            </div>
          </section>
        </main>
        <footer className="p-10 border-solid border-t-2 border-gray-200">
          <Button type="submit" style="filled" label="Enviar convite" />
        </footer>
      </form>
    </ModalAlert>
  );
}

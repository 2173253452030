import Label from '../Label/Label';

interface ITableResumeProps {
  headerChildren?: React.ReactNode;
  matrix: string[][];
}

export const TableResume: React.FC<ITableResumeProps> = ({
  headerChildren,
  matrix,
}) => {
  return (
    <>
      <div className="rounded-md">
        <table className="table-auto w-full h-full">
          {headerChildren}
          <tbody>
            {matrix[0].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {matrix.map((column, columnIndex) => (
                  <td
                    key={columnIndex}
                    className={'border border-gray-200 px-4 py-2 max-w-[280px]'}
                  >
                    {column[rowIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

import { ChangeEvent, useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AdministratorContext } from '../../contexts/AdministratorContext';
import { AuthContext, PerfilType } from '../../contexts/AuthContext';
import { InventoryFormContext } from '../../contexts/InventoryFormContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import {
    TUTORIAL_STATE,
    TutorialAppContext,
} from '../../contexts/TutorialAppContext';
import { ROUTES } from '../../routes/Index.routes';
import { api } from '../../services/api';
import { Breadcrumb } from '../Breadcrumb/Breadcrumb';
import Button from '../Button/Button';
import { ConsultOrganization } from '../ConsultOrganization/ConsultOrganization';
import { FormInviteAdmin } from '../FormInviteAdmin/FormInviteAdmin';
import { InviteSuccess } from '../OrganizationPageGroupComponents/InviteSuccess';
import { Popover } from '../Popover/Popover';
import { SearchInput } from '../SearchInput/SearchInput';
import { FormIdContext } from '../../contexts/FormContext';
import { formsService } from '../../services/formsService';
import Label from '../Label/Label';
import ModalAlert from '../Modal/ModalAlert';
import UploadComponent from '../UploadComponent/UploadComponent';

interface HeaderProps { }

interface HeaderBtnGroupProps { }

const HeaderBtnGroup: React.FC<HeaderBtnGroupProps> = () => {
    const location = useLocation();
    const { pauseTutorial, stateTutorial, updateTutorialAlreadySeen } =
        useContext(TutorialAppContext);
    const { userAddOrganization, session } = useContext(AuthContext);
    const { listAdminUsers } = useContext(AdministratorContext);
    const { setFormBeingFilled } = useContext(InventoryFormContext);
    const { selectedOrgId } = useContext(OrganizationContext);

    const [modalConsultOrg, setModalConsultOrg] = useState<boolean>(false);

    const [showForminviteAdmin, setShowForminviteAdmin] =
        useState<boolean>(false);

    const handleClickAdminInvite = () => setShowForminviteAdmin(true);

    const [showInviteSuccess, setShowInviteSuccess] = useState<boolean>(false);

    const { id, setIdValue, json, setJson } = useContext(FormIdContext);

    const [showModalUpload, setShowModalUpload] = useState(false);

    const [file, setFile] = useState<File | null>(null);

    function handleAddInventory() {
        if (stateTutorial === TUTORIAL_STATE.EXECUTE) pauseTutorial();
        pauseTutorial();
        setModalConsultOrg(true);
        updateTutorialAlreadySeen('firstAccess');
    }

    function handleOrgSearch(org: IOrganizationSearch) {
        if (org) userAddOrganization(org);
    }

    const handleReturnInvite = (response: Promise<any>) => {
        setShowForminviteAdmin(false);
        setShowInviteSuccess(true);
        listAdminUsers(0);
    };

    const handleFileChange = (file: File | null) => {
        setFile(file);
    };

    const handleFileUpload = async (file: File) => {
        if (file) {
            const response = await formsService.uploadSheetFile(file, id, selectedOrgId);
            setJson(null);
        }
    };


    switch (location.pathname) {
        case ROUTES.GENERAL_VISION.path:
            if (session?.role == PerfilType.ADMIN) {
                return (
                    <div className="w-[50px]">
                        {/* <Button
              style="outlined"
              icon="filter-blue-200"
              onClick={() => {}}
            /> */}
                    </div>
                );
            }
            return (
                <>
                    <Popover
                        onClickContinueButton={handleAddInventory}
                        exactPath={ROUTES.GENERAL_VISION.path}
                        tutorialType="firstAccess"
                        componentName="button-add-inventory"
                        orientation="bottom-full-right"
                        width="w-[340px]"
                        levelOrder={1}
                        activeClose
                        content={{
                            title: 'Adicionar inventário',
                            body: `Para visualizar ou adicionar um inventário de GEE, informe o CNPJ da sua organização.`,
                            button: 'Consultar organização',
                            btnIcon: 'search',
                        }}
                    >
                        {/* <div className="w-[218px]">
                            <Button
                                style="outlined"
                                icon="plus-light-blue-warm-200"
                                label="Adicionar inventário"
                                disabled={
                                    session?.organizations && session?.organizations.length === 0
                                }
                                onClick={() => { }}
                            />
                        </div> */}
                    </Popover>
                    <div className="w-[242px] hidden md:block">
                        <Button
                            style="filled"
                            icon="search"
                            label="Consultar organização"
                            onClick={() => setModalConsultOrg(true)}
                        />
                    </div>
                    <ConsultOrganization
                        onConsultedOrg={handleOrgSearch}
                        showSearchOrgModal={modalConsultOrg}
                        setShowSearchOrgModal={setModalConsultOrg}
                    />
                </>
            );

        case ROUTES.HOME.path:
            return (
                <></>
                // <div className="w-[260px]">
                //     <SearchInput placeholder="Pesquisar..."></SearchInput>
                // </div>
            );

        case ROUTES.NOTIFICATION.path:
            return (
                <>
                    <div className="w-[138px] hidden md:block">
                        <Button
                            style="filled"
                            icon="refresh-white-100"
                            label="Atualizar"
                            onClick={() => { }}
                        />
                    </div>
                    <div className="w-[50px] hidden md:block">
                        <Button
                            style="outlined"
                            icon="filter-blue-200"
                            onClick={() => { }}
                        />
                    </div>
                </>
            );

        case ROUTES.INVENTORY_FORM.path:
            return (
                <>
                    <Popover
                        orientation="bottom-full-left"
                        componentName="button-upload"
                        width="w-[340px]"
                        levelOrder={5}
                        exactPath={ROUTES.INVENTORY_FORM.path}
                        tutorialType="firstForm"
                        content={{
                            title: 'Carregar formulário',
                            body: 'Caso você tenha alterado o formulário já enviado, você pode atualizar as informações na plataforma reenviando um novo arquivo.',
                            button: 'Continuar',
                        }}
                    >
                        <div className="w-[217px] hidden md:block">
                            <Button
                                style="outlined"
                                label="Carregar formulário"
                                icon="upload-file-blue-200"
                                iconSize="sm"
                                onClick={() => setShowModalUpload(true)}
                            />
                        </div>
                    </Popover>
                    <>
                        <ModalAlert
                            visible={showModalUpload}
                            onClose={() => setShowModalUpload(false)}
                        >
                            <div className="w-[610px] relative p-10">
                                <div className="text-center gap-2.5 flex flex-col">
                                    <Label size="xl" className="font-bold">
                                        Carregar Formulário
                                    </Label>
                                    <Label color="text-gray-400">
                                        Escolha o arquivo que será carregado:
                                    </Label>
                                    <div className='mt-5'>
                                        <UploadComponent placeholder='Anexe o documento' onFileChange={handleFileChange}></UploadComponent>
                                    </div>
                                </div>
                                <div className="w-auto mt-10 mx-auto flex flex-row space-x-4">
                                    <Button
                                        label="Fechar"
                                        style="outlined"
                                        icon="arrow-left-blue-200"
                                        onClick={() => setShowModalUpload(false)}
                                        type="button"
                                    />
                                    <Button
                                        label="Enviar"
                                        style="filled"
                                        icon="arrow-right-button-white-100"
                                        iconPosition='right'
                                        onClick={() => {
                                            if (file) {
                                                handleFileUpload(file);
                                            }
                                            setShowModalUpload(false)
                                        }}
                                        type="button"
                                    />
                                </div>
                            </div>
                        </ModalAlert>
                    </>
                    <Popover
                        orientation="bottom-full-right"
                        componentName="button-download"
                        width="w-[340px]"
                        levelOrder={6}
                        exactPath={ROUTES.INVENTORY_FORM.path}
                        tutorialType="firstForm"
                        content={{
                            title: 'Baixar formulário',
                            body: 'Você também pode baixar o formulário com as informações que você já preencheu.',
                            button: 'Continuar',
                        }}
                    >
                        <div className="w-[203px] hidden md:block">
                            <Button
                                style="filled"
                                label="Baixar formulário"
                                icon="download-file-white-100"
                                iconSize="sm"
                                onClick={() => {
                                    if (id !== undefined) {
                                        formsService.downloadSheetFileFormId(id)
                                    } else {
                                        formsService.downloadSheetFile()
                                    }
                                }}
                            />
                        </div>
                    </Popover>
                    {/* <Popover
            orientation="bottom-full-right"
            componentName="button-save"
            width="w-[340px]"
            levelOrder={7}
            exactPath={ROUTES.INVENTORY_FORM.path}
            tutorialType="firstForm"
            content={{
              title: 'Salvar planilha',
              body: 'Caso queira salvar o preenchimento das informações como rascunho para posterior finalização.',
              button: 'Continuar',
            }}
          >
            <div className="w-[50px]">
              <Button
                style="outlined"
                icon="save-disket-blue-200"
                iconSize="xs"
                onClick={() => {
                  setFormBeingFilled(true);
                }}
              />
            </div>
          </Popover>  */}
                </>
            );

        case ROUTES.ORGANIZATION.path:
            return (
                <div className="w-[260px] hidden md:block">
                    <SearchInput placeholder="Pesquisar..."></SearchInput>
                </div>
            );

        case ROUTES.ADMINISTRATORS.path:
            return (
                <>
                    <div className="w-[254px] hidden md:block">
                        <Button
                            style="filled"
                            label="Adicionar administrador"
                            icon="plus-light-white-100"
                            onClick={handleClickAdminInvite}
                        />
                    </div>
                    <FormInviteAdmin
                        setOpen={setShowForminviteAdmin}
                        open={showForminviteAdmin}
                        onInviteChange={handleReturnInvite}
                    />

                    <InviteSuccess
                        open={showInviteSuccess}
                        setOpen={setShowInviteSuccess}
                    />
                </>
            );

        case ROUTES.FORM_REVIEW.path:
            return (
                <>
                    <div className="w-[203px] hidden md:block">
                        <Button
                            style="filled"
                            label="Baixar formulário"
                            icon="download-file-white-100"
                            iconSize="sm"
                            onClick={() => formsService.downloadSheetFileFormId(id)}
                        />
                    </div>
                    {/* <div className="w-[50px]">
            <Button
              style="outlined"
              icon="save-disket-blue-200"
              iconSize="xs"
            />
          </div> */}
                </>
            );

        default:
            return <></>;
    }
};

export const Header: React.FC<HeaderProps> = () => {
    return (
        <div className="flex flex-row h-32 w-full p-7 items-center justify-between">
            <Breadcrumb />
            <div className="flex flex-row gap-2.5">
                <HeaderBtnGroup />
            </div>
        </div>
    );
};
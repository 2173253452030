import { api } from './api';

const getCurrentFormJSON = (): Promise<IFormJSON> => {
    return api.get(`/form/inventory/current`);
};

const getFormJSONById = (companyId: string): Promise<IFormJSON> => {
    return api.get(`/form/inventory/answer`, '', `${companyId}`);
};

const getFormById = (formId: string): Promise<IFormJSON> => {
    return api.get(`/form/inventory/review/get-form`, '', `${formId}`);
};

const getAnswerFormById = (formId: string): Promise<any> => {
    return api.get(`/form/inventory/answer/by-id`, '', `${formId}`);
};

const getFactorGwpById = (formId: string): Promise<IFactorGwp[]> => {
    return api.get(`/form/inventory/answer/factorGwp/by-id`, '', `${formId}`);
};

const getFormsByCompanyId = (
    companyId: string[],
    page: number = 0,
    size: number = 10,
): Promise<IPagination<IInventoryInfo>> => {
    const ids = companyId.join(',');
    return api.get(
        `/form/inventory/answer/all`,
        `page=${page}&size=${size}`,
        ids,
    );
};

const deleteFormsById = (id: string) => {
    return api.del(`/form/inventory/answer/${id}`);
};

const getAllForms = (
    page: number = 0,
    size: number = 10,
): Promise<IPagination<IInventoryInfo>> => {
    return api.get(`/form/inventory/review/all`, `page=${page}&size=${size}`);
};

const submitNewAnswer = (
    JSONwithAnswer: IFormJSON,
    companyId: string,
): Promise<IFormJSON> => {
    return api.post(`/form/inventory/answer/${companyId}`, JSONwithAnswer);
};

const updateAnswer = (
    JSONwithAnswer: IFormJSON,
    companyId: string,
): Promise<IFormJSON> => {
    return api.put(`/form/inventory/answer/${companyId}`, JSONwithAnswer);
};

const finishInventoryFilling = (
    companyId: string,
    userId: string,
    formJSON: IFormJSON,
): Promise<IFormJSON> => {
    return api.put(
        `/form/inventory/answer/final/${companyId}/${userId}`,
        formJSON,
    );
};

const finishInventoryReview = (
    companyId: string,
    status: string,
    formJSON: IFormJSON,
): Promise<any> => {
    return api.put(
        `/form/inventory/review/update/${companyId}/${status}`,
        formJSON,
    );
};

const getQtdForms = (companyIds: string[] = []): Promise<[IGraphic]> => {
    const params =
        companyIds.length !== 0 ? `companyIds=${companyIds.join(',')}` : '';
    return api.get(`/form/inventory/answer/by-status`, params, '');
};

const getDrawFormJSON = (): Promise<IFormJSON> => {
    return api.get(`/form/inventory/current-draw`);
};

const createFormDraw = (JSONDraw: IFormJSON): Promise<IFormJSON> => {
    return api.post(`/form/inventory/draw`, JSONDraw);
};

const publishFormDraw = (formDrawId: string): Promise<any> => {
    return api.put(`/form/inventory/publish/${formDrawId}`, {});
};

const newUpdateAnswer = (
    JSONwithAnswer: Record<string, any>,
    formId: string,
): Promise<IFormJSON> => {
    return api.put(`/form/inventory/answer/update/${formId}`, JSONwithAnswer);
};

const newSubmitNewAnswer = (
    JSONwithAnswer: Record<string, any>,
    companyId: string,
): Promise<IFormJSON> => {
    return api.post(`/form/inventory/answer/new/${companyId}`, JSONwithAnswer);
};

const newFinishInventoryFilling = (
    formId: string,
    userId: string,
    JSONwithAnswer: Record<string, any>,
): Promise<IFormJSON> => {
    return api.put(
        `/form/inventory/answer/send/${formId}/${userId}`,
        JSONwithAnswer,
    );
};

const newFinishInventoryReview = (
    formId: string,
    status: string,
    JSONwithAnswer: Record<string, any>,
): Promise<any> => {
    return api.put(`/form/inventory/review/${formId}/${status}`, JSONwithAnswer);
};

const downloadSheetFileFormId = (formId: string): Promise<void> => {
    localStorage.setItem('isFile', 'true');
    return api
        .getFile(`/form/excel/download-form/${formId}`)
        .then((blob) => {
            const url = URL.createObjectURL(blob);
            const element = document.createElement('a');
            element.href = url;
            element.download = 'formulario.xlsx';
            element.click();
            URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('Error downloading the file', error);
        });
};

const downloadSheetFile = (): Promise<void> => {
    localStorage.setItem('isDownloadFile', 'true');
    return api
        .getFile(`/form/excel/download-form`)
        .then((blob) => {
            const url = URL.createObjectURL(blob);
            const element = document.createElement('a');
            element.href = url;
            element.download = 'formulario.xlsx';
            element.click();
            URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('Error downloading the file', error);
        });
};

const uploadSheetFile = (
    file: File,
    formId: string,
    companyId: string
): Promise<IFormJSON> => {
    localStorage.setItem('isUploadFile', 'true');
    if(formId && companyId){
        return api.postFile(`/form/excel/upload-form?formId=${formId}&companyId=${companyId}`, file);
    } else if (formId && !companyId) {
        return api.postFile(`/form/excel/upload-form?formId=${formId}`, file);
    } 
    return api.postFile(`/form/excel/upload-form?companyId=${companyId}`, file);
};

export const formsService = {
    getCurrentFormJSON,
    getFormJSONById,
    getFormById,
    getAnswerFormById,
    getFactorGwpById,
    submitNewAnswer,
    updateAnswer,
    finishInventoryFilling,
    getFormsByCompanyId,
    getAllForms,
    finishInventoryReview,
    getQtdForms,
    getDrawFormJSON,
    createFormDraw,
    publishFormDraw,
    newUpdateAnswer,
    newSubmitNewAnswer,
    newFinishInventoryFilling,
    newFinishInventoryReview,
    deleteFormsById,
    downloadSheetFileFormId,
    downloadSheetFile,
    uploadSheetFile
};

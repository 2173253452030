import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useSidebar } from '../../contexts/SidebarContext';
import { IRoutesProps, ROUTES } from '../../routes/Index.routes';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';

const ArrowSeparator = () => {
    return (
        <>
            <div className="px-5 h-6 flex items-end">
                <Icon name="arrow-right-gray-400" size="xs" />
            </div>
        </>
    );
};

const TitleBreadcrump = ({ title }: { title: string }) => {
    return (
        <>
            <div className="pl-3">
                <Label size="md" className="capitalize !font-bold">
                    {title}
                </Label>
            </div>
        </>
    );
};

interface BreadcrumbProps { }

export const Breadcrumb: React.FC<BreadcrumbProps> = () => {
    const { pathname } = useLocation();
    const params = useParams();
    const [breadcrumps, setBreadcrumps] = useState<IRoutesProps[]>([]);

    const { sidebarOpen, setSidebarOpen } = useSidebar();

    const existentPaths = ['/revisaoformulario', '/formularioinventariante'];

    function handleDynamicPath(levelPath: number) {
        const routes = Object.values(ROUTES);

        const someLevel = routes.filter((route) => {
            const level = route.path.split('/').length - 1;
            if (level === levelPath) return route;
        });

        const dynamicPath = someLevel.find((route) => route.path.includes(':'));

        if (!!dynamicPath) {
            const [_, prop] = dynamicPath?.path.split('/:') ?? [];
            if (!prop)
                throw new Error('Erro - Nenhum parâmetro foi encontrado para a rota');

            return {
                ...dynamicPath,
                title: params[prop]?.replaceAll('-', ' ') ?? '',
            };
        } else
            throw new Error(
                'Erro - Não foi declarada nenhuma URl dinâmica para a rota',
            );
    }

    useEffect(() => {
        const routes = Object.values(ROUTES);

        const levelPath = pathname.split('/').length - 1;

        const urlpath =
            levelPath > 1
                ? pathname.split('/')
                : [pathname.slice(1, pathname.length)];

        const parsedPaths = urlpath
            .map(
                (url) =>
                    routes.find(({ path }) => path === `/${url}`) ||
                    handleDynamicPath(levelPath),
            )
            .filter((f) => !!f);

        const paths =
            parsedPaths.some((path) =>
                existentPaths.some((epath) => epath === path.path),
            ) && breadcrumps.length > 0
                ? [
                    {
                        path: '',
                        title: '',
                        defaultIcon: breadcrumps[breadcrumps.length - 1].defaultIcon,
                    },
                    ...parsedPaths,
                ]
                : parsedPaths;

        setBreadcrumps(() => paths as IRoutesProps[]);
    }, [pathname]);

    return (
        <div className="flex flex-row w-full">
            <button
                onClick={() => {
                    setSidebarOpen(!sidebarOpen);
                }}
                className="md:hidden block"
            >
                <Icon name="menu" />
            </button>
    
            {breadcrumps.map((breadcrump, index) => {
                return (
                    <React.Fragment key={breadcrump.title}>
                        <div className="md:block hidden">
                            <Icon name={breadcrump.defaultIcon} />
                        </div>
                        {breadcrumps.length - 1 > index ? (
                            <div className="md:block hidden">
                                <ArrowSeparator />
                            </div>
                        ) : (
                            <TitleBreadcrump title={breadcrump.title} />
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
    
};
import { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';

const images = [
  {
    id: 1,
    src: require('../../assets/png/banner.png'),
  },
];

const SlideShow = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => {
      return prevIndex === 0 ? images.length - 1 : prevIndex - 1;
    });
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => {
      return prevIndex === images.length - 1 ? 0 : prevIndex + 1;
    });
  };

  return (
    <div className="relative w-full h-[195.84px]">
      <img
        src={images[currentImageIndex].src}
        alt={`${images[currentImageIndex].id}`}
        className={`absolute w-full h-full ${isMobile ? 'object-cover' : ''}`}
      />
    </div>
  );
};

export default SlideShow;

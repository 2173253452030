import { InputHTMLAttributes } from 'react';
import InputMask from 'react-input-mask';
import { InputMessage } from '../InputMessage/InputMessage';
import { NumericFormat } from 'react-number-format';
import { stat } from 'fs';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: 'text' | 'number' | 'password' | 'email' | 'double' | 'integer';
    state?: IStateClasses;
    mask?: 'cpf' | 'phone' | 'cnpj' | 'cep';
    registerInput?: any;
    isValid?: boolean;
    errorLabel?: string;
    size?: number | undefined;
    maxValue?: number | undefined;
    minValue?: number | undefined;
    reviewLabel?: string;
}

export const Input: React.FC<InputProps> = ({
    type = 'text',
    state,
    placeholder,
    onChange,
    value,
    disabled,
    mask = 'blank',
    registerInput,
    isValid,
    errorLabel,
    size,
    maxValue,
    minValue,
    reviewLabel,
    ...rest
}) => {
    const stateStyle = {
        warning: 'border-yellow-200',
        danger: 'border-red-200',
        primary: '',
        success: '',
    };

    const inputClasses = `
    w-full
    h-[60px]
    p-5
    text-black-300
    border-2
    border-solid
    rounded-base
    text-sm
    !text-black-300
    placeholder:italic
    placeholder:text-gray-300
    placeholder:!font-normal
    disabled:bg-gray-200
    focus-visible:outline-none
    hover:bg-gray-100
    ${state ? stateStyle[state] : 'border-gray-200 focus:border-blue-200'}
    ${reviewLabel ? 'border-yellow-200' : ''} 
`;

    const masks = {
        cpf: '999.999.999-99',
        phone: '(99) 9 9999-9999',
        cnpj: '99.999.999/9999-99',
        cep: '99999-999',
        blank: '',
    };

    function selectInputComponent() {
        if (type === 'double') {
            return (
                <NumericFormat
                    allowLeadingZeros={true}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={5}
                    className={inputClasses}
                    value={value}
                    placeholder={placeholder ?? 'Digite sua resposta aqui.'}
                    onChange={onChange}
                    disabled={disabled}
                    {...registerInput}
                    {...rest}
                    max={maxValue}
                    min={minValue}
                />
            );
        } else if (type === 'integer') {
            return (
                <NumericFormat
                   // thousandSeparator="."
                   // decimalSeparator=","
                    decimalScale={0}
                    className={inputClasses}
                    value={value}
                    placeholder={placeholder ?? 'Digite sua resposta aqui.'}
                    onChange={onChange}
                    disabled={disabled}
                    {...registerInput}
                    {...rest}
                    max={maxValue}
                    min={minValue}
                />
            );
        }

        return mask !== 'blank' ? (
            <InputMask
                mask={(masks as any)[mask]}
                className={inputClasses}
                type={type}
                placeholder={placeholder ?? 'Digite sua resposta aqui.'}
                onChange={onChange}
                value={value}
                disabled={disabled}
                data-testid="input"
                //@ts-ignore
                maskChar={null}
                {...registerInput}
                {...rest}
                size={size}
                max={maxValue}
                min={minValue}
            />
        ) : (
            <input
                className={inputClasses}
                type={type}
                placeholder={placeholder ?? 'Digite sua resposta aqui.'}
                onChange={onChange}
                value={value}
                disabled={disabled}
                data-testid="input"
                {...registerInput}
                {...rest}
                size={size}
                max={maxValue}
                min={minValue}
            />
        );
    }

    return (
        <>
            {selectInputComponent()}
            {state === 'warning' && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state={state} label={errorLabel} />
                    </div>
                </div>
            )}
            {reviewLabel && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state="warning" label={reviewLabel} />{' '}
                        {/* Aviso de revisão */}
                    </div>
                </div>
            )}
            {state === 'danger' && errorLabel && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state={state} label={errorLabel} />{' '}
                        {/* Aviso de erro */}
                    </div>
                </div>
            )}
        </>
    );
};

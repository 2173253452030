import Icon from '../Icon/Icon';
import Label from '../Label/Label';

interface HomeLinkProps {
  url: string;
  hasBorder?: boolean;
  children: React.ReactNode;
}

export const HomeLink: React.FC<HomeLinkProps> = ({
  children,
  url,
  hasBorder = false,
}) => {
  const anchorClass = `w-full flex justify-between ${
    hasBorder && 'border-b border-gray-100 pb-5 mb-5'
  }`;
  return (
    <a
      className={anchorClass}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Label size="md" color="text-blue-200">
        {children}
      </Label>
      <Icon size="sm" name="external-link-blue-200"></Icon>
    </a>
  );
};

import React from 'react';
import Icon from '../Icon/Icon';

interface ButtonProps {
    style: 'filled' | 'outlined';
    icon?: string;
    iconPosition?: 'center';
    disabled?: boolean;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    isSelected?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    style = 'filled',
    icon,
    iconPosition = 'center',
    disabled = false,
    onClick,
    type = 'button',
    isSelected = false
}) => {
    return (
        <button
        onClick={onClick}
        type={type}
        className={`w-14 h-12 flex flex-row items-center justify-center gap-2.5 rounded-full border-2 ${disabled && 'opacity-50 cursor-not-allowed'
          } ${style === 'filled' || isSelected
            ? `bg-blue-200 border-blue-200 ${!disabled && 'hover:bg-blue-300 hover:border-blue-300'}`
            : `bg-white-100 border-blue-200 ${!disabled && 'hover:bg-blue-warm-100'}`
          }`}
      >
        {icon && iconPosition === 'center' && <Icon name={icon}></Icon>}
      </button>
    );
};

export default Button;

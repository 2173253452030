import { useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import Label from '../Label/Label';
import { mask } from '../../utils/strings/mask';

export function CheckBoxBodyTable({
  name,
  cnpj,
  email,
  pendingInvite = false,
  onClick = () => {},
}: {
  name: string;
  cnpj?: string;
  email?: string;
  pendingInvite?: boolean;
  onClick?: () => void;
}) {
  const [selected, setSelected] = useState<boolean>(false);
  return (
    <>
      <div className="flex items-center justify-start gap-4">
        {/* <Checkbox
          isChecked={selected}
          handleChange={() => setSelected((selected) => !selected)}
        />
        <button
          onClick={() => onClick()}
          className="w-[50px] h-[50px] bg-gray-500 rounded-full flex items-center justify-center"
        >
          <Label className="!font-bold" color="text-white-100 text-base">
            {name.charAt(0).toUpperCase()}
          </Label>
        </button> */}

        <div className="flex flex-col">
          <button onClick={() => onClick()} className="flex flex-col">
            <Label
              className={`max-w-[390px] truncate ${
                pendingInvite ? '!font-normal' : '!font-bold'
              }`}
              color={pendingInvite ? 'text-gray-400' : undefined}
            >
              {name}
            </Label>
            {cnpj && (
              <Label color="text-gray-400" className={'!font-normal'}>
                {mask(cnpj, '##.###.###/####-##')}
              </Label>
            )}
            {email && (
              <Label color="text-gray-400" className={'!font-normal'}>
                {email}
              </Label>
            )}
          </button>
        </div>
      </div>
    </>
  );
}

import { api } from './api';

export interface IUserorganization {
  companyId: string;
  createdDate?: string;
  name: string;
  email: string;
  nickName: string;
  type: string;
  userId: string;
  inviteId?: string;
}

const getPublicCompanies = (): Promise<IOrganization[]> => {
  return api.get('/public/companies');
};

const getPublicOrganizationSectors = (): Promise<ICnae[]> => {
  return api.get('/public/cnaes');
};

const getOrgByCnpj = (cnpj: string): Promise<any> => {
  return api.get('/companies/search', '', cnpj);
};

const createOrganization = (
  newOrg: IOrganization,
  id: string,
): Promise<any> => {
  return api.post(`/companies/with-user/${id}`, newOrg);
};

const participationRequest = (paticipation: IParticipation): Promise<any> => {
  return api.post('/request-participation', paticipation);
};

const getUsersByOrgsIds = (
  ids: string[],
  page?: number,
  pageSize?: number,
): Promise<IPagination<IUserorganization>> => {
  const params = `ids=${ids.join(',')}&page=${page ?? 0}&size=${
    pageSize ?? 10
  }&sort=createdDate&direction=DESC`;
  return api.get(`/companies/users`, params, '');
};

const linkToOrganization = (link: any) => {
  return api.post('/user-company', link);
};

const getGeneralUsers = (
  ids: string[],
  page?: number,
  pageSize?: number,
): Promise<IPagination<IUserorganization>> => {
  const params = `ids=${ids.join(',')}&page=${page ?? 0}&size=${
    pageSize ?? 10
  }&sort=createdDate&direction=DESC`;
  return api.get(`/companies/general-users`, params, '');
};

const getCompaniesAdmin = (
  page?: number,
  pageSize?: number,
): Promise<IPagination<IOrganization>> => {
  const params = `page=${page ?? 0}&size=${pageSize ?? 10}`;

  return api.get(`/companies/get-all-admin`, params, '');
};

const getQtdCompanies = (): Promise<[IGraphic]> => {
  return api.get(`/companies/by-status`);
};

export const organizationService = {
  getOrgByCnpj,
  createOrganization,
  participationRequest,
  getUsersByOrgsIds,
  linkToOrganization,
  getGeneralUsers,
  getCompaniesAdmin,
  getQtdCompanies,
  getPublicCompanies,
  getPublicOrganizationSectors,
};

import React, { useContext, useState } from 'react';
import ModalAlert from './ModalAlert';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import { TutorialAppContext } from '../../contexts/TutorialAppContext';
import { AuthContext } from '../../contexts/AuthContext';
import { ROUTES } from '../../routes/Index.routes';
import { api } from '../../services/api';
import { FormIdContext } from '../../contexts/FormContext'; 
import { formsService } from '../../services/formsService';

interface AddInventoryModalProps {
    visible: boolean;
    closeModal: () => void;
}
export const AddInventoryModal: React.FC<AddInventoryModalProps> = ({
    visible,
    closeModal,
}) => {

    const navigate = useNavigate();
    const { continueTutorial } = useContext(TutorialAppContext);
    const { session } = useContext(AuthContext);

    const { id, setIdValue } = useContext(FormIdContext);

    const handleContinue = () => {
        closeModal();
        navigate(ROUTES.INVENTORY_FORM.path);
        if (!session?.tutorialVisualisation?.sawFormTutorial) continueTutorial();
    };

    return (
        <ModalAlert visible={visible}>
            <section className=" w-auto md:w-[38.125rem] h-screen md:h-[43.313rem] relative">
                <div className="absolute top-0 left-0 right-0 bottom-0">
                    <button
                        onClick={() => closeModal()}
                        className="absolute top-[19px] right-[19px] border-none bg-none"
                    >
                        <Icon name="close-blue-200" size="sm" />
                    </button>
                </div>
                <div className="pt-10 px-10">
                    <Label size="xl" className="!font-bold" color="text-black-100">
                        Adicionar inventário
                    </Label>
                </div>
                <div className="pt-2.5 px-10">
                    <Label color="text-gray-400">
                        Você pode baixar o formulário e carregá-lo com as informações
                        preenchidas, ou continuar e preencher as informações direto na
                        plataforma.
                    </Label>
                </div>
                <div className="mt-10 h-[0.063rem] bg-gray-200"></div>
                <div className="pt-10 px-10">
                    <Label color="text-gray-400">
                        Existem duas formas para adicionar um inventário:
                    </Label>
                </div>
                <div className="pt-5 px-10">
                    <Label color="text-gray-400">
                        (a) Você pode baixar o formulário de relato ("Baixar formulário"
                        abaixo), preencher as informações e "Carregar o arquivo" na etapa
                        seguinte.
                    </Label>
                </div>
                <div className="pt-5 px-10">
                    <Label color="text-gray-400">
                        (b) Ou você pode também preencher todos os campos diretamente na
                        plataforma. Para isso, basta ignorar a etapa de carregamento do
                        formulário e clicar no botão "Continuar".
                    </Label>
                </div>
                <div className="pt-5 px-10">
                    <Label color="text-gray-400">
                        Após continuar, você poderá editar os campos ou carregar um
                        formulário.
                    </Label>
                </div>
                <div className="absolute bottom-[12.0rem] md:bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>
                <div className="flex flex-col md:flex-row absolute bottom-10 left-10 right-10 gap-2.5">
                    <Button
                        style="outlined"
                        label="Baixar formulário"
                        icon="download-file-blue-200"
                        onClick={() => { 
                            formsService.downloadSheetFile(); 
                    }
                }
                    />
                    <Button
                        style="filled"
                        label="Continuar"
                        iconPosition="right"
                        icon="arrow-right-button-white-100"
                        onClick={() => handleContinue()}
                    />
                </div>
            </section>
        </ModalAlert>
    );
};

import React from 'react';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';

interface ButtonProps {
    style: 'filled' | 'outlined' | 'none';
    icon?: string;
    iconPosition?: 'left' | 'right';
    iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    isSelected?: boolean;
    width?: string;
    height?: string;
}

const Button: React.FC<ButtonProps> = ({
    style = 'filled',
    icon,
    iconPosition = 'left',
    iconSize = 'sm',
    label,
    disabled = false,
    onClick,
    type = 'button',
    isSelected = false,
    width,
    height,
}) => {
    function handleThemeColor() {
        switch (style) {
            case 'filled':
                return isSelected
                    ? `bg-blue-300 border-blue-300 ${!disabled && 'hover:none'}`
                    : `bg-blue-200 border-blue-200 ${!disabled && 'hover:bg-blue-300 hover:border-blue-300'
                    }`;
            case 'outlined':
                return isSelected
                    ? `bg-blue-warm-100 border-blue-200 ${!disabled && 'hover:none'}`
                    : `bg-white-100 border-blue-200 ${!disabled && 'hover:bg-blue-warm-100'
                    }`;
            default:
                return 'border-none';
        }
    }

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            type={type}
            className={`
        flex flex-row items-center justify-center gap-2.5 rounded-3xl border-2
        ${width ?? 'w-full h-12'}
        ${height ?? 'w-full h-12'}
        ${disabled && 'opacity-50 cursor-not-allowed'} 
        ${handleThemeColor()}
      `}
        >
            {icon && iconPosition === 'left' && (
                <Icon margin={false} name={icon} size={iconSize}></Icon>
            )}
            {label && (
                <Label color={style === 'filled' ? 'text-white-100' : 'text-blue-200'}>
                    {label}
                </Label>
            )}
            {icon && iconPosition === 'right' && (
                <Icon margin={false} name={icon} size={iconSize}></Icon>
            )}
        </button>
    );
};

export default Button;

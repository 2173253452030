import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/Index.routes';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';

interface CardProps {}

export const Card: React.FC<CardProps> = () => {
  const navigate = useNavigate();

  const cards = [
    { name: 'Sobre', icon: 'people', id: '1', path: ROUTES.ABOUT.path },
    { name: 'Emissões', icon: 'bars', id: '2', path: ROUTES.EMISSIONS.path },
    { name: 'Contato', icon: 'mail', id: '3', path: ROUTES.CONTACT.path },
  ];

  return (
    <div className="container mx-auto lg:px-10 px-4">
      <div className="grid grid-cols-3 gap-5">
        {cards.map((card) => (
          <div
            key={card.id}
            style={{
              boxShadow: '0px 3px 6px 0px rgba(51, 51, 51, 0.16)',
            }}
            className="w-full h-38 bg-white-100 hover:bg-gray-100 hover:cursor-pointer flex flex-col justify-center items-center"
          >
            <div className="mb-2">
              <Icon size="lg" name={card.icon}></Icon>
            </div>
            <div className="flex justify-center">
              <Label size="md">{card.name}</Label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

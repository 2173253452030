interface LoadingProps {}
export const Loading: React.FC<LoadingProps> = () => {
  return (
    <svg
      className="animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
    >
      <path
        fill="#4A77B4"
        d="M19 10.5c.552 0 1.005.449.95.998A10 10 0 1 1 9.002.55c.55-.055.998.398.998.95 0 .552-.45.994-.997 1.062a8 8 0 1 0 8.935 8.935c.068-.548.51-.997 1.062-.997Z"
      />
    </svg>
  );
};

import { api } from './api';

const createUser = (newUser: IUser): Promise<IUser> => {
  return api.post('/users', newUser);
};

const getUserById = (id: string): Promise<IUser> => {
  return api.get('/users', id);
};

const getAllUsers = () => {
  return api.get('/users');
};

const deleteUserById = (id: string) => {
  return api.del(`/users/${id}`);
};

const getUserByCpf = (cpf: string): Promise<IUser> => {
  return api.get('/users/getby', '', cpf);
};

const inviteNewUser = (data: any): Promise<any> => {
  return api.post('/invites/create-invite', data);
};

const deleteInviteUser = (id: string) => {
  return api.del(`/invites/${id}`);
};

const updateTutorialVisualization = (params: string) => {
  return api.put(`/users/${params}`, {});
};

const getAdminUsers = (
  page?: number,
  pageSize?: number,
): Promise<IPagination<IUser>> => {
  const params = `page=${page ?? 0}&size=${pageSize ?? 10}`;

  return api.get(`/users/get-all-admin`, params, '');
};

const inviteNewAdmin = (data: any): Promise<any> => {
  return api.post('/invites/admin/create-invite', data);
};

const deleteInviteAdmin = (id: string) => {
  return api.del(`/invites/admin/${id}`);
};

const getQtdUsersCompanies = (companyIds: string[]): Promise<[IGraphic]> => {
  const params = `companyIds=${companyIds.join(',')}`;
  return api.get(`/user-company/by-status`, params, '');
};

const deleteUserCompany = (id: string, companyId: string) => {
  const params = `?userId=${id}&companyId=${companyId}`;
  return api.del(`/user-company${params}`);
};

export const userService = {
  getAllUsers,
  getUserById,
  getUserByCpf,
  createUser,
  deleteUserById,
  inviteNewUser,
  deleteInviteUser,
  updateTutorialVisualization,
  getAdminUsers,
  inviteNewAdmin,
  deleteInviteAdmin,
  getQtdUsersCompanies,
  deleteUserCompany,
};

import { ReactNode } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';
import { Pagination } from '../Pagination/Pagination';

interface ITableContainer {
  setPagination: (page: number) => void;
  children: ReactNode;
  numberPages: number;
  pagination: number;
  btnLabel?: string;
  title: string;
  icon: string;
  hasButton?: boolean;
  tempHide?: boolean;
}
export const TableContainer = ({
  children,
  title,
  icon,
  btnLabel,
  numberPages,
  pagination,
  hasButton = true,
  setPagination,
  tempHide = false,
}: ITableContainer) => {
  return (
    <>
      <section className="flex-1">
        <header className="w-full flex items-center justify-center gap-2 mb-[40px]">
          <Icon name={icon} />
          <Label size="md" className="!text-bold" color="text-black-200">
            {title}
          </Label>
        </header>
        <div className="border flex flex-col border-gray-200 rounded-md overflow-hidden h-[450px] w-full">
          <div className="flex-1 overflow-auto flex flex-col">{children}</div>
          <footer className="px-7 py-3">
            {numberPages > 0 ? (
              <>
                <Pagination
                  pageNumber={pagination}
                  totalPages={numberPages}
                  setPageNumber={setPagination}
                />
              </>
            ) : (
              <>
                {!tempHide && hasButton && (
                  <Button
                    style="outlined"
                    label={btnLabel}
                    icon="plus-light-blue-200"
                  />
                )}
              </>
            )}
          </footer>
        </div>
      </section>
    </>
  );
};

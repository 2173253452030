import { Dispatch, SetStateAction } from 'react';
import ModalAlert from '../Modal/ModalAlert';
import Label from '../Label/Label';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
interface InviteSuccessProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}
export const InviteSuccess: React.FC<InviteSuccessProps> = ({
  open,
  setOpen,
}) => {
  return (
    <ModalAlert visible={open} onClose={() => setOpen((open) => !open)}>
      <div className="w-auto md:w-[610px] relative p-10">
        <button
          onClick={() => setOpen((open) => !open)}
          className="absolute top-[19px] right-[19px] border-none bg-none"
        >
          <Icon name="close-blue-200" size="sm" />
        </button>
        <div className="text-center gap-2.5 flex flex-col">
          <Label size="xl" className="font-bold">
            Convite enviado com sucesso!
          </Label>
          <Label color="text-gray-400">
            Caso o usuário ainda não possua uma conta na plataforma, o convite
            será encaminhado por e-mail para posterior cadastro pelo usuário.
          </Label>
        </div>
        <div className="w-[260px] mt-10 mx-auto">
          <Button
            style="filled"
            label="Continuar"
            onClick={() => setOpen((open) => !open)}
          />
        </div>
      </div>
    </ModalAlert>
  );
};

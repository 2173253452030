import React from 'react';
import Label from '../Label/Label';
import Icon from '../Icon/Icon';

interface ButtonStepProps {
    state?: IStateClasses | 'visited';
    index: number;
    errorAmount?: number;
    title: string;
    isSelected?: boolean;
    isCompleted?: boolean;
    hasTrack?: boolean;
    onClick?: () => void;
    hasReview?: boolean;
    reviewCount?: number;
    reviewHasBeenReviewed: boolean;
    review?: number;
    isAdmin?: boolean;
}

const ButtonStep: React.FC<ButtonStepProps> = ({
    state = 'primary',
    index,
    title,
    isSelected = false,
    isCompleted = false,
    hasTrack = false,
    onClick,
    errorAmount = 0,
    hasReview,
    reviewCount,
    reviewHasBeenReviewed,
    review,
    isAdmin = false
}) => {


    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    function handleThemeColor() {
        switch (state) {
            case 'primary':
                return isSelected
                    ? 'bg-blue-600 border-blue-600 hover:none'
                    : isCompleted
                        ? 'bg-white-100 border-green-200 hover:bg-blue-warm-100'
                        : 'bg-white-100 border-blue-200 hover:bg-blue-warm-100';
            case 'warning':
                return isSelected
                    ? 'bg-yellow-200 border-yellow-200 hover:bg-yellow-300'
                    : 'bg-white-100 border-yellow-200 hover:bg-yellow-300';

            case 'visited':
                return isAdmin && isSelected ? 'bg-blue-600 border-blue-600 hover:none' : 'bg-white-100 border-gray-500 hover:none'
            default:
                return '';
        }
    }

    function handleIndexColor() {
        switch (state) {
            case 'primary':
                return isSelected
                    ? 'text-white-100'
                    : isCompleted
                        ? 'text-green-200'
                        : 'text-blue-200';
            case 'warning':
                return 'text-gray-500';

            case 'visited':
                return isAdmin && isSelected ? 'text-white-100' : 'text-gray-500'
            default:
                return '';
        }
    }

    function handleTitleColor() {
        switch (state) {
            case 'primary':
                return isSelected
                    ? 'text-blue-600'
                    : isCompleted
                        ? 'text-green-200'
                        : 'text-blue-200';

            case 'warning':
                return 'text-gray-500'
            default:
                return '';
        }
    }

    function handleIconColor() {
        if (state === 'visited') return 'bg-gray-500 rounded-full';

        if (hasReview && !reviewHasBeenReviewed) {
            return `bg-yellow-100 text-yellow-500 after:content-['${reviewCount}'] after:absolute after:-top-1 after:right-[-7px] after:w-4 after:h-4 after:rounded-full after:flex after:items-center after:justify-center rounded-full`;
        }

        return 'bg-green-100 rounded-full';
    }

    function handleIconType() {

        if (index === 1 && hasReview && !reviewHasBeenReviewed) {
            return (<span className="font-bold">{reviewCount}</span>);
        } else if (index === 1 && hasReview && reviewHasBeenReviewed) {
            return (<Icon name="check-without-background-white-100" size="xs"></Icon>);
        }
        if (index !== 0 && reviewHasBeenReviewed) {
            return (<Icon name="check-without-background-white-100" size="xs"></Icon>);
        }
        if (hasReview && reviewHasBeenReviewed) {
            return (<span className="font-bold">{reviewCount}</span>);
        } else if (hasReview && !reviewHasBeenReviewed) {
            return (<span className="font-bold">{reviewCount}</span>);
        } else if (!hasReview) {
            return (<Icon name="check-without-background-white-100" size="xs"></Icon>);
        } else {
            return (<></>);
        }
    }

    return (
        <>
            <div className={`md:ml-[25px] md:w-[2px] md:h-10 ${hasTrack ? (title.length > 45 ? 'md:h-22 md:bg-blue-200' : 'md:h-16 md:bg-blue-200') : 'md:h-[5px]'} hidden md:block`}></div>
            <div className={`flex items-center gap-5 h-[50px]`}>
                <div className="flex items-center relative">
                    <button
                        type="button"
                        onClick={onClick}
                        className={`w-[50px] h-[50px] items-center justify-center gap-2.5 rounded-full border-2 ${handleThemeColor()} md:ml-0`}
                    >
                        <Label size="md" color={handleIndexColor()} className="!font-bold">
                            {index}
                        </Label>
                    </button>
                    {(isAdmin && (index < 24 && (isMobile && !isSelected))) ?
                        <div className={`h-[2px] w-10 ${title.length > 45 ? 'w-22 w-16 bg-blue-200' : 'w-16 bg-blue-200'} md:hidden`}></div> :
                        <div className={`h-[2px] w-10 ${title.length > 45 ? 'w-22 w-16 bg-blue-200' : 'w-16 bg-blue-200'} hidden`}></div>
                    }
                    {!isAdmin && (index < 25 && (isMobile && !isSelected)) ?
                        <div className={`h-[2px] w-10 ${title.length > 45 ? 'w-22 w-16 bg-blue-200' : 'w-16 bg-blue-200'} md:hidden`}></div> :
                        <div className={`h-[2px] w-10 ${title.length > 45 ? 'w-22 w-16 bg-blue-200' : 'w-16 bg-blue-200'} hidden`}></div>
                    }
                    {errorAmount > 0 && (
                        <div className="absolute flex items-center justify-center top-[-5px] left-[30px] w-[30px] h-[30px] rounded-full bg-gray-500">
                            <Label color='text-white-100'>{errorAmount}</Label>
                        </div>
                    )}
                    {review && review > 0 && errorAmount === 0 ?
                        <div className={`absolute flex items-center justify-center top-[-5px] left-[30px] w-[30px] h-[30px] bg-gray-500 rounded-full`}>
                            <Icon name="check-without-background-white-100" size="xs"></Icon>
                        </div> : isCompleted && !isSelected && (
                            <div className={`absolute flex items-center justify-center top-[-5px] left-[30px] w-[30px] h-[30px] ${handleIconColor()}`}>
                                {handleIconType()}
                            </div>
                        )
                    }
                </div>
                {isMobile && !isSelected ? <Label
                    size={'md'}
                    color={handleTitleColor()}
                    className={`!font-medium leading-tight hidden`}
                >
                    {title}
                </Label> : <Label
                    size={`${isMobile ? (title.length > 50 ? 'xs' : 'md') : 'md'}`}
                    color={handleTitleColor()}
                    className={`!font-medium leading-tight mr-[25px]`}
                >
                    {title}
                </Label>
                }
            </div>
        </>
    );
};

export default ButtonStep;

import { createContext, useEffect, useState } from 'react';
import { userService } from '../services/userService';

interface AdministratorContextProps {
  listAdministrator: IPagination<IUser> | null;
  listAdminUsers: (page: number) => void;
}

interface AdministratorProviderProps {
  children: React.ReactNode;
}

export const AdministratorContext = createContext<AdministratorContextProps>({
  listAdministrator: null,
  listAdminUsers: (page: number) => {},
});

export const AdministratorProvider: React.FC<AdministratorProviderProps> = ({
  children,
}) => {
  const [listAdministrator, setListAdministrator] =
    useState<IPagination<IUser> | null>(null);

  const listAdminUsers = (page: number) => {
    userService.getAdminUsers(page).then((list) => {
      setListAdministrator(list);
    });
  };

  return (
    <AdministratorContext.Provider
      value={{ listAdministrator, listAdminUsers }}
    >
      {children}
    </AdministratorContext.Provider>
  );
};

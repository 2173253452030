import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import VerticalChart from '../../components/Chart/VerticalChart/VerticalChart';
import Dropdown from '../../components/Dropdown/Dropdown';
import DropdownMultipleSelect from '../../components/Dropdown/DropdownMultipleSelect';
import Icon from '../../components/Icon/Icon';
import Label from '../../components/Label/Label';
import { categoryService } from '../../services/categoryService';
import { gasService } from '../../services/gasService';
import { graphicService } from '../../services/graphicService';
import { organizationService } from '../../services/organizationService';
import { yearService } from '../../services/yearService';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import React from 'react';
import Button from '../../components/Button/Button';
import DropdownSingleSelect from '../../components/Dropdown/DropdownSingleSelect';
import { LoadingForm } from '../../components/Loading/LoadingForm';
import { BaseTable } from '../../components/Table/BaseTable/BaseTable';
import { RowBody } from '../../components/Table/RowBody/RowBody';
import { RowCell } from '../../components/Table/RowCell/RowCell';
import Tabs from '../../components/TabsManaged/TabsManaged';
import { generatePdfBlob } from './Report';

interface IEmissionsPageProps {}

export const EmissionsPage: React.FC<IEmissionsPageProps> = () => {
  const { setValue, getValues, control } = useForm<any>({ mode: 'onChange' });
  const [isMobile, setIsMobile] = useState(false);
  const chartRef = useRef(null);
  const contentRef = useRef(null);
  const [activeTypeA, setActiveTypeA] = useState('3');
  const [activeTypeB, setActiveTypeB] = useState('3');
  const [activeTypeC, setActiveTypeC] = useState('3');
  const [activeTypeD, setActiveTypeD] = useState('3');

  const [shouldDisableFields, setShouldDisableFields] = useState(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const metric1 = 'CO2e - SAR (GWP100a)';
  const metric2 = 'CO2e - AR4 (GWP100a)';
  const metric3 = 'CO2e - AR5 (GWP100a)';

  let metricLabel = 'Por Gás ou CO2e';
  metricLabel = metricLabel.replace(
    '2',
    String.fromCharCode(metricLabel.charCodeAt(13) + 8272),
  );

  const metricObject = [
    { value: 'Resultado por gás', key: '0', slug: 'Resultado por gás' },
    {
      value: metric1.replace(
        '2',
        String.fromCharCode(metric1.charCodeAt(2) + 8272),
      ),
      key: '1',
      slug: 'Em CO2e - (GWP 100a SAR-1995)',
    },
    {
      value: metric2.replace(
        '2',
        String.fromCharCode(metric2.charCodeAt(2) + 8272),
      ),
      key: '2',
      slug: 'Em CO2e - (GWP 100a AR4-2007)',
    },
    {
      value: metric3.replace(
        '2',
        String.fromCharCode(metric1.charCodeAt(2) + 8272),
      ),
      key: '3',
      slug: 'Em CO2e - (GWP 100a AR5-2014)',
    },
  ];

  const defaultA1SelectedMetric = {
    value: metric3.replace(
      '2',
      String.fromCharCode(metric1.charCodeAt(2) + 8272),
    ),
    key: '3',
    slug: 'Em CO2e - (GWP 100a AR5-2014)',
  };

  const [a1SelectedMetric, setA1SelectedMetric] = useState<IOption | null>(
    defaultA1SelectedMetric,
  );

  const [a1YearState, setA1YearState] = useState<IStateClasses>('success');
  const [a1ScopeState, setA1ScopeState] = useState<IStateClasses>('success');
  const [a1GasState, setA1GasState] = useState<IStateClasses>('success');
  const [a1SimpleGasState, setA1SimpleGasState] =
    useState<IStateClasses>('success');
  const [a1MetricState, setA1MetricState] = useState('success');

  const [a1GraphicResult, setA1GraphicResult] =
    useState<IGraphicPayload | null>(null);

  const [b1YearState, setB1YearState] = useState<IStateClasses>('success');
  const [b1ScopeState, setB1ScopeState] = useState<IStateClasses>('success');
  const [b1GasState, setB1GasState] = useState<IStateClasses>('success');
  const [b1SimpleGasState, setB1SimpleGasState] =
    useState<IStateClasses>('success');
  const [b1CategoryState, setB1CategoryState] =
    useState<IStateClasses>('success');
  const [b1MetricState, setB1MetricState] = useState('success');
  const [b1SelectedGas, setB1SelectedGas] = useState<IOption | null>(null);
  const [b1SelectedMetric, setB1SelectedMetric] = useState<IOption | null>(
    defaultA1SelectedMetric,
  );
  const [b1GraphicResult, setB1GraphicResult] =
    useState<IGraphicPayload | null>(null);

  const [c1YearState, setC1YearState] = useState<IStateClasses>('success');
  const [c1ScopeState, setC1ScopeState] = useState<IStateClasses>('success');
  const [c1GasState, setC1GasState] = useState<IStateClasses>('success');
  const [c1SimpleGasState, setC1SimpleGasState] =
    useState<IStateClasses>('success');
  const [c1CategoryState, setC1CategoryState] =
    useState<IStateClasses>('success');

  const [c1MetricState, setC1MetricState] = useState('success');
  const [c1SectorState, setC1SectorState] = useState<IStateClasses>('success');

  const [c1SelectedGas, setC1SelectedGas] = useState<IOption | null>(null);
  const [c1SelectedMetric, setC1SelectedMetric] = useState<IOption | null>(
    defaultA1SelectedMetric,
  );

  const [c1GraphicResult, setC1GraphicResult] =
    useState<IGraphicPayload | null>(null);

  const [d1SelectedMetric, setD1SelectedMetric] = useState<IOption | null>(
    defaultA1SelectedMetric,
  );

  const [d1SelectedGas, setD1SelectedGas] = useState<IOption | null>(null);
  const [d1YearState, setD1YearState] = useState<IStateClasses>('success');
  const [d1ScopeState, setD1ScopeState] = useState<IStateClasses>('success');
  const [d1GasState, setD1GasState] = useState<IStateClasses>('success');

  const [d1SimpleGasState, setD1SimpleGasState] =
    useState<IStateClasses>('success');
  const [d1MetricState, setD1MetricState] = useState('success');
  const [d1OrganizationState, setD1OrganizationState] =
    useState<IStateClasses>('success');

  const [d1GraphicResult, setD1GraphicResult] =
    useState<IGraphicPayload | null>(null);

  const [d1TableResult, setD1TableResult] = useState<
    IGraphicOrganizationTable[] | null
  >(null);

  const [emptyStateLabel, setEmptyStateLabel] = useState('');

  const [classes, setClasses] = useState<IOption[]>([]);
  const [year, setYear] = useState<IOption[]>([]);
  const [organizations, setOrganizations] = useState<IOption[]>([]);
  const [categories, setCategories] = useState<ICategoryMap[]>([]);
  const [gas, setGas] = useState<IGasMap[]>([]);
  const [categoriesOriginal, setCategoriesOriginal] = useState<ICategoryMap[]>(
    [],
  );

  useEffect(() => {
    setEmptyStateLabel('Nenhum registro encontrado para os filtros aplicados.');
  }, []);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    setShowLoading(true);
    setDefaultFilters();
    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  }, []);

  const handleTabClick = async (tabIndex: number) => {
    switch (tabIndex) {
      case 1:
        await setDefaultFiltersB();
        break;
      case 2:
        await setDefaultFiltersC();
        break;
      case 3:
        await setDefaultFiltersD();
        break;
      default:
        break;
    }
  };

  function setCategoryFilter(option: any, type: string) {
    const transformedValues = option.map((item: { value: string }) =>
      item.value.replace(/ /g, '_').toUpperCase(),
    );

    const filteredCategories = categoriesOriginal.filter((item) =>
      transformedValues.includes(item.scope),
    );

    if (type === 'b1' && getValues('b1Category') !== undefined) {
      const selectedCategories = getValues('b1Category');

      const filteredSelectedCategories = selectedCategories.filter(
        (item: { scope: any }) => transformedValues.includes(item.scope),
      );
      if (filteredSelectedCategories.length === 0) {
        setValue('b1Category', undefined);
      }
    }

    if (type === 'c1' && getValues('c1Category') !== undefined) {
      const selectedCategories = getValues('c1Category');

      const filteredSelectedCategories = selectedCategories.filter(
        (item: { scope: any }) => transformedValues.includes(item.scope),
      );
      if (filteredSelectedCategories.length === 0) {
        setValue('c1Category', undefined);
      }
    }

    setCategories(
      filteredCategories.length > 0 ? filteredCategories : categoriesOriginal,
    );
  }

  const setDefaultFilters = async () => {
    // set default filter A
    const year = await getYear();
    const gas = await getGas();

    setValue('a1Year', year ? year : [{ value: 'Todos', key: 'Todos' }]);
    setValue('a1Scope', scopeObject);

    setActiveTypeA('3');
    setValue('a1Gas', gas ? gas : [{ value: 'Todos', key: 'Todos' }]);
    await handleValidationTypeA();
  };

  const setDefaultFiltersB = async () => {
    setShowLoading(true);
    // set default filter B
    const categoryList = await getCategories();
    setValue('b1Year', year ? year : [{ value: 'Todos', key: 'Todos' }]);
    setValue('b1Scope', scopeObject);
    setValue(
      'b1Category',
      categoryList ? categoryList : [{ value: 'Todos', key: 'Todos' }],
    );
    setActiveTypeB('3');
    setValue('b1Gas', gas ? gas : [{ value: 'Todos', key: 'Todos' }]);
    await handleValidationTypeB();
    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  };

  const setDefaultFiltersC = async () => {
    setShowLoading(true);
    // set default filter C
    const sector = await getClasses();
    setValue('c1Year', year ? year : [{ value: 'Todos', key: 'Todos' }]);
    setValue('c1Scope', scopeObject);
    setValue(
      'c1Category',
      categories ? categories : [{ value: 'Todos', key: 'Todos' }],
    );
    setValue('c1Sector', sector ? sector : [{ value: 'Todos', key: 'Todos' }]);
    setActiveTypeB('3');
    setValue('c1Gas', gas ? gas : [{ value: 'Todos', key: 'Todos' }]);
    await handleValidationTypeC();
    setShowLoading(false);
  };

  const setDefaultFiltersD = async () => {
    setShowLoading(true);
    // set default filter D
    const companiesList = await getCompanies();

    setValue('d1Year', year ? year : [{ value: 'Todos', key: 'Todos' }]);
    setValue('d1Scope', scopeObject);

    setActiveTypeD('3');
    setValue('d1Gas', gas ? gas : [{ value: 'Todos', key: 'Todos' }]);
    setValue(
      'd1Organization',
      companiesList ? companiesList : [{ value: 'Todos', key: 'Todos' }],
    );

    await handleValidationTypeD();
    setShowLoading(false);
  };

  const getYear = async () => {
    try {
      const response = await yearService.getAllYear();
      if (response) {
        const mappedYears: IOption[] = response.map((number) => ({
          key: number.toString(),
          value: number.toString(),
        }));
        mappedYears.unshift({
          key: 'Todos',
          value: 'Todos',
        });
        setYear(mappedYears);
        return mappedYears;
      }
    } catch (error) {
      console.error('Cannot fetch Year data.', error);
    }
  };

  const getClasses = async () => {
    try {
      const response = await organizationService.getPublicOrganizationSectors();
      if (response) {
        const mappedSectors: IOption[] = response.map(
          (item: { id: any; descricao: any }) => ({
            key: item.id ? item.id : '',
            value: item.descricao ? item.descricao : '',
          }),
        );
        mappedSectors.unshift({
          key: 'Todos',
          value: 'Todos',
        });
        setClasses(mappedSectors);
        return mappedSectors;
      }
    } catch (error) {
      console.error('Cannot fetch CNAE data.', error);
    }
  };

  const getCompanies = async () => {
    try {
      const response = await organizationService.getPublicCompanies();
      if (response) {
        const mappedOrganizations: IOption[] = response.map((item) => ({
          key: item.id ? item.id : '',
          value: item.fantasyName ? item.fantasyName : '',
        }));
        mappedOrganizations.unshift({
          key: 'Todos',
          value: 'Todos',
        });

        setOrganizations(mappedOrganizations);
        return mappedOrganizations;
      }
    } catch (error) {
      console.error('Cannot fetch Organization data.', error);
    }
  };

  const getCategories = async () => {
    try {
      const response = await categoryService.getCategories();
      const mappedCategories: ICategoryMap[] = response.map((item) => ({
        key: item.id,
        value: item.description,
        scope: item.scope,
      }));
      setCategoriesOriginal(mappedCategories);
      mappedCategories.unshift({
        key: 'Todos',
        value: 'Todos',
        scope: 'Todos',
      });
      setCategories(mappedCategories);
      return mappedCategories;
    } catch (error) {
      console.error('Cannot fetch Categories data.', error);
    }
  };

  const getGas = async () => {
    try {
      const response = await gasService.getAllGas();
      const mappedGas: IGasMap[] = response.map((item) => ({
        key: item.acronym,
        value: item.description,
      }));
      mappedGas.unshift({ key: 'Todos', value: 'Todos' });
      setGas(mappedGas);
      return mappedGas;
    } catch (error) {
      console.error('Cannot fetch Gas data.', error);
    }
  };

  const captureScreenshot = async (fileName: string) => {
    const chartElement = chartRef.current;

    if (chartElement) {
      try {
        const canvas = await html2canvas(chartElement);

        if (canvas) {
          canvas.toBlob((blob) => {
            if (blob) {
              saveAs(blob, `${fileName}.png`);
            } else {
              console.error('Error converting canvas to blob');
            }
          });
        } else {
          console.error('Error capturing screenshot: canvas is null');
        }
      } catch (error) {
        console.error('Error capturing screenshot:', error);
      }
    }
  };

  const exportToExcelBorC = async (
    headers: string[],
    fileName: string,
    data: IGraphicTableItem[],
  ) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const headerRow = worksheet.addRow(headers);
    headerRow.font = { bold: true };

    data.forEach((table) => {
      const dataRow = [
        table['category'],
        ...Object.values(table).filter((value, index) => index !== 7),
      ];
      worksheet.addRow(dataRow);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const exportToExcel = async (
    headers: string[],
    fileName: string,
    data: IGraphicTableItem[],
  ) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const headerRow = worksheet.addRow(headers);
    headerRow.font = { bold: true };

    data.forEach((row) => {
      const rowData = [String(row.year)];

      if (row.ESCOPO_1 !== undefined) {
        rowData.push(String(row.ESCOPO_1));
      }

      if (row.ESCOPO_2 !== undefined) {
        rowData.push(String(row.ESCOPO_2));
      }

      if (row.ESCOPO_3 !== undefined) {
        rowData.push(String(row.ESCOPO_3));
      }

      worksheet.addRow(rowData);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const downloadPDF = async () => {
    const pdfBlob = await generatePdfBlob();
    saveAs(pdfBlob, 'relatorio-org.pdf');
  };

  const handleValidationTypeA = async (metric?: IOption | null) => {
    let a1YearParam = getValues('a1Year');
    let a1GasParam = getValues('a1Gas');
    let a1GasSimpleParam = getValues('a1GasSimple');
    let a1ScopeParam = getValues('a1Scope');
    let a1MetricParam = metric?.slug ? metric.slug : a1SelectedMetric?.slug;
    let activeType = metric?.key ? metric.key : '1';

    let errorCount = 0;

    if (a1YearParam === undefined || a1YearParam.length === 0) {
      setA1YearState('danger');
      errorCount += 1;
    } else {
      setA1YearState('success');
    }

    if (activeType === '0') {
      if (a1GasSimpleParam === undefined) {
        setA1SimpleGasState('danger');
        errorCount += 1;
      } else {
        setA1SimpleGasState('success');
      }
    } else {
      if (a1GasParam === undefined) {
        setA1GasState('danger');
        errorCount += 1;
      } else {
        setA1GasState('success');
      }
    }

    if (a1ScopeParam === undefined || a1ScopeParam.length === 0) {
      setA1ScopeState('danger');
      errorCount += 1;
    } else {
      setA1ScopeState('success');
    }

    if (a1MetricParam === undefined) {
      setA1MetricState('danger');
      errorCount += 1;
    } else {
      setA1MetricState('success');
    }

    if (errorCount === 0) {
      if (activeType === '0') {
        fetchTypeAGraphic('A2', undefined);
      } else {
        fetchTypeAGraphic('A1', metric ? metric : undefined);
      }
    }
  };

  const handleValidationTypeB = (metric?: IOption | null) => {
    let b1YearParam = getValues('b1Year');
    let b1GasParam = getValues('b1Gas');
    let b1GasSimpleParam = getValues('b1GasSimple');
    let b1ScopeParam = getValues('b1Scope');
    let b1CategoryParam = getValues('b1Category');
    let b1MetricParam = metric?.slug ? metric.slug : b1SelectedMetric?.slug;
    let activeType = metric?.key ? metric.key : '1';

    let errorCount = 0;

    if (b1YearParam === undefined || b1YearParam.length === 0) {
      setB1YearState('danger');
      errorCount += 1;
    } else {
      setB1YearState('success');
    }

    if (activeType === '0') {
      if (b1GasSimpleParam === undefined) {
        setB1SimpleGasState('danger');
        errorCount += 1;
      } else {
        setB1SimpleGasState('success');
      }
    } else {
      if (b1GasParam === undefined) {
        setB1GasState('danger');
        errorCount += 1;
      } else {
        setB1GasState('success');
      }
    }

    if (b1CategoryParam === undefined || b1CategoryParam.length === 0) {
      setB1CategoryState('danger');
      errorCount += 1;
    } else {
      setB1CategoryState('success');
    }

    if (b1ScopeParam === undefined || b1ScopeParam.length === 0) {
      setB1ScopeState('danger');
      errorCount += 1;
    } else {
      setB1ScopeState('success');
    }

    if (b1MetricParam === undefined) {
      setB1MetricState('danger');
      errorCount += 1;
    } else {
      setB1MetricState('success');
    }

    if (errorCount === 0) {
      if (activeType === '0') {
        fetchTypeBGraphic('B2', undefined);
      } else {
        fetchTypeBGraphic('B1', metric ? metric : undefined);
      }
    }
  };

  const handleValidationTypeC = (metric?: IOption | null) => {
    let c1YearParam = getValues('c1Year');
    let c1GasParam = getValues('c1Gas');
    let c1GasSimpleParam = getValues('c1GasSimple');
    let c1ScopeParam = getValues('c1Scope');
    let c1CategoryParam = getValues('c1Category');
    let c1SectorParam = getValues('c1Sector');
    let c1MetricParam = metric?.slug ? metric.slug : c1SelectedMetric?.slug;
    let activeType = metric?.key ? metric.key : '1';

    let errorCount = 0;

    if (c1YearParam === undefined || c1YearParam.length === 0) {
      setC1YearState('danger');
      errorCount += 1;
    } else {
      setC1YearState('success');
    }

    if (activeType === '0') {
      if (c1GasSimpleParam === undefined) {
        setC1SimpleGasState('danger');
        errorCount += 1;
      } else {
        setC1SimpleGasState('success');
      }
    } else {
      if (c1GasParam === undefined) {
        setC1GasState('danger');
        errorCount += 1;
      } else {
        setC1GasState('success');
      }
    }

    if (c1CategoryParam === undefined || c1CategoryParam.length === 0) {
      setC1CategoryState('danger');
      errorCount += 1;
    } else {
      setC1CategoryState('success');
    }

    if (c1ScopeParam === undefined || c1ScopeParam.length === 0) {
      setC1ScopeState('danger');
      errorCount += 1;
    } else {
      setC1ScopeState('success');
    }

    if (c1MetricParam === undefined) {
      setC1MetricState('danger');
      errorCount += 1;
    } else {
      setC1MetricState('success');
    }

    if (c1SectorParam === undefined || c1SectorParam.length === 0) {
      setC1SectorState('danger');
      errorCount += 1;
    } else {
      setC1SectorState('success');
    }

    if (errorCount === 0) {
      if (activeType === '0') {
        fetchTypeCGraphic('C2', undefined);
      } else {
        fetchTypeCGraphic('C1', metric ? metric : undefined);
      }
    }
  };

  const handleValidationTypeD = (metric?: IOption | null, orgId?: string) => {
    let d1YearParam = getValues('d1Year');
    let d1GasParam = getValues('d1Gas');
    let d1GasSimpleParam = getValues('d1GasSimple');
    let d1ScopeParam = getValues('d1Scope');
    let d1MetricParam = metric?.slug ? metric.slug : d1SelectedMetric?.slug;
    let activeType = metric?.key ? metric.key : '1';
    let d1OrganizationParam = getValues('d1Organization');

    let errorCount = 0;

    if (d1YearParam === undefined || d1YearParam.length === 0) {
      setD1YearState('danger');
      errorCount += 1;
    } else {
      setD1YearState('success');
    }

    if (activeType === '0') {
      if (d1GasSimpleParam === undefined) {
        setD1SimpleGasState('danger');
        errorCount += 1;
      } else {
        setD1SimpleGasState('success');
      }
    } else {
      if (d1GasParam === undefined) {
        setD1GasState('danger');
        errorCount += 1;
      } else {
        setD1GasState('success');
      }
    }

    if (d1ScopeParam === undefined || d1ScopeParam.length === 0) {
      setD1ScopeState('danger');
      errorCount += 1;
    } else {
      setD1ScopeState('success');
    }

    if (d1MetricParam === undefined) {
      setD1MetricState('danger');
      errorCount += 1;
    } else {
      setD1MetricState('success');
    }

    if (d1OrganizationParam === undefined) {
      setD1OrganizationState('danger');
      errorCount += 1;
    } else {
      setD1OrganizationState('success');
    }

    if (errorCount === 0) {
      if (activeType === '0') {
        fetchTypeDGraphic('D2', undefined, orgId ? orgId : undefined);
      } else {
        fetchTypeDGraphic(
          'D1',
          metric ? metric : undefined,
          orgId ? orgId : undefined,
        );
      }
    }
  };

  const scopeObject = [
    { value: 'Todos', key: '0' },
    { value: 'Escopo 1', key: '1' },
    { value: 'Escopo 2', key: '2' },
    { value: 'Escopo 3', key: '3' },
  ];

  const TypeAGraphicContent: React.FC = () => {
    const isEscopo1Present = a1GraphicResult?.table.some(
      (item) => item['ESCOPO_1'],
    );
    const isEscopo2Present = a1GraphicResult?.table.some(
      (item) => item['ESCOPO_2'],
    );
    const isEscopo3Present = a1GraphicResult?.table.some(
      (item) => item['ESCOPO_3'],
    );

    const exportHeaders = ['Ano'];

    if (isEscopo1Present) {
      exportHeaders.push('Escopo 1');
    }
    if (isEscopo2Present) {
      exportHeaders.push('Escopo 2');
    }
    if (isEscopo3Present) {
      exportHeaders.push('Escopo 3');
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TypeAFiltersRow />
        <div className="flex justify-center mt-4 space-x-2"></div>

        {!showLoading &&
        a1GraphicResult != null &&
        a1GraphicResult.datasets.length > 0 ? (
          <>
            <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
              <Button
                style="filled"
                label="Exportar gráfico"
                icon="download-file"
                onClick={() => captureScreenshot('Grafico-Emissao-Escopo')}
              />
            </div>
            <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-hidden">
              <div className="mt-10">
                <div ref={chartRef}>
                  {showLoading ? (
                    <></>
                  ) : (
                    <VerticalChart payload={a1GraphicResult} />
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
              <Button
                style="filled"
                label="Exportar tabela"
                icon="download-file"
                onClick={() =>
                  exportToExcel(
                    exportHeaders,
                    'Exportacao-Emissao-Escopo.xlsx',
                    a1GraphicResult?.table,
                  )
                }
              />
            </div>
            <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-x-auto lg:overflow-x-hidden">
              <BaseTable
                headerTitlesComponents={[
                  'Ano',
                  isEscopo1Present ? 'Escopo 1' : 'hide',
                  isEscopo2Present ? 'Escopo 2' : 'hide',
                  isEscopo3Present ? 'Escopo 3' : 'hide',
                ]}
                childrensLength={a1GraphicResult?.table.length ?? 0}
              >
                {a1GraphicResult?.table.map((table) => {
                  return (
                    <>
                      <RowBody key={table.year}>
                        <RowCell>
                          <Label
                            size={isMobile ? 'xm' : 'sm'}
                            className="!whitespace-nowrap !font-normal"
                          >
                            {table.year}
                          </Label>
                        </RowCell>
                        {isEscopo1Present && (
                          <RowCell>
                            <Label size={isMobile ? 'xm' : 'sm'}>
                              {typeof table.ESCOPO_1 === 'number' &&
                              table.ESCOPO_1 > 0
                                ? table.ESCOPO_1.toFixed(2)
                                : table.ESCOPO_1}
                            </Label>
                          </RowCell>
                        )}
                        {isEscopo2Present && (
                          <RowCell>
                            <Label size={isMobile ? 'xm' : 'sm'}>
                              {typeof table.ESCOPO_2 === 'number' &&
                              table.ESCOPO_2 > 0
                                ? table.ESCOPO_2.toFixed(2)
                                : table.ESCOPO_2}
                            </Label>
                          </RowCell>
                        )}
                        {isEscopo3Present && (
                          <RowCell>
                            <Label size={isMobile ? 'xm' : 'sm'}>
                              {typeof table.ESCOPO_3 === 'number' &&
                              table.ESCOPO_3 > 0
                                ? table.ESCOPO_3.toFixed(2)
                                : table.ESCOPO_3}
                            </Label>
                          </RowCell>
                        )}
                      </RowBody>
                    </>
                  );
                })}
              </BaseTable>
            </div>
          </>
        ) : (
          <div style={{ flex: 1 }}>
            {showLoading ? <></> : <EmptyStateView />}
          </div>
        )}
      </div>
    );
  };

  const TypeBGraphicContent: React.FC = () => {
    let headerTitle = ['Categoria'];

    if (
      b1GraphicResult &&
      b1GraphicResult.labels &&
      b1GraphicResult.labels.length > 0
    ) {
      b1GraphicResult.labels.forEach((option) => {
        headerTitle.push(option);
      });
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TypeBFiltersRow />
        <div className="flex justify-center mt-4 space-x-2"></div>
        <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
          <Button
            style="filled"
            label="Exportar gráfico"
            icon="download-file"
            onClick={() => captureScreenshot('Grafico-Emissao-Categoria')}
          />
        </div>
        {!showLoading &&
        b1GraphicResult != null &&
        b1GraphicResult.datasets.length > 0 ? (
          <>
            <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-x-auto lg:overflow-x-hidden">
              <div className="mt-10">
                <div ref={chartRef}>
                  <VerticalChart payload={b1GraphicResult} />
                </div>
              </div>
            </div>

            <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
              <Button
                style="filled"
                label="Exportar tabela"
                icon="download-file"
                onClick={() =>
                  exportToExcelBorC(
                    headerTitle,
                    'Exportacao-Emissao-Categoria.xlsx',
                    b1GraphicResult?.table,
                  )
                }
              />
            </div>
            <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-x-auto lg:overflow-x-hidden">
              <BaseTable
                headerTitlesComponents={headerTitle}
                childrensLength={b1GraphicResult?.table.length ?? 0}
              >
                {b1GraphicResult?.table.map((table) => {
                  return (
                    <RowBody key={table.category}>
                      <RowCell>
                        <Label
                          size={'xm'}
                          className="whitespace-normal !font-normal"
                        >
                          {table.category}
                        </Label>
                      </RowCell>
                      {b1GraphicResult?.labels.map((year) => (
                        <>
                          {year !== 'Categoria' && (
                            <RowCell key={year}>
                              <Label size={'xm'}>
                                {typeof table[year] === 'number'
                                  ? (table[year] as number).toFixed(2)
                                  : table[year]}
                              </Label>
                            </RowCell>
                          )}
                        </>
                      ))}
                    </RowBody>
                  );
                })}
              </BaseTable>
            </div>
          </>
        ) : (
          <div style={{ flex: 1 }}>
            {showLoading ? <></> : <EmptyStateView />}
          </div>
        )}
      </div>
    );
  };

  const TypeCGraphicContent: React.FC = () => {
    let headerTitle = ['Categoria'];

    if (
      c1GraphicResult &&
      c1GraphicResult.labels &&
      c1GraphicResult.labels.length > 0
    ) {
      c1GraphicResult.labels.forEach((option) => {
        headerTitle.push(option);
      });
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TypeCFiltersRow />
        <div className="flex justify-center mt-4 space-x-2"></div>
        <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
          <Button
            style="filled"
            label="Exportar gráfico"
            icon="download-file"
            onClick={() => captureScreenshot('Grafico-Emissao-Setor')}
          />
        </div>
        {!showLoading &&
        c1GraphicResult != null &&
        c1GraphicResult.datasets.length > 0 ? (
          <>
            <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-x-auto lg:overflow-x-hidden">
              <div className="mt-10">
                <div ref={chartRef}>
                  <VerticalChart payload={c1GraphicResult} />
                </div>
              </div>
            </div>
            <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
              <Button
                style="filled"
                label="Exportar tabela"
                icon="download-file"
                onClick={() =>
                  exportToExcelBorC(
                    headerTitle,
                    'Exportacao-Emissao-Setor.xlsx',
                    c1GraphicResult?.table,
                  )
                }
              />
            </div>
            <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-x-auto lg:overflow-x-hidden">
              <BaseTable
                headerTitlesComponents={headerTitle}
                childrensLength={c1GraphicResult?.table.length ?? 0}
              >
                {c1GraphicResult?.table.map((table) => {
                  return (
                    <RowBody key={table.category}>
                      <RowCell>
                        <Label
                          size={'xm'}
                          className="whitespace-normal !font-normal"
                        >
                          {table.category}
                        </Label>
                      </RowCell>
                      {c1GraphicResult?.labels.map((year) => (
                        <>
                          {year !== 'Categoria' && (
                            <RowCell key={year}>
                              <Label size={'xm'}>
                                {typeof table[year] === 'number'
                                  ? (table[year] as number).toFixed(2)
                                  : table[year]}
                              </Label>
                            </RowCell>
                          )}
                        </>
                      ))}
                    </RowBody>
                  );
                })}
              </BaseTable>
            </div>
          </>
        ) : (
          <div style={{ flex: 1 }}>
            {showLoading ? <></> : <EmptyStateView />}
          </div>
        )}
      </div>
    );
  };

  const TypeDGraphicContent: React.FC = () => {
    let headerTitle = [
      'Organização Inventariante',
      'Setor',
      'Inventários Relatados',
      'UF',
    ];

    const isEscopo1Present = d1GraphicResult?.table.some(
      (item) => item['ESCOPO_1'],
    );
    const isEscopo2Present = d1GraphicResult?.table.some(
      (item) => item['ESCOPO_2'],
    );
    const isEscopo3Present = d1GraphicResult?.table.some(
      (item) => item['ESCOPO_3'],
    );

    const exportHeaders = ['Ano'];

    if (isEscopo1Present) {
      exportHeaders.push('Escopo 1');
    }
    if (isEscopo2Present) {
      exportHeaders.push('Escopo 2');
    }
    if (isEscopo3Present) {
      exportHeaders.push('Escopo 3');
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TypeDFiltersRow />
        <div className="flex justify-center mt-4 space-x-2"></div>

        {!showLoading && d1TableResult != null && d1TableResult.length > 0 ? (
          <>
            <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-x-auto lg:overflow-x-hidden">
              <BaseTable
                headerTitlesComponents={headerTitle}
                childrensLength={d1TableResult.length ?? 0}
              >
                {d1TableResult.map((table) => (
                  <RowBody key={table.cnpj}>
                    <RowCell>
                      <div
                        className="flex flex-col cursor-pointer"
                        onClick={() =>
                          handleValidationTypeD(
                            d1SelectedMetric?.key === '0'
                              ? { key: '0', value: 'Resultado por gás' }
                              : undefined,
                            organizations.find(
                              (org) => org.value === table.fantasyName,
                            )?.key,
                          )
                        }
                      >
                        <Label
                          size={'xm'}
                          className="whitespace-normal !font-semibold"
                        >
                          {table.fantasyName}
                        </Label>
                        <Label
                          size={'xm'}
                          className="whitespace-normal !font-normal"
                        >
                          {table.cnpj
                            .replace(/\D/g, '')
                            .replace(/(\d{2})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1/$2')
                            .replace(/(\d{4})(\d)/, '$1-$2')
                            .replace(/(-\d{2})\d+?$/, '$1')}
                        </Label>
                      </div>
                    </RowCell>
                    <RowCell>
                      <Label size={'xm'}>{table.activity}</Label>
                    </RowCell>
                    <RowCell>
                      <Label size={'xm'}>{table.years}</Label>
                    </RowCell>
                    <RowCell>
                      <Label size={'xm'}>{table.state}</Label>
                    </RowCell>
                  </RowBody>
                ))}
              </BaseTable>
            </div>
            {d1GraphicResult != null && d1GraphicResult.datasets.length > 0 && (
              <>
                <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
                  <Button
                    style="filled"
                    label="Exportar gráfico"
                    icon="download-file"
                    onClick={() =>
                      captureScreenshot('Grafico-Emissao-Escopo-Org')
                    }
                  />
                </div>
                <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-hidden">
                  <div className="mt-10">
                    <div ref={chartRef}>
                      <VerticalChart payload={d1GraphicResult} />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center px-4 lg:justify-end lg:w-[260px] lg:ml-auto lg:mr-10">
                  <Button
                    style="filled"
                    label="Exportar tabela"
                    icon="download-file"
                    onClick={() => downloadPDF()}
                  />
                </div>
                <div className="flex justify-center m-4 border border-gray-200 rounded-md overflow-x-auto lg:overflow-x-hidden">
                  <BaseTable
                    headerTitlesComponents={[
                      'Ano',
                      isEscopo1Present ? 'Escopo 1' : 'hide',
                      isEscopo2Present ? 'Escopo 2' : 'hide',
                      isEscopo3Present ? 'Escopo 3' : 'hide',
                    ]}
                    childrensLength={d1GraphicResult?.table.length ?? 0}
                  >
                    {d1GraphicResult?.table.map((table) => {
                      return (
                        <>
                          <RowBody key={table.year}>
                            <RowCell>
                              <Label
                                size={isMobile ? 'xm' : 'sm'}
                                className="!whitespace-nowrap !font-normal"
                              >
                                {table.year}
                              </Label>
                            </RowCell>
                            {isEscopo1Present && (
                              <RowCell>
                                <Label size={isMobile ? 'xm' : 'sm'}>
                                  {typeof table.ESCOPO_1 === 'number' &&
                                  table.ESCOPO_1 > 0
                                    ? table.ESCOPO_1.toFixed(2)
                                    : table.ESCOPO_1}
                                </Label>
                              </RowCell>
                            )}
                            {isEscopo2Present && (
                              <RowCell>
                                <Label size={isMobile ? 'xm' : 'sm'}>
                                  {typeof table.ESCOPO_2 === 'number' &&
                                  table.ESCOPO_2 > 0
                                    ? table.ESCOPO_2.toFixed(2)
                                    : table.ESCOPO_2}
                                </Label>
                              </RowCell>
                            )}
                            {isEscopo3Present && (
                              <RowCell>
                                <Label size={isMobile ? 'xm' : 'sm'}>
                                  {typeof table.ESCOPO_3 === 'number' &&
                                  table.ESCOPO_3 > 0
                                    ? table.ESCOPO_3.toFixed(2)
                                    : table.ESCOPO_3}
                                </Label>
                              </RowCell>
                            )}
                          </RowBody>
                        </>
                      );
                    })}
                  </BaseTable>
                </div>
              </>
            )}
          </>
        ) : (
          <div style={{ flex: 1 }}>
            {showLoading ? <></> : <EmptyStateView />}
          </div>
        )}
      </div>
    );
  };

  const TypeAFiltersRow: React.FC = () => {
    return (
      <div className="max-w-full bg-white py-10 px-5 m-auto w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 m-auto">
          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Ano
              </Label>
            </div>
            <div className="md:text-xl w-full bg-white-100">
              <DropdownMultipleSelect
                name={'a1Year'}
                placeholder="Selecione"
                options={year}
                state={a1YearState}
                labelCharLimit={15}
                handleOptionClick={(option) => {
                  if (
                    JSON.stringify(option) !==
                      JSON.stringify(getValues('a1Year')) &&
                    option.length > 0
                  ) {
                    setValue('a1Year', option);
                    handleValidationTypeA(
                      a1SelectedMetric?.key === '0'
                        ? { key: '0', value: 'Resultado por gás' }
                        : undefined,
                    );
                  } else {
                    setValue('a1Year', option);
                  }
                }}
                getValues={getValues}
              />
            </div>
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Escopo
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'a1Scope'}
              placeholder="Selecione"
              state={a1ScopeState}
              labelCharLimit={9}
              options={scopeObject}
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('a1Scope')) &&
                  option.length > 0
                ) {
                  setValue('a1Scope', option);
                  handleValidationTypeA(
                    a1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setValue('a1Scope', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                {metricLabel}
              </Label>
            </div>
            <Dropdown
              options={metricObject}
              icon="search-gray-400"
              label="Selecione"
              error={a1MetricState === 'danger' ? true : false}
              selectedOption={a1SelectedMetric}
              handleOptionClick={(option) => {
                setA1SelectedMetric(option);
                setShouldDisableFields(false);
                setActiveTypeA(option.key);
                if (
                  option.key === '0' &&
                  getValues('a1GasSimple') === undefined
                ) {
                  setA1SimpleGasState('danger');
                } else {
                  handleValidationTypeA(option);
                }
              }}
            ></Dropdown>
          </div>

          {activeTypeA === '0' ? (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <DropdownSingleSelect
                name={'a1GasSimple'}
                placeholder="Selecione"
                state={a1SimpleGasState}
                labelCharLimit={9}
                options={gas.filter((e) => !e.key.includes('Todos'))}
                handleOptionClick={(option) => {
                  setValue('a1GasSimple', option);
                  handleValidationTypeA(
                    a1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                }}
                getValues={getValues}
              />
            </div>
          ) : (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <div className="md:text-xl w-full bg-white-100">
                <DropdownMultipleSelect
                  name={'a1Gas'}
                  placeholder="Selecione"
                  disabled={shouldDisableFields}
                  options={gas}
                  state={a1GasState}
                  labelCharLimit={15}
                  handleOptionClick={(option) => {
                    if (
                      JSON.stringify(option) !==
                        JSON.stringify(getValues('a1Gas')) &&
                      option.length > 0
                    ) {
                      setValue('a1Gas', option);
                      handleValidationTypeA(
                        a1SelectedMetric?.key === '0'
                          ? { key: '0', value: 'Resultado por gás' }
                          : undefined,
                      );
                    } else {
                      setValue('a1Gas', option);
                    }
                  }}
                  getValues={getValues}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const TypeBFiltersRow: React.FC = () => {
    return (
      <div className="max-w-full bg-white py-10 px-5 m-auto w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 m-auto">
          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Ano
              </Label>
            </div>
            <div className="md:text-xl w-full bg-white-100">
              <DropdownMultipleSelect
                name={'b1Year'}
                placeholder="Selecione"
                options={year}
                state={b1YearState}
                labelCharLimit={15}
                handleOptionClick={(option) => {
                  if (
                    JSON.stringify(option) !==
                      JSON.stringify(getValues('b1Year')) &&
                    option.length > 0
                  ) {
                    setValue('b1Year', option);
                    handleValidationTypeB(
                      b1SelectedMetric?.key === '0'
                        ? { key: '0', value: 'Resultado por gás' }
                        : undefined,
                    );
                  } else {
                    setValue('b1Year', option);
                  }
                }}
                getValues={getValues}
              />
            </div>
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Escopo
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'b1Scope'}
              placeholder="Selecione"
              state={b1ScopeState}
              labelCharLimit={9}
              options={scopeObject}
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('b1Scope')) &&
                  option.length > 0
                ) {
                  setValue('b1Scope', option);
                  setCategoryFilter(option, 'b1');
                  handleValidationTypeB(
                    b1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setCategoryFilter(option, 'b1');
                  setValue('b1Scope', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Categoria
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'b1Category'}
              placeholder="Selecione"
              wrapText
              state={b1CategoryState}
              labelCharLimit={9}
              options={
                getValues('b1Scope') !== undefined &&
                !getValues('b1Scope').includes('Todos')
                  ? categories
                  : categories.filter((e) => !e.key.includes('Todos'))
              }
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('b1Category')) &&
                  option.length > 0
                ) {
                  setValue('b1Category', option);
                  handleValidationTypeB(
                    b1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setValue('b1Category', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                {metricLabel}
              </Label>
            </div>
            <Dropdown
              options={metricObject}
              icon="search-gray-400"
              label="Selecione"
              error={b1MetricState === 'danger' ? true : false}
              selectedOption={b1SelectedMetric}
              handleOptionClick={(option) => {
                setB1SelectedMetric(option);
                setShouldDisableFields(false);
                setActiveTypeB(option.key);
                if (
                  option.key === '0' &&
                  getValues('b1GasSimple') === undefined
                ) {
                  setB1SimpleGasState('danger');
                } else {
                  handleValidationTypeB(option);
                }
              }}
            ></Dropdown>
          </div>

          {activeTypeB === '0' ? (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <DropdownSingleSelect
                name={'b1GasSimple'}
                placeholder="Selecione"
                state={b1SimpleGasState}
                labelCharLimit={9}
                options={gas.filter((e) => !e.key.includes('Todos'))}
                handleOptionClick={(option) => {
                  setValue('b1GasSimple', option);
                  handleValidationTypeB(
                    b1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                }}
                getValues={getValues}
              />
            </div>
          ) : (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <div className="md:text-xl w-full bg-white-100">
                <DropdownMultipleSelect
                  name={'b1Gas'}
                  placeholder="Selecione"
                  disabled={shouldDisableFields}
                  options={gas}
                  state={b1GasState}
                  labelCharLimit={15}
                  handleOptionClick={(option) => {
                    if (
                      JSON.stringify(option) !==
                        JSON.stringify(getValues('b1Gas')) &&
                      option.length > 0
                    ) {
                      setValue('b1Gas', option);
                      handleValidationTypeB(
                        b1SelectedMetric?.key === '0'
                          ? { key: '0', value: 'Resultado por gás' }
                          : undefined,
                      );
                    } else {
                      setValue('b1Gas', option);
                    }
                  }}
                  getValues={getValues}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const TypeCFiltersRow: React.FC = () => {
    return (
      <div className="max-w-full bg-white py-10 px-5 m-auto w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 m-auto">
          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Ano
              </Label>
            </div>
            <div className="md:text-xl w-full bg-white-100">
              <DropdownMultipleSelect
                name={'c1Year'}
                placeholder="Selecione"
                options={year}
                state={c1YearState}
                labelCharLimit={15}
                handleOptionClick={(option) => {
                  if (
                    JSON.stringify(option) !==
                      JSON.stringify(getValues('c1Year')) &&
                    option.length > 0
                  ) {
                    setValue('c1Year', option);
                    handleValidationTypeC(
                      c1SelectedMetric?.key === '0'
                        ? { key: '0', value: 'Resultado por gás' }
                        : undefined,
                    );
                  } else {
                    setValue('c1Year', option);
                  }
                }}
                getValues={getValues}
              />
            </div>
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Escopo
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'c1Scope'}
              placeholder="Selecione"
              state={c1ScopeState}
              labelCharLimit={9}
              options={scopeObject}
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('c1Scope')) &&
                  option.length > 0
                ) {
                  setValue('c1Scope', option);
                  setCategoryFilter(option, 'c1');
                  handleValidationTypeC(
                    c1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setCategoryFilter(option, 'c1');
                  setValue('c1Scope', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Categoria
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'c1Category'}
              placeholder="Selecione"
              wrapText
              state={c1CategoryState}
              labelCharLimit={9}
              options={
                getValues('c1Scope') !== undefined &&
                !getValues('c1Scope').includes('Todos')
                  ? categories
                  : categories.filter((e) => !e.key.includes('Todos'))
              }
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('c1Category')) &&
                  option.length > 0
                ) {
                  setValue('c1Category', option);
                  handleValidationTypeC(
                    c1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setValue('c1Category', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Setor
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'c1Sector'}
              placeholder="Selecione"
              disabled={shouldDisableFields}
              options={classes}
              state={c1SectorState}
              labelCharLimit={35}
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('c1Sector')) &&
                  option.length > 0
                ) {
                  setValue('c1Sector', option);
                  handleValidationTypeC(
                    c1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setValue('c1Sector', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                {metricLabel}
              </Label>
            </div>
            <Dropdown
              options={metricObject}
              icon="search-gray-400"
              label="Selecione"
              error={c1MetricState === 'danger' ? true : false}
              selectedOption={c1SelectedMetric}
              handleOptionClick={(option) => {
                setC1SelectedMetric(option);
                setShouldDisableFields(false);
                setActiveTypeC(option.key);
                if (
                  option.key === '0' &&
                  getValues('c1GasSimple') === undefined
                ) {
                  setC1SimpleGasState('danger');
                } else {
                  handleValidationTypeC(option);
                }
              }}
            ></Dropdown>
          </div>

          {activeTypeC === '0' ? (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <DropdownSingleSelect
                name={'c1GasSimple'}
                placeholder="Selecione"
                state={c1SimpleGasState}
                labelCharLimit={9}
                options={gas.filter((e) => !e.key.includes('Todos'))}
                handleOptionClick={(option) => {
                  setValue('c1GasSimple', option);
                  handleValidationTypeC(
                    c1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                }}
                getValues={getValues}
              />
            </div>
          ) : (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <div className="md:text-xl w-full bg-white-100">
                <DropdownMultipleSelect
                  name={'c1Gas'}
                  placeholder="Selecione"
                  disabled={shouldDisableFields}
                  options={gas}
                  state={c1GasState}
                  labelCharLimit={15}
                  handleOptionClick={(option) => {
                    if (
                      JSON.stringify(option) !==
                        JSON.stringify(getValues('c1Gas')) &&
                      option.length > 0
                    ) {
                      setValue('c1Gas', option);
                      handleValidationTypeC();
                    } else {
                      setValue('c1Gas', option);
                    }
                  }}
                  getValues={getValues}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const TypeDFiltersRow: React.FC = () => {
    return (
      <div className="max-w-full bg-white py-10 px-5 m-auto w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 m-auto">
          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Ano
              </Label>
            </div>
            <div className="md:text-xl w-full bg-white-100">
              <DropdownMultipleSelect
                name={'d1Year'}
                placeholder="Selecione"
                options={year}
                state={d1YearState}
                labelCharLimit={15}
                handleOptionClick={(option) => {
                  if (
                    JSON.stringify(option) !==
                      JSON.stringify(getValues('d1Year')) &&
                    option.length > 0
                  ) {
                    setValue('d1Year', option);
                    handleValidationTypeD(
                      d1SelectedMetric?.key === '0'
                        ? { key: '0', value: 'Resultado por gás' }
                        : undefined,
                    );
                  } else {
                    setValue('d1Year', option);
                  }
                }}
                getValues={getValues}
              />
            </div>
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Escopo
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'d1Scope'}
              placeholder="Selecione"
              state={d1ScopeState}
              labelCharLimit={9}
              options={scopeObject}
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('d1Scope')) &&
                  option.length > 0
                ) {
                  setValue('d1Scope', option);
                  setCategoryFilter(option, 'd1');
                  handleValidationTypeD(
                    d1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setCategoryFilter(option, 'd1');
                  setValue('d1Scope', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                Organização
              </Label>
            </div>
            <DropdownMultipleSelect
              name={'d1Organization'}
              placeholder="Selecione"
              disabled={shouldDisableFields}
              options={organizations}
              state={d1OrganizationState}
              labelCharLimit={15}
              handleOptionClick={(option) => {
                if (
                  JSON.stringify(option) !==
                    JSON.stringify(getValues('d1Organization')) &&
                  option.length > 0
                ) {
                  setValue('d1Organization', option);
                  handleValidationTypeD(
                    d1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                } else {
                  setValue('d1Organization', option);
                }
              }}
              getValues={getValues}
            />
          </div>

          <div>
            <div className="mb-2 text-center">
              <Label size="sm" className="font-bold mb-2 text-center">
                {metricLabel}
              </Label>
            </div>
            <Dropdown
              options={metricObject}
              icon="search-gray-400"
              label="Selecione"
              error={d1MetricState === 'danger' ? true : false}
              selectedOption={d1SelectedMetric}
              handleOptionClick={(option) => {
                setD1SelectedMetric(option);
                setShouldDisableFields(false);
                setActiveTypeD(option.key);
                if (
                  option.key === '0' &&
                  getValues('d1GasSimple') === undefined
                ) {
                  setD1SimpleGasState('danger');
                } else {
                  handleValidationTypeD(option);
                }
              }}
            ></Dropdown>
          </div>

          {activeTypeD === '0' ? (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <DropdownSingleSelect
                name={'d1GasSimple'}
                placeholder="Selecione"
                state={d1SimpleGasState}
                labelCharLimit={9}
                options={gas.filter((e) => !e.key.includes('Todos'))}
                handleOptionClick={(option) => {
                  setValue('d1GasSimple', option);
                  handleValidationTypeD(
                    d1SelectedMetric?.key === '0'
                      ? { key: '0', value: 'Resultado por gás' }
                      : undefined,
                  );
                }}
                getValues={getValues}
              />
            </div>
          ) : (
            <div>
              <div className="mb-2 text-center">
                <Label size="sm" className="font-bold mb-2 text-center">
                  Gás
                </Label>
              </div>
              <div className="md:text-xl w-full bg-white-100">
                <DropdownMultipleSelect
                  name={'d1Gas'}
                  placeholder="Selecione"
                  disabled={shouldDisableFields}
                  options={gas}
                  state={d1GasState}
                  labelCharLimit={15}
                  handleOptionClick={(option) => {
                    if (
                      JSON.stringify(option) !==
                        JSON.stringify(getValues('d1Gas')) &&
                      option.length > 0
                    ) {
                      setValue('d1Gas', option);
                      handleValidationTypeD();
                    } else {
                      setValue('d1Gas', option);
                    }
                  }}
                  getValues={getValues}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const EmptyStateView: React.FC = () => {
    return (
      <>
        <div className="flex h-full px-8 mt-10 justify-center">
          <Label color="text-gray-400" size="sm">
            {emptyStateLabel}
          </Label>
        </div>
        <div className="flex justify-center pt-2">
          <Icon size="xxl" name={'bars'}></Icon>
        </div>
      </>
    );
  };

  const fetchTypeAGraphic = async (
    type: string,
    metric: IOption | undefined,
  ) => {
    switch (type) {
      case 'A1':
        let a1YearParam = getValues('a1Year');
        let a1GasParam = getValues('a1Gas');
        let a1ScopeParam = getValues('a1Scope');

        let query = '';

        if (a1YearParam !== undefined) {
          let startYear, endYear;

          if (
            a1YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = a1YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            query += `startYear=${startYear}&`;
          }
          if (endYear) {
            query += `endYear=${endYear}&`;
          }
        }

        if (a1GasParam !== undefined) {
          if (
            a1GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'gazList=&';
          } else {
            query += `gazList=${
              a1GasParam.length > 0
                ? encodeURIComponent(
                    a1GasParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (a1ScopeParam !== undefined) {
          if (
            a1ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'scopeList=&';
          } else {
            query += `scopeList=${
              a1ScopeParam.length > 0
                ? encodeURIComponent(
                    a1ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (metric?.value) {
          query += `factor=${metric
            ?.slug!!.replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        } else if (a1SelectedMetric?.slug) {
          query += `factor=${a1SelectedMetric.slug
            .replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        }

        query = query.replace(/&$/, '');
        query = query.replace(/\/$/, '');

        try {
          const result = await graphicService.getA1Type(query);
          result.stacked = false;
          setA1GraphicResult(result);
        } catch (error) {
          console.log('erro', error);
        }
        return;
      case 'A2':
        let a2YearParam = getValues('a1Year');
        let a2GasParam = getValues('a1GasSimple');
        let a2ScopeParam = getValues('a1Scope');

        let queryA2 = '';

        if (a2YearParam !== undefined) {
          let startYear, endYear;

          if (
            a2YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = a2YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            queryA2 += `startYear=${startYear}&`;
          }
          if (endYear) {
            queryA2 += `endYear=${endYear}&`;
          }
        }

        if (
          a2GasParam !== undefined &&
          typeof a2GasParam === 'object' &&
          a2GasParam.key !== undefined
        ) {
          if (a2GasParam.key === 'Todos') {
            queryA2 += `gazList=`;
          } else {
            queryA2 += `gazList=${a2GasParam.key}&`;
          }
        } else if (a2GasParam !== undefined && Array.isArray(a2GasParam)) {
          if (
            a2GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryA2 += 'gazList=&';
          } else {
            queryA2 += `gazList=${
              a2GasParam.length > 0
                ? encodeURIComponent(
                    a2GasParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (a2ScopeParam !== undefined) {
          if (
            a2ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryA2 += 'scopeList=&';
          } else {
            queryA2 += `scopeList=${
              a2ScopeParam.length > 0
                ? encodeURIComponent(
                    a2ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        queryA2 = queryA2.replace(/&$/, '');
        queryA2 = queryA2.replace(/\/$/, '');

        try {
          const resultA2 = await graphicService.getA2Type(queryA2);
          resultA2.stacked = false;
          setA1GraphicResult(resultA2);
        } catch (error) {
          console.log('error', error);
        }
        return;
      default:
        return;
    }
  };

  const fetchTypeBGraphic = async (
    type: string,
    metric: IOption | undefined,
  ) => {
    switch (type) {
      case 'B1':
        let b1YearParam = getValues('b1Year');
        let b1GasParam = getValues('b1Gas');
        let b1ScopeParam = getValues('b1Scope');
        let b1CategoryParam = getValues('b1Category');

        let query = '';

        if (b1YearParam !== undefined) {
          let startYear, endYear;

          if (
            b1YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = b1YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            query += `startYear=${startYear}&`;
          }
          if (endYear) {
            query += `endYear=${endYear}&`;
          }
        }

        if (b1GasParam !== undefined) {
          if (
            b1GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'gazList=&';
          } else {
            query += `gazList=${
              b1GasParam.length > 0
                ? encodeURIComponent(
                    b1GasParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (b1ScopeParam !== undefined) {
          if (
            b1ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'scopeList=&';
          } else {
            query += `scopeList=${
              b1ScopeParam.length > 0
                ? encodeURIComponent(
                    b1ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (b1CategoryParam !== undefined) {
          if (
            b1CategoryParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'categoryIds=&';
          } else {
            query += `categoryIds=${
              b1CategoryParam.length > 0
                ? encodeURIComponent(
                    b1CategoryParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (metric?.value) {
          query += `factor=${metric
            ?.slug!!.replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        } else if (b1SelectedMetric?.slug) {
          query += `factor=${b1SelectedMetric.slug
            .replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        }

        query = query.replace(/&$/, '');
        query = query.replace(/\/$/, '');

        try {
          const result = await graphicService.getB1Type(query);
          result.stacked = true;
          setB1GraphicResult(result);
        } catch (error) {
          console.log('erro', error);
        }
        return;
      case 'B2':
        let b2YearParam = getValues('b1Year');
        let b2GasParam = getValues('b1GasSimple');
        let b2ScopeParam = getValues('b1Scope');
        let b2CategoryParam = getValues('b1Category');

        let queryB2 = '';

        if (b2YearParam !== undefined) {
          let startYear, endYear;

          if (
            b2YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = b2YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            queryB2 += `startYear=${startYear}&`;
          }
          if (endYear) {
            queryB2 += `endYear=${endYear}&`;
          }
        }

        if (
          b2GasParam !== undefined &&
          typeof b2GasParam === 'object' &&
          b2GasParam.key !== undefined
        ) {
          if (b2GasParam.key === 'Todos') {
            queryB2 += `gazList=`;
          } else {
            queryB2 += `gazList=${b2GasParam.key}&`;
          }
        } else if (b2GasParam !== undefined && Array.isArray(b2GasParam)) {
          if (
            b2GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryB2 += 'gazList=&';
          } else {
            queryB2 += `gazList=${
              b2GasParam.length > 0
                ? encodeURIComponent(
                    b2GasParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (b1SelectedGas?.value) {
          queryB2 += `factor=${b1SelectedGas.value
            .replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        }

        if (b2ScopeParam !== undefined) {
          if (
            b2ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryB2 += 'scopeList=&';
          } else {
            queryB2 += `scopeList=${
              b2ScopeParam.length > 0
                ? encodeURIComponent(
                    b2ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (b2CategoryParam !== undefined) {
          if (
            b2CategoryParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryB2 += 'categoryIds=&';
          } else {
            queryB2 += `categoryIds=${
              b2CategoryParam.length > 0
                ? encodeURIComponent(
                    b2CategoryParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        queryB2 = queryB2.replace(/&$/, '');
        queryB2 = queryB2.replace(/\/$/, '');

        try {
          const resultB2 = await graphicService.getB2Type(queryB2);
          resultB2.stacked = true;
          setB1GraphicResult(resultB2);
        } catch (error) {
          console.log('error', error);
        }
        return;
      default:
        return;
    }
  };

  const fetchTypeCGraphic = async (
    type: string,
    metric: IOption | undefined,
  ) => {
    switch (type) {
      case 'C1':
        let c1YearParam = getValues('c1Year');
        let c1GasParam = getValues('c1Gas');
        let c1ScopeParam = getValues('c1Scope');
        let c1CategoryParam = getValues('c1Category');
        let c1SectorParam = getValues('c1Sector');

        let query = '';

        if (c1YearParam !== undefined) {
          let startYear, endYear;

          if (
            c1YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = c1YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            query += `startYear=${startYear}&`;
          }
          if (endYear) {
            query += `endYear=${endYear}&`;
          }
        }

        if (c1GasParam !== undefined) {
          if (
            c1GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'gazList=&';
          } else {
            query += `gazList=${
              c1GasParam.length > 0
                ? encodeURIComponent(
                    c1GasParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (c1ScopeParam !== undefined) {
          if (
            c1ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'scopeList=&';
          } else {
            query += `scopeList=${
              c1ScopeParam.length > 0
                ? encodeURIComponent(
                    c1ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (c1CategoryParam !== undefined) {
          if (
            c1CategoryParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'categoryIds=&';
          } else {
            query += `categoryIds=${
              c1CategoryParam.length > 0
                ? encodeURIComponent(
                    c1CategoryParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (c1SectorParam !== undefined) {
          if (
            c1SectorParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'sector=&';
          } else {
            query += `sector=${
              c1SectorParam.length > 0
                ? encodeURIComponent(
                    c1SectorParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (metric?.value) {
          query += `factor=${metric
            ?.slug!!.replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        } else if (c1SelectedMetric?.slug) {
          query += `factor=${c1SelectedMetric.slug
            .replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        }

        query = query.replace(/&$/, '');
        query = query.replace(/\/$/, '');

        try {
          const result = await graphicService.getC1Type(query);
          result.stacked = true;
          setC1GraphicResult(result);
        } catch (error) {
          console.log('erro', error);
        }
        return;
      case 'C2':
        let c2YearParam = getValues('c1Year');
        let c2GasParam = getValues('c1GasSimple');
        let c2ScopeParam = getValues('c1Scope');
        let c2CategoryParam = getValues('c1Category');
        let c2SectorParam = getValues('c1Sector');

        let queryC2 = '';

        if (c2YearParam !== undefined) {
          let startYear, endYear;

          if (
            c2YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = c2YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            queryC2 += `startYear=${startYear}&`;
          }
          if (endYear) {
            queryC2 += `endYear=${endYear}&`;
          }
        }

        if (
          c2GasParam !== undefined &&
          typeof c2GasParam === 'object' &&
          c2GasParam.key !== undefined
        ) {
          if (c2GasParam.key === 'Todos') {
            queryC2 += `gazList=`;
          } else {
            queryC2 += `gazList=${c2GasParam.key}&`;
          }
        } else if (c2GasParam !== undefined && Array.isArray(c2GasParam)) {
          if (
            c2GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryC2 += 'gazList=&';
          } else {
            queryC2 += `gazList=${
              c2GasParam.length > 0
                ? encodeURIComponent(
                    c2GasParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (c1SelectedGas?.value) {
          queryC2 += `factor=${c1SelectedGas.value
            .replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        }

        if (c2ScopeParam !== undefined) {
          if (
            c2ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryC2 += 'scopeList=&';
          } else {
            queryC2 += `scopeList=${
              c2ScopeParam.length > 0
                ? encodeURIComponent(
                    c2ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (c2CategoryParam !== undefined) {
          if (
            c2CategoryParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryC2 += 'categoryIds=&';
          } else {
            queryC2 += `categoryIds=${
              c2CategoryParam.length > 0
                ? encodeURIComponent(
                    c2CategoryParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        queryC2 = queryC2.replace(/&$/, '');
        queryC2 = queryC2.replace(/\/$/, '');

        if (c2SectorParam !== undefined) {
          if (
            c2SectorParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryC2 += '&sector=';
          } else {
            queryC2 += `&sector=${
              c2SectorParam.length > 0
                ? encodeURIComponent(
                    c2SectorParam
                      .map((item: { key: string }) =>
                        item.key
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        try {
          const resultC2 = await graphicService.getC2Type(queryC2);
          resultC2.stacked = true;
          setC1GraphicResult(resultC2);
        } catch (error) {
          console.log('error', error);
        }
        return;
      default:
        return;
    }
  };

  const fetchTypeDGraphic = async (
    type: string,
    metric: IOption | undefined,
    orgId: string | undefined,
  ) => {
    switch (type) {
      case 'D1':
        let d1YearParam = getValues('d1Year');
        let d1GasParam = getValues('d1Gas');
        let d1ScopeParam = getValues('d1Scope');
        let d1OrganizationParam = getValues('d1Organization');

        let query = '';

        if (d1YearParam !== undefined) {
          let startYear, endYear;

          if (
            d1YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = d1YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            query += `startYear=${startYear}&`;
          }
          if (endYear) {
            query += `endYear=${endYear}&`;
          }
        }

        if (d1GasParam !== undefined) {
          if (
            d1GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'gazList=&';
          } else {
            query += `gazList=${
              d1GasParam.length > 0
                ? encodeURIComponent(
                    d1GasParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (d1ScopeParam !== undefined) {
          if (
            d1ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            query += 'scopeList=&';
          } else {
            query += `scopeList=${
              d1ScopeParam.length > 0
                ? encodeURIComponent(
                    d1ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (metric?.value) {
          query += `factor=${metric
            ?.slug!!.replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        } else if (d1SelectedMetric?.slug) {
          query += `factor=${d1SelectedMetric.slug
            .replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        }

        if (orgId === undefined) {
          if (d1OrganizationParam !== undefined) {
            if (
              d1OrganizationParam.some((item: { value: string | string[] }) =>
                item.value.includes('Todos'),
              )
            ) {
              query += '&companyList=';
            } else {
              query += `&companyList=${
                d1OrganizationParam.length > 0
                  ? encodeURIComponent(
                      d1OrganizationParam
                        .map((item: { key: string }) =>
                          item.key
                            .split(' ')
                            .map((part, index) =>
                              index === 0 ? part : '_' + part,
                            )
                            .join(''),
                        )
                        .join(','),
                    )
                  : ''
              }&`;
            }
          }
        } else {
          query += `&companyList=${orgId}`;
        }

        query = query.replace(/&$/, '');
        query = query.replace(/\/$/, '');

        try {
          if (orgId === undefined) {
            const resultOrg = await graphicService.getOrganizationTable(query);
            setD1TableResult(resultOrg);
            setD1GraphicResult(null);
          } else {
            const result = await graphicService.getD1Type(query);
            setD1GraphicResult(result);
          }
        } catch (error) {
          console.log('erro', error);
        }
        return;
      case 'D2':
        let d2YearParam = getValues('d1Year');
        let d2GasParam = getValues('d1GasSimple');
        let d2ScopeParam = getValues('d1Scope');
        let d2OrganizationParam = getValues('d1Organization');

        let queryD2 = '';

        if (d2YearParam !== undefined) {
          let startYear, endYear;

          if (
            d2YearParam.some(
              (item: { value: string }) => item.value === 'Todos',
            )
          ) {
            startYear = '1970';
            endYear = new Date().getFullYear().toString();
          } else {
            const years = d2YearParam.map((item: { value: string }) =>
              parseInt(item.value, 10),
            );
            if (years.length > 0) {
              startYear = Math.min(...years).toString();
              endYear = Math.max(...years).toString();
            }
          }

          if (startYear) {
            queryD2 += `startYear=${startYear}&`;
          }
          if (endYear) {
            queryD2 += `endYear=${endYear}&`;
          }
        }

        if (
          d2GasParam !== undefined &&
          typeof d2GasParam === 'object' &&
          d2GasParam.key !== undefined
        ) {
          if (d2GasParam.key === 'Todos') {
            queryD2 += `gazList=`;
          } else {
            queryD2 += `gazList=${d2GasParam.key}&`;
          }
        } else if (d2GasParam !== undefined && Array.isArray(d2GasParam)) {
          if (
            d2GasParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryD2 += 'gazList=&';
          } else {
            queryD2 += `gazList=${
              d2GasParam.length > 0
                ? encodeURIComponent(
                    d2GasParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (d1SelectedGas?.value) {
          queryD2 += `factor=${d1SelectedGas.value
            .replace('Em CO2e - (GWP 100a ', '')
            .replace(')', '')
            .replace('-', '')}`;
        }

        if (d2ScopeParam !== undefined) {
          if (
            d2ScopeParam.some((item: { value: string | string[] }) =>
              item.value.includes('Todos'),
            )
          ) {
            queryD2 += 'scopeList=&';
          } else {
            queryD2 += `scopeList=${
              d2ScopeParam.length > 0
                ? encodeURIComponent(
                    d2ScopeParam
                      .map((item: { value: string }) =>
                        item.value
                          .split(' ')
                          .map((part, index) =>
                            index === 0 ? part.toUpperCase() : '_' + part,
                          )
                          .join(''),
                      )
                      .join(','),
                  )
                : ''
            }&`;
          }
        }

        if (orgId === undefined) {
          if (d2OrganizationParam !== undefined) {
            if (
              d2OrganizationParam.some((item: { value: string | string[] }) =>
                item.value.includes('Todos'),
              )
            ) {
              queryD2 += '&companyList=';
            } else {
              queryD2 += `&companyList=${
                d2OrganizationParam.length > 0
                  ? encodeURIComponent(
                      d2OrganizationParam
                        .map((item: { key: string }) =>
                          item.key
                            .split(' ')
                            .map((part, index) =>
                              index === 0 ? part : '_' + part,
                            )
                            .join(''),
                        )
                        .join(','),
                    )
                  : ''
              }&`;
            }
          }
        } else {
          queryD2 += `&companyList=${orgId}`;
        }

        queryD2 = queryD2.replace(/&$/, '');
        queryD2 = queryD2.replace(/\/$/, '');

        try {
          if (orgId === undefined) {
            const resultOrg = await graphicService.getOrganizationTable(
              queryD2,
            );
            setD1TableResult(resultOrg);
          } else {
            const result = await graphicService.getD1Type(queryD2);
            setD1GraphicResult(result);
          }
        } catch (error) {
          console.log('error', error);
        }
        return;
      default:
        return;
    }
  };

  const tabsData = [
    {
      label: 'Escopo',
      icon: '',
      hasHeader: false,
      headerButtons: <></>,
      content: (
        <>
          <div className="h-full  rounded-md overflow-hidden">
            <TypeAGraphicContent />
          </div>
        </>
      ),
    },

    {
      label: 'Categoria',
      icon: '',
      hasHeader: false,
      headerButtons: <></>,
      content: (
        <>
          <div className="h-full  rounded-md overflow-hidden">
            <TypeBGraphicContent />
          </div>
        </>
      ),
    },

    {
      label: 'Setor',
      icon: 'edit',
      hasHeader: false,
      headerButtons: <></>,
      content: (
        <>
          <div className="h-full  rounded-md overflow-hidden">
            <TypeCGraphicContent />
          </div>
        </>
      ),
    },
    {
      label: 'Organização',
      icon: 'edit',
      hasHeader: false,
      headerButtons: <></>,
      content: (
        <>
          <div className="h-full  rounded-md overflow-hidden">
            <TypeDGraphicContent />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {showLoading && <LoadingForm />}
      <Tabs
        onTabClick={(tabIndex) => handleTabClick(tabIndex)}
        tabsData={tabsData}
      ></Tabs>
    </>
  );
};

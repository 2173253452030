import { ReactNode, useContext, useEffect, useState } from 'react';
import Button from '../Button/Button';
import Label from '../Label/Label';
import { TutorialAppContext } from '../../contexts/TutorialAppContext';
import Icon from '../Icon/Icon';
import { AuthContext } from '../../contexts/AuthContext';

type IOrientationPopover =
  | 'top-full-right'
  | 'top-full-left'
  | 'bottom-full-left'
  | 'bottom-full-right'
  | 'left-full-bottom'
  | 'left-full-top'
  | 'right-full-top'
  | 'right-full-bottom';

type IPopoverProps = {
  componentName: string;
  levelOrder: number;
  children: ReactNode;
  exactPath: string;
  orientation: IOrientationPopover;
  width?: string;
  content?: {
    title: string;
    body: string;
    button: string;
    btnIcon?: string;
  };
  onClickContinueButton?: () => void;
  tutorialType: 'firstAccess' | 'firstOrg' | 'firstForm';
  activeClose?: boolean;
};

export function Popover({
  componentName,
  levelOrder,
  orientation,
  exactPath,
  children,
  content,
  onClickContinueButton,
  width,
  activeClose = false,
  tutorialType,
}: IPopoverProps) {
  const {
    createNewInstaceTutorial,
    currentTutorial,
    nextTutorial,
    updateTutorialAlreadySeen,
    pauseTutorial,
  } = useContext(TutorialAppContext);
  const { session } = useContext(AuthContext);
  const [orientationStyles, setOrientationStyles] = useState<string>('');

  const [displayState, setDisplayState] = useState<string>('hidden');
  useEffect(
    () =>
      setDisplayState(() =>
        currentTutorial === componentName ? 'block' : 'hidden',
      ),
    [currentTutorial],
  );

  const handleOrientation = () => {
    switch (orientation) {
      case 'top-full-right':
        return 'origin-bottom-right bottom-full right-0 mb-4';

      case 'top-full-left':
        return 'origin-bottom-right bottom-full left-0 mb-4';

      case 'bottom-full-left':
        return 'origin-top-right top-full left-0 mt-4';

      case 'bottom-full-right':
        return 'origin-top-right top-full right-0 mt-4';

      case 'left-full-bottom':
        return 'origin-top-right top-0 right-full mr-4';

      case 'left-full-top':
        return 'origin-top-right bottom-0 right-full mr-4';

      case 'right-full-top':
        return 'origin-top-right bottom-0 left-full ml-4';

      case 'right-full-bottom':
        return 'origin-top-right top-0 left-full ml-4';

      default:
        return '';
    }
  };

  const stopCurrentTutorial = async () => {
    pauseTutorial();
    updateTutorialAlreadySeen(tutorialType);
  };

  useEffect(() => {
    setOrientationStyles(handleOrientation);
  }, [orientation]);

  useEffect(() => {
    createNewInstaceTutorial(componentName, levelOrder, exactPath);
  }, []);

  return (
    <>
      <div
        className={
          currentTutorial === componentName ? 'relative w-min' : 'w-min'
        }
      >
        <div
          className={
            currentTutorial === componentName ? 'relative z-50' : 'z-20'
          }
        >
          {children}
        </div>

        <div
          className={`w-full h-screen bg-gray-400 fixed inset-0 opacity-50 z-10 ${displayState}`}
        />
        <div
          className={`absolute h-auto bg-white-100 z-50 rounded-[1.25rem] p-[40px] ${displayState} ${
            width ?? 'w-auto'
          } ${orientationStyles}`}
        >
          {activeClose && (
            <>
              <button
                className="absolute top-6 right-6"
                onClick={stopCurrentTutorial}
              >
                <Icon name="close-blue-200" />
              </button>
            </>
          )}
          <Label color="text-black-400" size="xl" className="whitespace-nowrap">
            {content?.title}
          </Label>
          <div className="mt-1 mb-10">
            <Label color="text-gray-400">{content?.body}</Label>
          </div>
          <Button
            onClick={onClickContinueButton ?? nextTutorial}
            icon={content?.btnIcon}
            label={content?.button}
            style="filled"
          />
        </div>
      </div>
    </>
  );
}

import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { ConsultOrganization } from '../../components/ConsultOrganization/ConsultOrganization';
import { GraphicPie } from '../../components/Graphics/GraphicPie';
import Icon from '../../components/Icon/Icon';
import Label from '../../components/Label/Label';
import { LabelFunction } from '../../components/LabelFunction/LabelFunction';
import ListOption from '../../components/ListOption/ListOption';
import ModalAlert from '../../components/Modal/ModalAlert';
import { BaseTable } from '../../components/Table/BaseTable/BaseTable';
import { RowBody } from '../../components/Table/RowBody/RowBody';
import { RowCell } from '../../components/Table/RowCell/RowCell';
import { TableContainer } from '../../components/TableContainer/TableContainer';
import { AuthContext } from '../../contexts/AuthContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { ROUTES } from '../../routes/Index.routes';
import { formsService } from '../../services/formsService';
import {
    IUserorganization,
    organizationService,
} from '../../services/organizationService';
import { userService } from '../../services/userService';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import { LoadingForm } from '../../components/Loading/LoadingForm';

const EmptyInventory = () => {
    return (
        <>
            <div className="w-full h-full flex flex-col items-center justify-center">
                <Label size="md" color="text-black-400" className="!font-bold">
                    Ainda não há inventários.
                </Label>
                <Label size="sm" color="text-gray-400" className="text-center">
                    Adicione inventários para visualizar essas informações.
                </Label>
            </div>
        </>
    );
};

interface GeneralVisionCompanyMemberProps { }
export const GeneralVisionCompanyMember: React.FC<
    GeneralVisionCompanyMemberProps
> = () => {
    const { session, userAddOrganization } = useContext(AuthContext);
    const { setSelectedOrgId } = useContext(OrganizationContext);
    const navigate = useNavigate();
    const [modalConsultOrg, setModalConsultOrg] = useState<boolean>(false);
    const [users, setUsers] = useState<IPagination<any> | null>(null);
    const [inventories, setInventories] = useState<IPagination<IInventoryInfo>>();
    const [userGraphic, setUserGraphic] = useState<[IGraphic] | null>(null);
    const [formIdGraphic, setFormIdGraphic] = useState<[IGraphic] | null>(null);

    const [showConfirmationInventoryModal, setShowConfirmationInventoryModal] =
        useState<boolean>(false);

    const [showConfirmationUsersModal, setShowConfirmationUsersModal] =
        useState<boolean>(false);

    const [selectedInventoryItem, setSelectedInventoryItem] =
        useState<IInventoryInfo | null>(null);

    const [selectedUsersItem, setSelectedUsersItem] =
        useState<IUserorganization | null>(null);

    const handleInventoryClick = (itemId: IInventoryInfo) => {
        setSelectedInventoryItem(itemId === selectedInventoryItem ? null : itemId);
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleUsersClick = (itemUsersId: IUserorganization) => {
        setSelectedUsersItem(
            itemUsersId === selectedUsersItem ? null : itemUsersId,
        );
    };

    function handleOrgSearch(org: IOrganizationSearch) {
        if (org) userAddOrganization(org);
    }

    function handleUsersData(page: number) {
        setIsLoading(true);
        if (session?.organizations && session?.organizations.length > 0) {
            organizationService
                .getUsersByOrgsIds(
                    session?.organizations.map((e) => e.id),
                    page,
                    5,
                )
                .then((orgUsers) => {
                    const activeUsers = orgUsers;

                    activeUsers.content = activeUsers.content.filter(
                        (user) => user.userId !== null,
                    );

                    setUsers(activeUsers);
                });
        }
        setIsLoading(false);
        return 0;
    }

    function graphicUsersCompanies() {
        setIsLoading(true)
        if (!session?.organizations) return;
        userService
            .getQtdUsersCompanies(session?.organizations.map((e) => e.id))
            .then((graph) => {
                setUserGraphic(graph);
            });
        setIsLoading(false)
    }

    function getInventoryList(page: number) {
        setIsLoading(true);
        if (!session?.organizations) return;

        const orgIdArray = session.organizations.map((org) => org.id);

        formsService.getFormsByCompanyId(orgIdArray, page).then((response) => {
            setInventories(response);
        });
        setIsLoading(false);
    }

    function graphicIdForms() {
        setIsLoading(true)
        if (!session?.organizations) return;
        formsService
            .getQtdForms(session.organizations.map((org) => org.id))
            .then((graph) => {
                setFormIdGraphic(graph);
            });
            setIsLoading(false)
    }

    function getOrgIdByCnpj(cnpj: string): string {
        return session?.organizations.find((org) => org.cnpj === cnpj)?.id ?? '';
    }

    const openInventoryForm = (cnpj: string, status: TInventoryStatus) => {
        if (cnpj === '') return;

        setSelectedOrgId(getOrgIdByCnpj(cnpj));
        navigate(ROUTES.INVENTORY_FORM.path, { state: { status } });
    };

    const deleteForm = (id: string) => {
        formsService.deleteFormsById(id).then(() => {
            setShowConfirmationInventoryModal(false);
            getInventoryList(0);
        });
    };

    const deleteUser = (id: string, companyId: string) => {
        userService.deleteUserCompany(id, companyId).then(() => {
            setShowConfirmationUsersModal(false);
            handleUsersData(0);
        });
    };

    useEffect(() => {
        handleUsersData(0);
        getInventoryList(0);
        graphicUsersCompanies();
        graphicIdForms();
    }, [session?.organizations]);

    const options = [
        { label: 'Consultar organização', onClick: () => setModalConsultOrg(true), icon: 'search-blue-200' },
    ];

    return (
        <>
            {/* {isLoading && <LoadingForm />} */}
            {!!users ? (
                <section className="w-full h-auto flex flex-col flex-wrap items-start justify-start p-5 gap-5">
                    <div className="flex md:flex-row flex-col gap-5 w-full">
                        {inventories && (
                            <TableContainer
                                setPagination={getInventoryList}
                                title="Últimos inventários enviados"
                                btnLabel="teste inventário"
                                icon="document-black-200"
                                tempHide={true}
                                numberPages={inventories?.totalPages ?? 0}
                                pagination={1}
                            >
                                <BaseTable
                                    headerTitlesComponents={[
                                        'Organização',
                                        'Data de envio',
                                        'Status',
                                    ]}
                                    childrensLength={inventories?.totalElements ?? 0}
                                    emptyComponent={
                                        inventories?.totalElements === 0 && <EmptyInventory />
                                    }
                                >
                                    {typeof inventories.content !== 'undefined' &&
                                        inventories.content.map((inventory: IInventoryInfo) => {
                                            return (
                                                <RowBody key={inventory.formId}>
                                                    <RowCell>
                                                        <button
                                                            className="text-start"
                                                            onClick={() => {
                                                                openInventoryForm(
                                                                    inventory.cnpj,
                                                                    inventory.status,
                                                                );
                                                            }}
                                                        >
                                                            <p className="font-bold text-gray-500 max-w-full line-clamp-2 ">
                                                                {inventory.companyName}
                                                            </p>
                                                        </button>
                                                    </RowCell>
                                                    <RowCell>
                                                        <Label className="!font-normal max-w-full">
                                                            {moment(inventory.createdDate).format(
                                                                'DD/MM/YYYY',
                                                            )}
                                                        </Label>
                                                    </RowCell>
                                                    <RowCell>
                                                        <div className="flex items-end justify-between h-10 max-w-full">
                                                            <LabelFunction type={inventory.status} />
                                                            <div className="flex items-center md:space-x-4">
                                                                <button
                                                                    className="h-full ml-10 md:ml-0"
                                                                    onClick={() =>
                                                                        handleInventoryClick(inventory)
                                                                    }
                                                                >
                                                                    <Icon name="vertical-dot-gray-400" />
                                                                </button>
                                                                <ListOption
                                                                    visible={
                                                                        inventory.formId ===
                                                                        selectedInventoryItem?.formId
                                                                    }
                                                                    onClose={() => setSelectedInventoryItem(null)}
                                                                >
                                                                    {(inventory.status === 'PENDING' ||
                                                                        inventory.status === 'APPROVED') && (
                                                                            <button
                                                                                className="p-5 font-bold text-left justify-start"
                                                                                onClick={() => {
                                                                                    openInventoryForm(
                                                                                        inventory.cnpj,
                                                                                        inventory.status,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Visualizar
                                                                            </button>
                                                                        )}

                                                                    {inventory.status === 'REVISION' && (
                                                                        <button
                                                                            className="font-bold text-left justify-start"
                                                                            onClick={() => {
                                                                                openInventoryForm(
                                                                                    inventory.cnpj,
                                                                                    inventory.status,
                                                                                );
                                                                            }}
                                                                        >
                                                                            Editar
                                                                        </button>
                                                                    )}

                                                                    {inventory.status === 'FILLING' && (
                                                                        <div className="flex flex-col gap-5 p-5">
                                                                            <button
                                                                                className="font-bold text-left justify-start"
                                                                                onClick={() => {
                                                                                    openInventoryForm(
                                                                                        inventory.cnpj,
                                                                                        inventory.status,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Editar
                                                                            </button>
                                                                            <button
                                                                                className="font-bold text-left justify-start"
                                                                                onClick={() => {
                                                                                    setShowConfirmationInventoryModal(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Remover
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </ListOption>
                                                            </div>
                                                        </div>
                                                    </RowCell>
                                                </RowBody>
                                            );
                                        })}
                                </BaseTable>
                            </TableContainer>
                        )}
                        <TableContainer
                            title="Últimos usuários adicionados"
                            setPagination={handleUsersData}
                            btnLabel="Convidar usuário"
                            icon="people-black-200"
                            numberPages={users.totalPages}
                            pagination={1}
                        >
                            <BaseTable
                                headerTitlesComponents={['Nome', 'Organização', 'Função']}
                                childrensLength={0}
                            >
                                {users.content.map((user) => (
                                    <RowBody key={user.nickName + '-' + user.name}>
                                        <RowCell>
                                            <Label className="!font-bold w-full">{user.name}</Label>
                                        </RowCell>
                                        <RowCell>
                                            <Label className="!whitespace-nowrap !font-normal">
                                                {user.nickName}
                                            </Label>
                                        </RowCell>
                                        <RowCell>
                                            <div className="flex items-end justify-between h-10 w-full">
                                                <LabelFunction
                                                    type={user.userId !== null ? user.type : ''}
                                                />

                                                {session?.id !== user.userId && (
                                                    <div className="f500pxlex items-center space-x-4">
                                                        <button
                                                            className="h-full"
                                                            onClick={() => {
                                                                handleUsersClick(user);
                                                            }}
                                                        >
                                                            <Icon name="vertical-dot-gray-400" />
                                                        </button>

                                                        <ListOption
                                                            visible={
                                                                user.companyId === selectedUsersItem?.companyId
                                                            }
                                                            onClose={() => setSelectedUsersItem(null)}
                                                        >
                                                            <div className="flex flex-col gap-5 p-5">
                                                                <button
                                                                    className="font-bold text-left justify-start"
                                                                    onClick={() => { }}
                                                                >
                                                                    Editar
                                                                </button>

                                                                {session?.organizations.find(
                                                                    (org) => org.id === user.companyId,
                                                                )?.companyUserType === 'REPRESENTATIVE' && (
                                                                        <button
                                                                            className="font-bold text-left justify-start"
                                                                            onClick={() => {
                                                                                setShowConfirmationUsersModal(true);
                                                                            }}
                                                                        >
                                                                            Remover
                                                                        </button>
                                                                    )}
                                                            </div>
                                                        </ListOption>
                                                    </div>
                                                )}
                                            </div>
                                        </RowCell>
                                    </RowBody>
                                ))}
                            </BaseTable>
                        </TableContainer>
                    </div>
                    <div className="flex md:flex-row flex-col gap-5 w-full">
                        <GraphicPie
                            labelText={{ singular: 'Inventário', plural: 'Inventários' }}
                            data={
                                formIdGraphic
                                    ?.filter((graph) => graph.status !== 'FILLING')
                                    .map((graph) => {
                                        let graphColor = '#FFFFFF';
                                        let graphLegend = '';

                                        switch (graph.status) {
                                            case 'PENDING':
                                                graphColor = '#4A77B4';
                                                graphLegend = 'Pendentes';
                                                break;
                                            case 'REVISION':
                                                graphColor = '#4A77B4';
                                                graphLegend = 'Revisar';
                                                break;
                                            case 'APPROVED':
                                                graphColor = '#168821';
                                                graphLegend = 'Aprovados';
                                                break;

                                            case 'FILLING':
                                                graphColor = '#2F4668';
                                                graphLegend = '';
                                                break;
                                        }

                                        return {
                                            name: graph.type ?? '',
                                            value: graph.quantity,
                                            color: graphColor,
                                            legend: graphLegend,
                                            isChecked: true,
                                        };
                                    }) ?? []
                            }
                        />

                        <GraphicPie
                            labelText={{ singular: 'Usuário', plural: 'Usuários' }}
                            data={
                                userGraphic?.map((graph) => {
                                    let graphColor = '#FFFFFF';
                                    let graphLegend = '';

                                    switch (graph.type) {
                                        case 'REPRESENTATIVE':
                                            graphColor = '#2F4668';
                                            graphLegend = 'Responsáveis';
                                            break;
                                        case 'REPORTER':
                                            graphColor = '#4A77B4';
                                            graphLegend = 'Relatores';
                                            break;
                                    }

                                    return {
                                        name: graph.type ?? '',
                                        value: graph.quantity,
                                        color: graphColor,
                                        legend: graphLegend,
                                        isChecked: true,
                                    };
                                }) ?? []
                            }
                        />
                    </div>
                </section>
            ) : (
                <>
                    <div className="w-full flex flex-col items-center justify-center pt-[50px] md:pt-[356px] px-10 md:px-52">
                        <Label color="text-black-400" size="xl">
                            Consulte sua primeira organização.
                        </Label>
                        <div className='pt-8 md:pt-0'>
                        <Label color="text-gray-400">
                            Você ainda não está vinculado a nenhuma Organização Inventariante.
                        </Label>
                        </div>
                        <Label color="text-gray-400">
                            Para visualizar ou adicionar um inventário de GEE, informe o CNPJ
                            da sua organização.
                        </Label>
                        <div className="mt-10">
                            <div className="w-[242px]">
                                <Button
                                    style="filled"
                                    icon="search"
                                    label="Consultar organização"
                                    onClick={() => setModalConsultOrg(true)}
                                />
                            </div>
                            <ConsultOrganization
                                onConsultedOrg={handleOrgSearch}
                                setShowSearchOrgModal={setModalConsultOrg}
                                showSearchOrgModal={modalConsultOrg}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className='block md:hidden'>
                <FloatingButton style="filled" options={options} />
                <ConsultOrganization
                    onConsultedOrg={handleOrgSearch}
                    showSearchOrgModal={modalConsultOrg}
                    setShowSearchOrgModal={setModalConsultOrg}
                />
            </div>

            <ModalAlert visible={showConfirmationInventoryModal}>
                <section className="w-[610px] p-10 flex flex-col items-center">
                    <button
                        className="absolute top-[19px] right-[19px] border-none bg-none"
                        onClick={() => {
                            setShowConfirmationInventoryModal(false);
                        }}
                    >
                        <Icon name="close-blue-200" size="sm" />
                    </button>
                    <Label size="md" className="font-bold">
                        Tem certeza que deseja remover o inventário?
                    </Label>

                    <div className="w-65 pt-8">
                        <Button
                            style="filled"
                            label="Confirmar"
                            onClick={() => {
                                deleteForm(selectedInventoryItem?.formId ?? '');
                            }}
                        />
                    </div>
                </section>
            </ModalAlert>

            <ModalAlert visible={showConfirmationUsersModal}>
                <section className="w-[610px] p-10 flex flex-col items-center">
                    <button
                        className="absolute top-[19px] right-[19px] border-none bg-none"
                        onClick={() => {
                            setShowConfirmationUsersModal(false);
                        }}
                    >
                        <Icon name="close-blue-200" size="sm" />
                    </button>
                    <Label size="md" className="font-bold">
                        Tem certeza que deseja remover o usuário {selectedUsersItem?.name}?
                    </Label>

                    <div className="w-65 pt-8">
                        <Button
                            style="filled"
                            label="Confirmar"
                            onClick={() => {
                                deleteUser(
                                    selectedUsersItem?.userId ?? '',
                                    selectedUsersItem?.companyId ?? '',
                                );
                            }}
                        />
                    </div>
                </section>
            </ModalAlert>
        </>
    );
};

import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

export const LoginPage = () => {
  const { logIn } = useContext(AuthContext);
  useEffect(() => {
    logIn();
  }, []);
  return <></>;
};

import React, { useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../components/Button/Button';
import ButtonStep from '../../components/Button/ButtonStep';
import { DateInput } from '../../components/DateInput/DateInput';
import DropdownMultipleSelect from '../../components/Dropdown/DropdownMultipleSelect';
import { InputDropdown } from '../../components/Dropdown/InputDropdown';
import { Input } from '../../components/Input/Input';
import Label from '../../components/Label/Label';
import { Popover } from '../../components/Popover/Popover';
import Radio from '../../components/Radio/Radio';
import { TutorialAppContext } from '../../contexts/TutorialAppContext';
import { ROUTES } from '../../routes/Index.routes';
import { formsService } from '../../services/formsService';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { useNavigate } from 'react-router-dom';
import ModalAlert from '../../components/Modal/ModalAlert';
import { AuthContext } from '../../contexts/AuthContext';
import { InventoryFormContext } from '../../contexts/InventoryFormContext';
import cloneDeep from 'lodash/cloneDeep';
import { useLocation } from 'react-router-dom';
import TooltipIcon from '../../components/TooltipIcon/TooltipIcon';
import { InventoryResumePage } from '../InventoryResumePage/InventoryResumePage';
import { LoadingForm } from '../../components/Loading/LoadingForm';
import { Element, scroller } from 'react-scroll';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import UploadComponent from '../../components/UploadComponent/UploadComponent';
import { FormIdContext } from '../../contexts/FormContext';

interface InventoryFormPageProps { }

export const InventoryFormPage: React.FC<InventoryFormPageProps> = () => {
    const {
        register,
        setError,
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        formState,
        formState: { isValid, errors },
        control,
    } = useForm<any>({ mode: 'onChange' });

    const location = useLocation();
    const formId = location.state?.formId;
    const formStatus = location.state?.status;

    const [formsJSON, setFormsJSON] = useState<IFormJSON>();
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState<IFormStep>();
    const [showInventorySuccessModal, setShowInventorySuccessModal] =
        useState<boolean>(false);
    const [searchOp, setSearchOp] = useState<IFormQuestionOptions[]>([]);

    const { updateTutorialAlreadySeen, pauseTutorial } =
        useContext(TutorialAppContext);

    const { session } = useContext(AuthContext);
    const { selectedOrgId } = useContext(OrganizationContext);
    const { formBeingFilled, setFormBeingFilled } =
        useContext(InventoryFormContext);
    const navigate = useNavigate();

    const errorMessages = {
        'form-inventory.invalid.init.final.date':
            'A data inicial deve ser anterior à data final.',
    };

    const [notReviewReviewed, setNotReviewReviewed] = useState<boolean>(false);
    const [formsJSONCopy, setFormsJSONCopy] = useState<IFormJSON>();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [enableInputOther, setEnableInputOther] = useState<IEnableInputOther>(
        {},
    );
    const [otherValues, setOtherValues] = useState<{ [key: string]: string }>({});
    const [enableInputOtherClick, setEnableInputOtherClick] =
        useState<IEnableInputOtherClick>({});
    const [inputOther, setInputOther] = useState<string>('');
    const [numbers, setNumbers] = useState<number[]>([1]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showModalUpload, setShowModalUpload] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const isFinishStep = (index: number): boolean => {
        return index === formsJSON?.steps.length;
    };
    const { id, setIdValue, json, setJson } = useContext(FormIdContext);
    
    useEffect(() => {
        setIdValue(formId)
    }, []);

    useEffect(() => {
        const fetchFormJSON = async () => {
            setIsLoading(true);
            if (typeof formId === 'undefined') {
                const formResponse = await formsService.getFormJSONById(selectedOrgId);
                setFormsJSON(formResponse);
                const copy = cloneDeep(formResponse);
                setFormsJSONCopy(copy);
                if (formResponse.steps) setCurrentStep(formResponse.steps[0]);
            } else {
                const formResponse = await formsService.getFormById(formId);
                setFormsJSON(formResponse);
                const copy = cloneDeep(formResponse);
                setFormsJSONCopy(copy);
                if (formResponse.steps) setCurrentStep(formResponse.steps[0]);
            }
            setIsLoading(false);
            const steps = formsJSON?.steps;
            steps?.forEach((step) => {
                step.questions.forEach((question) => {
                    setValue(question.name, question.answer ?? undefined);
                    if (typeof question.review === 'undefined') {
                        setStepState(step.number);
                    }
                });
            });
        };

        fetchFormJSON().catch(console.error);
    }, []);

    const handleStepClick = (index: number) => {
        if (formStatus !== 'APPROVED' && formStatus !== 'PENDING') {
            setNumbers((prevNumber) => [...prevNumber, index + 1]);
            if (!isFinishStep(currentStepIndex)) {
                hasStepErrors(currentStepIndex);
                saveStepAnswers();
            }
            changeStep(index);
        } else {
            changeStep(index);
        }
    };

    const saveStepAnswers = async () => {
        setIsLoading(true);
        if (!formsJSON) return;

        fillFormJsonWithCurrentStep();

        const answersObject: { [key: string]: Object } = {};

        if (formStatus === undefined || formStatus === 'FILLING') {
            formsJSON.steps[currentStepIndex].questions.forEach((question) => {
                if (question.answer !== undefined) {
                    const option = question.answer as IFormQuestionOptions;
                    if (
                        question.validation.type === 'SELECT' &&
                        typeof question.answer === 'object' &&
                        'key' in question.answer
                    ) {
                        if (option.key === '0' && option.description !== undefined) {
                            answersObject[question.name] = option.description;
                        } else if (typeof option === 'object') {
                            answersObject[question.name] = option.key;
                        } else {
                            answersObject[question.name] = option;
                        }
                    } else if (
                        question.validation.type === 'MULTIPLE_SELECT' &&
                        Array.isArray(question.answer)
                    ) {
                        const options = question.answer as IFormQuestionOptions[];
                        const values: string[] = [];

                        options.forEach((option) => {
                            if (option.key === '0' && option.description !== undefined) {
                                values.push(option.description);
                            } else {
                                values.push(option.key);
                            }
                        });

                        answersObject[question.name] = values.join(';');
                    } else {
                        answersObject[question.name] = option;
                    }
                }
            });

            for (const questionName in otherValues) {
                if (otherValues.hasOwnProperty(questionName)) {
                    const other = otherValues[questionName];

                    if (answersObject.hasOwnProperty(questionName)) {
                        const currentValue = answersObject[questionName];

                        const newValue = currentValue.toString().replace(/0/g, other);

                        answersObject[questionName] = newValue;
                    }
                }
            }
        }

        if (formStatus === 'REVISION') {
            formsJSON.steps[currentStepIndex].questions.forEach((question) => {
                if (question.answer !== undefined) {
                    if (question.validation.type === 'MULTIPLE_SELECT') {
                        const options = question.answer as IFormQuestionOptions[];
                        const values: string[] = [];

                        if (Array.isArray(options)) {
                            options.forEach((option) => {
                                if (option.key === '0' && option.description !== undefined) {
                                    values.push(option.description);
                                } else {
                                    values.push(option.key);
                                }
                            });
                        } else {
                            values.push(options);
                            answersObject[question.name] = values.join(';');
                        }
                    } else if (question.validation.type === 'SELECT') {
                        const option = question.answer as IFormQuestionOptions;
                        if (option.key === '0' && option.description !== undefined) {
                            answersObject[question.name] = option.description;
                        } else if (typeof option === 'object') {
                            answersObject[question.name] = option.key;
                        } else {
                            answersObject[question.name] = option;
                        }
                    } else {
                        answersObject[question.name] = question.answer;
                    }
                }
            });
        }
        await saveForm(selectedOrgId, answersObject).then((response) => {
            setFormsJSON(response);
            triggerLinkedFieldErrors(response.expressionsErrors || []);
        });
        setIsLoading(false);
    };

    const saveForm = async (
        orgId: string,
        formAnswerJSON: Record<string, any>,
    ): Promise<IFormJSON> => {
        let responseForm = {} as IFormJSON;
        const hasId = formsJSON?.id !== null;
        setIsLoading(true);

        if (formId) {
            await formsService
                .newUpdateAnswer(formAnswerJSON, formId)
                .then((response) => (responseForm = response));
        } else if (hasId) {
            if (formsJSON?.id)
                await formsService
                    .newUpdateAnswer(formAnswerJSON, formsJSON.id)
                    .then((response) => (responseForm = response));
        } else {
            await formsService
                .newSubmitNewAnswer(formAnswerJSON, orgId)
                .then((response) => (responseForm = response));
        }

        setIsLoading(false);
        return responseForm;
    };

    const triggerLinkedFieldErrors = (errors: IExpressionErrors[]) => {
        errors?.forEach((error) => {
            error.fields.forEach((field) =>
                setError(field, {
                    type: 'manual',
                    message: errorMessages[error.errorMsg as keyof typeof errorMessages],
                }),
            );
        });
    };

    const changeStep = (index: number) => {
        setCurrentStepIndex(index);
        setCurrentStep(formsJSON!.steps[index] ?? []);
        if (!isFinishStep(index)) {
            const review = reviewHasBeenReviewed(index);
            setNotReviewReviewed(review);
        }
    };

    function deepEqual(obj1: { [x: string]: any }, obj2: { [x: string]: any }) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!deepEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    }

    function reviewHasBeenReviewed(index: number): boolean {
        const currentStep = formsJSON?.steps[index];
        const currentStepCopy = formsJSONCopy?.steps[index];

        if (!currentStep || !currentStepCopy) {
            return false;
        }

        const hasReview = formsJSON.steps.find((step) =>
            step.questions.some(
                (question) =>
                    question.review != null &&
                    question.review !== '' &&
                    step.number === currentStep.number,
            ),
        );
        let count = 0;
        if (hasReview) {
            if (hasReview.number) {
            }
        }

        for (let i = 0; i < currentStep.questions.length; i++) {
            const question = currentStep.questions[i];
            const questionCopy = currentStepCopy.questions[i];

            if (question.review) {
                if (
                    Array.isArray(question.answer) &&
                    Array.isArray(questionCopy.answer)
                ) {
                    if (deepEqual(question, questionCopy)) {
                        count += 1;
                    }
                } else if (typeof question.answer === 'object') {
                    const option = question.answer as IFormQuestionOptions;
                    const optionCopy = questionCopy.answer as IFormQuestionOptions;
                    if (option.value === optionCopy.value) {
                        count += 1;
                    }
                } else {
                    if (question.answer === questionCopy.answer) {
                        count += 1;
                    }
                }
            }
        }

        if (count === 0) {
            return true;
        }
        return false;
    }

    useEffect(() => { }, [notReviewReviewed]);

    function checkMaxValue(question: IFormQuestion, value: number): boolean {
        const valueAboveMax = question.validation.maxValue! < value;

        if (valueAboveMax)
            setError(question.name, {
                type: 'max',
                message: 'Acima do valor máximo.',
            });

        return valueAboveMax;
    }

    function checkMinValue(question: IFormQuestion, value: number): boolean {
        const valueBelowMin = question.validation.minValue! > value;

        if (valueBelowMin)
            setError(question.name, {
                type: 'min',
                message: 'Abaixo do valor mínimo.',
            });
        return valueBelowMin;
    }

    function checkMaxLength(question: IFormQuestion, value: string): boolean {
        const lengthAboveMax = question.validation.size! < value.length;

        if (lengthAboveMax)
            setError(question.name, {
                type: 'maxLength',
                message: 'Máximo de caracteres excedido.',
            });

        return lengthAboveMax;
    }

    function checkFieldEmptiness(
        question: IFormQuestion,
        value: string,
    ): boolean {
        const isUndefined =
            typeof value === 'undefined' ||
            (question.validation.type === 'MULTIPLE_SELECT' &&
                typeof getValues(question.name)[0] === 'undefined');
        const isEmpty = isUndefined || value === '';

        if (isUndefined || isEmpty) {
            if (
                question.validation.type === 'DOUBLE' ||
                question.validation.type === 'INTEGER'
            ) {
                setError(question.name, {
                    type: 'required',
                    message: 'Deve ser inserido no mínimo o valor zero.',
                });
            } else {
                setError(question.name, {
                    type: 'required',
                    message: 'O campo é obrigatório.',
                });
            }
        }

        return isEmpty;
    }

    function hasStepErrors(index: number): boolean {
        let hasValueError = false;
        let hasLengthError = false;
        let hasEmptinessError = false;
        let hasInputNotRequired = false;

        const currStep = formsJSON?.steps[index];

        currStep?.questions.forEach((question) => {
            const validation = question.validation;
            const fieldValue = getValues(`${question.name}`);

            if (validation.required) {
                hasEmptinessError = checkFieldEmptiness(question, fieldValue);
            }

            if (validation.type === 'INTEGER' || validation.type === 'DOUBLE') {
                hasValueError =
                    checkMinValue(question, fieldValue as number) ||
                    checkMaxValue(question, fieldValue as number);
            }

            if (validation.type === 'TEXT') {
                hasLengthError = checkMaxLength(question, fieldValue);
            }

            if (
                question.validation.required === false &&
                question.answer === undefined
            ) {
                hasInputNotRequired = true;
            }
        });

        return (
            hasEmptinessError ||
            hasLengthError ||
            hasValueError ||
            hasInputNotRequired
        );
    }

    const handleFormTutorialEnd = () => {
        pauseTutorial();
        updateTutorialAlreadySeen('firstForm');
    };

    const fillFormJsonWithCurrentStep = () => {
        if (!formsJSON) return;

        currentStep?.questions.forEach((question, index) => {
            const value = getValues(`${question.name}`);
            if (typeof errors[question.name] === 'undefined') {
                formsJSON.steps[currentStepIndex].questions[index].answer =
                    value !== '' ? value : undefined;
            }
        });
    };

    const formFillingEnd = () => {
        const userId = session?.id;

        if (!formsJSON || !userId) return;

        const answersObject: { [key: string]: Object } = {};

        formsJSON.steps.forEach((step) => {
            step.questions.forEach((question) => {
                if (question.answer !== undefined) {
                    if (question.validation.type === 'MULTIPLE_SELECT') {
                        const options = question.answer as IFormQuestionOptions[];
                        const values: string[] = [];

                        options.forEach((option) => {
                            if (option.key === '0' && option.description !== undefined) {
                                values.push(option.description);
                            } else {
                                values.push(option.key);
                            }
                        });

                        answersObject[question.name] = values.join(';');
                    } else if (question.validation.type === 'SELECT') {
                        const option = question.answer as IFormQuestionOptions;

                        if (option.key === '0' && option.description !== undefined) {
                            answersObject[question.name] = option.description;
                        } else {
                            answersObject[question.name] = option.key;
                        }
                    } else {
                        answersObject[question.name] = question.answer;
                    }
                }
            });
        });
        if (formsJSON.id) {
            setIsLoading(true);
            formsService
                .newFinishInventoryFilling(formsJSON.id, userId, answersObject)
                .then((response) => {
                    setShowInventorySuccessModal(true);
                    setIsLoading(false);
                });
        }
    };

    const submitTeste: SubmitHandler<any> = (data) => {
        if (isValid) {
            console.log(data);
        } else {
            console.log('Form has validation errors');
        }
    };

    function fitAnswer(
        answer: IFormQuestionAnswer,
    ): IFormQuestionAnswer | undefined {
        if (typeof answer == typeof '') return undefined;

        return answer;
    }

    function getMultipleSelectOptions(
        options: IFormQuestionOptions[],
    ): IOption[] {
        let currentOptions = options;
        if (searchOp.length > 0) currentOptions = searchOp;
        return currentOptions;
    }

    const clearLinkedFieldsErrors = (fieldName: string) => {
        if (!formsJSON || formsJSON?.expressionsErrors === null) return;

        const error = formsJSON?.expressionsErrors.find((error) =>
            error.fields.some((field) => field === fieldName),
        );

        if (typeof error === 'undefined' || error.fields.length === 0) return;

        clearErrors(error?.fields);
        setFormsJSON((prev: any) => ({ ...prev, expressionsErrors: null }));
    };

    function getMultipleSelectFieldAnswer(question: IFormQuestion) {
        let answer = question.answer;
        if (typeof answer === 'string') {
            return question.options!.find((option) => option.key === answer);
        }
        return answer;
    }

    function getSelectFieldAnswer(question: IFormQuestion) {
        let answer = question.answer;
        if (typeof answer === 'string') {
            return question.options!.find((option) => option.key === answer);
        }
        return answer;
    }

    function getInput(question: IFormQuestion) {
        return question.validation.type === 'MULTIPLE_SELECT'
            ? getInputMultipleSelect(question)
            : getInputSelect(question);
    }

    function checkHasReview(questionName: string) {
        const question = formsJSON?.steps[currentStepIndex].questions.find((question) => question.name === questionName && (question.review !== undefined && question.review.length > 0));
        if (formStatus === 'FILLING') {
            return false;
        }

        if (formStatus === 'REVISION') {
            if (question) {
                return false;
            } else {
                return true;
            }
        }
    }

    function getInputSelect(question: IFormQuestion) {
        let inputValue: string | undefined;
        const hasReview = checkHasReview(question.name)
        if (question.answer) {
            const obj = question.answer as IFormQuestionOptions;
            if (obj.description) {
                inputValue = obj.description;
            }
        }

        return (
            <div className="mt-2">
                <Input
                    disabled={
                        formStatus === 'APPROVED' || formStatus === 'PENDING' || hasReview
                    }
                    value={inputValue}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        setOtherValues((prevValues) => ({
                            ...prevValues,
                            [question.name]: newValue,
                        }));
                    }}
                    errorLabel={formState.errors[`${question.name}`]?.message! as string}
                    state={formState.errors[`${question.name}`] && 'danger'}
                    size={question.validation.size}
                    onBlur={() => {
                        clearLinkedFieldsErrors(question.name);
                    }}
                    reviewLabel={question.review}
                    placeholder="Digite sua resposta."
                />
            </div>
        );
    }

    function getInputMultipleSelect(question: IFormQuestion) {
        let inputValue: string | undefined;
        const hasReview = checkHasReview(question.name)
        if (Array.isArray(question.answer)) {
            inputValue = question.answer
                .map((op) => {
                    if (op.key === '0') {
                        if (op.description) {
                            if (op.description.match(/description=([^,]+),/)) {
                                const match = op.description.match(/description=([^,]+),/);
                                if (match) {
                                    const description = match[1];
                                    return description;
                                }
                            }
                        }
                    }
                    return op.description;
                })
                .join('');
        }

        return (
            <div className="mt-2">
                <Input
                    disabled={
                        formStatus === 'APPROVED' || formStatus === 'PENDING' || hasReview
                    }
                    value={inputValue !== undefined && inputValue?.length > 0 ? inputValue : inputOther}
                    onChange={(event) => {
                        setInputOther(event.target.value)
                        setOtherValues((prevValues) => ({
                            ...prevValues,
                            [question.name]: inputOther,
                        }));
                    }}
                    errorLabel={formState.errors[`${question.name}`]?.message! as string}
                    state={formState.errors[`${question.name}`] && 'danger'}
                    size={question.validation.size}
                    onBlur={() => {
                        clearLinkedFieldsErrors(question.name);
                    }}
                    reviewLabel={question.review}
                    placeholder="Digite sua resposta."
                />
            </div>
        );
    }

    function shouldRenderInputOther(question: IFormQuestion) {
        if (question.validation.type === 'MULTIPLE_SELECT') {
            const questionValues = getValues(question.name);
            if (Array.isArray(questionValues)) {
                const hasValueOther = questionValues!.find(
                    (option) => option.key === '0',
                );
                if (hasValueOther) {
                    return true;
                } else {
                    return false;
                }
            } else if (
                questionValues !== undefined &&
                questionValues != null &&
                questionValues.includes('0')
            ) {
                return true;
            } else {
                return false;
            }
        }

        if (question.validation.type === 'SELECT') {
            const questionValues = getValues(question.name);
            if (typeof questionValues === 'object') {
                const option = questionValues as IFormQuestionOptions;
                if (option.key === '0') {
                    return true;
                }
            } else if (
                questionValues !== undefined &&
                questionValues != null &&
                questionValues.includes('0')
            ) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const renderExpressionErrors = (fieldName: string): string => {
        if (formsJSON && formsJSON?.expressionsErrors !== null) {
            const matchingErrors = formsJSON.expressionsErrors.filter((error) => {
                return error.fields.find((field) => field === fieldName);
            });

            const errorString = matchingErrors
                .map((error) => error.errorMsg)
                .join(', ');
            return errorString;
        }
        return '';
    };

    function shouldDisableField(question: IFormQuestion): boolean {
        if (
            !question.review &&
            (formStatus === undefined || formStatus === 'FILLING')
        ) {
            return false;
        }

        if (question.review && formStatus === 'REVISION') {
            return false;
        }

        return true;
    }

    function getInputType(question: IFormQuestion) {
        const shouldDisable = shouldDisableField(question);
        switch (question.validation.type) {
            case 'TEXT':
                return (
                    <Input
                        onKeyDown={handleKeyPress}
                        registerInput={register(question.name, {
                            value: question.answer ?? undefined,
                            onChange: (event) => {
                                checkFieldEmptiness(question, event.target.value);
                                checkMaxLength(question, event.target.value);
                            },
                        })}
                        errorLabel={
                            formState.errors[`${question.name}`]?.message! as string
                        }
                        state={formState.errors[`${question.name}`] && 'danger'}
                        size={question.validation.size}
                        disabled={
                            !question.validation.editable ||
                            formStatus === 'APPROVED' ||
                            formStatus === 'PENDING' ||
                            shouldDisable
                        }
                        onBlur={() => {
                            clearLinkedFieldsErrors(question.name);
                        }}
                        reviewLabel={question.review}
                    />
                );

            case 'SELECT':
                if (question.answer) {
                    const obj = question.answer as IFormQuestionOptions;
                    if (obj.description && !obj.description.includes('{value=')) {
                        enableInputOther[question.name] = true;
                    }
                }

                return (
                    <InputDropdown
                        disabled={
                            !question.validation.editable ||
                            formStatus === 'APPROVED' ||
                            formStatus === 'PENDING' ||
                            shouldDisable
                        }
                        handleKeyPress={handleKeyPress}
                        options={question.options ?? []}
                        {...register(question.name, {
                            required: question.validation.required,
                            value: getSelectFieldAnswer(question) ?? undefined,
                            onChange: (e) => {
                                setValue(question.name, e.target.value.key);
                                setEnableInputOther((prevEnableInputOther) => ({
                                    ...prevEnableInputOther,
                                    [question.name]: e.target.value.key === '0',
                                }));
                                setEnableInputOtherClick((prevEnableInputOther) => ({
                                    ...prevEnableInputOther,
                                    [question.name]: e.target.value !== '0',
                                }));
                            },
                        })}
                        state={formState.errors[`${question.name}`] && 'danger'}
                        control={control}
                        getValues={getValues}
                        reviewLabel={question.review}
                    />
                );

            case 'MULTIPLE_SELECT':
                if (Array.isArray(question.answer)) {
                    for (const op of question.answer) {
                        if (op.key === '0') {
                            if (op.description) {
                                enableInputOther[question.name] = true;
                                break;
                            }
                        } else {
                            enableInputOther[question.name] = false;
                        }
                    }
                }

                return (
                    <DropdownMultipleSelect
                        disabled={
                            !question.validation.editable ||
                            formStatus === 'APPROVED' ||
                            formStatus === 'PENDING' ||
                            shouldDisable
                        }
                        handleKeyPress={handleKeyPress}
                        name={question.name}
                        options={getMultipleSelectOptions(question.options ?? [])}
                        registerInput={register(question.name, {
                            required: question.validation.required,
                            value: getMultipleSelectFieldAnswer(question),
                            onChange: (event: any) => {
                                setSearchOp(
                                    question.options?.filter(
                                        (option) =>
                                            event.target.value !== '' &&
                                            option.value
                                                .toLowerCase()
                                                .includes((event.target.value as string).toLowerCase()),
                                    ) ?? [],
                                );
                            },
                        })}
                        state={formState.errors[`${question.name}`] && 'danger'}
                        handleOptionClick={(option) => {
                            const selectedKeys = option.map((op) => op.key).join(';');
                            if (option.some((op) => op.key === '0')) {
                                setEnableInputOther((prevEnableInputOther) => ({
                                    ...prevEnableInputOther,
                                    [question.name]: true,
                                }));
                                setEnableInputOtherClick((prevEnableInputOtherClick) => ({
                                    ...prevEnableInputOtherClick,
                                    [question.name]: true,
                                }));
                            } else {
                                setEnableInputOther((prevEnableInputOther) => ({
                                    ...prevEnableInputOther,
                                    [question.name]: false,
                                }));
                                setEnableInputOtherClick((prevEnableInputOtherClick) => ({
                                    ...prevEnableInputOtherClick,
                                    [question.name]: false,
                                }));
                            }

                            setValue(question.name, selectedKeys);

                            if (
                                !checkFieldEmptiness(question, getValues(`${question.name}`))
                            ) {
                                clearErrors(question.name);
                            }
                        }}
                        getValues={getValues}
                        reviewLabel={question.review}
                    />
                );

            case 'DATE':
                return (
                    <Controller
                        control={control}
                        name={question.name}
                        defaultValue={question.answer ?? undefined}
                        render={({ field: { onChange, ...fieldProps } }) => (
                            <DateInput
                                fieldProps={{
                                    ...fieldProps,
                                }}
                                onChangeDate={(e) => {
                                    clearLinkedFieldsErrors(question.name);
                                    onChange(e);
                                }}
                                state={formState.errors[`${question.name}`] && 'danger'}
                                onBlur={() => {
                                    clearLinkedFieldsErrors(question.name);
                                }}
                                disabled={
                                    !question.validation.editable ||
                                    formStatus === 'APPROVED' ||
                                    formStatus === 'PENDING' ||
                                    shouldDisable
                                }
                                errorLabel={
                                    formState.errors[`${question.name}`]?.message! as string
                                }
                                reviewLabel={question.review}
                            />
                        )}
                    />
                );

            case 'INTEGER':
                return (
                    <Controller
                        key={question.name}
                        name={question.name}
                        control={control}
                        defaultValue={question.answer}
                        rules={{
                            required: {
                                value: question.validation.required,
                                message: 'Deve ser inserido no mínimo o valor zero.',
                            },
                            max: {
                                value: question.validation.maxValue!,
                                message: 'Acima do valor máximo.',
                            },
                            min: {
                                value: question.validation.minValue!,
                                message: 'Abaixo do valor mínimo.',
                            },
                        }}
                        render={({ field }) => {
                            const errorMessage =
                                renderExpressionErrors(question.name) ||
                                (formState.errors[question.name]?.message as string);
                            const fieldValue = field.value || null;
                            return (
                                <Input
                                    onKeyDown={handleKeyPress}
                                    type="integer"
                                    value={fieldValue}
                                    onChange={(e) => {
                                        const newValue = +e.target.value.replaceAll('.', '');
                                        if (newValue === undefined) {
                                            field.onChange(null);
                                        } else {
                                            field.onChange(newValue);
                                        }
                                    }}
                                    state={formState.errors[`${question.name}`] && 'danger'}
                                    errorLabel={errorMessage}
                                    maxValue={question.validation.maxValue}
                                    minValue={question.validation.minValue}
                                    disabled={
                                        !question.validation.editable ||
                                        formStatus === 'APPROVED' ||
                                        formStatus === 'PENDING' ||
                                        shouldDisable
                                    }
                                    onBlur={(e) => {
                                        clearLinkedFieldsErrors(question.name);
                                    }}
                                    reviewLabel={question.review}
                                />
                            );
                        }}
                    />
                );

            case 'DOUBLE':
                return (
                    <Controller
                        key={question.name}
                        name={question.name}
                        control={control}
                        defaultValue={question.answer}
                        rules={{
                            required: {
                                value: true,
                                message: 'Deve ser inserido no mínimo o valor zero.',
                            },
                            max: {
                                value: question.validation.maxValue!,
                                message: 'Acima do valor máximo.',
                            },
                            min: {
                                value: question.validation.minValue!,
                                message: 'Abaixo do valor mínimo.',
                            },
                        }}
                        render={({ field }) => {
                            const errorMessage =
                                renderExpressionErrors(question.name) ||
                                (formState.errors[question.name]?.message as string);
                            let fieldValue = null;
                            if (field.value !== undefined) {
                                fieldValue = field.value;
                            }
                            return (
                                <Input
                                    onKeyDown={handleKeyPress}
                                    type="double"
                                    value={fieldValue}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        const numericValue = +value
                                            .replaceAll('.', '')
                                            .replace(',', '.');

                                        if (numericValue === undefined) {
                                            field.onChange(null);
                                        } else {
                                            field.onChange(numericValue);
                                        }
                                    }}
                                    state={formState.errors[`${question.name}`] && 'danger'}
                                    errorLabel={errorMessage}
                                    maxValue={question.validation.maxValue}
                                    minValue={question.validation.minValue}
                                    disabled={
                                        !question.validation.editable ||
                                        formStatus === 'APPROVED' ||
                                        formStatus === 'PENDING' ||
                                        shouldDisable
                                    }
                                    onBlur={(e) => {
                                        clearLinkedFieldsErrors(question.name);
                                    }}
                                    reviewLabel={question.review}
                                />
                            );
                        }}
                    />
                );

            case 'RADIO':
                return (
                    <Controller
                        control={control}
                        name={question.name}
                        defaultValue={question.answer ?? undefined}
                        render={({ field: { onChange } }) => (
                            <div className="flex flex-row gap-2.5">
                                <div className="flex flex-row gap-2.5">
                                    <Radio
                                        name={question.name}
                                        isChecked={getValues(question.name) === 'Sim'}
                                        value="Sim"
                                        onChange={onChange}
                                    />
                                    <Label>Sim</Label>
                                </div>
                                <div className="flex flex-row gap-2.5">
                                    <Radio
                                        name={question.name}
                                        isChecked={getValues(question.name) === 'Não'}
                                        value="Não"
                                        onChange={onChange}
                                    />
                                    <Label>Não</Label>
                                </div>
                            </div>
                        )}
                    />
                );

            case 'FILE_UPLOAD_URL':
                return <div className='mt-1'>
                    <UploadComponent 
                    placeholder='Anexe o documento' 
                    types={question.validation.fileTypes}
                    onFileChange={handleFileChange}
                    errorLabel={
                        formState.errors[`${question.name}`]?.message! as string
                    }
                    state={formState.errors[`${question.name}`] && 'danger'}
                    disabled={
                        !question.validation.editable ||
                        formStatus === 'APPROVED' ||
                        formStatus === 'PENDING' ||
                        shouldDisable
                    }
                    reviewLabel={question.review}
                    ></UploadComponent>
                    </div>
            default:
                <></>;
        }
    }

    function stepIsComplete(index: number): boolean {
        const currentStep = formsJSON?.steps[index];

        if (!currentStep) {
            return false;
        }

        if (formStatus === 'REVISION') {
            const stepNumberRevision: number[] = [];
            formsJSON?.steps.forEach((step) => {
                return step.questions.forEach((question) => {
                    if (question.review) {
                        stepNumberRevision.push(step.number);
                    }
                });
            });

            if (currentStep) {
                if (
                    stepNumberRevision.includes(currentStep.number) &&
                    !reviewHasBeenReviewed(currentStep.number)
                ) {
                    return true;
                }

                if (
                    stepNumberRevision.includes(currentStep.number) &&
                    reviewHasBeenReviewed(currentStep.number)
                ) {
                    return true;
                }
                return true;
            }
        }

        if (formStatus !== 'REVISION') {
            for (let i = 0; i < currentStep.questions.length; i++) {
                const question = currentStep.questions[i];

                if (formStatus === 'PENDING' || formStatus === 'APPROVED') {
                    return true;
                }

                if (
                    typeof question.answer === 'undefined' &&
                    typeof errors[question.name] === 'undefined' &&
                    question.validation.required === false
                ) {
                    if (currentStep?.number && numbers.includes(currentStep?.number)) {
                        let hasRequiredTrueWithoutAnswer = false;

                        for (const item of currentStep.questions) {
                            if (
                                item.validation.required === true &&
                                item.answer === undefined
                            ) {
                                hasRequiredTrueWithoutAnswer = true;
                                break;
                            }
                        }

                        if (hasRequiredTrueWithoutAnswer) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }

                if (
                    typeof question.answer === 'undefined' ||
                    typeof errors[question.name] !== 'undefined'
                ) {
                    return false;
                }
            }
        }
        if (currentStep?.number && numbers.includes(currentStep?.number)) {
            let hasRequiredTrueWithoutAnswer = false;

            for (const item of currentStep.questions) {
                if (item.validation.required === true && item.answer === undefined) {
                    hasRequiredTrueWithoutAnswer = true;
                    break;
                }
            }

            if (hasRequiredTrueWithoutAnswer) {
                return false;
            } else {
                return true;
            }
        }

        const notRequiredAndnotEditable = formsJSON.steps.find((step) => {
            return step.questions.some((question) => {
                return !question.validation.editable && !question.validation.required;
            });
        });

        if (notRequiredAndnotEditable) {
            return false;
        }
        return true;
    }

    function setStepState(index: number): IStateClasses {
        const currentStep = formsJSON?.steps[index];

        if (formStatus === 'REVISION') {
            const stepNumberRevision: number[] = [];
            formsJSON?.steps.forEach((step) => {
                return step.questions.forEach((question) => {
                    if (question.review) {
                        stepNumberRevision.push(step.number);
                    }
                });
            });

            if (currentStep) {
                if (
                    stepNumberRevision.includes(currentStep.number) &&
                    !reviewHasBeenReviewed(index)
                ) {
                    return 'warning';
                }

                if (
                    stepNumberRevision.includes(currentStep.number) &&
                    reviewHasBeenReviewed(index)
                ) {
                    return 'primary';
                }
            }
            return 'primary';
        } else {
            if (
                currentStep?.questions.some(
                    (question) => typeof question.review !== 'undefined',
                )
            ) {
                return 'warning';
            }

            if (
                //   !stepIsComplete(index) &&
                currentStep?.questions.some(
                    (question) => typeof errors[question.name] !== 'undefined',
                )
            ) {
                return 'warning';
            }
        }
        return 'primary';
    }

    function stepHasReview(index: number): boolean {
        const currentStep = formsJSON?.steps[index];

        if (currentStep) {
            const stepNumberRevision: number[] = [];
            formsJSON?.steps.find((step) => {
                return step.questions.some((question) => {
                    if (question.review) {
                        stepNumberRevision.push(step.number);
                    }
                    return 0;
                });
            });

            if (stepNumberRevision.includes(currentStep.number)) {
                return true;
            }
        }

        return false;
    }

    function stepReviewCount(index: number): number {
        const currentStep = formsJSON?.steps[index];

        if (currentStep) {
            let reviewCount = 0;

            for (let i = 0; i < currentStep.questions.length; i++) {
                const question = currentStep.questions[i];

                if (typeof question.review !== 'undefined') {
                    reviewCount++;
                }
            }

            return reviewCount;
        }

        return 0;
    }

    const checkSubmitDisabled = () => {
        if (!formsJSON) {
            setIsSubmitDisabled(true);
            return;
        }

        let counter = 0;
        formsJSON.steps.forEach((step, stepIndex) => {
            step.questions.forEach((question) => {
                const hasError = !!errors[question.name];

                if (
                    (question.validation.required === true &&
                        question.answer === undefined) ||
                    hasError
                ) {
                    counter += 1;
                }
            });

            if (formStatus === 'REVISION') {
                if (!reviewHasBeenReviewed(stepIndex)) {
                    counter += 1;
                }
            }
        });
        setIsSubmitDisabled(counter > 0);
    };

    useEffect(() => {
        checkSubmitDisabled();
    }, [formState]);

    const options = [
        { label: 'Baixar formulário', onClick: () => {
            if (formId !== undefined) {
                formsService.downloadSheetFileFormId(formId)
            } else {
                formsService.downloadSheetFile()
            }
        }, icon: 'download-file-blue-200' },
        { label: 'Upload formulário', onClick: () => setShowModalUpload(true), icon: 'upload-file-blue-200' },
    ];

    const handleFileChange = (file: File | null) => {
        setFile(file);
    };

    const handleFileUpload = async (file: File) => {
        if (file) {
            await formsService.uploadSheetFile(file, formId, selectedOrgId).then((response) => {
                setFormsJSON(response);
            });
        }
    };

    useEffect(() => {
    }, [formsJSON]);

    return (
        <>
            {isLoading && <LoadingForm />}
            {formsJSON &&
                typeof formsJSON.steps !== 'undefined' &&
                formsJSON.steps.length > 0 && (
                    <div className="md:flex md:flex-row md:h-[calc(100vh-128px)] flex-col h-auto">
                        <aside className="md:w-80 md:flex md:flex-row border-gray-100 md:border-r md:border-b-0 border-b w-full h-32">
                            <Popover
                                orientation="right-full-bottom"
                                componentName="form-step"
                                width="w-[340px]"
                                levelOrder={8}
                                exactPath={ROUTES.INVENTORY_FORM.path}
                                tutorialType="firstForm"
                                content={{
                                    title: 'Etapas',
                                    body: 'Você pode navegar pelas etapas quando quiser. Basta selecionar uma das opções da lista ao lado.',
                                    button: 'Continuar',
                                }}
                            >
                                <div className="w-[378px] md:w-80 p-10 flex flex-col max-h-[calc(100vh-128px)] overflow-y-auto scrollmobo">
                                    <ul className="md:h-[calc(100vh-128px)] flex flex-row md:flex-col">
                                        {formsJSON.steps.map((step, index) => (
                                            <li key={step.name}>
                                                <ButtonStep
                                                    index={index + 1}
                                                    title={step.description}
                                                    isSelected={currentStepIndex === index}
                                                    isCompleted={stepIsComplete(index)}
                                                    hasTrack={index !== 0}
                                                    onClick={() => {
                                                        handleStepClick(index);
                                                        scroller.scrollTo('myScrollToElement', {
                                                            duration: 100,
                                                            delay: 50,
                                                            smooth: true,
                                                            containerId: 'ContainerElementID',
                                                            offset: 0,
                                                        });
                                                    }}
                                                    state={setStepState(index)}
                                                    hasReview={stepHasReview(index)}
                                                    reviewCount={stepReviewCount(index)}
                                                    reviewHasBeenReviewed={reviewHasBeenReviewed(index)}
                                                />
                                            </li>
                                        ))}
                                        <li>
                                            <ButtonStep
                                                index={formsJSON.steps.length + 1}
                                                title={'Finalizar'}
                                                isSelected={currentStepIndex === formsJSON.steps.length}
                                                isCompleted={false}
                                                hasTrack={formsJSON.steps.length !== 0}
                                                onClick={() => handleStepClick(formsJSON.steps.length)}
                                                state={'primary'}
                                                hasReview={false}
                                                reviewCount={0}
                                                reviewHasBeenReviewed={false}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </Popover>
                        </aside>
                        {currentStep && (
                            <main className="flex overflow-hidden">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit(submitTeste)();
                                    }}
                                    className="relative h-auto md:h-screen w-full overflow-hidden"
                                >
                                    <Element name="myScrollToElement">
                                        <div
                                            id="ContainerElementID"
                                            className="md:max-h-[calc(100vh-258px)] h-auto md:h-screen w-full overflow-y-auto p-10"
                                        >
                                            {isFinishStep(currentStepIndex)
                                                ? formsJSON.id && (
                                                    <InventoryResumePage formId={formsJSON.id} />
                                                )
                                                : typeof currentStep.questions !== 'undefined' &&
                                                currentStep.questions.map((question) => {
                                                    return (
                                                        <div key={question.name} className="mb-2.5">
                                                            <Label>
                                                                {question.number +
                                                                    ' - ' +
                                                                    question.description}
                                                            </Label>
                                                            {currentStep.number > 21 &&
                                                                question.validation.editable === false && (
                                                                    <TooltipIcon
                                                                        text={
                                                                            'Campos calculados automaticamente.'
                                                                        }
                                                                    ></TooltipIcon>
                                                                )}
                                                            <>{getInputType(question)}</>
                                                            <>
                                                                {shouldRenderInputOther(question) &&
                                                                    getInput(question)}
                                                            </>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className='block md:hidden'>
                                            <FloatingButton style="filled" options={options} />
                                        </div>
                                    </Element>
                                    <footer className="sticky h-[130px] w-full flex flex-col md:flex-row items-center justify-center bottom-0 left-0 border-t border-gray-100 bg-white-100">
                                        <Popover
                                            orientation="top-full-right"
                                            componentName="form-btn-group"
                                            levelOrder={9}
                                            exactPath={ROUTES.INVENTORY_FORM.path}
                                            tutorialType="firstForm"
                                            width="w-[340px]"
                                            content={{
                                                title: 'Navegação',
                                                body: 'Além de poder navegar pelas etapas, você também pode avançar por meio do botão "Continuar".',
                                                button: 'Continuar',
                                            }}
                                            onClickContinueButton={() => handleFormTutorialEnd()}
                                            activeClose
                                        >
                                            <div className="flex flex-col md:flex-row items-center justify-center gap-2.5">
                                                <div className="w-[344px]">
                                                    <Button
                                                        label="Voltar"
                                                        style="outlined"
                                                        icon="arrow-left-blue-200"
                                                        disabled={currentStepIndex === 0}
                                                        onClick={() =>
                                                            currentStepIndex !== 0 &&
                                                            handleStepClick(currentStepIndex - 1)
                                                        }
                                                        type="button"
                                                    />
                                                </div>
                                                <div className="w-[344px]">
                                                    {currentStepIndex !== formsJSON.steps.length ? (
                                                        <Button
                                                            label="Continuar"
                                                            style="filled"
                                                            icon="arrow-right-button-white-100"
                                                            iconPosition="right"
                                                            onClick={() => {
                                                                handleStepClick(currentStepIndex + 1);
                                                                scroller.scrollTo('myScrollToElement', {
                                                                    duration: 100,
                                                                    delay: 50,
                                                                    smooth: true,
                                                                    containerId: 'ContainerElementID',
                                                                    offset: 0,
                                                                });
                                                            }}
                                                            type="button"
                                                        />
                                                    ) : (
                                                        <Button
                                                            disabled={isSubmitDisabled}
                                                            type="button"
                                                            onClick={() => formFillingEnd()}
                                                            label="Enviar"
                                                            style="filled"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Popover>
                                    </footer>
                                </form>
                            </main>
                        )}
                    </div>
                )}
            <>
                {showInventorySuccessModal && (
                    <ModalAlert visible={showInventorySuccessModal}>
                        <section className="w-auto md:w-[610px] p-10 flex flex-col items-center">
                            <div className="pb-2.5">
                                <Label size="xl" className="font-bold">
                                    Inventário enviado com sucesso!
                                </Label>
                            </div>
                            <div className="pb-10 text-center">
                                <Label color="text-gray-400">
                                    Você pode acompanhar o progresso por meio da página de
                                    inventários de sua organização na plataforma ou por meio das
                                    notificações.
                                </Label>
                            </div>
                            <div className="w-65">
                                <Button
                                    style="filled"
                                    label="Continuar"
                                    type="button"
                                    onClick={() => {
                                        setShowInventorySuccessModal(false);
                                        navigate(-1);
                                    }}
                                />
                            </div>
                        </section>
                    </ModalAlert>
                )}
            </>
            <>
                <ModalAlert visible={showModalUpload} onClose={() => setShowModalUpload(false)}>
                    <div className="w-auto p-10">
                        <div className="text-center gap-2.5 flex flex-col">
                            <Label size="xl" className="font-bold">
                                Carregar Formulário
                            </Label>
                            <Label color="text-gray-400">
                                Escolha o arquivo que será carregado:
                            </Label>
                            <div className='mt-5'>
                                <UploadComponent placeholder='Anexe o documento' onFileChange={handleFileChange}></UploadComponent>
                            </div>
                        </div>
                        <div className="w-auto mt-10 mx-auto flex flex-row space-x-4">
                            <Button
                                label="Fechar"
                                style="outlined"
                                icon="arrow-left-blue-200"
                                onClick={() => setShowModalUpload(false)}
                                type="button"
                            />
                            <Button
                                label="Enviar"
                                style="filled"
                                icon="arrow-right-button-white-100"
                                iconPosition='right'
                                onClick={() => {
                                    if (file) {
                                        handleFileUpload(file);
                                    }
                                    setShowModalUpload(false)
                                }}
                                type="button"
                            />
                        </div>
                    </div>
                </ModalAlert>
            </>
        </>
    );
};

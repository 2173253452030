import Label from '../../components/Label/Label';
import Logo from '../../components/Logo/Logo';

interface IInventoryInfoPageProps {}

export const InventoryInfoPage: React.FC<IInventoryInfoPageProps> = () => {
  return (
    <div className="p-10">
      {/* <div className="flex mb-10 justify-center">
        <Logo width={'457.212'} height={'150'} />
      </div> */}
      <Label className="text-black-200 text-justify !font-normal">
        <p>
          <strong>
            Como submeter um inventário de emissões e remoções por meio do
            SIRENE Organizacionais?
          </strong>
        </p>
        <br />
        <p>
          Antes de começar, é importante que as organizações possam preparar
          seus inventários de emissões e remoções, de forma completa e precisa.
        </p>
        <br />
        <p>
          Quanto às metodologias para quantificação, a recomendação é que as
          organizações se utilizem das metodologias, que em sua maioria estão
          recomendadas nas plataformas{' '}
          <span className="italic text-blue-200">
            <a
              href="https://eaesp.fgv.br/centros/centro-estudos-sustentabilidade/projetos/programa-brasileiro-ghg-protocol"
              target="_blank"
              rel="noopener noreferrer"
            >
              do Programa Brasileiro GHG Protocol
            </a>
          </span>{' '}
          e no{' '}
          <span className="italic text-blue-200">
            <a
              href="https://eaesp.fgv.br/centros/centro-estudos-sustentabilidade/projetos/programa-brasileiro-ghg-protocol"
              target="_blank"
              rel="noopener noreferrer"
            >
              Carbon Disclosure Project (CDP)
            </a>
          </span>
          . As organizações poderão utilizar suas metodologias setoriais, desde
          que sejam mais precisas ou que sejam, pelo menos consistentes com as
          diretrizes do Programa Brasileiro GHG Protocol.
        </p>
        <br />
        <p>
          A submissão dos inventários de emissões e remoções na plataforma
          Sirene Organizacionais é voluntária, todavia há alguns requisitos que
          todos os inventários a serem submetidos deverão atender:{' '}
        </p>
        <p className="lg:ml-10 mt-2">
          <ul className="list-disc">
            <li className="mb-2">
              A cobertura de relato é ampla, ou seja, qualquer organização de
              qualquer setor e tamanho poderá submeter suas informações na
              plataforma;
            </li>
            <li className="mb-2">
              A unidade de análise para inserção dos resultados se dará via CNPJ
              da organização;
            </li>
            <li className="mb-2">
              Para o relato no SIRENE, será obrigatório a inserção das emissões
              desagregadas por categorias de Escopo 1, ou seja, a partir de
              fontes pertencentes ou controladas pela entidade; e emissões do
              Escopo 2, que são emissões indiretas originadas da geração de
              energia elétrica e/ou térmica adquirida para consumo próprio da
              entidade relatora.
            </li>
            <li className="mb-2">
              As emissões do Escopo 3 (emissões indiretas, não incluídas no
              Escopo 2) poderão ser relatadas no SIRENE, mas são de relato
              opcionais;
            </li>
            <li className="mb-2">
              Para o relato no SIRENE, os gases inventariados deverão ser
              aqueles indicados na última versão das diretrizes para inventários
              nacionais publicada pelo IPCC, que atualmente são: Dióxido de
              carbono (CO<sub>2</sub>), Metano (CH<sub>4</sub>), Óxido nitroso
              (N<sub>2</sub>O), Hexafluoreto de enxofre (SF<sub>6</sub>),
              Hidrofluorcarbonos (HFCs), Perfluorcarbonos (PFCs) e Trifluoreto
              de nitrogênio (NF<sub>3</sub>).
            </li>
            <li className="mb-2">
              Todos os inventários deverão ser verificados por terceira parte
              por Organismos de Verificação de Inventários de Gases de Efeito
              Estufa (GEE) acreditados pelo Instituto Nacional de Metrologia,
              Qualidade e Tecnologia (INMETRO).
            </li>
          </ul>
        </p>
        <br />
        <p>
          <i>
            Em breve tutorial de como navegar na plataforma e submeter um
            inventário organizacional de GEE.
          </i>
        </p>
      </Label>
    </div>
  );
};

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { storageService } from '../services/storageService';
import { ROUTES } from '../routes/Index.routes';
import { AuthContext } from './AuthContext';
import { userService } from '../services/userService';

enum TUTORIAL_STATUS {
  UNVISITED = 0,
  VISITED = 1,
  SKIPED = 2,
}

export enum TUTORIAL_STATE {
  EXECUTE = 'EXECUTE',
  PAUSED = 'PAUSED',
  STOPED = 'STOPED',
}

export type TutorialType = 'firstAccess' | 'firstOrg' | 'firstForm';

interface ITutorialInstance {
  componentName: string;
  levelOrder: number;
  status: number;
  exactPath: string;
}

interface TutorialAppContextType {
  createNewInstaceTutorial: (
    componentName: string,
    levelOrder: number,
    exactPath?: string,
  ) => void;
  continueTutorial: () => void;
  pauseTutorial: () => void;
  nextTutorial: () => void;
  stopTutorial: () => void;
  updateTutorialAlreadySeen: (
    type: 'firstAccess' | 'firstOrg' | 'firstForm',
  ) => void;
  currentTutorial: string | undefined;
  stateTutorial: string;
}

interface TutorialAppContextProps {
  children: React.ReactNode;
}

export const TutorialAppContext = createContext<TutorialAppContextType>(
  {} as TutorialAppContextType,
);

export const TutorialAppProvider: React.FC<TutorialAppContextProps> = ({
  children,
}) => {
  const location = useLocation();

  const { session, setSession } = useContext(AuthContext);

  const storage =
    storageService.getOnStorage<ITutorialInstance[]>('tutorial', true) ?? [];

  const [tutorialsList, setTutorialsList] = useState<ITutorialInstance[]>([]);

  const [scriptTutorial, setScriptTutorial] = useState<ITutorialInstance[]>([]);

  const [stateTutorial, setStateTutorial] = useState<string>(
    TUTORIAL_STATE.PAUSED,
  );

  const [currentTutorial, setTutorialComp] = useState<string | undefined>('');

  const continueTutorial = () => setStateTutorial(TUTORIAL_STATE.EXECUTE);

  const pauseTutorial = () => {
    if (stateTutorial === TUTORIAL_STATE.EXECUTE) {
      const updatedTutorial = scriptTutorial.map((tutorial) => {
        if (tutorial.componentName == currentTutorial)
          return { ...tutorial, status: TUTORIAL_STATUS.VISITED };
        return tutorial;
      });
      setScriptTutorial(updatedTutorial);
      setStateTutorial(TUTORIAL_STATE.PAUSED);
      setTutorialComp('');
    }
  };

  const stopTutorial = () => {
    if (stateTutorial === TUTORIAL_STATE.EXECUTE) {
      const updateTutorials = scriptTutorial.map((tutorial) => {
        if (tutorial.exactPath === location.pathname)
          return { ...tutorial, status: TUTORIAL_STATUS.SKIPED };
        return tutorial;
      });
      setTutorialComp('');
      setScriptTutorial(updateTutorials);
    }
  };

  const nextTutorial = () => {
    if (stateTutorial === TUTORIAL_STATE.EXECUTE) {
      const updateTutorial = scriptTutorial.map((tutorial) => {
        if (tutorial.componentName === currentTutorial)
          return { ...tutorial, status: TUTORIAL_STATUS.VISITED };
        else return tutorial;
      });
      setScriptTutorial(updateTutorial);
      setTutorialComp(
        () =>
          updateTutorial.find(
            (tutorial) => tutorial.status === TUTORIAL_STATUS.UNVISITED,
          )?.componentName ?? '',
      );
      if (updateTutorial.every((e) => e.status !== TUTORIAL_STATUS.UNVISITED))
        updateTutorialData(updateTutorial);
    }
  };

  const createNewInstaceTutorial = (
    componentName: string,
    levelOrder: number,
    exactPath?: string,
  ) => {
    if (tutorialAlreadySeen(componentName, exactPath)) return;

    setTutorialsList((prev) =>
      !prev.some((f) => f.componentName === componentName)
        ? [
            ...prev,
            {
              status: TUTORIAL_STATUS.UNVISITED,
              componentName: componentName,
              exactPath: exactPath ?? '/',
              levelOrder,
            },
          ]
        : prev,
    );
  };

  const updateTutorialData = (updatedList?: ITutorialInstance[]) => {
    const updated = tutorialsList.map((list) => {
      const exists = (updatedList ?? scriptTutorial).find(
        (script) => script.componentName === list.componentName,
      );
      if (!!exists) return exists;
      return list;
    });
    if (updated.length > 0) storageService.saveOnStorage('tutorial', updated);
  };

  function tutorialAlreadySeen(
    componentName: string,
    exactPath: string = '',
  ): boolean {
    if (session === null) return true;
    const tutorialSeen = session.tutorialVisualisation ?? {
      sawAccessTutorial: false,
      sawCompanyTutorial: false,
      sawFormTutorial: false,
    };
    switch (exactPath) {
      case ROUTES.GENERAL_VISION.path:
        if (
          componentName.includes(ROUTES.GENERIC_ORGANIZATION.tutorialName) ||
          componentName.includes(ROUTES.GENERAL_VISION.tutorialName) ||
          componentName.includes(ROUTES.NOTIFICATION.tutorialName)
        )
          return tutorialSeen.sawCompanyTutorial ?? true;

        if (
          componentName.includes('toggle-switch') ||
          componentName.includes('button-add-inventory') ||
          componentName.includes(ROUTES.GENERAL_VISION.path) ||
          componentName.includes(ROUTES.INVENTORY.tutorialName) ||
          componentName.includes(ROUTES.ORGANIZATION.tutorialName) ||
          componentName.includes(ROUTES.ADMINISTRATORS.tutorialName) ||
          componentName.includes(ROUTES.NOTIFICATION.tutorialName)
        )
          return tutorialSeen.sawAccessTutorial ?? true;

        break;

      case ROUTES.INVENTORY_FORM.path:
        if (
          componentName.includes('button-upload') ||
          componentName.includes('button-download') ||
          componentName.includes('button-save') ||
          componentName.includes('form-step') ||
          componentName.includes('form-btn-group')
        )
          return tutorialSeen.sawFormTutorial ?? true;

        break;
    }

    return true;
  }

  const updateTutorialAlreadySeen = async (type: TutorialType) => {
    if (session === null) return;

    const id = session.id;
    const tutorialType = {
      firstAccess: 'sawAccessTutorial',
      firstOrg: 'sawCompanyTutorial',
      firstForm: 'sawFormTutorial',
    };

    const params = `${id}?${tutorialType[type]}=true`;

    await userService.updateTutorialVisualization(params).then((response) => {
      updateTutorialInSession(type);
    });
  };

  const updateTutorialInSession = (type: TutorialType) => {
    if (session === null) return;
    let tutorialAlreadyInSession = session.tutorialVisualisation ?? {
      sawAccessTutorial: false,
      sawCompanyTutorial: false,
      sawFormTutorial: false,
    };
    switch (type) {
      case 'firstAccess':
        tutorialAlreadyInSession.sawAccessTutorial = true;
        break;

      case 'firstOrg':
        tutorialAlreadyInSession.sawCompanyTutorial = true;
        break;

      case 'firstForm':
        tutorialAlreadyInSession.sawFormTutorial = true;
        break;
    }

    const updateSession: ISession = {
      ...session,
      tutorialVisualisation: tutorialAlreadyInSession,
    } as ISession;
    setSession(updateSession);
  };

  function levelOrderSorter(a: number, b: number): number {
    if (a > b) return 1;
    if (b > a) return -1;

    return 0;
  }

  useEffect(() => {
    const userTutorials = tutorialsList
      .map((list) => {
        const exists = storage.find(
          (store) => store.componentName === list.componentName,
        );
        return !!exists ? exists : list;
      })
      .filter((tutorial) => tutorial.exactPath === location.pathname);
    setScriptTutorial(() =>
      userTutorials.sort((a, b) =>
        levelOrderSorter(a.levelOrder, b.levelOrder),
      ),
    );
    if (stateTutorial === TUTORIAL_STATE.EXECUTE)
      setTutorialComp(
        () =>
          userTutorials.find(
            (tutorial) => tutorial.status === TUTORIAL_STATUS.UNVISITED,
          )?.componentName ?? '',
      );
  }, [location.pathname, tutorialsList]);

  useEffect(() => {
    switch (stateTutorial) {
      case TUTORIAL_STATE.EXECUTE: {
        setTutorialComp(
          () =>
            scriptTutorial.find(
              (tutorial) => tutorial.status === TUTORIAL_STATUS.UNVISITED,
            )?.componentName ?? '',
        );
        break;
      }
      case TUTORIAL_STATE.PAUSED: {
        updateTutorialData();
        break;
      }
      case TUTORIAL_STATE.STOPED: {
        break;
      }
    }
  }, [stateTutorial]);

  useEffect(() => {
    if (session !== null) return;
    setTutorialsList([]);
    setScriptTutorial([]);
    setStateTutorial(TUTORIAL_STATE.PAUSED);
    setTutorialComp('');
  }, [session]);

  return (
    <TutorialAppContext.Provider
      value={{
        createNewInstaceTutorial,
        continueTutorial,
        currentTutorial,
        stateTutorial,
        pauseTutorial,
        nextTutorial,
        stopTutorial,
        updateTutorialAlreadySeen,
      }}
    >
      {children}
    </TutorialAppContext.Provider>
  );
};

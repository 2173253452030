import { useContext, useEffect, useState } from 'react';
import Button from '../Button/Button';
import ModalAlert from '../Modal/ModalAlert';
import Label from '../Label/Label';
import { SearchInput } from '../SearchInput/SearchInput';
import { organizationService } from '../../services/organizationService';
import { unmask } from '../../utils/strings/mask';
import { Loading } from '../Loading/Loading';
import Icon from '../Icon/Icon';
import { Input } from '../Input/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Checkbox/Checkbox';
import { AuthContext } from '../../contexts/AuthContext';
import { TutorialAppContext } from '../../contexts/TutorialAppContext';
import Recaptcha from '../Recaptcha/Recaptcha';
import { ToastContext } from '../../contexts/ToastContext';
import { Toast } from '../Toast/Toast';
import validateCnpj from '../../utils/validations/cnpjValidation';
import { InputMessage } from '../InputMessage/InputMessage';
import { AutoComplete } from '../AutoComplete/AutoComplete';
import { cnaeService } from '../../services/cnaeService';

interface ConsultOrganizationProps {
    showSearchOrgModal: boolean;
    setShowSearchOrgModal: React.Dispatch<React.SetStateAction<boolean>>;
    onConsultedOrg?: (org: IOrganizationSearch) => void;
}
export const ConsultOrganization: React.FC<ConsultOrganizationProps> = ({
    showSearchOrgModal,
    setShowSearchOrgModal,
    onConsultedOrg,
}) => {
    const {
        register,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
        formState,
        getValues,
    } = useForm<any>();
    const { session, setSession } = useContext(AuthContext);
    const { continueTutorial } = useContext(TutorialAppContext);
    const { setOrgNickname, addToast, triggerToast, toastQueue } =
        useContext(ToastContext);
    const [cnpjAlreadySearched, setCnpjAlreadySearched] = useState(false);
    const [cnpj, setCnpj] = useState<string>('');
    const [maskedCnpj, setMaskedCnpj] = useState<string>('');
    const [orgSocialName, setOrgSocialName] = useState<string>('');
    const [showAddOrg, setShowAddOrg] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showApplyToOrg, setShowApplyToOrg] = useState<boolean>(false);
    const [showLinkToOrg, setShowLinkToOrg] = useState<boolean>(false);
    const [showParticipationToast, setShowParticipationToast] =
        useState<boolean>(false);
    const [errorSearch, setErrorSearch] = useState<boolean>(false);
    const [modalPage, setModalPage] = useState(0);
    const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
    const [org, setOrg] = useState<IOrganization[] | null>(null);
    const [toastOrg, setToastOrg] = useState<string>('');
    const [maskedCep, setMaskedCep] = useState<string>('');
    const [maskedTelephone, setMaskedTelephone] = useState<string>('');
    const [classes, setClasses] = useState<ICnae[]>([]);
    const [buttonEnable, setButtonEnable] = useState(false);
    const [search, setSearch] = useState('');

    const options: IOption[] = [
        { key: 'REPORTER', value: 'Relator' },
        { key: 'REPRESENTATIVE', value: 'Responsável' },
    ];

    const handleOptionClick = (option: IOption) => {
        setSelectedOption(option);
    };

    const [newOrganization, setNewOrganization] = useState<IOrganization>({
        id: '',
        cnpj: '',
        socialName: '',
        fantasyName: '',
        nickName: '',
        activity: '',
        country: '',
        telephone: '',
        association: '',
        email: '',
        address: {
            id: '',
            cep: '',
            state: '',
            country: '',
            city: '',
            neighborhood: '',
            street: '',
            complement: '',
        },
    });
    const [policyAndPrivacy, setPolicyAndPrivacy] = useState(false);
    const [captchaChecked, setCaptchaChecked] = useState(false);

    const checkCnpj = (event: any) => {
        event.preventDefault();
        const searchCnpj = unmask(event.target.value);

        if (cnpjNotEmptyAndDiffers(searchCnpj)) return;

        handleCnpjChange(searchCnpj);

        if (searchCnpj.length === 14) {
            setMaskedCnpj(event.target.value);
            setCnpjAlreadySearched(false);
        }
    };

    function cnpjNotEmptyAndDiffers(searchCnpj: string): boolean {
        return cnpj !== '' && searchCnpj === cnpj;
    }

    const handleCnpjChange = (searchCnpj: string) => {
        setCnpj(searchCnpj);
        resetFieldsAndStates();
    };

    useEffect(() => {
        if (cnpj.length < 14 || cnpjAlreadySearched) return;

        async function getOrg() {
            if (!validateCnpj(cnpj)) return setErrorSearch(true);
            setCnpjAlreadySearched(false);
            setShowLoading(true);
            setOrg((await organizationService.getOrgByCnpj(cnpj)) || null);
            setCnpjAlreadySearched(true);
        }

        getOrg();
    }, [cnpjAlreadySearched, cnpj, maskedCnpj]);

    const handleModalClose = () => {
        setShowSearchOrgModal(false);
        setPolicyAndPrivacy(false);
        setCnpj('');
        setModalPage(0);
        resetFieldsAndStates();
        sessionStorage.clear();
        localStorage.removeItem('_grecaptcha');
    };

    const resetFieldsAndStates = () => {
        reset();
        setShowApplyToOrg(false);
        setShowLinkToOrg(false);
        setOrg(null);
        setSelectedOption(null);
        setMaskedCnpj('');
        setMaskedCep('');
        setMaskedTelephone('');
        setShowAddOrg(false);
        setShowLoading(false);
        setErrorSearch(false);
        setNewOrganization({
            id: '',
            cnpj: '',
            socialName: '',
            fantasyName: '',
            nickName: '',
            activity: '',
            country: '',
            telephone: '',
            association: '',
            email: '',
            address: {
                id: '',
                cep: '',
                state: '',
                country: '',
                city: '',
                neighborhood: '',
                street: '',
                complement: '',
            },
        });
    };

    const submitOrgInfo: SubmitHandler<any> = (data) => {
        setNewOrganization((prevState: any) => ({
            ...prevState,
            cnpj: unmask(data.cnpj),
            socialName: data.socialName,
            country: data.country,
            fantasyName: data.fantasyName,
            activity: search,
        }));
        setModalPage(2);
        setValue('cep', maskedCep);
        setValue('phone', maskedTelephone);
    };

    const submitOrgAddress: SubmitHandler<any> = (data) => {
        setNewOrganization((prevState: any) => ({
            ...prevState,
            email: data.email,
            telephone: unmask(data.phone),
            address: {
                cep: unmask(data.cep),
                complement: unmask(data.number) + ', ' + data.complement,
                street: data.street,
            },
        }));
        setModalPage(3);
    };

    const submitRoleAndNickname: SubmitHandler<any> = (data) => {
        setNewOrganization((prevState: any) => ({
            ...prevState,
            nickName: data.nickName,
            association: data.association,
        }));
        setModalPage(4);
    };

    const createOrganization = async () => {
        if (!policyAndPrivacyChecked()) return;
        // TODO: integrar reCaptcha com RNP
        // if (!policyAndPrivacyChecked() || !captchaChecked) return;
        const addOrg: IOrganization = {
            cnpj: newOrganization.cnpj,
            socialName: newOrganization.socialName,
            fantasyName: newOrganization.fantasyName,
            nickName: newOrganization.nickName,
            email: newOrganization.email,
            telephone: newOrganization.telephone,
            activity: newOrganization.activity,
            country: newOrganization.country,
            association: newOrganization.association,
            address: newOrganization.address,
        };
        await organizationService
            .createOrganization(addOrg, session?.id!)
            .then((org) => {
                onConsultedOrg && onConsultedOrg(org.company);
                createToast();
                setOrgNickname(newOrganization.nickName);
                handleModalClose();
            })
            .then(() => {
                continueTutorial();
            });
    };

    useEffect(() => {
        if (session?.tutorialVisualisation?.sawCompanyTutorial)
            triggerToast('createOrg');
    }, [toastQueue]);

    const createToast = () => {
        addToast({
            label: `Sua organização ${newOrganization.nickName ?? ''
                } foi cadastrada na plataforma e você está vinculado à ela como um responsável. Você poderá visualizar mais informações por meio do menu lateral.`,
            title: 'Organização Inventariante.',
            state: 'primary',
            type: 'createOrg',
        });
    };

    const participationRequest = async () => {
        if (
            selectedOption == null ||
            session == null ||
            org == null ||
            session.id == null ||
            org[0].id == null
        )
            return;

        const participation: IParticipation = {
            type: 'SOLICITATION',
            companyUserType: selectedOption.key,
            fromUserId: session.id,
            companyId: org[0].id,
        };
        await organizationService
            .participationRequest(participation)
            .then(() => {
                setToastOrg(org[0].nickName);
                setShowParticipationToast(true);
            })
            .then(() => handleModalClose());
    };

    const handleCaptcha = (event: any) => {
        setCaptchaChecked(false);
        if (event !== null) setCaptchaChecked(true);
    };

    function policyAndPrivacyChecked(): boolean {
        if (policyAndPrivacy) return true;

        setShowErrorMessage(true);
        return false;
    }

    function emailErrorType(type?: any): IStateClasses {
        if (type === 'pattern') return 'warning';
        if (type === 'required') return 'danger';

        return undefined;
    }
    function orgHasResponsible(org: IOrganization): boolean {
        return org.hasResponsible ?? false;
    }

    const linkUserToOrg = async () => {
        if (org === null || newOrganization.nickName === '') return;

        const reqPayload = {
            companyId: org[0].id,
            userId: session?.id,
            nickName: newOrganization.nickName,
            association: newOrganization.association,
            type: 'REPRESENTATIVE',
        };

        await organizationService.linkToOrganization(reqPayload).then((org) => {
            const updateOrgs: IOrganizationProps[] = [
                ...(session?.organizations ?? []),
                {
                    id: org.company.id,
                    activity: org.company.activity,
                    cnpj: org.company.cnpj,
                    country: org.company.country,
                    fantasyName: org.company.fantasyName,
                    nickName: org.company.nickName,
                    socialName: org.company.socialName,
                    hasResponsible: org.company.hasResponsible,
                },
            ];

            const updateSession: ISession = {
                ...session,
                organizations: updateOrgs,
            } as ISession;

            setSession(updateSession);
            continueTutorial();
        });
    };

    const handleModalFlowEnd = () => {
        if (showLinkToOrg) linkUserToOrg();
        else createOrganization();
    };

    useEffect(() => {
        setShowLoading(false);
        if (org === null) return;

        if (org.length === 0) return setShowAddOrg(true);

        if (orgHasResponsible(org[0])) return setShowApplyToOrg(true);

        setOrgSocialName(org[0].socialName);
        setShowLinkToOrg(true);
    }, [org]);

    const getClasses = async () => {
        try {
            const response = await cnaeService.findCnae();
            setClasses(response);
        } catch (error) {
            console.error('Cannot fetch the data.', error);
        }
    };

    useEffect(() => {
        getClasses();
    }, []);

    const handleHasInputChange = (hasInput: boolean) => {
        setButtonEnable(hasInput);
    }

    const isFormValid = () => {
        if (buttonEnable) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        isFormValid()
    }, [buttonEnable]);

    const handleSearch = (value: string) => {
        setSearch(value)
    }

    return (
        <>
            <ModalAlert visible={showSearchOrgModal}>
                <section className="w-[400px] h-[45rem] md:w-[38.125rem] md:h-[45rem] relative">
                    <div className="absolute top-0 left-0 right-0 bottom-0">
                        <button
                            onClick={() => {
                                handleModalClose();
                            }}
                            className="absolute top-[19px] right-[19px] border-none bg-none"
                        >
                            <Icon name="close-blue-200" size="sm" />
                        </button>
                        {modalPage === 0 && (
                            <>
                                <div className="pt-10 px-10">
                                    <Label size="xl" color="text-black-100">
                                        Consultar Organização Inventariante
                                    </Label>
                                </div>
                                <div className="pt-[0.625rem] px-10">
                                    <Label color="text-gray-400">
                                        Utilize o campo de pesquisa para iniciar sua busca.
                                    </Label>
                                </div>
                                <div className="py-10 px-10 border-b border-gray-600">
                                    <SearchInput
                                        placeholder="Informe um CNPJ..."
                                        mask="cnpj"
                                        value={cnpj}
                                        state={errorSearch === true ? 'danger' : undefined}
                                        errorLabel="CNPJ inválido, verifique os dados."
                                        onChange={(event) => checkCnpj(event)}
                                    />
                                </div>
                                {validateCnpj(cnpj) && (
                                    <>
                                        <div className="py-10 px-10 bg-gray-100 border-b border-gray-600">
                                            <div className="flex flex-row items-center gap-5">
                                                <Label size="md" color="text-black-100">
                                                    {maskedCnpj}
                                                </Label>
                                                {showLoading && <Loading />}
                                            </div>
                                            {showLinkToOrg && (
                                                <Label color="text-gray-400">{orgSocialName}</Label>
                                            )}
                                        </div>
                                        {showAddOrg && (
                                            <>
                                                <div className="p-10">
                                                    <Label>
                                                        Esse CNPJ ainda não está cadastrado na plataforma.
                                                        Você pode cadastrar a Organização Inventariante ou
                                                        realizar uma nova busca no campo de pesquisa.
                                                    </Label>
                                                </div>
                                                <div className="px-10">
                                                    <Button
                                                        label="Cadastrar"
                                                        style="filled"
                                                        iconPosition="right"
                                                        icon="arrow-right-button-white-100"
                                                        onClick={() => {
                                                            setModalPage(1);
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {showApplyToOrg && (
                                            <>
                                                <div className="p-10">
                                                    <Label>
                                                        Esse CNPJ já está cadastrado na nossa plataforma,
                                                        você pode enviar um pedido de participação como
                                                        relator ou responsável.
                                                    </Label>
                                                </div>
                                                <div className="px-10">
                                                    <Button
                                                        label="Pedido de participação"
                                                        style="filled"
                                                        iconPosition="right"
                                                        icon="arrow-right-button-white-100"
                                                        onClick={() => {
                                                            setModalPage(5);
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {showLinkToOrg && (
                                            <>
                                                <div className="p-10">
                                                    <Label>
                                                        Esta organização já está cadastrada em na
                                                        plataforma. Você pode se vincular à ela como
                                                        responsável.{' '}
                                                    </Label>
                                                </div>
                                                <div className="px-10">
                                                    <Button
                                                        label="Sou o responsável por essa organização"
                                                        style="filled"
                                                        iconPosition="right"
                                                        icon="arrow-right-button-white-100"
                                                        onClick={() => {
                                                            setModalPage(6);
                                                            setValue('nickName', orgSocialName);
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {modalPage === 1 && (
                            <form
                                onSubmit={handleSubmit(submitOrgInfo)}
                                className="max-h-[calc(100%-130px)] overflow-auto"
                            >
                                <div className="pt-10 px-10">
                                    <Label>CNPJ</Label>
                                </div>
                                <div className="pt-2 5 px-10">
                                    <Input
                                        mask="cnpj"
                                        value={cnpj}
                                        disabled={true}
                                        registerInput={register('cnpj', {
                                            required: true,
                                        })}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>Razão Social</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        placeholder="Insira a razão social aqui"
                                        registerInput={register('socialName', {
                                            required: true,
                                        })}
                                        state={errors.socialName && 'danger'}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>Nome Fantasia</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        placeholder="Insira o nome fantasia aqui"
                                        registerInput={register('fantasyName', {
                                            required: true,
                                        })}
                                        state={errors.fantasyName && 'danger'}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>País</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        placeholder="Insira o país aqui"
                                        registerInput={register('country', {
                                            required: true,
                                        })}
                                        state={errors.country && 'danger'}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>Atividade Primária</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <AutoComplete placeholder='Insira a sua CNAE' options={classes}
                                        state={errors.primaryActivity && 'danger'}
                                        updateHasInput={handleHasInputChange} onValueChange={handleSearch} minLength={3}></AutoComplete>
                                </div>
                                <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                                <div className="absolute bottom-10 left-10 right-10 flex gap-2.5">
                                    <Button
                                        style="outlined"
                                        label="Voltar"
                                        icon="arrow-left-blue-200"
                                        onClick={() => {
                                            setModalPage(0);
                                        }}
                                    />
                                    <Button
                                        disabled={!isFormValid()}
                                        style="filled"
                                        label="Continuar"
                                        iconPosition="right"
                                        icon="arrow-right-button-white-100"
                                        type="submit"
                                    />
                                </div>
                            </form>
                        )}
                        {modalPage === 2 && (
                            <form
                                onSubmit={handleSubmit(submitOrgAddress)}
                                className="max-h-[calc(100%-130px)] overflow-y-auto"
                            >
                                <div className="pt-10 px-10">
                                    <Label>CEP</Label>
                                </div>
                                <div className="pt-2 5 px-10">
                                    <Input
                                        mask="cep"
                                        placeholder="Ex: 12345-678"
                                        registerInput={register('cep', {
                                            required: true,
                                            onChange: (event) => setMaskedCep(event.target.value),
                                        })}
                                        state={errors.cep && 'danger'}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>Endereço</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        placeholder="Insira o endereço aqui"
                                        registerInput={register('street', {
                                            required: true,
                                        })}
                                        state={errors.street && 'danger'}
                                    />
                                </div>
                                <div className="flex flex-row gap-2.5 px-10">
                                    <div className="w-full">
                                        <div className="pt-2.5">
                                            <Label>Número</Label>
                                        </div>
                                        <div className="pt-2.5">
                                            <Input
                                                type="number"
                                                placeholder="Insira o número aqui"
                                                registerInput={register('number', {
                                                    required: true,
                                                })}
                                                state={errors.number && 'danger'}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div className="pt-2.5">
                                            <Label>Complemento</Label>
                                        </div>
                                        <div className="pt-2.5">
                                            <Input
                                                placeholder="Ex: Bloco A; Apto 1;"
                                                registerInput={register('complement')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>Telefone</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        mask="phone"
                                        placeholder="Ex: (11) 9 9999-9999"
                                        registerInput={register('phone', {
                                            required: true,
                                            onChange: (event) =>
                                                setMaskedTelephone(event.target.value),
                                        })}
                                        state={errors.phone && 'danger'}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>E-mail de contato</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        placeholder="Ex: exemplo@email.com"
                                        registerInput={register('email', {
                                            required: true,
                                            pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                                        })}
                                        state={errors.email && emailErrorType(errors.email.type)}
                                    />
                                </div>
                                <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                                <div className="absolute bottom-10 left-10 right-10 flex gap-2.5">
                                    <Button
                                        style="outlined"
                                        label="Voltar"
                                        icon="arrow-left-blue-200"
                                        onClick={() => {
                                            setModalPage(1);
                                        }}
                                    />
                                    <Button
                                        style="filled"
                                        label="Continuar"
                                        iconPosition="right"
                                        icon="arrow-right-button-white-100"
                                        type="submit"
                                    />
                                </div>
                            </form>
                        )}
                        {modalPage === 3 && (
                            <form
                                onSubmit={handleSubmit(submitRoleAndNickname)}
                                className="max-h-[calc(100%-130px)] overflow-y-auto"
                            >
                                <div className="pt-10 px-10">
                                    <Label size="xl" color="text-black-100">
                                        Organização Inventariante
                                    </Label>
                                </div>
                                <div className="pt-2.5 pb-10 px-10 border-b border-gray-600">
                                    <Label color="text-gray-400">
                                        Você pode continuar através do botão abaixo.
                                    </Label>
                                </div>
                                <div className="pt-10 px-10">
                                    <Label>Seu cargo dentro dessa organização (opcional)</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        placeholder="Ex: Sócio; Desenvolvedor;"
                                        registerInput={register('association')}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>Apelido dessa organização</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        registerInput={register('nickName', {
                                            required: true,
                                        })}
                                        state={errors.nickName && 'danger'}
                                    />
                                </div>
                                <div className="pt-10 px-10">
                                    <Label color="text-gray-400">
                                        O apelido irá facilitar a visualização e acesso rápido à
                                        essa organização em sua Área Restrita. Esse apelido poderá
                                        ser editado posteriormente.{' '}
                                    </Label>
                                </div>

                                <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                                <div className="absolute bottom-10 left-10 right-10 flex gap-2.5">
                                    <Button
                                        style="outlined"
                                        label="Voltar"
                                        icon="arrow-left-blue-200"
                                        onClick={() => {
                                            setModalPage(2);
                                            setValue('cep', maskedCep);
                                            setValue('phone', maskedTelephone);
                                        }}
                                    />
                                    <Button
                                        style="filled"
                                        label="Continuar"
                                        iconPosition="right"
                                        icon="arrow-right-button-white-100"
                                        type="submit"
                                    />
                                </div>
                            </form>
                        )}
                        {modalPage === 4 && (
                            <>
                                <div className="pt-10 px-10">
                                    <Label size="xl" color="text-black-100">
                                        Termos de uso e política de privacidade
                                    </Label>
                                </div>
                                <div className="pt-2.5 pb-10 px-10 border-b border-gray-600">
                                    <Label color="text-gray-400">
                                        É necessário concordar com os termos de uso e com a política
                                        de privacidade para prosseguir.
                                    </Label>
                                </div>
                                <div className="max-h-[384px] p-10 overflow-y-scroll font-normal">
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            Estes Termos de Uso (“Termos”) regulam a utilização do SIRENE Organizacionais - Sistema de Registro Nacional de Emissões, (“Sistema” ou “Plataforma”), que visa conferir a perenidade e acessibilidade aos resultados do Inventário Nacional de Emissões Antrópicas por Fontes e Remoções por Sumidouros de Gases de Efeito Estufa não Controlados pelo Protocolo de Montreal. Assim, o Governo brasileiro instituiu o SIRENE Organizacionais, por meio do Decreto n.º 9.172/2017, como meio oficial para disponibilização de resultados de emissões de gases de efeito estufa no país. Salienta-se que tal decreto deve ser interpretado em conjunto com estes Termos de Uso, bem como o Aviso de Privacidade do SIRENE Organizacionais.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label size="sm" color="text-black-100">
                                            1	Características Gerais do Projeto
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            1.1.	Termos Gerais
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            1.1.1 A disponibilização do SIRENE Organizacionais, que tem como objetivo operacionalizar a política pública de controle de resultados de emissões de gases de efeito estufa no país, é uma iniciativa da UNIÃO FEDERAL, por intermédio do Ministério da Ciência, Tecnologia e Inovação “MCTI”, pessoa jurídica de direito público interno com sede em Brasília/DF, inscrito no CNPJ sob o número 03.132.745/0001-00, com apoio da Rede Nacional de Ensino e Pesquisa ("RNP"), associação civil qualificada como Organização Social pelo Decreto nº 4.077 de 09 de janeiro de 2002, inscrita no CNPJ/MF sob o nº 03.508.097/0001-36, Inscrição Municipal nº 02.838.109, com sede na Rua Lauro Müller, nº 116, 11º andar, salas 1101 a 1104, Botafogo, Rio de Janeiro/RJ. Cabe à RNP, neste contexto, sustentar e executar o Sistema.
                                            <br />1.1.2. O projeto tem por missão não só conferir segurança e transparência ao processo de confecção de inventários de emissões de gases de efeito estufa, mas também ser suporte à tomada de decisão no âmbito de políticas, planos, programas e projetos na área de mudanças climáticas – no que tange a geração de conhecimento científico e adoção de medidas de mitigação que deverão ser adotadas.
                                            <br />1.1.3. São apresentados os resultados de emissões de todos os gases de efeito estufa não controlados pelo Protocolo de Montreal (CO2, CH4, N2O, CF4, C2F6, HFC-23, HFC125, HFC134a, HFC143a, HFC152a, SF6, CO, NOx e NMVOC) para organização dos setores Tratamento de Resíduos, Agropecuária, Uso da Terra, Mudança do Uso da Terra e Floresta, Energia e Processos Industriais. Diante disso, o Usuário do Sistema será a pessoa declarada como responsável da organização cadastrada, após o aceite do presente Termo de Uso.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            1.2.	Definições Importantes
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            1.2.1. Para todos os efeitos, especialmente o de interpretação destes Termos, as expressões abaixo indicadas deverão ser entendidas com base nas seguintes definições:
                                            <br />•	Conta / Perfil: o perfil pessoal do Usuário, por meio do qual este poderá imputar informações e fazer uso regular do Sistema;
                                            <br />•	Dado Pessoal: é uma informação relacionada a pessoa natural identificada ou identificável, a exemplo de: nome, pronome, RG, CPF, endereço, E-mail etc.;
                                            <br />•	SIRENE Organizacionais / Sistema /Plataforma: sistema que possibilita que o Usuário acesse ferramentas virtuais concebidas para facilitar a disponibilização de resultados do Inventário Brasileiro de Emissões Antrópicas por Fontes e Remoções por Sumidouros de Gases de Efeito Estufa não Controlados pelo Protocolo de Montreal e de outras iniciativas de contabilização de emissões, tais como as Estimativas Anuais de Emissões de Gases de Efeito Estufa no Brasil;
                                            <br />•	Usuário: pessoa natural que faz uso do Sistema, com o intuito de utilizar as suas funcionalidades, públicas ou de acesso restrito;
                                            <br />•	Serviços: a disponibilização da Sistema e a prestação do suporte técnico necessário à sua utilização;
                                            <br />•	Controlador de dados pessoais ou Controlador: como pessoa física ou jurídica a quem compete as decisões referentes ao tratamento de dados pessoais. Considera-se o MCTI o controlador de dados para os fins destes Termos;
                                            <br />•	Operador de dados pessoais ou Operador: como pessoa física ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador. Considera-se, por exemplo, a RNP como operadora de dados para os fins destes Termos;
                                            <br />•	Organização inventariante: organização legalmente constituída e reconhecida pela legislação brasileira, responsável pela realização e pela submissão ao Sirene do seu inventário organizacional;
                                            <br />•	Titular: pessoa natural a quem os dados pessoais se referem. Para os efeitos deste Termos e Condições de Uso, são titulares de dados pessoais os Usuários ou responsáveis ou convidados dos Usuários – organizações inventariantes.

                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            1.3.	Aceitação dos Termos e Natureza
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            1.3.1. Ao utilizar o Sistema, o Usuário confirma que leu e compreendeu os Termos de Uso e o Aviso de Privacidade aplicáveis, bem como concorda em se vincular a tais disposições.
                                            <br />1.3.1.1. Caso não concorde com as regras presentes nestes Termos ou com o Aviso de Privacidade, o Usuário não poderá acessar e utilizar a Plataforma.
                                            <br />1.3.2. O Usuário declara ter capacidade jurídica para a prática dos atos civis, especialmente para anuir com os presentes Termos, isto é, ser maior de 18 (dezoito) anos ou emancipado, além de não se enquadrar em nenhuma das causas incapacitantes previstas na lei civil, nem a curatela determinada pelo Poder Judiciário.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label size="sm" color="text-black-100">
                                            2.	 Acesso e Utilização
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            2.1. Ao acessar o Sistema com login e senha, o Usuário poderá usar as funcionalidades disponíveis.
                                            <br />2.1.1. O Usuário pode realizar o cadastro diretamente quando não está vinculado a uma instituição -  hipótese em que recebe um convite por e-mail – ou tenha sido pré-cadastrado através dos dados da Plataforma CDP.
                                            <br />2.1.1.1. O cadastro no SIRENE é realizado via integração ao Amazon Cognito ou da plataforma GOV.BR.
                                            <br />2.2. Na hipótese de autenticação via GOV.BR, caso o Usuário ainda não tenha o cadastro, o usuário poderá realizar o seu cadastro das seguintes formas:
                                            <br />2.2.1. Login por meio de autenticação por instituições financeiras; ou
                                            <br />2.2.2. Escolha da opção “Tentar de outra forma”, por meio da checagem de algumas informações pessoais, como, por exemplo, nome da mãe, mês e ano do nascimento, por meio do próprio GOV.BR.
                                            <br />2.3. Após finalizar o cadastro e autenticação no GOV.BR, surgirá uma página do SIRENE no qual o Usuário deverá confirmar algumas informações de contato (Telefone e E-mail), bem como, CPF e nome completo. Nesta fase, poderá manifestar a concordância com os Termos de Uso e Política de Privacidade do SIRENE, para prosseguir com a utilização dos serviços disponíveis por meio do Sistema, após a confirmação do Captcha (“Não sou um robô”).
                                            <br />2.3.1. Em relação ao perfil da organização inventariante, são solicitados os seguintes dados, que ficarão públicos no Sistema: CNPJ, Razão Social, Nome Fantasia, País, Atividade Primária, CEP e Endereço da organização (contendo número, complemento), Telefone, E-mail de contato e cargo do responsável pela organização inventariante.
                                            <br />2.4. Para verificar a existência de cadastro de uma empresa, pode-se realizar consulta pelo CNPJ e verificar se o cadastro já foi realizado ou não. Na hipótese de não haver cadastro, o Sistema indicará e o visitante poderá prosseguir com o cadastro da instituição no Sistema.
                                            <br />2.5. Cabe ao Usuário informar corretamente os dados exigidos para o cadastro, prestando apenas informações verdadeiras. O Usuário responderá por imprecisões ou falsidades dessas informações.
                                            <br />2.6. O Usuário é responsável pela atualização dos dados informados no cadastro sempre que houver qualquer modificação que a imponha, afirmando-se ciente de que eventuais divergências e/ou inconsistências poderão gerar prejuízos à plena execução dos Serviços ou à sua identificação.
                                            <br />2.7. Ao acessar o SIRENE os Usuários poderão inserir inventários de duas formas: a) baixar o formulário Excel, preencher e, posteriormente, fazer upload no Sistema, ou b) realizar o preenchimento dentro do Sistema.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label size="sm" color="text-black-100">
                                            3.	Deveres / Responsabilidades do Usuário, da União Federal e da RNP
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            3.1.	Deveres / Responsabilidades dos Usuários
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            3.1.1. O Usuário é responsável por todos os atos praticados por sua conta, incluindo o cadastro e as informações e dados pessoais sobre si e terceiros.
                                            <br /><br />Abaixo é possível visualizar uma tabela com a descrição de todos os tipos de Usuários/Perfis da Plataforma, bem como suas respectivas funcionalidades:
                                            <br />•	Área Pública: Perfil que qualquer pessoa pode ter acesso sem necessidade de cadastro. Este perfil tem acesso a um menu com informações referentes a “Emissões”, onde o Usuário pode visualizar e fazer o download de gráficos (por setor, escopo, empresa) criados a partir do inventário das organizações participantes, bem como realizar o envio de inventário.
                                            <br />•	Organização inventariante: Perfil de organização legalmente constituída e reconhecida pela legislação brasileira, responsável pela realização e pela submissão ao SIRENE do seu inventário organizacional. Ao realizar o cadastro pelo GOV.BR acessa uma área restrita para publicação das emissões. Realiza o registro voluntário das emissões no Sistema. Para acesso ao perfil da organização existem diferentes perfis de usuário vinculados à organização administrador – responsável ou relator de organização inventariante. Há a identificação do perfil e logo após, direcionamento para a área restrita. Enviado o convite, a pessoa que é responsável por aquela organização recebe uma notificação (no menu lateral “Notificações”) e pode aceitar ou não os perfis dentro da organização. Quando a pessoa é aceita, ela recebe uma notificação e já possui acesso ao painel geral com todas as informações cadastradas dentro da organização. O perfil que faz parte de uma organização pode convidar um usuário preenchendo o seu e-mail e a sua função na organização (relator ou responsável).
                                            *A organização pode ser cadastrada no SIRENE ou ser importada do Carbon Disclosure Project (CDP) – organização de âmbito global que realiza o registro de informações sobre emissões. As informações do CDP, de anos anteriores, alimentarão o banco de dados do Sistema. O SIRENE informará que a organização está cadastrada, mas não possui responsável. Assim, o Usuário pode se cadastrar como responsável.
                                            *É possível que perfil vinculado à organização convide um usuário por meio da seção “Convite de usuário”, preenchendo o e-mail do convidado e a sua função na organização (relator ou responsável).
                                            <br />•	Administrador: Perfil criado a partir de convite – link enviando por e-mail – onde são solicitados dados pessoais (Nome, CPF, E-mail e Celular). Os administradores podem gerenciar os acessos dos perfis da organização e os inventários publicados, bem como fazer revisões dos inventários.
                                            <br /><br />3.1.2. Os Usuários receberão o status do inventário anexado no SIRENE Organizacionais por e-mail – rascunho, aprovado e aguardando análise. No “aguardando”, o inventário é devolvido com observações para revisão e orientação do Administrador.
                                            <br />3.1.3. Os Usuários terão acesso ao SIRENE Organizacionais por meio de suas contas, cujo login e senha são individuais e intransferíveis. A responsabilidade da segurança e uso das credenciais de acesso (login e senha) são exclusivas do Usuário, não devendo este cedê-las ou disponibilizá-las a terceiros, estando a União Federal e a RNP completamente isentas de dever reparar eventuais danos em decorrência de tal situação.
                                            <br />3.1.4. O Usuário é responsável pela segurança dos dispositivos pelos quais acessa o Sistema, sejam eles de uso pessoal ou de uso compartilhado, devendo ter as cautelas necessárias face aos riscos e ameaças existentes. Assim, fica estabelecido que o acesso ao SIRENE deve ser feito sempre com cuidado e em dispositivos seguros e revestidos de instrumentos de segurança e proteção contra ações ilícitas de terceiros, pautado em uma orientação preventiva de danos.
                                            <br />3.1.5. Se o Usuário utilizar o Sistema para fins ilícitos ou não permitidos nestes Termos de Uso, será integralmente responsável pelo resultado danoso decorrente da conduta realizada.
                                            <br />3.1.6. É responsabilidade do Usuário adotar as medidas necessárias para acessar o Sistema. A impossibilidade de acessar a Plataforma por motivos de ordem técnica dos computadores ou terminais (smartphones, tablets etc.), eventuais falhas de conexão com a internet ou outros fatores que impossibilitem a transferência de dados externos são de responsabilidade exclusiva do Usuário. Não há garantias de que o SIRENE funcionará em qualquer equipamento ou dispositivo, cabendo ao Usuário utilizar equipamentos e dispositivos apropriados e necessários para acessá-lo.
                                            <br />3.1.7. O Usuário é responsável por fornecer informações verdadeiras ao SIRENE  incluindo, mas sem se limitar à autodeclaração de “Responsável” por uma organização, vinculando seu perfil ao dela, bem como os dados de inventário imputados no Sistema.

                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            3.2.	Limitação da Responsabilidade da União Federal
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            3.2.1. A União Federal (por intermédio do MCTI), no papel de controladora dos dados pessoais dos Usuários, cumprirá todas as normas jurídicas inerentes ao tratamento correto dos dados pessoais, na forma da lei (especialmente da Lei 13.709/18 – Lei Geral de Proteção de Dados Pessoais) e da forma descrita nos Termos de Uso e no Aviso de Privacidade.
                                            <br />3.2.2 Apesar de se utilizar de diversas medidas de segurança da informação, não são prestadas garantias contra acessos indevidos ou outras condutas de terceiros que subvertam os sistemas de segurança para acessar as informações do Usuário ou outros dados, incluindo dados pessoais de titulares que tenham sido incluídos pelos Usuários na Plataforma.
                                            <br />3.2.3. O Sistema poderá estar sujeito a interferências, mau funcionamento e/ou atrasos inerentes ao uso da internet e de comunicações eletrônicas, e o Usuário declara que tem conhecimento desses riscos, bem como que esses fatores poderão afetar de algum modo sua utilização, sem que lhe seja devida qualquer indenização por eventuais prejuízos, de qualquer natureza, que venha a sofrer, a exemplo de, mas não se limitando a: danos materiais, lucros cessantes, perda de uma chance, danos morais etc. Nesses casos, a União Federal, em colaboração com a RNP, providenciará, com maior brevidade possível, o retorno do funcionamento do Sistema.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            3.3.	Limitação da Responsabilidade da RNP
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            3.3.1. A RNP, no papel de operadora dos dados pessoais dos Usuários, responsável pela hospedagem do SIRENE Organizacionais e no apoio na execução do Sistema, cumprirá todas as normas jurídicas vigentes relativas ao tratamento correto dos dados pessoais, na forma da lei (especialmente da Lei 13.709/18 - Lei Geral de Proteção de Dados Pessoais) e da forma descrita nos Termos de Uso e no Aviso de Privacidade do SIRENE Organizacionais.
                                            <br />3.3.2. A RNP se isenta de qualquer responsabilidade por qualquer informação imputada ou disponibilizada pelos Usuários por meio do SIRENE assim como por eventuais danos, diretos ou indiretos, provocados por tais condutas.
                                            <br />3.3.3. A RNP não poderá ser responsabilizada 1. por problemas na autenticação via GOV.BR, bem como usos exclusivos da União Federal dos dados tratados; 2. pela perda da qualidade da conexão dos Usuários quando da utilização do Sistema ou pela impossibilidade de utilização do serviço em razão da incompatibilidade técnica dos dispositivos dos Usuários; 3. erros, falhas ou imprecisões no Sistema; 3. qualquer acesso ou uso não autorizado do Sistema; 4. qualquer interrupção ou cessação do Sistema; 5. vírus ou códigos maliciosos transmitidos por qualquer terceiro para o serviço ou por meio dele; 6. qualquer acesso indevido à conta do usuário por ações maliciosas de terceiros ou compartilhamento de senhas; e 7. ações indevidas decorrentes do mau uso do Sistema pelo usuário, incluindo, mas sem se limitar à vinculação errônea a determinada empresa.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            3.4.	Propriedade Intelectual
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            3.4.1. O nome empresarial, marca, domínio, bem como o conteúdo acessado por meio do SIRENE, assim como o código-fonte do serviço e todos os direitos são de propriedade da União e estão protegidos pela legislação aplicável, configurando o acesso ao serviço como mera licença de uso.
                                            <br />3.4.2. Não é permitido ao Usuário, sob pena de responsabilidade por qualquer prejuízo causado nesse sentido:
                                            <br />3.4.3. O uso destes elementos (inclusive medidas de engenharia reversa) para propósitos diversos à sua utilização sem que haja expressa e prévia autorização por parte da União;
                                            <br />3.4.4. Copiar ou exibir o conteúdo e layout do SIRENE, ou mesmo reproduzir qualquer parte ou conteúdo das suas páginas, para quaisquer fins, sem autorização expressa da União.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label color="text-gray-400">
                                            3.5.	Privacidade e Proteção de Dados
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            3.5.1. A RNP, por meio do SIRENE Organizacionais, se compromete a realizar o tratamento de dados pessoais do Usuário em respeito às Leis 13.709/2018 (“Lei Geral de Proteção de Dados” ou “LGPD”) e 12.965/2014 (“Marco Civil da Internet”), e às demais leis brasileiras relacionadas à proteção dos dados pessoais, tudo nos termos do Aviso de Privacidade do SIRENE Organizacionais.
                                            <br />3.5.2. Neste ato, o Usuário declara ter ciência do conteúdo de tal Aviso de Privacidade, sem que tenha qualquer ressalva a opor quanto ao seu conteúdo.
                                            <br />3.5.3. Neste aro, o Usuário declara conhecer a LGPD e as legislações de proteção de dados eventualmente aplicáveis às suas atividades e que deverá durante o uso do Sistema respeitar tais regulamentações, inclusive atendendo ao princípio da minimização, não imputando nem solicitando dados pessoais não necessários.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label size="sm" color="text-black-100">
                                            4.	Segurança
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            4.1. De acordo com as leis e regulamentações aplicáveis, a União Federal, na condição de Controladora de dados pessoais, bem como a RNP, como Operadora, com responsabilidade mantém medidas técnicas e organizacionais cabíveis para proteger as informações contra destruições acidentais ou ilegais, ou perda, alteração, divulgação ou acesso não autorizado.
                                            <br />4.1.1. Trabalha-se continuamente para manter seus dados seguros no SIRENE Organizacionais, contra transmissão, armazenamento ou qualquer outra operação de tratamento indevida dos dados pessoais que tramitam no Sistema.
                                            <br />4.1.2. Uma combinação de controles técnicos é utilizada para manter a segurança dos dados, e isso inclui o uso dos protocolos TLS (Transport Layer Security) para proteger a comunicação dos serviços. O armazenamento das informações é realizado também de forma protegida, além de possuir uma equipe de segurança da informação realizando análises periódicas para identificar vulnerabilidades e necessidades de melhorias.
                                            <br />4.1.4. Se entender que os seus dados não estão sendo tratados de forma correta ou estão vulneráveis a qualquer situação de acesso indevido, perda, destruição ou alteração, entre em contato com o Encarregado pelo Tratamento de Dados do MCTI, por meio do contato indicado no item 11 destes Termos.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label size="sm" color="text-black-100">
                                            5.	Condições Gerais
                                        </Label>
                                    </div>
                                    <div className="pb-2.5">
                                        <span className="text-sm font-normal !text-black-100">
                                            5.1. Estes Termos devem ser interpretados em conjunto com o Aviso de Privacidade do SIRENE Organizacionais.
                                            <br />5.2. Caso o Sistema deixe de exigir o cumprimento pontual e integral de qualquer das obrigações decorrentes destes Termos, ou deixe de exercer qualquer direito ou faculdade que lhe seja atribuída, tal fato será interpretado como mera tolerância e não importará na renúncia dos direitos e faculdades não exercidos, nem em precedente, novação ou revogação de quaisquer cláusulas ou condições dos presentes Termos que, para todos os efeitos, continuarão em pleno vigor.
                                            <br />5.3. Caso qualquer cláusula ou condição destes Termos seja reputada inválida ou ineficaz, permanecerão plenamente válidas e vinculantes todas as cláusulas e condições restantes, as quais deverão gerar efeitos em sua máxima extensão, como forma de alcançar a vontade das partes.
                                            <br />5.4. Estes Termos têm validade indeterminada e poderão ser alterados a qualquer momento, sem a necessidade de autorização prévia dos Usuários ou de qualquer pessoa; cabe à União, em colaboração com a RNP, apenas notificar os Usuários das modificações realizadas, por exemplo, por meio da divulgação nos seus respectivos meios de comunicação, como e-mails institucionais e aplicações próprias. Tais alterações entrarão em vigor imediatamente após a publicação da versão atualizada dos Termos de Uso da Plataforma em seus canais de comunicação. Em todo caso, caberá sempre a cada Usuário verificar periodicamente a ocorrência de atualizações no conteúdo deste documento, declarando desde já que tem conhecimento e aceita a possibilidade de os Termos serem modificados e compreende a relevância da sua verificação periódica.
                                            <br />5.5. Fica, desde logo, assegurado à União e à RNP o direito de regresso proveniente do pagamento de indenizações judiciais e extrajudiciais cuja responsabilização decorra de atos imputáveis ao Usuário, sendo-lhes igualmente outorgado o direito de denunciar a ele a lide.
                                            <br />5.6. Fica a RNP autorizada a ceder a sua posição nestes Termos, desde que sem prejuízo à qualidade e ao escopo dos Serviços, independente de autorização do Usuário.
                                            <br />5.7. Estes Termos serão regidos e interpretados exclusivamente de acordo com as leis do Brasil. Qualquer reclamação, conflito ou controvérsia que surgir deste Termo ou a ele relacionado, inclusive que diga respeito a sua validade, interpretação ou exequibilidade, será solucionada exclusivamente na jurisdição brasileira, elegendo-se especificamente o foro da Justiça Federal do Brasil com renúncia a qualquer outro, por mais privilegiado que seja.
                                        </span>
                                    </div>
                                    <div className="pb-2.5">
                                        <Label size="sm" color="text-black-100">
                                        6.	Canal de Contato
                                        </Label>
                                    </div>
                                    <div className="pb-2.5 pb-8">
                                        <span className="text-sm font-normal !text-black-100">
                                            6.1. Para esclarecer qualquer dúvida sobre o presente Termo de Uso, obter mais informação acerca das condições de uso impostas aos Usuários ou assistência na execução do Sistema, entre em contato com a RNP pelo e-mail atendimento@rnp.br.
                                            <br />6.2. Para esclarecer dúvidas sobre o presente Termo de Uso, obter mais informações acerca dos tratamentos dos dados pessoais dos titulares realizados ou exercer algum dos direitos dos titulares, o titular poderá entrar em contato com o encarregado de proteção de dados pessoais do MCTI - Bruno Parente Norberto - por meio do e-mail encarregado.lgpd@mcti.gov.br.
                                        </span>
                                    </div>
                                    <div className="flex flex-row gap-2.5">
                                        <Checkbox
                                            handleChange={(event) => {
                                                setPolicyAndPrivacy(event.target.checked);
                                                setShowErrorMessage(!event.target.checked);
                                            }}
                                            isChecked={policyAndPrivacy}
                                            hasError={showErrorMessage}
                                        />
                                        <Label>
                                            Eu li e concordo com os termos de uso e política de
                                            privacidade
                                        </Label>
                                    </div>
                                    {showErrorMessage && (
                                        <div className="mt-2.5 w-56">
                                            <InputMessage state="danger" />
                                        </div>
                                    )}
                                    {/* <div className="pt-10 flex flex-row justify-center">
                    <Recaptcha onChange={(ev) => handleCaptcha(ev)} />
                  </div> */}
                                </div>

                                <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                                <div className="absolute bottom-10 left-10 right-10 flex gap-2.5">
                                    <Button
                                        style="outlined"
                                        label="Voltar"
                                        icon="arrow-left-blue-200"
                                        onClick={() => {
                                            setPolicyAndPrivacy(false);
                                            setShowErrorMessage(false);
                                            setModalPage(showLinkToOrg ? 6 : 3);
                                        }}
                                    />
                                    <Button
                                        style="filled"
                                        label="Finalizar"
                                        onClick={() => handleModalFlowEnd()}
                                    />
                                </div>
                            </>
                        )}

                        {modalPage === 5 && (
                            <>
                                <div className="pt-10 px-10">
                                    <Label size="xl" color="text-black-100">
                                        Consultar Organização Inventariante
                                    </Label>
                                </div>
                                <div className="pt-[0.625rem] pb-10 px-10">
                                    <Label color="text-gray-400">
                                        Você pode enviar seu pedido através do botão abaixo.
                                    </Label>
                                </div>

                                <div className="absolute left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                                <div className="pt-10 px-10">
                                    <Label>Qual sua função dentro dessa organização?</Label>
                                </div>

                                <div className="pt-2.5 px-10">
                                    <Dropdown
                                        options={options}
                                        icon="search-gray-400"
                                        label="Selecione sua função"
                                        selectedOption={selectedOption}
                                        handleOptionClick={handleOptionClick}
                                    ></Dropdown>
                                </div>

                                <div className="pt-10 px-10">
                                    <Label>
                                        Após finalizar, os responsáveis por essa organização poderão
                                        revisar seu pedido.
                                    </Label>
                                </div>

                                <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                                <div className="absolute bottom-10 left-10 right-10 flex gap-2.5">
                                    <Button
                                        style="outlined"
                                        label="Voltar"
                                        icon="arrow-left-blue-200"
                                        onClick={() => {
                                            setModalPage(0);
                                        }}
                                    />
                                    <Button
                                        style="filled"
                                        label="Enviar"
                                        onClick={() => participationRequest()}
                                    />
                                </div>
                            </>
                        )}

                        {modalPage === 6 && (
                            <form
                                onSubmit={handleSubmit(submitRoleAndNickname)}
                                className="max-h-[calc(100%-130px)] overflow-y-auto"
                            >
                                <div className="pt-10 px-10">
                                    <Label size="xl" color="text-black-100">
                                        Organização Inventariante
                                    </Label>
                                </div>
                                <div className="pt-2.5 pb-10 px-10 border-b border-gray-600">
                                    <Label color="text-gray-400">
                                        Você pode continuar através do botão abaixo.
                                    </Label>
                                </div>
                                <div className="pt-10 px-10">
                                    <Label>Seu cargo dentro dessa organização (opcional)</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        placeholder="Ex: Sócio; Desenvolvedor;"
                                        registerInput={register('association')}
                                    />
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Label>Apelido dessa organização</Label>
                                </div>
                                <div className="pt-2.5 px-10">
                                    <Input
                                        registerInput={register('nickName', {
                                            required: true,
                                        })}
                                        state={errors.nickName && 'danger'}
                                    />
                                </div>
                                <div className="pt-10 px-10">
                                    <Label color="text-gray-400">
                                        O apelido irá facilitar a visualização e acesso rápido à
                                        essa organização em sua Área Restrita. Esse apelido poderá
                                        ser editado posteriormente.{' '}
                                    </Label>
                                </div>

                                <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                                <div className="absolute bottom-10 left-10 right-10 flex gap-2.5">
                                    <Button
                                        style="outlined"
                                        label="Voltar"
                                        icon="arrow-left-blue-200"
                                        onClick={() => {
                                            setModalPage(0);
                                        }}
                                    />
                                    <Button
                                        style="filled"
                                        label="Continuar"
                                        iconPosition="right"
                                        icon="arrow-right-button-white-100"
                                        type="submit"
                                    />
                                </div>
                            </form>
                        )}
                    </div>
                </section>
            </ModalAlert>
            {showParticipationToast && (
                <div className="absolute w-full pt-8 px-10 top-0 left-0">
                    <Toast
                        onClose={() => {
                            setShowParticipationToast(false);
                            setToastOrg('');
                        }}
                        state={'primary'}
                        title="Pedido de participação."
                        label={`Seu pedido para participar como relator da organização ${toastOrg} foi enviado e assim que houver uma mudança de status, você será notificado.`}
                    />
                </div>
            )}
        </>
    );
};

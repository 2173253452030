import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { useSidebar } from '../../../contexts/SidebarContext';
import { ROUTES } from '../../../routes/Index.routes';
import Button from '../../Button/Button';
import SidebarButton from '../SidebarButton/SidebarButton';

export default function PublicSideBar() {
  const { session } = useContext(AuthContext);
  const { setSidebarOpen } = useSidebar();
  const navigate = useNavigate();

  return (
    <>
      <div className="space-y-3 p-10">
        <div className="flex-1">
          <ul className="pt-2  space-y-1 text-sm">
            <li className="rounded-sm">
              <SidebarButton
                icon={ROUTES.HOME.defaultIcon}
                label={ROUTES.HOME.title}
                to={ROUTES.HOME.path}
                isSelected={false}
                onClick={() => setSidebarOpen(false)}
              />
            </li>
            <li className="rounded-sm">
              <SidebarButton
                icon={ROUTES.ABOUT.defaultIcon}
                label={ROUTES.ABOUT.title}
                to={ROUTES.ABOUT.path}
                isSelected={false}
                onClick={() => setSidebarOpen(false)}
              />
            </li>
            <li className="rounded-sm">
              <SidebarButton
                icon={ROUTES.INVENTORY_INFO.defaultIcon}
                label={ROUTES.INVENTORY_INFO.title}
                to={ROUTES.INVENTORY_INFO.path}
                isSelected={false}
                onClick={() => setSidebarOpen(false)}
              />
            </li>
            <li className="rounded-sm">
              <SidebarButton
                icon={ROUTES.EMISSIONS.defaultIcon}
                label={ROUTES.EMISSIONS.title}
                to={ROUTES.EMISSIONS.path}
                isSelected={false}
                onClick={() => setSidebarOpen(false)}
              />
            </li>
            <li className="rounded-sm">
              <SidebarButton
                icon={ROUTES.CONTACT.defaultIcon}
                label={ROUTES.CONTACT.title}
                to={ROUTES.CONTACT.path}
                isSelected={false}
                onClick={() => setSidebarOpen(false)}
              />
            </li>
          </ul>
        </div>
        <div className="absolute bottom-0 left-0 right-0 p-10">
          {session === null && (
            <Button
              style="filled"
              icon="profile-white-100"
              label="Acessar área restrita"
              onClick={() => {
                navigate(ROUTES.LOGIN.path);
                setSidebarOpen(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

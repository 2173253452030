import moment from 'moment';
import { useContext, useState } from 'react';
import Button from '../../components/Button/Button';
import { AdministratorContext } from '../../contexts/AdministratorContext';
import { userService } from '../../services/userService';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';
import { LabelFunction } from '../LabelFunction/LabelFunction';
import ListOption from '../ListOption/ListOption';
import ModalAlert from '../Modal/ModalAlert';
import { CheckBoxBodyTable } from '../OrganizationPageGroupComponents/CheckBoxBodyTable';
import { CheckBoxHeaderTable } from '../OrganizationPageGroupComponents/CheckBoxHeaderTable';
import { Pagination } from '../Pagination/Pagination';
import { BaseTable } from '../Table/BaseTable/BaseTable';
import { RowBody } from '../Table/RowBody/RowBody';
import { RowCell } from '../Table/RowCell/RowCell';
import { AuthContext } from '../../contexts/AuthContext';
import FloatingButton from '../FloatingButton/FloatingButton';
import { FormInviteAdmin } from '../FormInviteAdmin/FormInviteAdmin';
import { InviteSuccess } from '../OrganizationPageGroupComponents/InviteSuccess';
import { useLocation } from 'react-router-dom';

interface IListAdministratorsProps {
    admins?: IPagination<IUser> | null;
    adminUsers: (page: number) => void;
}

export function ListAdministrators({
    admins,
    adminUsers,
}: IListAdministratorsProps) {

    let location = useLocation();
    console.log(location.pathname)
    const [selectedItem, setSelectedItem] = useState<IUser | null>(null);
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);

    const { listAdminUsers } = useContext(AdministratorContext);
    const { session } = useContext(AuthContext);

    const handleClick = (item: IUser) => {
        setSelectedItem(item === selectedItem ? null : item);
    };

    const deleteAdmin = (id: string) => {
        userService.deleteUserById(id).then(() => {
            setShowConfirmationModal(false);
            listAdminUsers(0);
        });
    };

    const deleteInviteAdmin = (id: string) => {
        userService.deleteInviteAdmin(id).then(() => {
            setShowConfirmationModal(false);
            listAdminUsers(0);
        });
    };
    const [showInviteSuccess, setShowInviteSuccess] = useState<boolean>(false);
    const [showForminviteAdmin, setShowForminviteAdmin] =
        useState<boolean>(false);
    const handleClickAdminInvite = () => setShowForminviteAdmin(true);

    const options = [
        {
            label: 'Adicionar administrador',
            onClick:
                () => {
                    handleClickAdminInvite()
                },
            icon: 'plus-light-blue-200'
        },
    ];

    const handleReturnInvite = (response: Promise<any>) => {
        setShowForminviteAdmin(false);
        setShowInviteSuccess(true);
        listAdminUsers(0);
    };


    return (
        <>
            <div className="border rounded-md m-10 border-gray-100 overflow-auto">
                <BaseTable
                    headerTitlesComponents={[<CheckBoxHeaderTable />, 'Adicionado']}
                    childrensLength={admins?.content.length ?? 0}
                >
                    {admins?.content.map((admin) => {
                        return (
                            <RowBody key={admin.userId}>
                                <RowCell>
                                    <CheckBoxBodyTable
                                        name={admin.name}
                                        email={admin.status === 'WAITING' ? '' : admin.email}
                                        pendingInvite={admin.status === 'WAITING'}
                                    />
                                </RowCell>
                                <RowCell>
                                    <div className="flex items-end justify-between">
                                        {admin.status === 'ACCEPTED' && (
                                            <Label className={'!whitespace-nowrap !font-normal'}>
                                                {admin.createdDate &&
                                                    moment(admin.createdDate).format('DD/MM/YYYY')}
                                            </Label>
                                        )}
                                        {admin.status === 'WAITING' && <LabelFunction type={''} />}

                                        {session?.id !== admin.userId && (
                                            <div className="flex items-center space-x-4">
                                                <button
                                                    onClick={() => handleClick(admin)}
                                                    className="h-full "
                                                >
                                                    <Icon name="vertical-dot-gray-400" />
                                                </button>
                                                <ListOption
                                                    visible={admin.userId === selectedItem?.userId}
                                                    onClose={() => setSelectedItem(null)}
                                                >
                                                    <button
                                                        className="p-5 font-bold text-left justify-start"
                                                        onClick={() => {
                                                            setShowConfirmationModal(true);
                                                        }}
                                                    >
                                                        {admin.status === 'ACCEPTED'
                                                            ? 'Remover'
                                                            : 'Cancelar convite'}
                                                    </button>
                                                </ListOption>
                                            </div>
                                        )}
                                    </div>
                                </RowCell>
                            </RowBody>
                        );
                    })}
                </BaseTable>
                {admins && admins?.totalPages > 0 && (
                    <div className="m-4">
                        <Pagination
                            pageNumber={1}
                            setPageNumber={adminUsers}
                            totalPages={admins.totalPages}
                        />
                    </div>
                )}
                <ModalAlert visible={showConfirmationModal}>
                    <section className="w-[610px] p-10 flex flex-col items-center">
                        <button
                            className="absolute top-[19px] right-[19px] border-none bg-none"
                            onClick={() => {
                                setShowConfirmationModal(false);
                            }}
                        >
                            <Icon name="close-blue-200" size="sm" />
                        </button>
                        <Label size="md" className="font-bold">
                            {selectedItem?.status === 'ACCEPTED'
                                ? `Tem certeza que deseja remover o usuário ${selectedItem?.name}?`
                                : `Tem certeza que deseja cancelar o convite de ${selectedItem?.name}?`}
                        </Label>

                        <div className="w-65 pt-8">
                            <Button
                                style="filled"
                                label="Confirmar"
                                onClick={() => {
                                    if (selectedItem?.status === 'ACCEPTED') {
                                        deleteAdmin(selectedItem?.userId ?? '');
                                    } else deleteInviteAdmin(selectedItem?.userId ?? '');
                                }}
                            />
                        </div>
                    </section>
                </ModalAlert>
                <div className={`${location.pathname !== '/administradores' ? 'hidden' : 'block'} md:hidden`}>
                    <FloatingButton style="filled" options={options} />
                </div>
                <FormInviteAdmin
                        setOpen={setShowForminviteAdmin}
                        open={showForminviteAdmin}
                        onInviteChange={handleReturnInvite}
                    />

                    <InviteSuccess
                        open={showInviteSuccess}
                        setOpen={setShowInviteSuccess}
                    />
            </div>
        </>
    );
}

import { useEffect, useRef, useState } from 'react';

interface ProfileProps {
  name: string;
  email: string;
  onClick: () => void;
}

const Profile: React.FC<ProfileProps> = ({ name, email, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [gradientStyle, setGradientStyle] = useState('');

  const divRef = useRef<HTMLDivElement>(null);

  const handleHover = (hovered: boolean) => {
    setIsHovered(hovered);
    if (!hovered) {
      setGradientStyle('');
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const div = divRef.current;

      if (div) {
        const { width, height, left, top } = div.getBoundingClientRect();
        const x = (e.clientX - left) / width;
        const y = (e.clientY - top) / height;
        const gradient = `radial-gradient(circle at ${x * 100}% ${
          y * 100
        }%, #C6CACE, transparent)`;
        setGradientStyle(gradient);
      }
    };

    const divElement = divRef.current;
    if (divElement) {
      divElement.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  const containerStyle = isHovered
    ? 'bg-white-100 cursor-pointer'
    : 'transition-opacity duration-500';

  return (
    <div
      onClick={onClick}
      ref={divRef}
      className={`w-64 h-22 border-2 rounded-md border-gray-200 pr-10 transition duration-10000 hover:duration-10000 ${containerStyle}`}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      style={{ backgroundImage: gradientStyle }}
    >
      <div className="flex flex-row items-center">
        <div className="pl-5 pt-5 pb-5">
          <div
            className={`w-10 h-10 bg-gray-500 rounded-full flex items-center justify-center ${
              isHovered ? 'bg-red-100' : ''
            }`}
          >
            {isHovered ? (
              <span className="text-white-100 font-bold text-base pb-1">x</span>
            ) : (
              <span className="text-white-100 font-bold text-base pb-1">
                {name.charAt(0).toUpperCase()}
              </span>
            )}
          </div>
        </div>

        {isHovered ? (
          <div className="pl-2.5">
            <p className="text-black-300 text-sm font-bold">Sair</p>
          </div>
        ) : (
          <div className="pl-2.5 w-40">
            <p className="text-black-300 text-sm font-bold truncate">{name}</p>
            <p className="text-gray-400 text-sm font-normal truncate">{email}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;

import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { InputHTMLAttributes, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import Icon from '../Icon/Icon';
import { InputMessage } from '../InputMessage/InputMessage';
import './sirene-theme-datepicker.css';

interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
    fieldProps?: any;
    state?: IStateClasses;
    errorLabel?: string;
    onChangeDate: (ev: Date) => void;
    reviewLabel?: string;
}

export const DateInput: React.FC<DateInputProps> = ({
    fieldProps,
    errorLabel,
    state,
    placeholder = 'dd/mm/aaaa',
    onChangeDate,
    value,
    reviewLabel,
}) => {
    const [showPicker, setShowPicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const stateStyle = {
        warning: 'border-yellow-200',
        danger: 'border-red-200',
        primary: '',
        success: '',
    };

    function setStateClasses(): string {
        if (showPicker) return 'border-blue-300';

        if (state) return stateStyle[state];
        return 'border-gray-200';
    }
    const inputContainerClasses = `h-[60px] relative flex items-center border-2 rounded-base  cursor-pointer group hover:bg-gray-100 ${setStateClasses()}    ${reviewLabel ? 'border-yellow-200' : ''} `;

    const inputClasses = `
   focus:outline-none
   focus:border-0
   focus:shadow-tansparent
   focus:shadow-none
   focus-visible:outline-none
   bg-white-100
   bg-opacity-0
   w-full
   h-full
   p-0
   pl-5
   z-10
   border-0
   cursor-pointer
   placeholder:!text-gray-300
   placeholder:!font-normal
   placeholder:!italic
   datepicker-input`;

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        const elm = ev.target as HTMLInputElement;
        elm.addEventListener('keypress', (e) => {
            if (e.keyCode < 47 || e.keyCode > 57) {
                e.preventDefault();
            }
            const len = elm.value.length;
            if (len === 2) {
                elm.value += '/';
            }
            if (len === 5) {
                elm.value += '/';
            }
        });
    };
    return (
        <>
            <div className={inputContainerClasses}>
                <Flatpickr
                    {...fieldProps}
                    className={inputClasses}
                    options={{
                        dateFormat: 'd/m/Y',
                        locale: Portuguese,
                        allowInput: true,
                        onChange: (ev) => {
                            onChangeDate(ev[0]);
                            setSelectedDate(ev[0]);
                        },
                        onClose: () => setShowPicker(false),
                        onOpen: () => setShowPicker(true),
                    }}
                    placeholder={placeholder}
                    onKeyDown={(ev) => handleKeyDown(ev)}
                    maxLength={10}
                />
                <div className="absolute right-5">
                    <Icon name="calendar-blue-200" />
                </div>
            </div>
            {!showPicker && reviewLabel && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state="warning" label={reviewLabel} />  {/* Aviso de revisão */}
                    </div>
                </div>
            )}
            {state === 'danger' && errorLabel && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state={state} label={errorLabel} />  {/* Aviso de erro */}
                    </div>
                </div>
            )}
        </>
    );
};

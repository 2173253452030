export const unmask = (value: string): string => value.replace(/[^\d]/g, '');

export const mask = (value: string, mask: string): string => {
  const valueChars = unmask(value);
  let maskedValue = '';

  let valueIndex = 0;
  for (let i = 0; i < mask.length && valueIndex < valueChars.length; i++) {
    const maskChar = mask[i];
    if (maskChar === '#') {
      maskedValue += valueChars[valueIndex];
      valueIndex++;
    } else {
      maskedValue += maskChar;
    }
  }

  return maskedValue;
};

import React, { createContext, useState, useEffect } from 'react';
import { formsService } from '../services/formsService';

interface FormDrawContextProps {
    setFormsJSON: React.Dispatch<React.SetStateAction<IFormJSON | null>>;
    formsJSON: IFormJSON | null | undefined;
    getForm: () => void;
}

interface FormDrawProviderProps {
    children: React.ReactNode;
}

const initialForm: IFormJSON | null = null;

export const FormDrawContext = createContext<FormDrawContextProps>(
    {} as FormDrawContextProps
);

export const FormDrawProvider: React.FC<FormDrawProviderProps> = ({
    children,
}) => {
    const [formsJSON, setFormsJSON] = useState<IFormJSON | null>(initialForm);
  
    const getForm = async () => {
        try {
            const response = await formsService.getDrawFormJSON();
            setFormsJSON(response); 
        } catch (error) {
            console.error('Could not load form:', error);
        }
    };

    return (
        <FormDrawContext.Provider value={{ setFormsJSON, formsJSON, getForm }}>
            {children}
        </FormDrawContext.Provider>
    );
};

import { useEffect, useState } from 'react';

interface ILabelFunctionProps {
  type: string;
}

export function LabelFunction({ type }: ILabelFunctionProps) {
  const [label, setLabel] = useState<string>('');
  const [bgColor, setbgColor] = useState<string>('');
  const [textColor, setTextColor] = useState<string>('text-white-100');

  function handleLabel() {
    switch (type) {
      case 'REPRESENTATIVE':
        setLabel('Responsável');
        setbgColor('bg-blue-600');
        break;

      case 'REPORTER':
        setLabel('Relator');
        setbgColor('bg-blue-200');
        break;

      case 'CREATED_SIRE':
        setLabel('Cadastrada');
        setbgColor('bg-blue-600');
        break;

      case 'IMPORTED':
        setLabel('Sem vínculo');
        setbgColor('bg-blue-warm-200');
        break;

      case 'LINKED':
        setLabel('Vinculada');
        setbgColor('bg-blue-200');
        break;

      case 'PENDING':
        setLabel('Aguardando');
        setbgColor('bg-blue-200');
        break;

      case 'PENDING_ADM':
        setLabel('Pendente');
        setTextColor('text-gray-500');
        setbgColor('bg-yellow-200');
        break;

      case 'FILLING':
        setLabel('Rascunho');
        setbgColor('bg-blue-600');
        break;

      case 'REVISION':
        setLabel('Revisar');
        setbgColor('bg-yellow-200');
        break;

      case 'REVISION_ADM':
        setLabel('Revisão');
        setbgColor('bg-blue-200');
        break;

      case 'APPROVED':
        setLabel('Aprovado');
        setbgColor('bg-green-200');
        break;

      default:
        setLabel('Aguardando');
        setbgColor('bg-blue-200');
    }
  }

  useEffect(() => handleLabel(), [type]);

  return (
    <span
      className={`px-2 py-1 ${bgColor} ${textColor} font-bold rounded-base`}
    >
      {label}
    </span>
  );
}

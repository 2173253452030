import React, { useRef, useEffect } from 'react';

interface ListOptionProps {
  children: React.ReactNode;
  visible?: boolean;
  onClose?: () => void;
}

const ListOption: React.FC<ListOptionProps> = ({ children, visible, onClose }) => {
  const listRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node)) {
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [visible]);

  const boxShadowStyle = visible ? '0 2px 4px rgba(0, 0, 0, 0.2)' : '';

  return (
    <>
      {visible && (
        <div ref={listRef} className="absolute bg-white-100 z-50 rounded-md" style={{ boxShadow: boxShadowStyle }}>
          {children}
        </div>
      )}
    </>
  );
};

export default ListOption;

import Icon from '../Icon/Icon';

interface IFilterSidebar {
  icon: string;
  label: string;
  headerButtons?: JSX.Element;
}

export function HeaderComponent({
  icon,
  label,
  headerButtons,
}: IFilterSidebar) {
  return (
    <div className="flex flex-row p-10 border-solid border-b border-gray-100 h-32 w-full items-center justify-between">
      <div className="flex flex-row gap-4">
        <Icon name={icon}></Icon>
        <p className="text-3xl font-bold text-gray-500">{label}</p>
      </div>
      <div className="flex flex-row gap-[10px] hidden md:block">{headerButtons}</div>
    </div>
  );
}

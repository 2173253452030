import React, { useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';
import Checkbox from '../Checkbox/Checkbox';
import { InputMessage } from '../InputMessage/InputMessage';

const stateStyle = {
  warning: 'border-yellow-200',
  danger: 'border-red-200',
  primary: '',
  success: '',
};

interface DropdownMultipleSelectProps {
  options: IOption[];
  icon?: string;
  handleOptionClick: (options: IOption[]) => void;
  registerInput?: any;
  isValid?: boolean;
  errorLabel?: string;
  state?: IStateClasses;
  getValues?: any;
  name?: string;
  reviewLabel?: string;
  handleKeyPress?: (event: React.KeyboardEvent) => void;
  disabled?: boolean;
  labelCharLimit?: number;
  placeholder?: string;
  wrapText?: boolean;
}

const DropdownMultipleSelect: React.FC<DropdownMultipleSelectProps> = ({
  options,
  icon,
  handleOptionClick,
  registerInput,
  isValid,
  errorLabel,
  state,
  getValues,
  name,
  reviewLabel,
  handleKeyPress,
  disabled,
  labelCharLimit,
  placeholder,
  wrapText = false,
}) => {
  const inputClasses = `
  w-full
  h-[60px]
  p-5
  text-black-300
  border-2
  border-solid
  rounded-base
  text-sm
  !text-black-300
  placeholder:text-gray-300
  focus-visible:outline-none
  disabled:bg-gray-200
  placeholder:italic
  hover:bg-gray-100
  group
  flex
  items-center
  justify-start
  gap-3
  cursor-pointer
  border-gray-200
  relative,
  ${state ? stateStyle[state] : 'border-gray-200 focus:border-blue-200'}
  ${reviewLabel ? 'border-yellow-200' : ''} 
  ${disabled ? 'bg-gray-200' : ''}
  `;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [typedValue, setTypedValue] = useState('');
  const [selecteds, setSelecteds] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleChangesDropDown = (option: IOption) => {
    setSelectedOptions((prevSelectedOptions) => {
      let updatedSelectedOptions = [...prevSelectedOptions];

      const isTodosOption = option.value === 'Todos';

      if (isTodosOption) {
        if (
          updatedSelectedOptions.some(
            (selectedOption) => selectedOption.value === 'Todos',
          )
        ) {
          updatedSelectedOptions = [];
        } else {
          updatedSelectedOptions = [
            option,
            ...options.filter((o) => o.value !== 'Todos'),
          ];
        }
      } else {
        const index = updatedSelectedOptions.findIndex(
          (selectedOption) => selectedOption.key === option.key,
        );

        if (index !== -1) {
          updatedSelectedOptions.splice(index, 1);
        } else {
          updatedSelectedOptions = [...updatedSelectedOptions, option];
        }

        if (
          updatedSelectedOptions.some(
            (selectedOption) => selectedOption.value === 'Todos',
          )
        ) {
          updatedSelectedOptions = updatedSelectedOptions.filter(
            (selectedOption) => selectedOption.value !== 'Todos',
          );
        }
      }

      handleOptionClick(updatedSelectedOptions);
      return updatedSelectedOptions;
    });
  };

  const handleChangesDropDownBKP = (option: IOption) => {
    const isTodosOption = option.value === 'Todos';

    if (isTodosOption) {
      setSelectedOptions([option]);
    } else {
      const updatedSelectedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption.value !== 'Todos',
      );

      setSelectedOptions([...updatedSelectedOptions, option]);
    }

    handleOptionClick(selectedOptions);
  };

  useEffect(() => {
    setSelecteds(getOptionsLabel(selectedOptions));
  }, [setSelecteds, selectedOptions]);

  useEffect(() => {
    if (getValues(name)) {
      const selectedKeysString = getValues(name);
      if (typeof selectedKeysString === 'string') {
        const selectedKeysArray = selectedKeysString.split(';');
        let updatedSelectedOptions = options.filter((selectedOption) =>
          selectedKeysArray.includes(selectedOption.key as string),
        );
        setSelectedOptions(updatedSelectedOptions);
      }
    }
  }, [getValues, name, options]);

  useEffect(() => {
    if (Array.isArray(getValues(name))) {
      let valuesArray = getValues(name) as IFormQuestionOptions[];
      setSelectedOptions(valuesArray);
    }
  }, [getValues, name]);

  useEffect(() => {
    if (isOpen) return;

    setTypedValue('');
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const wipeSelecteds = () => {
    setSelecteds('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(true);
    setTypedValue(e.target.value);
  };

  const filteredOptions = options.filter((option) => {
    return option.value.toLowerCase().includes(typedValue.toLowerCase());
  });

  function getOptionsLabel(
    selectedOptions: IOption[],
    excludeValue?: string,
  ): string {
    let finalLabel = '';
    let optionCounter = 0;

    if (Array.isArray(selectedOptions)) {
      selectedOptions.forEach((option, index) => {
        if (option.value !== excludeValue) {
          if (
            option.value.length + finalLabel.length <
            (labelCharLimit ? labelCharLimit : 60)
          ) {
            finalLabel += option.value + ',  ';
          } else {
            optionCounter += 1;
          }
        }
      });

      if (optionCounter > 0) {
        finalLabel.slice(0, -1);
        finalLabel += ` + (${optionCounter})`;
      }
    }
    return finalLabel;
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []); 

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className={`w-full h-[60px] relative left-0 p-5 flex items-center justify-between cursor-text text-gray-400 border-2 border-solid border-gray-200 hover:bg-gray-100 group ${
          state ? stateStyle[state] : 'border-gray-200 focus:border-blue-200'
        }
                ${reviewLabel ? 'border-yellow-200' : ''} ${
          disabled ? 'bg-gray-200' : ''
        }`}
        onClick={() => {
          if (!isOpen) toggleDropdown();
        }}
        type="button"
        disabled={disabled}
      >
        <Icon name="search-gray-400" />
        {!isOpen ? (
          <Label
            color={`${
              selectedOptions && selectedOptions.length > 0
                ? 'text-black-300'
                : 'text-gray-300'
            }`}
            className={`absolute flex left-0 ml-16 pr-8 ${
              selectedOptions.length === 0 && '!font-normal !italic'
            } ${wrapText && selectedOptions.length === 1 ? 'text-[9px]' : ''}`}
          >
            {selectedOptions && selectedOptions.length === 0
              ? placeholder
                ? placeholder
                : 'Selecione a resposta'
              : selectedOptions.length === 1
              ? selectedOptions[0].value
              : getOptionsLabel(selectedOptions, 'Todos')}
          </Label>
        ) : (
          <input
            onKeyDown={handleKeyPress}
            type="text"
            className="w-full p-2 border-0 focus:outline-none group-hover:bg-gray-100"
            placeholder={
              placeholder ? placeholder : 'Digite para filtrar as opções'
            }
            onChange={handleInputChange}
            onClick={wipeSelecteds}
            value={typedValue ? typedValue : 'Selecione os itens'}
            disabled={disabled}
          />
        )}
        <input type="text" className="hidden" {...registerInput} />
        <button
          type="button"
          onClick={toggleDropdown}
          className={`transition-transform ${isOpen ? 'rotate-180' : ''}`}
        >
          <Icon name={'arrow-down-blue-200'}></Icon>
        </button>
      </button>
      {isOpen && (
        <ul className="absolute z-50 top-16 left-0 right-0 max-h-60 overflow-x-auto overflow-y-auto bg-white shadow-lg">
          {filteredOptions.map((option) => (
            <li key={option.key}>
              <button
                className={`relative w-full h-[50px] p-5 flex items-center z-50 font-semibold text-sm border-[1px] border-solid border-gray-200 ${
                  selectedOptions.some(
                    (selectedOption) => selectedOption.key === option.key,
                  )
                    ? 'bg-blue-200 text-white-100 hover:bg-blue-300'
                    : 'bg-white-100 text-gray-400 hover:bg-gray-200'
                }`}
                onClick={() => handleChangesDropDown(option)}
                type="button"
              >
                <Checkbox
                  isChecked={selectedOptions.some(
                    (selectedOption) => selectedOption.key === option.key,
                  )}
                  handleChange={() => handleChangesDropDown(option)}
                />
                {wrapText && (
                  <div
                    className={`p-2 text-left truncate whitespace-normal ${
                      option.value.length > 25 ? 'text-[10px]' : 'text-[12px]'
                    }`}
                    title={option.value.trimStart()}
                  >
                    {option.value.trimStart()}
                  </div>
                )}
                {!wrapText && (
                  <div
                    className={`p-2 text-left truncate`}
                    title={option.value.trimStart()}
                  >
                    {option.value.trimStart()}
                  </div>
                )}
              </button>
            </li>
          ))}
        </ul>
      )}
      {reviewLabel && (
        <div className="mt-2.5 w-fit">
          <div className="mb-2.5">
            <InputMessage state="warning" label={reviewLabel} />{' '}
            {/* Aviso de revisão */}
          </div>
        </div>
      )}
      {state === 'danger' && errorLabel && (
        <div className="mt-2.5 w-fit">
          <div className="mb-2.5">
            <InputMessage state={state} label={errorLabel} />{' '}
            {/* Aviso de erro */}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMultipleSelect;

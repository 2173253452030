import { useEffect, useState } from 'react';
import { Card } from '../../components/Card/Card';
import { HomeLink } from '../../components/HomeLink/HomeLink';
import Label from '../../components/Label/Label';
import SlideShow from '../../components/SlideShow/SlideShow';

interface IHomepageProps {}

export const HomePage: React.FC<IHomepageProps> = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <>
      <div className="flex justify-center pt-10 px-10">
        <SlideShow />
      </div>
      <div className="flex justify-center pt-10">
        <Label size="md">Central de Conteúdo</Label>
      </div>
      <div className="flex justify-center pt-10">
        <Card />
      </div>

      <div className="flex jus flex-col pt-10  lg:px-10 px-4 md:flex-row pb-[127px]">
        <div
          style={{
            boxShadow: '0px 3px 6px 0px rgba(51, 51, 51, 0.16)',
          }}
          className="w-full h-56 p-[30px]"
        >
          <HomeLink url="#" hasBorder={true}>
            Infográficos
          </HomeLink>
          <HomeLink url="#" hasBorder={true}>
            Notícias
          </HomeLink>
          <HomeLink url="#">Galeria de Vídeos</HomeLink>
        </div>
        <div
          style={{
            boxShadow: '0px 3px 6px 0px rgba(51, 51, 51, 0.16)',
          }}
          className={`w-full h-56 p-[30px] lg:ml-6 ${isMobile ? 'mt-6' : ''}`}
        >
          <HomeLink url="#" hasBorder={true}>
            Perguntas Frequentes
          </HomeLink>
          <HomeLink url="#" hasBorder={true}>
            Dados e Ferramentas
          </HomeLink>
          <HomeLink url="#">Publicações</HomeLink>
        </div>
      </div>
    </>
  );
};

import { Auth } from 'aws-amplify';

const baseURL = 'https://sirene-api.develop.pumpkintech.com.br';

const overrideFetch = async (method?: string, body?: any) => {
  let isUploadFile = localStorage.getItem('isUploadFile');
  let isDownloadFile = localStorage.getItem('isDownloadFile');

  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    let [resource, config] = args;
    let authorization = '';

    try {
      await Auth.currentSession()
        .then(async (session) => {
          let idTokenExpire = session.getIdToken().getExpiration();
          let refreshToken = session.getRefreshToken();
          let currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then((res) => {
              res.refreshSession(refreshToken, (err: any, data: any) => {
                if (err) {
                  Auth.signOut();
                } else {
                  authorization = 'Bearer ' + data.getIdToken().getJwtToken();
                }
              });
            });
          } else {
            authorization = 'Bearer ' + session.getIdToken().getJwtToken();
          }
        })
        .catch((error) => console.log(error));
    } catch (err) {
      console.log(err);
    } finally {
      let headersUpload = {
        Accept:
          isDownloadFile && isDownloadFile === 'true'
            ? '*/*'
            : 'application/json',
        Authorization: authorization,
        ...config?.headers,
      };

      let headersGeneric = {
        Accept:
          isDownloadFile && isDownloadFile === 'true'
            ? '*/*'
            : 'application/json',
        'Content-Type':
          isDownloadFile === 'true'
            ? 'application/octet-stream'
            : 'application/json',
        Authorization: authorization,
        ...config?.headers,
      };

      config = {
        method: method ?? 'GET',
        body: body ? JSON.stringify(body) : undefined,
        headers:
          isUploadFile && isUploadFile === 'true'
            ? headersUpload
            : headersGeneric,
        ...config,
      };

      const response = await originalFetch(resource, config);

      localStorage.setItem('isDownloadFile', 'false');
      localStorage.setItem('isUploadFile', 'false');
      return response;
    }
  };
};

const get = async (url: string, params: string = '', path: string = '') => {
  const getParams = params !== '' ? `?${params}` : '';
  overrideFetch();
  return await fetch(baseURL + url + `/${path}` + getParams)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

const getFile = async (url: string, params: string = '', path: string = '') => {
  const getParams = params !== '' ? `?${params}` : '';
  overrideFetch();
  return fetch(baseURL + url + `/${path}` + getParams)
    .then((response) => {
      if (response.status === 200) {
        return response.arrayBuffer();
      } else {
        throw new Error('Error in API response');
      }
    })
    .then((arrayBuffer) => {
      const blob = new Blob([arrayBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      return blob;
    })
    .catch((error) => {
      console.warn('Error when making the request', error);
      throw error;
    });
};

const postFile = async (
  url: string,
  file: File,
  formId?: string,
  companyId?: string,
) => {
  const formData = new FormData();

  formData.append('file', file);

  if (formId) {
    formData.append('formId', formId);
  }

  if (companyId) {
    formData.append('companyId', companyId);
  }

  overrideFetch('POST', formData);

  return await fetch(baseURL + url, {
    method: 'POST',
    body: formData,
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error('Error in API response');
      }
    })
    .catch((error) => {
      console.warn('Error when making the request', error);
      throw error;
    });
};

const post = async (url: string, body: any) => {
  overrideFetch('POST', body);
  return await fetch(baseURL + url)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

const put = async (url: string, body: any) => {
  overrideFetch('PUT', body);
  return await fetch(baseURL + url)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

const del = async (url: string, body?: any) => {
  overrideFetch('DELETE', body);
  return await fetch(baseURL + url)
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const api = {
  get,
  post,
  put,
  del,
  getFile,
  postFile,
};

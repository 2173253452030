import { useEffect, useState } from "react";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

interface IPaginationProps {
    setPageNumber: (page: number) => void;
    size?: 'small' | 'large'
    pageNumber: number;
    totalPages: number;
}

export function Pagination({ totalPages = 10, size = 'small', setPageNumber }: IPaginationProps) {
    const [pages, setPagination] = useState<{ type: string, number: number }[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [maxButtons] = useState<number>(size === 'small' ? 4 : 8);

    function handlerButtonsElements(filterDots: number[], d: number) {
        const pageElements = []
        let pagen = d
        for (let index = 0; index < Math.min(totalPages, maxButtons); (index++, pagen++)) {
            pageElements.push({
                type: filterDots.some(f => index === (maxButtons - f) && totalPages > maxButtons) ? "MIDDLE" : 'PAGE',
                number: pagen
            })
        }
        setPagination(pageElements)
    }

    function nextPage() {
        const pageNumber = currentPage + 1
        if (pageNumber !== totalPages)
            setCurrentPage(() => pageNumber)
        if (pageNumber > (maxButtons - 2) && totalPages > pageNumber + 2)
            handlerButtonsElements([pageNumber > 1 ? maxButtons : 0], pageNumber)
    }

    function prevPage() {
        const pageNumber = currentPage - 1
        if (pageNumber >= 0)
            setCurrentPage(() => pageNumber)
        if (pageNumber < (totalPages - (maxButtons - 1)) && pageNumber > 0)
            handlerButtonsElements([1], pageNumber)
    }

    useEffect(() => {
        const last = maxButtons < totalPages ? 1 : 0
        handlerButtonsElements([last], 1)
    }, []);

    useEffect(() => {
        setPageNumber(currentPage)
    }, [currentPage]);

    return <>
        <div className='flex w-full'>
            <Button
                icon='arrow-left-button-blue-200'
                disabled={currentPage === 0}
                onClick={prevPage}
                height='h-[50px]'
                style='outlined'
                width='w-[50px]'
            />
            <nav className='flex flex-1 items-center justify-center gap-2'>
                {pages.map(page => page.type === 'PAGE' ? (
                    <Button
                        key={page.number}
                        style={currentPage === (page.number - 1) ? 'filled' : 'none'}
                        label={page.type === 'PAGE' ? page.number.toString() : ''}
                        onClick={() => setCurrentPage(() => (page.number - 1))}
                        height='h-[50px]'
                        width='w-[50px]'
                    />
                ) : (
                    <div
                        className="w-[50px] h-[50px] bg-transparent rounded-full flex items-center justify-center"
                        key={page.number}
                    >
                        <Icon name="horizontal-dot-blue-300" />
                    </div>
                ))}
            </nav>
            <Button
                disabled={(currentPage + 1) >= totalPages}
                icon='arrow-right-button-blue-200'
                onClick={nextPage}
                height='h-[50px]'
                style='outlined'
                width='w-[50px]'
            />
        </div>
    </>
}
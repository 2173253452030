import { api } from './api';

const getA1Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/scopeIssuanceResult', params);
};

const getA2Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/scopeIssuance', params);
};

const getA3Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/scopeIssuanceResultPizza', params);
};

const getB1Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/categoryIssuanceResultYear', params);
};

const getB2Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/categoryIssuanceYear', params);
};

const getC1Type = (params: string): Promise<IGraphicPayload> => {
  return api.get(
    '/public/graphic/categoryIssuanceResultYearWithSectorAndCategory',
    params,
  );
};

const getC2Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/categoryIssuanceResultYearByGas', params);
};

const getD1Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/scopeIssuanceResultWithOrganization', params);
};

const getD2Type = (params: string): Promise<IGraphicPayload> => {
  return api.get('/public/graphic/scopeIssuanceWithOrganization', params);
};

const getOrganizationTable = (
  params: string,
): Promise<IGraphicOrganizationTable[]> => {
  return api.get('/public/graphic/organizationTable', params);
};

export const graphicService = {
  getA1Type,
  getA2Type,
  getA3Type,
  getB1Type,
  getB2Type,
  getC1Type,
  getC2Type,
  getD1Type,
  getD2Type,
  getOrganizationTable,
};

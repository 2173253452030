import moment from 'moment';
import { BaseTable } from '../Table/BaseTable/BaseTable';
import { CheckBoxHeaderTable } from './CheckBoxHeaderTable';
import { RowBody } from '../Table/RowBody/RowBody';
import { RowCell } from '../Table/RowCell/RowCell';
import { CheckBoxBodyTable } from './CheckBoxBodyTable';
import Label from '../Label/Label';
import Icon from '../Icon/Icon';
import { LabelFunction } from '../LabelFunction/LabelFunction';
import { Pagination } from '../Pagination/Pagination';

interface IListOrganizationsProps {
  listAllCompanies: (page: number) => void;
  orgs?: IPagination<IOrganization> | null;
}

export function ListOrganizations({
  orgs,
  listAllCompanies,
}: IListOrganizationsProps) {
  return (
    <>
      <div className="border rounded-md m-10 border-gray-100 overflow-auto">
        <BaseTable
          headerTitlesComponents={[
            <CheckBoxHeaderTable titleLabel={'Org.'} />,
            'Data de cadastro',
            'Status',
          ]}
          childrensLength={orgs?.content.length ?? 0}
        >
          {orgs?.content.map((org) => {
            return (
              <RowBody key={org.companyId}>
                <RowCell>
                  <CheckBoxBodyTable name={org.fantasyName} cnpj={org.cnpj} />
                </RowCell>
                <RowCell>
                  <Label className={'!whitespace-nowrap !font-normal'}>
                    {org.createdDate &&
                      moment(org.createdDate).format('DD/MM/YYYY')}
                  </Label>
                </RowCell>
                <RowCell>
                  <div className="flex items-end justify-between h-10 w-[150px]">
                    <LabelFunction
                      type={
                        org.isImported && org.hasResponsible
                          ? 'LINKED'
                          : org.isImported && !org.hasResponsible
                          ? 'IMPORTED'
                          : 'CREATED_SIRE'
                      }
                    />
                  </div>
                </RowCell>
              </RowBody>
            );
          })}
        </BaseTable>
        {orgs && orgs?.totalPages > 0 && (
          <div className="m-4">
            <Pagination
              pageNumber={1}
              setPageNumber={listAllCompanies}
              totalPages={orgs.totalPages}
            />
          </div>
        )}
      </div>
    </>
  );
}

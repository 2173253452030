import { createContext, useState } from 'react';

type ToastState = 'primary' | 'danger' | 'success' | 'warning';

interface IToast {
  label: string,
  title: string,
  state: ToastState,
  type?: string,
}

interface ToastContextType {
  showToast: boolean;
  addToast: (
    toast: IToast
  ) => void;
  title: string;
  label:string;
  orgNickname: string;
  state:ToastState,
  toastQueue: IToast[],
  setShowToast: (show: boolean) => void;
  setOrgNickname: (orgNickname: string) => void;
  triggerToast: (type?: string) => void;
}

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastContext = createContext<ToastContextType>({
  showToast: false,
  addToast: (
    toast: IToast
  ) => {},
  title: '',
  label:'',
  orgNickname: '',
  state:'primary',
  toastQueue: [],
  setShowToast: (show: boolean) => {},
  setOrgNickname: (orgNickname: string) => { },
  triggerToast: (type?: string) => {},
});

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [state, setState] = useState<
    ToastState
  >('primary');
  const [title, setTitle] = useState<string>('');
  const [label, setLabel] = useState<string>('');
  const [orgNickname, setOrgNickname] = useState<string>('');

  const [toastQueue, setToastQueue] = useState<IToast[]>([]);

  const addToast = (
    toast: IToast
  ) => {
    setToastQueue((prev) => ([...prev ?? [] , toast]));
  };
  
  const triggerToast = (type = '') => {
    if(type !== '' && verifyToastQueue(type)) return;

    const currentToast = toastQueue?.pop()!;
    if(typeof currentToast === 'undefined') return;

    setTitle(currentToast.title);
    setLabel(currentToast.label);
    setState(currentToast.state);
    setShowToast(true);
    setOrgNickname('');
  }

  function verifyToastQueue(type: string): boolean {
    return toastQueueIsEmpty() || !toastTypeMatches(type);
  }

  function toastQueueIsEmpty(): boolean{
    return toastQueue.length === 0;
  }

  function toastTypeMatches(type: string):boolean{
    const currentType = toastQueue[toastQueue.length - 1].type; 
    return type === currentType;
  }

  return (
    <ToastContext.Provider
      value={{
        showToast,
        addToast,
        title,
        label,
        orgNickname,
        setOrgNickname,
        state,
        setShowToast,
        triggerToast,
        toastQueue
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

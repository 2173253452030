import { Controller } from 'react-hook-form';
import Label from '../Label/Label';
import Icon from '../Icon/Icon';
import { useEffect, useRef, useState } from 'react';
import { InputMessage } from '../InputMessage/InputMessage';
import { get } from 'lodash';

interface IInputDropdownProps {
  control: any;
  name: string;
  placeholder?: string;
  options: IOption[];
  errorMessage?: string;
  register?: any;
  state?: IStateClasses;
  isValid?: boolean;
  errorLabel?: string;
  getValues?: any;
  reviewLabel?: string;
  handleKeyPress?: (event: React.KeyboardEvent) => void;
  disabled?: boolean;
  fullHeight?: boolean;
}

const stateStyle = {
  warning: 'border-yellow-200',
  danger: 'border-red-200',
  primary: '',
  success: '',
};

interface IItemDropdownProps {
  active: boolean;
  onChange: (option: IOption) => void;
  option: IOption;
  getValues?: any;
  fullHeight?: boolean;
}

function ItemDropdown({
  active,
  onChange,
  option,
  fullHeight,
}: IItemDropdownProps) {
  return (
    <li
      className={` ${
        fullHeight ? 'h-full' : 'h-[50px]'
      }  border-b-2 border-solid border-gray-200 cursor-pointer
      flex items-center justify-start px-5 rounded-base
      ${active ? 'bg-blue-200' : ''}
    `}
      onClick={() => onChange(option)}
    >
      <Label color={active ? 'text-white-100' : 'text-gray-400'}>
        {option.value}
      </Label>
    </li>
  );
}

export function InputDropdown({
  control,
  placeholder,
  options,
  errorMessage,
  name,
  register,
  getValues,
  state,
  isValid,
  errorLabel,
  reviewLabel,
  handleKeyPress,
  disabled,
  fullHeight = false,
}: IInputDropdownProps) {
  const inputClasses = `
    w-full
    h-[60px]
    p-5
    text-black-300
    border-2
    border-solid
    rounded-base
    text-sm
    !text-black-300
    placeholder:text-gray-300
    focus-visible:outline-none
    disabled:bg-gray-200
    placeholder:italic
    hover:bg-gray-100
    group
    flex 
    items-center
    justify-start
    gap-3
    cursor-pointer
    border-gray-200
    relative
    ${disabled ? 'bg-gray-200' : ''}
    ${state ? stateStyle[state] : 'border-gray-200 focus:border-blue-200'}
    ${reviewLabel ? 'border-yellow-200' : ''} 
    `;

  const [open, setOpen] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<IOption | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>(options);
  const [searchText, setSearchText] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (typeof getValues(name) === 'string') {
      if (getValues(name)) {
        const selected = options.find(
          (option) => option.key === getValues(name),
        );
        setCurrentOption(selected || null);
        setSearchText(selected?.value ?? '');
      }
    } else if (typeof getValues(name) === 'object') {
      if (getValues(name)) {
        const selected = options.find(
          (option) => option.key === getValues(name).key,
        );
        setCurrentOption(selected || null);
        setSearchText(selected?.value ?? '');
      }
    }
  }, [getValues, name]);

  useEffect(() => {
    if (typeof getValues(name) === 'string') {
      if (getValues(name)) {
        const selected = options.find(
          (option) => option.key === getValues(name),
        );
        setCurrentOption(selected || null);
        setSearchText(selected?.value ?? '');
      }
    } else if (typeof getValues(name) === 'object') {
      if (getValues(name)) {
        const selected = options.find(
          (option) => option.key === getValues(name).key,
        );
        setCurrentOption(selected || null);
        setSearchText(selected?.value ?? '');
      }
    }
  }, []);

  const handleChangesDropDown = (event: any, callback: any) => {
    if (event && event.target) {
      const searchText = event.target.value;
      setSearchText(searchText);
      const filtered = options.filter((option) =>
        option.value.toLowerCase().includes(searchText.toLowerCase()),
      );
      setFilteredOptions(filtered);
      setCurrentOption(null);
    } else {
      const selected = options.find((option) => option.key === event.key);
      setCurrentOption(selected || null);
      setSearchText(selected?.value ?? '');
      setOpen(false);
      callback(selected);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    const filtered = options.filter((option) =>
      option.value.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredOptions(filtered);
    setCurrentOption(null);
    setOpen(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [])

  return (
    <section>
      <Controller
        defaultValue={currentOption?.key ?? ''}
        control={control}
        name={name}
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <div className="relative" ref={dropdownRef}>
              <header
                className={inputClasses}
                onClick={() => {
                  if (!disabled) {
                    setOpen((open) => !open);
                  }
                }}
              >
                <Icon name="search-gray-400" />

                <input
                  disabled={disabled}
                  onKeyDown={handleKeyPress}
                  type="text"
                  className="hidden"
                  onChange={onChange}
                  value={value.value ?? ''}
                  {...field}
                />
                <input
                  onChange={handleInputChange}
                  className={`w-full outline-none border-0 !bg-transparent placeholder:!text-gray-300 placeholder:!font-normal placeholder:!italic
                        group-hover:bg-gray-100 ${
                          disabled ? 'bg-gray-200' : ''
                        }`}
                  value={searchText}
                  placeholder={placeholder ?? 'Selecione a resposta.'}
                  type="text"
                  {...register}
                  disabled={disabled}
                />
                <Icon name="arrow-down-blue-200" />
              </header>
              {open && (
                <ul className="absolute z-50 top-16 left-0 right-0 max-h-60 overflow-x-auto overflow-y-auto bg-white-100 shadow-lg">
                  {filteredOptions.map((option) => (
                    <ItemDropdown
                      onChange={(op) => handleChangesDropDown(op, onChange)}
                      active={option.key === currentOption?.key}
                      option={option}
                      key={option.key}
                      fullHeight={fullHeight}
                    />
                  ))}
                </ul>
              )}
            </div>
          );
        }}
      />
      {reviewLabel && (
        <div className="mt-2.5 w-fit">
          <div className="mb-2.5">
            <InputMessage state="warning" label={reviewLabel} />{' '}
            {/* Aviso de revisão */}
          </div>
        </div>
      )}
      {state === 'danger' && errorLabel && (
        <div className="mt-2.5 w-fit">
          <div className="mb-2.5">
            <InputMessage state={state} label={errorLabel} />{' '}
            {/* Aviso de erro */}
          </div>
        </div>
      )}
    </section>
  );
}

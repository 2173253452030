import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { formsService } from '../../services/formsService';
import ButtonStep from '../../components/Button/ButtonStep';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Label from '../../components/Label/Label';
import { formatDateDDMMYYYY } from '../../utils/dates/formatDateDDMMYYYY';
import Icon from '../../components/Icon/Icon';
import TextArea from '../../components/TextArea/TextArea';
import ModalAlert from '../../components/Modal/ModalAlert';
import { useNavigate } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import { FormIdContext } from '../../contexts/FormContext';

interface InventoryFormReviewPageProps { }

interface IReview {
    stepIndex: number;
    name: string;
}

export const InventoryFormReviewPage: React.FC<
    InventoryFormReviewPageProps
> = () => {
    const location = useLocation();
    const formId = location.state?.formId;
    const status = location.state?.status;

    const { setValue, control, getValues, resetField, formState } = useForm<any>({
        mode: 'onChange',
    });

    const navigate = useNavigate();

    const [formsJSON, setFormsJSON] = useState<IFormJSON>();
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState<IFormStep>();
    const [stepsVisitedArray, setStepsVisitedArray] = useState<boolean[]>(
        [] as boolean[],
    );
    const [reviewArray, setReviewArray] = useState<IReview[]>([]);

    const [firsRun, setFirstRun] = useState<boolean>(true);

    const [showModalWhenReviewFinished, setShowModalWhenReviewFinished] =
        useState<boolean>(false);

    const [isApproved, setIsApproved] = useState<boolean>();

    const [buttonEnable, setButtonEnable] = useState(true);

    const handleHasInputChange = (hasInput: boolean) => {
        setButtonEnable(hasInput);
    }

    const [otherEnable, setOtherEnable] = useState(false);

    const [numbers, setNumbers] = useState<number[]>([1]);

    const { id, setIdValue } = useContext(FormIdContext);

    useEffect(() => {
        setIdValue(formId);
    }, []);
    
    useEffect(() => {
        fetchFormJSON();
    }, [formId]);

    useEffect(() => {
        if (!formsJSON || !firsRun) return;

        const steps = formsJSON?.steps;
        let stepsVisited: boolean[] = [];
        let fillReviewArray: IReview[] = [];

        steps?.forEach((step, stepIndex) => {
            stepsVisited.push(false);
            step.questions.forEach((question) => {
                setValue(question.name, question.review ?? undefined);
                if (typeof question.review !== 'undefined' && question.review !== '')
                    fillReviewArray.push({ stepIndex, name: question.name });
            });
        });

        setReviewArray(fillReviewArray);
        stepsVisited[0] = true;
        setStepsVisitedArray(stepsVisited);
        setFirstRun(false);
    }, [formsJSON]);

    async function fetchFormJSON() {
        await formsService.getFormById(formId).then((response) => {
            setFormsJSON(response);
            if (response.steps) setCurrentStep(response.steps[0]);
        });
    }

    const changeStep = (index: number) => {
        setCurrentStepIndex(index);
        setCurrentStep(formsJSON!.steps[index] ?? []);
    };

    const handleStepClick = (index: number) => {
        setNumbers((prevNumber) => [...prevNumber, index + 1]);
        changeStep(index);

        fillFormJSON();

        let stepsVisited: boolean[] = stepsVisitedArray;
        stepsVisited[index] = true;
        setStepsVisitedArray(stepsVisited);
    };

    const fillFormJSON = () => {
        if (!formsJSON) return;

        const steps = formsJSON.steps;

        steps.forEach((step) => {
            step.questions.forEach((question) => {
                question.review = reviewArray.some(
                    (review) => question.name === review.name,
                )
                    ? getValues(question.name)
                    : undefined;
            });
        });

        setFormsJSON((prev: any) => ({ steps: steps, ...prev }));
    };

    useEffect(() => { }, [isApproved]);

    const formReviewEnd = () => {
        setShowModalWhenReviewFinished(true);
        setIsApproved(reviewArray.length === 0);
    };

    const sendFormReview = () => {
        if (formsJSON) {
            const status = isApproved ? 'APPROVED' : 'REVISION';

            const answersObject: { [key: string]: Object | null } = {};

            formsJSON.steps.forEach((step) => {
                step.questions.forEach((question) => {
                    if (question.review !== undefined) {
                        answersObject[question.name] = Object(question.review);
                    } else {
                        answersObject[question.name] = null;
                    }
                });
            });

            formsService
                .newFinishInventoryReview(formId, status, answersObject)
                .then((response) => {
                    setShowModalWhenReviewFinished(false);
                    navigate(-1);
                });
        }
    };

    function enableOther(question: IFormQuestion): boolean {
        if (question.validation.type === 'MULTIPLE_SELECT') {
            if (Array.isArray(question.answer)) {
                for (const option of question.answer) {
                    if (option) {
                        if (option.description !== 'undefined') {
                            if (option.description) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (question.validation.type === 'SELECT') {
            const obj = question.answer as IFormQuestionOptions
            if (obj) {
                if (obj.description !== 'undefined') {
                    if (obj.description) {
                        return true;
                    }
                }
            }
        }
        return false;
    }


    function formatOther(question: IFormQuestion): string | undefined {
        const answer = question.answer;
        if (question.validation.type === 'MULTIPLE_SELECT') {
            if (Array.isArray(answer)) {
                const option = answer.find((option) => option.key === '0');
                if (option) {
                    return option.description?.toString();
                }
            }
        } else if (question.validation.type === 'SELECT') {
            const obj = question.answer as IFormQuestionOptions
            return obj.description;
        }
        return '';
    }

    function formatAnswers(question: IFormQuestion): string {

        const answer = question.answer;
        const type = question.validation.type;

        switch (type) {
            case 'DATE':
                return formatDateDDMMYYYY(new Date(answer as string));

            case 'SELECT':
                const answerSelect = answer as IFormQuestionOptions;
                const selectOption = question.options?.find((option) => {
                    if (typeof answer === 'object' && option.key === answerSelect.key) {
                        return option.value;
                    }
                    return false;
                });
                return selectOption ? selectOption.value : '';

            case 'MULTIPLE_SELECT':
                if (Array.isArray(answer)) {
                    const selectedValues: string[] = [];

                    for (const selectedKey of answer) {
                        if (typeof selectedKey === 'object' && 'key' in selectedKey) {
                            const matchingOption = question.options?.find(
                                (option) => option.key === selectedKey.key,
                            );
                            if (matchingOption) {
                                selectedValues.push(matchingOption.value);
                            }
                        }
                    }

                    return selectedValues.join(', ');
                }
                return '';

            case 'INTEGER': case 'DOUBLE': default:
                return answer as string;
        }
    }

    function isFieldBeingReviewed(name: string): boolean {
        return (
            typeof reviewArray.find((question) => question.name === name) !==
            'undefined'
        );
    }

    function renderReviewInput(question: IFormQuestion): JSX.Element {
        if (!isFieldBeingReviewed(question.name)) return <></>;

        return (
            <div className="mt-5">
                <Controller
                    control={control}
                    key={question.name}
                    name={question.name}
                    defaultValue={question.review ?? ''}
                    render={({ field }) => {
                        return (
                            <TextArea
                                disabled={status === 'APPROVED' || status === 'REVISION'}
                                onChange={field.onChange}
                                value={field.value}
                                maxLength={300}
                                updateHasInput={handleHasInputChange}
                                required={true}
                            />
                        );
                    }}
                />
            </div>
        );
    }

    function reviewStyles(name: string): { bg: string; border: string } {
        if (!isFieldBeingReviewed(name)) return { bg: '', border: 'bg-gray-200' };

        return { bg: 'bg-yellow-300', border: 'bg-yellow-200' };
    }

    const toggleFieldReview = (name: string) => {
        if (!reviewArray.some((pos) => pos.name === name)) {
            setReviewArray((prev: any) => [
                ...prev,
                {
                    stepIndex: currentStepIndex,
                    name: name,
                },
            ]);
        } else {
            const newReviewArray = reviewArray.filter((pos) => pos.name !== name);
            setReviewArray(newReviewArray);
        }
    };


    function handleStepStyle(index: number): IStateClasses | 'visited' {

        if (reviewArray.some((field) => field.stepIndex === index)) {
            return 'warning';
        }

        // if (stepsVisitedArray[index] && currentStepIndex !== index)
        //     return 'visited';

        return 'visited';
    }

    function stepIsComplete(index: number): boolean {
        const hasBeenReviewed = reviewArray.find((step) => {
            if (step && step.stepIndex) {
                if (step.stepIndex === index) {
                    return true;
                }
            }
            return false;
        });

        const currStep = index + 1;

        if (index === 0 && hasBeenReviewed) {
            return false;
        }

        if (status === 'REVISION' && index === 0 && hasBeenReviewed?.stepIndex === currStep) {
            return false;
        }

        if (status === 'REVISION' && hasBeenReviewed?.stepIndex !== index && index !== 0) {
            return true;
        }

        if (status === 'REVISION' && reviewArray.length !== 0 && hasBeenReviewed?.stepIndex === currStep) {
            return false;
        }

        if (status === 'PENDING' && index === 0 && hasBeenReviewed && numbers.includes(currStep)) {
            return false;
        }

        if (status === 'PENDING' && !hasBeenReviewed && numbers.includes(currStep) && index !== 0) {
            return true;
        }

        if (status === 'PENDING' && reviewArray.length === 0 && hasBeenReviewed) {
            return true;
        }

        if (status === 'PENDING' && reviewArray.length === 0 && !hasBeenReviewed && numbers.includes(currStep)) {
            return true;
        }

        if (status === 'PENDING' && reviewArray.length !== 0 && hasBeenReviewed) {
            return false;
        }

        if (status === 'APPROVED') {
            return true;
        }

        return false;
    }

    function enableSubmitButton(): boolean {
        if (status === 'APPROVED' ||
            status === 'REVISION') {
            return true;
        }

        let errorCount = 0;
        reviewArray.forEach((review) => {
            const value = getValues(review.name)
            if (value.length === 0) {
                errorCount = +1;
            }
        });

        if (errorCount > 0) {
            return true;
        }

        return false;
    }

    const options = [
        { label: 'Baixar formulário', onClick: () => formsService.downloadSheetFileFormId(formId), icon:'download-file-blue-200' },
      ];

    return (
        <>
            {formsJSON && (
                <div className="md:flex md:flex-row md:h-[calc(100vh-128px)] flex-col h-auto">
                    <aside className="md:w-80 md:flex md:flex-row border-gray-100 md:border-r md:border-b-0 border-b w-full md:h-auto h-32">
                        <div className="w-[378px] md:w-80 p-10 flex flex-col max-h-[calc(100vh-128px)] overflow-y-auto scrollmobo">
                            <ul className="md:h-[calc(100vh-128px)] flex flex-row md:flex-col">
                                {formsJSON.steps &&
                                    formsJSON.steps.map((step, index) => {
                                        const errorAmount = +reviewArray.filter(
                                            (field) => field.stepIndex === index,
                                        ).length;
                                        return (
                                            <li key={step.name}>
                                                <ButtonStep
                                                    index={index + 1}
                                                    title={step.description}
                                                    isSelected={currentStepIndex === index}
                                                    isCompleted={
                                                        stepIsComplete(index)
                                                    }
                                                    hasTrack={index !== 0}
                                                    errorAmount={errorAmount}
                                                    onClick={() => {
                                                        handleStepClick(index);
                                                        scroller.scrollTo('myScrollToElement', {
                                                            duration: 100,
                                                            delay: 50,
                                                            smooth: true,
                                                            containerId: 'ContainerElementID',
                                                            offset: 0,
                                                        });
                                                    }}
                                                    state={handleStepStyle(index)}
                                                    reviewHasBeenReviewed={false}
                                                    review={formsJSON.review}
                                                    isAdmin={true}
                                                />
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </aside>
                    <main className="flex overflow-hidden">
                        <form className="relative h-auto md:h-screen w-full overflow-hidden">
                            <Element name="myScrollToElement">
                                <div
                                    id="ContainerElementID" className="md:max-h-[calc(100vh-258px)] h-auto md:h-screen w-full overflow-y-auto p-10">
                                    {currentStep?.questions &&
                                        currentStep.questions.map((question) => {
                                            const { bg, border } = reviewStyles(question.name);
                                            return (
                                                <div
                                                    key={question.name}
                                                    className={`p-10 w-full border-b border-gray-200 ${bg}`}
                                                >
                                                    <div className="w-full relative">
                                                        <Label>{question.description}</Label>
                                                        {question.validation.editable && (
                                                            <button
                                                                disabled={status === 'APPROVED' ||
                                                                    status === 'REVISION'}
                                                                className="absolute top-0 left-auto right-0 border-0 outline-none"
                                                                type="button"
                                                                onClick={() => {
                                                                    toggleFieldReview(question.name);
                                                                    setButtonEnable(!buttonEnable);
                                                                    if (status === 'APPROVED' ||
                                                                        status === 'REVISION') {
                                                                        setButtonEnable(!buttonEnable)
                                                                    }
                                                                }}
                                                            >
                                                                <Icon name="warn-gray-500" size="xs" />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className={`w-full h-[1px] my-5 ${border}`}></div>
                                                    <Label>{formatAnswers(question)}</Label>
                                                    {enableOther(question) && (
                                                        <div className='py-2'>
                                                            <Label>{'Resposta: ' + formatOther(question)}</Label>
                                                        </div>
                                                    )}
                                                    {renderReviewInput(question)}
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className='md:hidden'>
                                <FloatingButton style="filled" options={options} />
                                </div>
                            </Element>
                            <footer className="sticky h-[130px] w-full flex flex-col md:flex-row items-center justify-center bottom-0 left-0 border-t border-gray-100 bg-white-100">
                                <div className="flex flex-col md:flex-row items-center justify-center gap-2.5">
                                    <div className="w-[344px]">
                                        <Button
                                            label="Voltar"
                                            style="outlined"
                                            icon="arrow-left-blue-200"
                                            disabled={currentStepIndex === 0}
                                            onClick={() =>
                                                currentStepIndex !== 0 &&
                                                changeStep(currentStepIndex - 1)
                                            }
                                            type="button"
                                        />
                                    </div>
                                    <div className="w-[344px]">
                                        {currentStepIndex !== formsJSON.steps.length - 1 ? (
                                            <Button
                                                label="Continuar"
                                                style="filled"
                                                icon="arrow-right-button-white-100"
                                                iconPosition="right"
                                                onClick={() => {
                                                    handleStepClick(currentStepIndex + 1);
                                                    scroller.scrollTo('myScrollToElement', {
                                                        duration: 100,
                                                        delay: 50,
                                                        smooth: true,
                                                        containerId: 'ContainerElementID',
                                                        offset: 0,
                                                    });
                                                }}
                                                type="button"
                                            />
                                        ) : (
                                            <Button
                                                disabled={
                                                    enableSubmitButton()
                                                }
                                                type="button"
                                                onClick={() => formReviewEnd()}
                                                label="Finalizar revisão"
                                                style="filled"
                                            />

                                        )}
                                    </div>
                                </div>
                            </footer>
                        </form>
                    </main>
                </div>
            )}
            <>
                {showModalWhenReviewFinished && (
                    <ModalAlert visible={showModalWhenReviewFinished}>
                        <section className="w-[350px] md:w-[610px] p-10 flex flex-col items-center">
                            <div className="pb-2.5">
                                {isApproved ? (
                                    <Label size="xl" className="font-bold">
                                        Aprovar formulário
                                    </Label>
                                ) : (
                                    <Label size="xl" className="font-bold">
                                        Enviar formulário para correções
                                    </Label>
                                )}
                            </div>
                            <div className="pb-10 text-center">
                                {isApproved ? (
                                    <Label color="text-gray-400">
                                        Não foram sinalizadas correções no formulário e ele será
                                        aprovado imediatamente, deseja continuar mesmo assim?
                                    </Label>
                                ) : (
                                    <Label color="text-gray-400">
                                        Foram sinalizadas correções nas respostas do inventário,
                                        deseja continuar mesmo assim?
                                    </Label>
                                )}
                            </div>
                            <div className="flex flex-col md:flex-row items-center justify-center gap-2.5">
                                <div className="w-65">
                                    <Button
                                        style="outlined"
                                        label="Cancelar"
                                        type="button"
                                        onClick={() => {
                                            setShowModalWhenReviewFinished(false);
                                        }}
                                    />
                                </div>
                                <div className="w-65">
                                    <Button
                                        style="filled"
                                        label="Continuar"
                                        type="button"
                                        onClick={() => sendFormReview()}
                                    />
                                </div>
                            </div>
                        </section>
                    </ModalAlert>
                )}
            </>
        </>
    );
};

import { ReactNode, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { useSidebar } from '../../../contexts/SidebarContext';
import { ROUTES } from '../../../routes/Index.routes';
import Icon from '../../Icon/Icon';
import Logo from '../../Logo/Logo';
import { Popover } from '../../Popover/Popover';
import ToggleSwitch from '../../Toggle/Toggle';

type IBaseSidebar = {
  children: ReactNode;
};

export default function BaseSideBar({ children }: IBaseSidebar) {
  const { session } = useContext(AuthContext);
  const { sidebarOpen, setSidebarOpen } = useSidebar();

  return (
    <aside className="relative w-full flex flex-col md:items-start items-center h-screen bg-white-100 border-solid border-r border-gray-100 text-gray-100 pt-10">
      <div className="flex justify-center gap-6 md:px-10  w-ful">
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="md:hidden block"
        >
          <Icon name="close-menu" />
        </button>
        <Logo width={'220'} height={'73'} />
      </div>
      {session !== null && (
        <div className="pt-[60px] px-10 pb-5">
          <Popover
            orientation="right-full-bottom"
            componentName="toggle-switch"
            tutorialType="firstAccess"
            width="w-[370px]"
            levelOrder={0}
            exactPath={ROUTES.GENERAL_VISION.path}
            content={{
              title: 'Alternar a visualização.',
              body: `Você pode alternar entre a visualização das informações de sua 
                            Área Restrita e a informações da Área Pública, que estão disponíveis 
                            para acesso de qualquer usuário.`,
              button: 'Continuar',
            }}
          >
            <ToggleSwitch />
          </Popover>
        </div>
      )}
      {children}
    </aside>
  );
}

const saveOnStorage = (key: string, obj: any) => {
    localStorage.setItem(key, JSON.stringify(obj || null))
}

const getOnStorage = <T>(key: string, parsed?: boolean): T | null => {
    const storageData = localStorage.getItem(key)
    if (!!storageData && !!parsed) return JSON.parse(storageData)
    else if (!!storageData && !parsed) return storageData as any
    return null
}

const removeStorage = (key: string) => {
    localStorage.removeItem(key);
}

export const storageService = {
    saveOnStorage,
    removeStorage,
    getOnStorage,
}
import { Controller, useForm } from 'react-hook-form';
import Button from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import Label from '../../components/Label/Label';
import TextArea from '../../components/TextArea/TextArea';
import { useState } from 'react';

interface IContactPageProps {}

export const ContactPage: React.FC<IContactPageProps> = () => {
  const [hasTextArea, setHasTextArea] = useState(false);
  const [requireTextArea, setRequireTextArea] = useState(false);

  const updateHasInput = (value: boolean) => {
    setHasTextArea(value);
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const onSubmit = (data: any) => {
    if (hasTextArea) {
      console.log(data);
    }
  };

  const validateState = (error?: any): IStateClasses => {
    switch (error?.type) {
      case 'required':
        return 'danger';
      case 'pattern':
        return 'warning';
      default:
        return undefined;
    }
  };

  const getErrorLabel = (error?: any): string | undefined => {
    switch (error?.type) {
      case 'required':
        return 'Esse campo é obrigatório';
      default:
        return undefined;
    }
  };

  const getReviewLabel = (error?: any): string | undefined => {
    switch (error?.type) {
      case 'pattern':
        return 'E-mail inválido!';
      default:
        return undefined;
    }
  };

  return (
    <div className="p-10">
      <Label className="text-black-200 text-justify !font-normal">
        MINISTÉRIO DA CIÊNCIA, TECNOLOGIA E INOVAÇÃO (MCTI) <br />
        Coordenação-Geral de Ciência do Clima (CGCL) <br />
        Esplanada dos Ministérios, Bloco E <br />
        CEP 70.067-900 | Brasília/DF
      </Label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col mt-10">
          <div className="flex flex-row gap-5">
            <div className="w-full">
              <div className="mb-[10px]">
                <Label>Seu e-mail</Label>
              </div>
              <div>
                <Input
                  placeholder="Ex: exemplo@email.com"
                  registerInput={register('email', {
                    required: true,
                    pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                  })}
                  state={validateState(errors.email)}
                  errorLabel={getErrorLabel(errors.email)}
                  reviewLabel={getReviewLabel(errors.email)}
                />
              </div>
            </div>

            <div className="w-full">
              <div className="mb-[10px]">
                <Label>Assunto</Label>
              </div>
              <Input
                placeholder="Insira o assunto aqui"
                registerInput={register('subject', {
                  required: true,
                })}
                state={validateState(errors.subject)}
                errorLabel={getErrorLabel(errors.subject)}
              />
            </div>
          </div>
          <div className="mt-[20px] mb-[10px]">
            <Label>Comentários</Label>
          </div>

          <Controller
            control={control}
            name={'comments'}
            render={({ field }) => {
              return (
                <TextArea
                  placeholder="Insira seus comentários aqui"
                  required={requireTextArea}
                  onChange={field.onChange}
                  updateHasInput={updateHasInput}
                />
              );
            }}
          />
        </div>
        <div className="flex justify-center lg:justify-end lg:w-[260px] ml-auto">
          <Button
            label="Enviar"
            icon="arrow-right-button-white-100"
            iconPosition="right"
            style={'filled'}
            type="submit"
            onClick={() => {
              setRequireTextArea(true);
            }}
          />
        </div>
      </form>
    </div>
  );
};

import { useContext, useEffect, useState } from 'react';
import NotificationItem from '../../components/Notification/NotificationsItem';
import { AuthContext } from '../../contexts/AuthContext';
import { notificationService } from '../../services/notificationService';
import { Pagination } from '../../components/Pagination/Pagination';
import Label from '../../components/Label/Label';
import FloatingButton from '../../components/FloatingButton/FloatingButton';

interface INotificationsPageProps { }

export const NotificationsPage: React.FC<INotificationsPageProps> = () => {
    const { session } = useContext(AuthContext);
    const [notificationData, setNotificationData] =
        useState<IPagination<INotification> | null>(null);

    const handleNotificationsData = async (page: number) => {
        session?.id &&
            (await notificationService
                .getNotificationByUserId(session.id, page)
                .then((data) => {
                    if (!data?.status) setNotificationData(data);
                    else setNotificationData(null);
                }));
    };

    useEffect(() => {
        handleNotificationsData(0);
    }, []);

    const options = [
        { label: 'Atualizar', onClick: () => {}, icon: 'refresh-blue-200' },
        { label: 'Filtrar', onClick: () => {}, icon: 'filter-blue-200' },
    ];


    return (
        <div className="p-4">
            {notificationData &&
                notificationData.content.length > 0 &&
                notificationData.totalPages > 0 ? (
                <>
                    <ul className="divide-y divide-gray-200">
                        {notificationData.content.map((notification) => (
                            <NotificationItem
                                key={notification.id}
                                notification={notification}
                                reloadNotifications={handleNotificationsData}
                            />
                        ))}
                    </ul>
                    <Pagination
                        pageNumber={1}
                        totalPages={notificationData.totalPages}
                        setPageNumber={handleNotificationsData}
                    />
                </>
            ) : (
                <Label>Não há notificações.</Label>
            )}
            <div className='block md:hidden'>
                <FloatingButton style="filled" options={options} />
            </div>
        </div>
    );
};

import { createContext, useState } from 'react';

interface InventoryFormContextProps {
  setFormBeingFilled: React.Dispatch<React.SetStateAction<boolean>>;
  formBeingFilled: boolean;
}

interface InventoryFormProviderProps {
  children: React.ReactNode;
}

export const InventoryFormContext = createContext<InventoryFormContextProps>(
  {} as InventoryFormContextProps,
);

export const InventoryFormProvider: React.FC<InventoryFormProviderProps> = ({
  children,
}) => {
  const [formBeingFilled, setFormBeingFilled] = useState<boolean>(false);

  return (
    <InventoryFormContext.Provider
      value={{
        setFormBeingFilled,
        formBeingFilled,
      }}
    >
      {children}
    </InventoryFormContext.Provider>
  );
};

import { useEffect, useState } from 'react';
import { ListOrganizations } from '../../components/OrganizationPageGroupComponents/ListOrganizations';
import { organizationService } from '../../services/organizationService';

interface IOrganizationPageProps {}

export const OrganizationPage: React.FC<IOrganizationPageProps> = () => {
  const [orgs, setOrgs] = useState<IPagination<IOrganization> | null>(null);
  const listAllCompanies = (page: number) => {
    organizationService.getCompaniesAdmin(page).then((org) => {
      setOrgs(org);
    });
  };

  useEffect(() => {
    listAllCompanies(0);
  }, []);
  return (
    <>
      <ListOrganizations orgs={orgs} listAllCompanies={listAllCompanies} />
    </>
  );
};

import React, { createContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userService } from '../services/userService';
import { storageService } from '../services/storageService';
import { Auth } from 'aws-amplify';
import { PUBLIC_ROUTES, ROUTES } from '../routes/Index.routes';

type TPosition = 'right' | 'left' | null;

export enum PerfilType {
  COMPANY_MEMBER = 'COMPANY_MEMBER',
  ADMIN = 'ADMIN',
  SPECIALIST = 'SPECIALIST',
}

interface AuthContextType {
  govToken: string | null;
  setToken: (newToken: string | null) => void;
  session: ISession | null;
  setSession: (newSession: any | null) => void;
  userAddOrganization: (orgId: any) => void;
  logIn: () => void;
  logOut: () => void;
  setTogglePosition: (togglePosition: 'right' | 'left') => void;
  togglePosition: TPosition;
}
interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
  govToken: null,
  setToken: () => {},
  session: null,
  setSession: () => {},
  userAddOrganization: () => {},
  logIn: () => {},
  logOut: () => {},
  setTogglePosition: (togglePosition: TPosition) => {},
  togglePosition: null,
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [govToken, setToken] = useState<string | null>(null);
  const [session, setSession] = useState<ISession | null>(null);
  const navigate = useNavigate();
  const [togglePosition, setTogglePosition] = useState<TPosition>(null);
  const location = useLocation();

  useEffect(() => {
    const storedToken = localStorage.getItem('govToken');
    if (storedToken) {
      setToken(storedToken);
    }

    const storedSession = localStorage.getItem('session');
    if (storedSession) {
      const jsonSession: ISession = JSON.parse(storedSession) as ISession;
      setSession(jsonSession);
      setTogglePosition(jsonSession.togglePosition ?? 'left');
    }
    sessionStorage.clear();
  }, []);

  // useEffect(() => {
  //   const storedToken = localStorage.getItem('govToken');
  //   const storedSession = localStorage.getItem('session');
  //   if (
  //     (storedSession === null || storedToken === null || storedToken === '') &&
  //     document.cookie.split(';').some((cookie) => cookie.includes('idToken'))
  //   )
  //     return logOut();

  //   if (
  //     !Object.values(PUBLIC_ROUTES).some((route) =>
  //       route.path.includes(location.pathname),
  //     ) &&
  //     (storedToken === null || storedToken === '')
  //   )
  //     return navigate(ROUTES.LOGIN.path);
  // }, [session, govToken, location.pathname]);

  const updateToken = (newToken: string | null) => {
    setToken(newToken);
    localStorage.setItem('govToken', newToken || '');
  };

  const updateSession = (newSession: ISession | null) => {
    setSession(newSession);
    localStorage.setItem('session', JSON.stringify(newSession));
  };

  const logIn = async () => {
    const currentSession = await Auth.currentSession();
    if (!currentSession.isValid()) return Auth.signOut();

    const currentUserInfo = await Auth.currentUserInfo();

    let newSession: any = await userService.getUserByCpf(
      currentUserInfo.username,
    );

    if (newSession && newSession.id && typeof newSession.id !== 'undefined') {
      newSession = {
        firstAccess: false,
        organizations: newSession.companies ?? [],
        tutorialVisualisation: {
          sawAccessTutorial: newSession.sawAccessTutorial,
          sawCompanyTutorial: newSession.sawCompanyTutorial,
          sawFormTutorial: newSession.sawFormTutorial,
        },
        ...newSession,
      };
    } else {
      newSession = {
        firstAccess: true,
        cpf: currentUserInfo.username,
        sawAccessTutorial: false,
        sawCompanyTutorial: false,
        sawFormTutorial: false,
      };
      storageService.removeStorage('tutorial');
    }

    updateToken(currentSession.getIdToken().getJwtToken());
    updateSession(newSession);
    navigate(ROUTES.GENERAL_VISION.path);
  };

  const logOut = () => {
    updateToken(null);
    updateSession(null);
    localStorage.clear();
    document.cookie = '';
    Auth.signOut().then(() => window.location.reload());
    navigate(ROUTES.HOME.path);
  };

  const userAddOrganization = (newOrg: any) => {
    const currentSession: ISession = {
      ...session,
      organizations: !session?.organizations.some((org) => org.id === newOrg)
        ? [...(session?.organizations ?? []), newOrg]
        : session?.organizations,
    } as ISession;
    updateSession(currentSession);
  };

  const saveTogglePosition = (pos: 'right' | 'left') => {
    setTogglePosition(pos);
    const currentSession: ISession = {
      ...session,
      togglePosition: pos,
    } as ISession;

    updateSession(currentSession);
  };

  return (
    <AuthContext.Provider
      value={{
        logIn,
        logOut,
        userAddOrganization,
        togglePosition,
        session,
        govToken,
        setToken: updateToken,
        setSession: updateSession,
        setTogglePosition: saveTogglePosition,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

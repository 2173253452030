import { Dispatch, SetStateAction } from "react";
import { Toast } from "../Toast/Toast";

interface INotificationComponentProps {
    showNotification: boolean;
    onClose?: Dispatch<SetStateAction<boolean>>;
}

export function NotificationComponent({ showNotification, onClose }: INotificationComponentProps) {
    return (
        <div className={`absolute w-full pt-8 px-10 top-0 left-0 ${!!showNotification ? 'block' : 'hidden'}`}>
            <Toast
                onClose={() => onClose && onClose(close => !close)}
                state={'primary'}
                title="Pedido de participação."
                label="Seu convite de participação foi enviado e assim que houver uma mudança de status, você será notificado."
            />
        </div>
    )
}
function validateCnpj(cnpj: any) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  let arrCnpj = cnpj.split('');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  const weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let index, sum;

  for (index = 0, sum = 0; index < 12; sum += arrCnpj[index] * weights[++index]);
  if (+arrCnpj[12] !== ((sum %= 11) < 2 ? 0 : 11 - sum)) return false;

  for (index = 0, sum = 0; index <= 12; sum += arrCnpj[index] * weights[index++]);
  if (+arrCnpj[13] !== ((sum %= 11) < 2 ? 0 : 11 - sum)) return false;

  return true;
}

export default validateCnpj;

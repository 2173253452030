import { useEffect, useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import Icon from '../../Icon/Icon';
import Label from '../../Label/Label';

interface ISidebarButton extends LinkProps {
  icon: string;
  label: string;
  isSelected: boolean;
  hasNotification?: boolean;
}

const SidebarButton = ({
  icon,
  label,
  isSelected,
  hasNotification = false,
  to,
  ...rest
}: ISidebarButton) => {
  const { pathname } = useLocation();
  const [linkStyles, setLinkStyles] = useState<string>(
    'bg-gray-500 text-white-100',
  );

  useEffect(() => {
    setLinkStyles(() =>
      isSelected || pathname === to
        ? '!bg-gray-500 text-white-100'
        : 'text-gray-500 hover:bg-gray-100',
    );
  }, [isSelected, pathname]);

  return (
    <Link
      {...rest}
      to={to}
      className={`md:w-65 w-80 h-12 flex flex-row items-center justify-start p-5 gap-2.5 rounded-3xl bg-white-100 ${linkStyles}`}
    >
      <Icon name={icon}></Icon>
      <Label color={linkStyles}>{label}</Label>

      {hasNotification && !isSelected && (
        <div className="w-13 h-13 mx-3 mb-3 absolute rounded-full bg-blue-300"></div>
      )}
    </Link>
  );
};

export default SidebarButton;

// Tabs.js
import React, { useState } from 'react';

import { HeaderComponent } from '../OrganizationPageGroupComponents/HeaderComponent';
import { TabsData } from '../../@types/ITabTypes';


const Tabs: React.FC<TabsData> = ({ tabsData }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const activeTab = tabsData[activeTabIndex];
    
    return (
        <>
            <div className="flex flex-row w-full h-full space-x-10 text-gray-300 font-semibold pl-10 steps-center justify-initial bg-gray-100">
                {tabsData.map((tab, idx) => (
                    <button
                        key={idx}
                        className={`disabled py-5 transition-colors duration-300 ${idx === activeTabIndex
                            ? ' border-b-2 text-gray-500 border-gray-500'
                            : 'text-gray-300'
                            }`}
                        onClick={() => setActiveTabIndex(idx)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {activeTab.hasHeader && (
                <HeaderComponent
                    icon={activeTab.icon}
                    label={activeTab.label}
                    headerButtons={activeTab.headerButtons}
                />
            )}

            <div className="flex-1">{tabsData[activeTabIndex].content}</div>
        </>
    );
};

export default Tabs;

export const formatDateDDMMYYYY = (day: Date | undefined): string => {
  if (!day) return '';

  const currentDay =
    day.getDate().toString().length < 2
      ? '0'.concat(day.getDate().toString())
      : day.getDate();
  const currentMonth =
    day.getMonth().toString().length < 2
      ? '0'.concat((day.getMonth() + 1).toString())
      : day.getMonth() + 1;

  return `${currentDay}/${currentMonth}/${day.getFullYear()}`;
};

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AboutPage } from '../pages/AboutPage/AboutPage';
import { ContactPage } from '../pages/ContactPage/ContactPage';
import { EmissionsPage } from '../pages/EmissionsPage/EmissionsPage';
import { GeneralVision } from '../pages/GeneralVision/GeneralVision';
import { GenericOrganizationPage } from '../pages/GenericOrganizationPage/GenericOrganizationPage';
import { HomePage } from '../pages/HomePage/HomePage';
import { NotificationsPage } from '../pages/NotificationsPage/NotificationsPage';

import AuthSideBar from '../components/Sidebar/AuthSideBar/AuthSideBar';
import PublicSideBar from '../components/Sidebar/PublicSideBar/PublicSideBar';
import { AdministratorProvider } from '../contexts/AdministratorContext';
import { AuthProvider } from '../contexts/AuthContext';
import { InventoryFormProvider } from '../contexts/InventoryFormContext';
import { NotificationProvider } from '../contexts/NotificationContext';
import { OrganizationProvider } from '../contexts/OrganizationContext';
import { ToastProvider } from '../contexts/ToastContext';
import { TutorialAppProvider } from '../contexts/TutorialAppContext';
import { SireneBaseLayout } from '../layout/SireneBaseLayout';
import { AdministratorsPage } from '../pages/Administrators/AdministratorsPage';
import { InventoryFormPage } from '../pages/InventoryFormPage/InventoryFormPage';
import { InventoryFormReviewPage } from '../pages/InventoryFormReviewPage/InventoryFormReviewPage';
import { InventoryPage } from '../pages/InventoryPage/InventoryPage';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import { OrganizationPage } from '../pages/Organization/OrganizationPage';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { FormDrawProvider } from '../contexts/FormDrawContext';
import { SidebarProvider } from '../contexts/SidebarContext';
import { FormIdProvider } from '../contexts/FormContext';
import { InventoryInfoPage } from '../pages/InventoryInfoPage/InventoryInfoPage';

export type IRoutesProps = { path: string; title: string; defaultIcon: string };

export const PUBLIC_ROUTES = {
  HOME: {
    path: '/',
    title: 'Home',
    defaultIcon: 'home',
  },
  ABOUT: {
    path: '/sobre',
    title: 'Sobre',
    defaultIcon: 'people',
  },
  CONTACT: {
    path: '/contato',
    title: 'Contatos',
    defaultIcon: 'mail',
  },
  INVENTORY_INFO: {
    path: '/inventoryinfo',
    title: 'Enviar inventário',
    defaultIcon: 'folder',
  },
  EMISSIONS: {
    path: '/emissoes',
    title: 'Emissões de GEE',
    defaultIcon: 'bars',
  },
  LOGIN: {
    path: '/login',
    title: 'Login',
    defaultIcon: 'home',
  },
};

export const PRIVATE_ROUTES = {
  NOTIFICATION: {
    path: '/notificacoes',
    title: 'Notificações',
    defaultIcon: 'notification',
    tutorialName: 'button-notifications',
    tutorialBody:
      'Quando existirem notificações não lidas, o sino apresentará um círculo azul.',
  },
  // CONFIG: {
  //   path: '/configuracoes',
  //   title: 'Configurações',
  //   defaultIcon: 'gear',
  // },
  GENERAL_VISION: {
    path: '/visaogeral',
    title: 'Visão Geral',
    defaultIcon: 'bars',
    tutorialName: 'button-general-vision',
    tutorialBody: 'Aqui você pode visualizar um resumo das suas organizações.',
  },
  GENERIC_ORGANIZATION: {
    path: '/:organization',
    title: 'Organização',
    defaultIcon: 'folder',
    tutorialName: 'button-generic-organization',
    tutorialBody: 'Aqui você pode visualizar e configurar sua organização.',
  },
  INVENTORY_FORM: {
    path: '/formularioinventariante',
    title: 'Formulário Inventariante',
    defaultIcon: 'document',
  },
  INVENTORY: {
    path: '/inventarios',
    title: 'Inventários',
    defaultIcon: 'document',
    tutorialName: 'button-inventory',
    tutorialBody: 'Aqui você pode visualizar e revisar inventários recebidos.',
  },
  FORM_REVIEW: {
    path: '/revisaoformulario',
    title: 'Formulário inventariante',
    defaultIcon: 'document-edit',
    tutorialName: 'button-inventory',
    tutorialBody:
      'Aqui você pode visualizar, revisar e aprovar inventários recebidos.',
  },
  ORGANIZATION: {
    path: '/organizacoes',
    title: 'Organizações',
    defaultIcon: 'folder',
    tutorialName: 'button-organization',
    tutorialBody:
      'Aqui você pode visualizar as organizações cadastradas na plataforma.',
  },
  ADMINISTRATORS: {
    path: '/administradores',
    title: 'Administradores',
    defaultIcon: 'people',
    tutorialName: 'button-administrators',
    tutorialBody: 'Aqui você pode visualizar e adicionar administradores.',
  },
};

export const ROUTES = {
  ...PUBLIC_ROUTES,
  ...PRIVATE_ROUTES,
};

export function SireneAppRoutes() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <SidebarProvider>
            <TutorialAppProvider>
              {/* contexto para o funcionamento de tutorial para primeiro acesso */}
              <ToastProvider>
                <OrganizationProvider>
                  <NotificationProvider>
                    <AdministratorProvider>
                      <InventoryFormProvider>
                        <FormDrawProvider>
                          <FormIdProvider>
                            <Routes>
                              <Route
                                element={
                                  <SireneBaseLayout
                                    sideBarComponent={<PublicSideBar />}
                                  />
                                }
                              >
                                <Route
                                  path={PUBLIC_ROUTES.HOME.path}
                                  element={<HomePage />}
                                />
                                <Route
                                  path={PUBLIC_ROUTES.ABOUT.path}
                                  element={<AboutPage />}
                                />
                                <Route
                                  path={PUBLIC_ROUTES.EMISSIONS.path}
                                  element={<EmissionsPage />}
                                />
                                <Route
                                  path={PUBLIC_ROUTES.INVENTORY_INFO.path}
                                  element={<InventoryInfoPage />}
                                />
                                <Route
                                  path={PUBLIC_ROUTES.CONTACT.path}
                                  element={<ContactPage />}
                                />
                              </Route>

                              <Route
                                element={
                                  <Authenticator className={`h-screen`}>
                                    <SireneBaseLayout
                                      sideBarComponent={<AuthSideBar />}
                                    />
                                  </Authenticator>
                                }
                              >
                                <Route
                                  path={PUBLIC_ROUTES.LOGIN.path}
                                  element={<LoginPage />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.GENERAL_VISION.path}
                                  element={<GeneralVision />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.NOTIFICATION.path}
                                  element={<NotificationsPage />}
                                />
                                {/* <Route
                              path={PRIVATE_ROUTES.CONFIG.path}
                              element={<ConfigPage />}
                            /> */}
                                <Route
                                  path={
                                    PRIVATE_ROUTES.GENERIC_ORGANIZATION.path
                                  }
                                  element={<GenericOrganizationPage />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.INVENTORY_FORM.path}
                                  element={<InventoryFormPage />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.INVENTORY.path}
                                  element={<InventoryPage />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.FORM_REVIEW.path}
                                  element={<InventoryFormReviewPage />}
                                />
                                <Route
                                  path={PRIVATE_ROUTES.ORGANIZATION.path}
                                  element={<OrganizationPage />}
                                />

                                <Route
                                  path={PRIVATE_ROUTES.ADMINISTRATORS.path}
                                  element={<AdministratorsPage />}
                                />
                              </Route>
                            </Routes>
                          </FormIdProvider>
                        </FormDrawProvider>
                      </InventoryFormProvider>
                    </AdministratorProvider>
                  </NotificationProvider>
                </OrganizationProvider>
              </ToastProvider>
            </TutorialAppProvider>
          </SidebarProvider>
          {/* contexto para o funcionamento de tutorial para primeiro acesso */}
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

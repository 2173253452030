import { useContext, useEffect, useState } from 'react';
import { AuthContext, PerfilType } from '../../../contexts/AuthContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { useSidebar } from '../../../contexts/SidebarContext';
import { ToastContext } from '../../../contexts/ToastContext';
import {
  TutorialAppContext,
  TutorialType,
} from '../../../contexts/TutorialAppContext';
import { ROUTES } from '../../../routes/Index.routes';
import { Popover } from '../../Popover/Popover';
import Profile from '../../Profile/Profile';
import SidebarButton from '../SidebarButton/SidebarButton';

export default function AuthSideBar() {
  const { session, logOut } = useContext(AuthContext);
  const { setSidebarOpen } = useSidebar();
  const { nextTutorial, updateTutorialAlreadySeen } =
    useContext(TutorialAppContext);
  const { triggerToast } = useContext(ToastContext);
  const { setSelectedOrgId, selectedOrgId } = useContext(OrganizationContext);
  const { newNotifications } = useContext(NotificationContext);

  const [userOrgs, setUserOrgs] = useState<
    {
      id: string;
      name: string;
      org: string;
    }[]
  >([]);

  const handleTutorialEnd = async () => {
    nextTutorial();
    tutorialType === 'firstOrg' && triggerToast();
    updateTutorialAlreadySeen(tutorialType);
  };

  useEffect(() => {
    if (session?.organizations && session?.organizations.length > 0) {
      session?.organizations.forEach((orgUser: any) => {
        setUserOrgs((prevState) => {
          if (!prevState.some((f) => f.id === orgUser.id))
            return [
              ...prevState,
              { id: orgUser.id, name: orgUser.nickName, org: orgUser.nickName },
            ];
          return prevState;
        });
      });
    }
  }, [session?.organizations]);

  let sidebarRoutes = [ROUTES.GENERAL_VISION];
  let tutorialType: TutorialType;
  let contLevelOrder = 0;

  switch (session?.role) {
    case PerfilType.COMPANY_MEMBER:
      sidebarRoutes = [ROUTES.GENERAL_VISION, ROUTES.GENERIC_ORGANIZATION];
      tutorialType = 'firstOrg';
      contLevelOrder = 1;
      break;
    case PerfilType.ADMIN:
      sidebarRoutes = [
        ROUTES.GENERAL_VISION,
        ROUTES.INVENTORY,
        ROUTES.ORGANIZATION,
        ROUTES.ADMINISTRATORS,
      ];
      tutorialType = 'firstAccess';
      contLevelOrder = 0;
      break;
    default:
      tutorialType = 'firstOrg';
      contLevelOrder = 1;
      break;
  }

  return (
    <div className="flex-1 p-10 flex flex-col">
      <section className="flex-1 space-y-3 flex flex-col items-center">
        {sidebarRoutes.map((route) => {
          ++contLevelOrder;
          if (route == ROUTES.GENERIC_ORGANIZATION) {
            return userOrgs.map((organization) => (
              <Popover
                orientation="right-full-bottom"
                componentName={`${route.tutorialName}-${organization.name}`}
                width="w-[340px]"
                levelOrder={contLevelOrder}
                exactPath={ROUTES.GENERAL_VISION.path}
                tutorialType={tutorialType}
                content={{
                  title: route.title,
                  body: route.tutorialBody,
                  button: 'Continuar',
                }}
                key={organization.id}
              >
                <SidebarButton
                  to={`/${(organization.org ?? '')
                    .replaceAll(' ', '-')
                    .toLowerCase()}`}
                  icon={route.defaultIcon}
                  label={organization.org}
                  isSelected={selectedOrgId === organization.id}
                  onClick={() => {
                    setSelectedOrgId(organization.id);
                    setSidebarOpen(false);
                  }}
                />
              </Popover>
            ));
          }

          return (
            <Popover
              orientation="right-full-bottom"
              componentName={route.tutorialName}
              width="w-[340px]"
              levelOrder={contLevelOrder}
              exactPath={ROUTES.GENERAL_VISION.path}
              tutorialType={tutorialType}
              content={{
                title: route.title,
                body: route.tutorialBody,
                button: 'Continuar',
              }}
              key={route.path}
            >
              <SidebarButton
                icon={route.defaultIcon}
                label={route.title}
                to={route.path}
                isSelected={false}
                onClick={() => {
                  setSelectedOrgId('');
                  setSidebarOpen(false);
                }}
              />
            </Popover>
          );
        })}
      </section>
      <section className="space-y-3 flex flex-col items-center">
        <Popover
          orientation="right-full-top"
          componentName={ROUTES.NOTIFICATION.tutorialName}
          width="w-[340px]"
          levelOrder={++contLevelOrder}
          exactPath={ROUTES.GENERAL_VISION.path}
          tutorialType={tutorialType}
          content={{
            title: ROUTES.NOTIFICATION.title,
            body: ROUTES.NOTIFICATION.tutorialBody,
            button: 'Continuar',
          }}
          onClickContinueButton={() => handleTutorialEnd()}
        >
          <SidebarButton
            icon={ROUTES.NOTIFICATION.defaultIcon}
            label={ROUTES.NOTIFICATION.title}
            to={ROUTES.NOTIFICATION.path}
            isSelected={false}
            hasNotification={newNotifications}
            onClick={() => {
              setSelectedOrgId('');
              setSidebarOpen(false);
            }}
          />
        </Popover>

        {/* <SidebarButton
          icon={ROUTES.CONFIG.defaultIcon}
          label={ROUTES.CONFIG.title}
          to={ROUTES.CONFIG.path}
          isSelected={false}
          onClick={() => setSelectedOrgId('')}
        /> */}
        {session !== null && (
          <Profile
            name={session.name || ''}
            email={session.email || ''}
            onClick={() => logOut()}
          />
        )}
      </section>
    </div>
  );
}

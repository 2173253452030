import { InputHTMLAttributes } from 'react';
import Icon from '../Icon/Icon';
import InputMask from 'react-input-mask';
import { InputMessage } from '../InputMessage/InputMessage';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  mask?: 'cnpj';
  state?: IStateClasses;
  errorLabel?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  value,
  onChange,
  mask,
  state,
  errorLabel,
  ...rest
}) => {
  const searchInputClass = `
    w-full 
    text-black-100 
    text-sm 
    placeholder:text-gray-400 
    font-semibold 
    outline-none 
    border-0 
    focus:outline-none 
    focus:border-0 
    focus:shadow-tansparent 
    focus:shadow-none 
    focus-visible:outline-none 
    group-hover:bg-gray-100
  `;

  return (
    <>
      <div className="w-full h-[50px] p-5 border-2 border-solid border-gray-200 rounded-3xl flex items-center gap-2.5 group hover:bg-gray-100">
        <Icon name="search-gray-400" />
        {mask !== 'cnpj' ? (
          <input
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={searchInputClass}
            {...rest}
          />
        ) : (
          <InputMask
            mask={'99.999.999/9999-99'}
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={searchInputClass}
            //@ts-ignore
            maskChar={null}
            {...rest}
          />
        )}
      </div>

      {state && (
        <div className="mt-2.5 w-72">
          <InputMessage state={state} label={errorLabel} />
        </div>
      )}
    </>
  );
};

import moment from 'moment';

import { IUserorganization } from '../../services/organizationService';
import { LabelFunction } from '../LabelFunction/LabelFunction';
import { CheckBoxHeaderTable } from './CheckBoxHeaderTable';
import { BaseTable } from '../Table/BaseTable/BaseTable';
import { CheckBoxBodyTable } from './CheckBoxBodyTable';
import { RowCell } from '../Table/RowCell/RowCell';
import { RowBody } from '../Table/RowBody/RowBody';
import Label from '../Label/Label';
import Icon from '../Icon/Icon';
import ListOption from '../ListOption/ListOption';
import { useContext, useState } from 'react';
import Button from '../Button/Button';
import ModalAlert from '../Modal/ModalAlert';
import { userService } from '../../services/userService';
import { AuthContext } from '../../contexts/AuthContext';

export function ListUserOrganization({
  users,
  updateUsers,
}: {
  users: IPagination<IUserorganization> | null;
  updateUsers: () => void;
}) {
  const [selectedItem, setSelectedItem] = useState<IUserorganization | null>(
    null,
  );

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const { session } = useContext(AuthContext);

  const handleClick = (itemId: IUserorganization) => {
    setSelectedItem(itemId === selectedItem ? null : itemId);
  };

  const deleteInvite = (id: string) => {
    userService.deleteInviteUser(id).then(() => {
      setShowConfirmationModal(false);
      updateUsers();
    });
  };

  const deleteUser = (id: string, companyId: string) => {
    userService.deleteUserCompany(id, companyId).then(() => {
      setShowConfirmationModal(false);
      updateUsers();
    });
  };

  return (
    <>
      <BaseTable
        headerTitlesComponents={[
          <CheckBoxHeaderTable />,
          'Organização',
          'Adicionado',
          'Função',
        ]}
        childrensLength={users?.content.length ?? 0}
      >
        {users?.content.map((user) => {
          const pendingInvite = user.userId === null;
          return (
            <RowBody key={user.userId ?? user.name}>
              <RowCell>
                <CheckBoxBodyTable
                  name={user.name}
                  email={pendingInvite ? '' : user.email}
                  pendingInvite={pendingInvite}
                />
              </RowCell>
              <RowCell>
                <Label className="!whitespace-nowrap !font-normal">
                  {user.nickName}
                </Label>
              </RowCell>
              <RowCell>
                <Label
                  className={
                    !pendingInvite
                      ? '!whitespace-nowrap !font-normal'
                      : 'hidden'
                  }
                >
                  {user.createdDate &&
                    moment(user.createdDate).format('DD/MM/YYYY')}
                </Label>
              </RowCell>
              <RowCell>
                <div className="flex items-end justify-between h-10 w-[150px]">
                  <LabelFunction type={!pendingInvite ? user.type : ''} />

                  {session?.id !== user.userId && (
                    <div className="flex items-center space-x-4">
                      <button
                        className="h-full"
                        onClick={() => handleClick(user)}
                      >
                        <Icon name="vertical-dot-gray-400" />
                      </button>

                      <ListOption
                        visible={
                          pendingInvite
                            ? user.inviteId === selectedItem?.inviteId
                            : user.userId === selectedItem?.userId
                        }
                        onClose={() => setSelectedItem(null)}
                      >
                        {pendingInvite ? (
                          <button
                            className="p-5 font-bold text-left justify-start"
                            onClick={() => {
                              setShowConfirmationModal(true);
                            }}
                          >
                            Cancelar convite
                          </button>
                        ) : (
                          <div className="flex flex-col gap-5 p-5">
                            <button
                              className="font-bold text-left justify-start"
                              onClick={() => {}}
                            >
                              Editar
                            </button>

                            {session?.organizations.find(
                              (org) => org.id === user.companyId,
                            )?.companyUserType === 'REPRESENTATIVE' && (
                              <button
                                className=" font-bold text-left justify-start"
                                onClick={() => {
                                  setShowConfirmationModal(true);
                                }}
                              >
                                Remover
                              </button>
                            )}
                          </div>
                        )}
                      </ListOption>
                    </div>
                  )}
                </div>
              </RowCell>
            </RowBody>
          );
        })}
      </BaseTable>

      <ModalAlert visible={showConfirmationModal}>
        <section className="w-[610px] p-10 flex flex-col items-center">
          <button
            className="absolute top-[19px] right-[19px] border-none bg-none"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
          >
            <Icon name="close-blue-200" size="sm" />
          </button>
          <Label size="md" className="font-bold">
            {selectedItem?.userId === null
              ? `Tem certeza que deseja cancelar o convite de ${selectedItem?.email}?`
              : `Tem certeza que deseja remover o usuário ${selectedItem?.name}?`}
          </Label>

          <div className="w-65 pt-8">
            <Button
              style="filled"
              label="Confirmar"
              onClick={() => {
                if (selectedItem?.userId === null) {
                  deleteInvite(selectedItem?.inviteId ?? '');
                } else {
                  deleteUser(
                    selectedItem?.userId ?? '',
                    selectedItem?.companyId ?? '',
                  );
                }
              }}
            />
          </div>
        </section>
      </ModalAlert>
    </>
  );
}

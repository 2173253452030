import React from 'react';
import Icon from '../Icon/Icon';

interface ModalProps {
  visible: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  hasButtonClose?: boolean;
}

const ModalAlert: React.FC<ModalProps> = ({
  visible,
  onClose,
  children,
  hasButtonClose,
}) => {
  return (
    <>
      {visible && (
        <>
          <span className="w-full h-screen bg-gray-400 z-40 fixed inset-0 opacity-50" />

          <section className="fixed inset-0 flex items-center z-50 justify-center w-full h-screen">
            <div className="w-auto h-auto relative bg-white-100 rounded-[1.25rem] overflow-hidden">
              {hasButtonClose && (
                <button
                  onClick={onClose}
                  className="absolute top-[19px] right-[19px] border-none bg-none z-50"
                >
                  <Icon name="close-blue-200" size="sm" />
                </button>
              )}
              {children}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ModalAlert;

import React, { useState } from 'react';
import Icon from '../Icon/Icon';

const TooltipIcon: React.FC<{ text: string }> = ({ text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="relative inline-block top-1.5 left-1"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <button className="text-gray-600 hover:text-blue-500 focus:outline-none space-x-2">
        <Icon name={'info-blue-300'} size="xm" />
      </button>
      {showTooltip && (
        <div className="absolute top-0 left-[10.5] transform ml-6 -translate-x-1/2 w-1 p-2rounded-lg shadow-lg whitespace-nowrap">
          {text}
        </div>
      )}
    </div>
  );
};

export default TooltipIcon;

import { useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import Label from '../Label/Label';

export function CheckBoxHeaderTable({
  titleLabel = 'Nome',
}: {
  titleLabel?: String;
}) {
  const [selected, setSelected] = useState<boolean>(false);
  return (
    <div className="flex items-center justify-start gap-4">
      {/* <Checkbox
        isChecked={selected}
        handleChange={() => setSelected((selected) => !selected)}
      /> */}
      <Label className="!font-bold">{titleLabel}</Label>
    </div>
  );
}

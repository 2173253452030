import React from 'react';

interface CheckboxProps {
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  isChecked,
  handleChange,
  hasError = false,
}) => {
  function checkboxState(): string {
    if (isChecked)
      return 'checked:bg-white-100 checked:border-blue-200 checked:text-blue-warm-100 checked:hover:border-blue-200 bg-no-repeat bg-auto bg-center hover:bg-blue-warm-100';

    if (hasError) return 'border-red-200 bg-white-100';

    return 'bg-white-100 border-gray-200 hover:bg-gray-100';
  }

  return (
    <div
      className={`cursor-pointer`}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="checkbox"
        className={`appearance-none h-6 w-6 rounded-[0.375rem] border-2 focus-within:none ${checkboxState()}`}
        checked={isChecked}
        onChange={handleChange}
      />
    </div>
  );
};

export default Checkbox;

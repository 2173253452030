import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  BarElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
);

interface VerticalChartProps {
  payload: IGraphicPayload;
}

const colors = [
  '#408BF7',
  '#D3B53B',
  '#D77345',
  '#162298',
  '#377076',
  '#A74AC7',
  '#4CAF50',
  '#FFC107',
  '#FF5722',
  '#795548',
  '#2196F3',
  '#FF9800',
  '#E91E63',
  '#9C27B0',
  '#00BCD4',
  '#607D8B',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#673AB7',
  '#009688',
  '#F44336',
  '#03A9F4',
  '#795548',
  '#FFEB3B',
  '#4CAF50',
  '#9E9E9E',
  '#795548',
  '#607D8B',
];

const VerticalChart: React.FC<VerticalChartProps> = (payload) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const getOrCreateLegendList = (
    _chart: {
      options: {
        plugins: { legend: { labels: { generateLabels: (arg0: any) => any } } };
      };
      config: { type: any };
      toggleDataVisibility: (arg0: any) => void;
      setDatasetVisibility: (arg0: any, arg1: boolean) => void;
      isDatasetVisible: (arg0: any) => any;
      update: () => void;
    },
    id: string,
  ) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector('.legend-list');

    if (!listContainer) {
      listContainer = document.createElement('div');
      listContainer.className = 'legend-list overflow-x-auto';

      legendContainer?.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(
      chart: {
        options: any;
        config: any;
        toggleDataVisibility: any;
        setDatasetVisibility: any;
        isDatasetVisible: any;
        update: any;
      },
      args: any,
      options: { containerID: string },
    ) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach(
        (item: {
          index: any;
          datasetIndex: any;
          fillStyle: string;
          strokeStyle: string;
          lineWidth: any;
          fontColor: string;
          hidden: any;
          text: string;
        }) => {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '10px';

          li.onclick = () => {
            const { type } = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              chart.toggleDataVisibility(item.index);
            } else {
              chart.setDatasetVisibility(
                item.datasetIndex,
                !chart.isDatasetVisible(item.datasetIndex),
              );
            }
            chart.update();
          };

          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = String(item.lineWidth) + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '20px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '20px';

          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.style.fontSize = '14px';
          textContainer.style.margin = '0';
          textContainer.style.padding = '6px';
          textContainer.style.textDecoration = item.hidden
            ? 'line-through'
            : '';

          const text = document.createTextNode(
            item.text.length > 40 ? item.text.slice(0, 55) + '...' : item.text,
          );
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        },
      );
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: 'Bar Chart',
      },
    },
    scales: {
      x: {
        stacked: payload.payload.stacked,
      },
      y: {
        stacked: payload.payload.stacked,
      },
    },
  };

  const optionsMobile = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      htmlLegend: {
        containerID: 'legend-container',
      },
      title: {
        display: true,
        text: 'Bar Chart',
      },
    },
    scales: {
      x: {
        stacked: payload.payload.stacked,
      },
      y: {
        stacked: payload.payload.stacked,
      },
    },
  };

  const addBackgroundColorToDatasets = (
    graphicPayload: { datasets: any[] },
    colors: string | any[],
  ) => {
    if (
      !graphicPayload ||
      !graphicPayload.datasets ||
      !Array.isArray(graphicPayload.datasets)
    ) {
      throw new Error('Invalid graphic payload');
    }

    const modifiedDatasets = graphicPayload.datasets.map((dataset, index) => ({
      ...dataset,
      backgroundColor: colors[index % colors.length],
    }));

    return {
      ...graphicPayload,
      datasets: modifiedDatasets,
    };
  };

  const newData = JSON.parse(JSON.stringify(payload.payload));

  newData.datasets.forEach(
    (dataset: { data: any; values: any; label: string }) => {
      dataset.label = dataset.label.includes('ESCOPO')
        ? dataset.label
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase())
        : dataset.label;
    },
  );

  const payloadWithColors = addBackgroundColorToDatasets(newData, colors);

  return (
    <>
      <article
        style={{
          height: isMobile ? '60vh' : '60vh',
          width: isMobile ? '90vw' : '70vw',
          position: 'relative',
          paddingBottom: 60,
        }}
      >
        <Bar
          height={100}
          width={300}
          options={isMobile ? optionsMobile : options}
          data={payloadWithColors}
          plugins={[htmlLegendPlugin]}
        />
      </article>
      {isMobile && (
        <div
          className="w-full flex overflow-y-auto overflow-x-auto justify-center mb-10"
          id="legend-container"
        />
      )}
    </>
  );
};

export default VerticalChart;

import Label from '../../components/Label/Label';
import Logo from '../../components/Logo/Logo';

interface IAboutPageProps {}

export const AboutPage: React.FC<IAboutPageProps> = () => {
  return (
    <div className="p-10">
      <div className="flex mb-10 justify-center">
        <Logo width={'457.212'} height={'150'} />
      </div>
      <Label className="text-black-200 text-justify !font-normal">
        <strong>SIRENE Organizacionais</strong> <br></br>
        <strong>
          <i>
            Transparência e sustentabilidade para as organizações brasileiras
          </i>
        </strong>
        <br /> <br />
        <p>
          <strong>O que é o SIRENE Organizacionais?</strong>
        </p>
        <br />
        <p>
          O SIRENE Organizacionais é uma plataforma pública e gratuita,
          coordenada pelo Ministério da Ciência, Tecnologia e Inovação (MCTI) e
          desenvolvida com a finalidade dar visibilidade e transparência para os
          dados de inventários de emissões e remoções de gases de efeito estufa
          (GEE) das organizações em geral.
        </p>
        <br />
        <p>
          <strong>Como surgiu o SIRENE Organizacionais?</strong>
        </p>
        <br />
        <p>
          A plataforma faz parte do Sistema de Registro Nacional de Emissões
          (SIRENE), instituído pelo{' '}
          <span className="italic text-blue-200">
            <a
              href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2017/decreto/d9172.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Decreto nº 9.172/2017
            </a>
          </span>
          , com o objetivo de disponibilizar os resultados de inventários de
          emissões e remoções, incluindo o registro voluntário de inventários
          organizacionais. O módulo SIRENE Organizacionais foi construído em um
          processo colaborativo com as diversas instituições públicas e
          privadas.
        </p>
        <br />
        <p>
          Desde 2018, o Grupo de Trabalho sobre Inventários Organizacionais de
          Emissões de GEE, no âmbito do{' '}
          <u>Comitê Técnico da Indústria de Baixo Carbono (CTBIC)</u>, conduziu
          esforços de modo a planejar e desenvolver uma plataforma que
          considerasse o contexto nacional e as práticas já realizadas no
          Brasil, com intuito de aproveitar a experiencia das organizações no
          que se refere ao relato de emissões de GEE.
        </p>
        <br />
        <p>
          A elaboração da plataforma contou com apoio do Programa de políticas
          sobre Mudanças do Clima (PoMuC), que é uma parceria entre o Ministério
          do Meio Ambiente (MMA), Ministério da Fazenda (MF) e o Ministério
          Federal da Economia e Ação Climática (BMWK) da Alemanha como parte da
          Iniciativa Internacional para o Clima (IKI). É implementado pela
          Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH no
          contexto da Cooperação Brasil-Alemanha para o Desenvolvimento
          Sustentável.
        </p>
        <br />
        <p>
          <strong>Qual a importância do SIRENE Organizacionais?</strong>
        </p>
        <br />
        <p>
          O SIRENE Organizacionais é uma iniciativa pioneira do governo
          brasileiro dedicada a fortalecer a transparência e a sustentabilidade
          das organizações do país, no que se refere a elaboração e divulgação
          das informações de inventários de emissões de GEE
        </p>
        <br />
        <p>
          Essa iniciativa estabelece alguns requisitos padronizados para a
          contabilização de emissões de gases de efeito estufa (GEE) em todo o
          país, garantindo a segurança e robustez dos dados; estimula a gestão e
          a conscientização das organizações sobre a coleta de dados,
          preparando-as para mercados e cenários de comércio de carbono; motiva
          o setor produtivo brasileiro a reduzir as emissões de GEE,
          contribuindo para que o Brasil alcance suas metas da Contribuição
          Nacionalmente Determinada (NDC); além de que os dados coletados podem
          servir como base para melhorias contínuas no Inventário Nacional de
          Emissões de GEE, proporcionando informações essenciais para aprimorar
          as políticas de mitigação no país.
        </p>
        <br />
        <p>
          A plataforma foi construída de forma flexível e modular, para ser
          rapidamente adaptada para atender futuras necessidades sobre as
          regulamentações do tema, seja pela inclusão de novos questionamentos
          que deverão ser respondidos pelas organizações, seja pela indicação de
          novas metodologias para contabilização de emissões ou arquivos que
          comprovem a integridade e confiabilidade dos cálculos.
        </p>
        <br />
      </Label>
    </div>
  );
};

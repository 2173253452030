import { useContext, useEffect, useState } from 'react';
import { ListAdministrators } from '../../components/ListAdministrators/ListAdministrators';
import { userService } from '../../services/userService';
import { AdministratorContext } from '../../contexts/AdministratorContext';

interface IAdministratorsPageProps {}

export const AdministratorsPage: React.FC<IAdministratorsPageProps> = () => {
  const { listAdministrator, listAdminUsers } =
    useContext(AdministratorContext);

  useEffect(() => {
    listAdminUsers(0);
  }, []);

  return (
    <>
      <ListAdministrators
        admins={listAdministrator}
        adminUsers={listAdminUsers}
      />
    </>
  );
};

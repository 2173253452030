import { ReactNode, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '../components/Header/Header';
import BaseSideBar from '../components/Sidebar/Base/BaseSideBar';
import { Toast } from '../components/Toast/Toast';
import { useSidebar } from '../contexts/SidebarContext';
import { ToastContext } from '../contexts/ToastContext';

type IAuthLayoutProps = {
  sideBarComponent: ReactNode;
};

export function SireneBaseLayout({ sideBarComponent }: IAuthLayoutProps) {
  const { showToast, title, label, state, setShowToast } =
    useContext(ToastContext);

  const { sidebarOpen } = useSidebar();

  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    if (showToast === true) {
      setShowToast(false);
      setToastVisible(true);
    }
  }, [showToast]);

  const asideStyle = sidebarOpen
    ? 'md:block w-full h-full  text-gray-100'
    : 'md:block hidden w-[340px] h-full  text-gray-100';

  return (
    <>
      <div className="font-body flex h-screen w-full 2xl:mx-auto border-x border-gray-100">
        <aside className={asideStyle}>
          <BaseSideBar>{sideBarComponent}</BaseSideBar>
        </aside> 

        <main className="flex-1 flex items-start justify-start overflow-hidden">
          <div className="flex-1 w-full h-full">
            <div className="px-4">
              <Header />
            </div>
            <div className="border-solid border-b text-gray-100 w-full"></div>
            <div className="overflow-y-auto max-h-[calc(100%-128px)]">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
      {toastVisible && (
        <div className="absolute w-full pt-10 px-10 top-0 left-0">
          <Toast
            onClose={() => {
              setToastVisible(false);
            }}
            state={state}
            title={title}
            label={label}
          />
        </div>
      )}
    </>
  );
}

import { PieChart, Pie, Cell } from 'recharts';
import Label from '../Label/Label';
import ButtonCircle from '../Button/ButtonCircle';
import ListOption from '../ListOption/ListOption';
import { useEffect, useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';

export interface IGraphicPieData {
    name: string;
    value: number;
    color: string;
    legend: string;
    isChecked?: boolean;
}

type labelText = {
    singular: string;
    plural: string;
};

interface IGraphicPieProps {
    labelText: labelText;
    data: IGraphicPieData[];
}

export function GraphicPie({ labelText, data }: IGraphicPieProps) {
    const [visibleOption, setVisibleOption] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<IGraphicPieData[]>(data);

    const checkedData = currentData?.filter((item) => item.isChecked);
    const labelNumber =
        checkedData?.reduce((total, data) => total + data.value, 0) ?? 0;

    const handleCheckboxChange =
        (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentData((prevData) => {
                return prevData?.map((item) => {
                    if (item.name === key) {
                        item.isChecked = event.target.checked;
                    }
                    return item;
                });
            });
        };

    useEffect(() => {
        setCurrentData(data);
    }, [data]);

    return (
        <>
            <section className="flex-1">
                <div className="w-auto border border-gray-200 rounded-md flex flex-col items-center justify-center py-6">
                    <section className="relative w-min h-min">
                        <div className="absolute ml-10 md:ml-28 left-40">
                            <ButtonCircle
                                style="outlined"
                                icon="filter"
                                disabled={data.length === 0}
                                onClick={() => {
                                    setVisibleOption(!visibleOption);
                                }}
                            />
                            <div className="p-2">
                                {data.length !== 0 && (
                                    <ListOption visible={visibleOption}
                                        onClose={() => setVisibleOption(!visibleOption)}>
                                        {currentData?.map((option) => {
                                            return (
                                                <div className="flex flex-row gap-3 p-3">
                                                    <Checkbox
                                                        handleChange={handleCheckboxChange(option.name)}
                                                        isChecked={option.isChecked ?? true}
                                                    />
                                                    <Label color="font-bold">{option.legend}</Label>
                                                </div>
                                            );
                                        })}
                                    </ListOption>
                                )}
                            </div>
                        </div>
                        <PieChart width={220} height={220}>
                            <Pie
                                data={checkedData}
                                innerRadius={70}
                                outerRadius={100}
                                dataKey="value"
                            >
                                {checkedData?.map((entry) => (
                                    <Cell key={`cell-${entry.name}`} fill={entry.color} />
                                ))}
                            </Pie>
                        </PieChart>
                        <div className="w-full h-full absolute top-0 left-0 bg-transparent flex flex-col items-center justify-center">
                            <Label color="text-black-100" className=" text-xl !font-bold">
                                {labelNumber}
                            </Label>
                            <Label color="text-gray-400" className="text-sm">
                                {labelNumber === 1 ? labelText.singular : labelText.plural}
                            </Label>
                        </div>
                    </section>
                    <div className="w-full border-b border-gray-200 mt-2 mb-6" />
                    <section className="flex flex-row items-center justify-between gap-4">
                        {checkedData?.map((item) => {
                            return (
                                <div className="flex items-center space-x-1">
                                    <div
                                        style={{ backgroundColor: `${item.color}` }}
                                        className={`w-[14px] h-[14px] mr-1`}
                                    ></div>
                                    <Label>{item.legend}</Label>
                                </div>
                            );
                        })}
                    </section>
                </div>
            </section>
        </>
    );
}

import React, { InputHTMLAttributes, useState, useRef, ChangeEvent } from 'react';
import Icon from '../Icon/Icon';
import { InputMessage } from '../InputMessage/InputMessage';

interface UploadComponentProps extends InputHTMLAttributes<HTMLInputElement> {
    state?: IStateClasses;
    types?: string[];
    registerInput?: any;
    isValid?: boolean;
    errorLabel?: string;
    size?: number | undefined;
    reviewLabel?: string;
    onFileChange?: (file: File | null) => void;
    placeholder?: string;
}
interface ExtensionMap {
    [key: string]: string;
}

export const UploadComponent: React.FC<UploadComponentProps> = ({
    state,
    types = 'xlsx',
    placeholder,
    value,
    disabled,
    registerInput,
    isValid,
    errorLabel,
    size,
    reviewLabel,
    onFileChange,
    ...rest
}) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [typeError, setTypeError] = useState('');

    const extensionMapExcel: ExtensionMap = {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    };

    const extensionMapPdf: ExtensionMap = {
        'application/pdf': 'pdf',
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        let fileTypes = types;
        const file = event.target.files && event.target.files[0];
        setSelectedFile(file);

        if (file) {
            if (fileTypes?.includes('xlsx')) {
                const fileExtension = extensionMapExcel[file.type] || '';
                if (fileTypes?.includes(fileExtension)) {
                    setTypeError('');
    
                    if (onFileChange) {
                        onFileChange(file);
                    }
                } else {
                    setTypeError('Por favor, selecione o formato correto.');
                }
            } else if (fileTypes?.includes('pdf')) {
                const  fileExtension = extensionMapPdf[file.type] || '';
                if (fileTypes?.includes(fileExtension)) {
                    setTypeError('');
    
                    if (onFileChange) {
                        onFileChange(file);
                    }
                } else {
                    setTypeError('Por favor, selecione o formato correto.');
                }
            }
        }
    };


    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <div className="flex fle-row items-center border border-dashed border-blue-200 rounded-sm h-11 hover:bg-gray-200 cursor-pointer" onClick={handleButtonClick}>
                <div className='ml-4'>
                    <Icon name='upload-file-blue-200'></Icon>
                </div>
                <input
                    type="file"
                    accept=''
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    hidden
                    {...rest}
                />
                <label className="text-blue-200 italic ml-4">
                    {selectedFile ? selectedFile.name : placeholder}
                </label>
            </div>
            {typeError && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state="warning" label={typeError} />{' '}
                    </div>
                </div>
            )}
            {reviewLabel && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state="warning" label={reviewLabel} />{' '}
                    </div>
                </div>
            )}
            {state === 'danger' && errorLabel && (
                <div className="mt-2.5 w-fit">
                    <div className="mb-2.5">
                        <InputMessage state={state} label={errorLabel} />{' '}
                    </div>
                </div>
            )}
        </>
    );
};

export default UploadComponent;

import React, { createContext, useEffect, useState } from 'react';

interface FormIdContextProps {
    id: string;
    setIdValue: (newFormId: string) => void;
    setJson: React.Dispatch<React.SetStateAction<IFormJSON | null>>;
    json: IFormJSON | null | undefined;
}

interface FormIdProviderProps {
    children: React.ReactNode;
}

const initialForm: IFormJSON | null = null;

export const FormIdContext = createContext<FormIdContextProps>(
    {} as FormIdContextProps
);

export const FormIdProvider: React.FC<FormIdProviderProps> = ({
    children,
}) => {
    const [id, setFormId] = useState('');
    const [json, setJson] = useState<IFormJSON | null>(initialForm);

    const setIdValue = (newFormId: string) => {
        setFormId(newFormId);
    };

    useEffect(() => {
        if (json !== null && json !== undefined) {
            setJson(json);
        }
    }, [json])

    return (
        <FormIdContext.Provider value={{ id, setIdValue, json, setJson }}>
            {children}
        </FormIdContext.Provider>
    );
};


import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import Label from '../../components/Label/Label';
import ModalAlert from '../../components/Modal/ModalAlert';
import { AuthContext, PerfilType } from '../../contexts/AuthContext';
import { TutorialAppContext } from '../../contexts/TutorialAppContext';
import { userService } from '../../services/userService';
import { unmask } from '../../utils/strings/mask';
import { GeneralVisionAdmin } from './GeneralVisionAdmin';
import { GeneralVisionCompanyMember } from './GeneralVisionCompanyMember';

interface GeneralVisionProps {}
export const GeneralVision: React.FC<GeneralVisionProps> = () => {
  const { session, setSession, logIn } = useContext(AuthContext);
  const { continueTutorial } = useContext(TutorialAppContext);
  const [currentSession, setCurrentSession] = useState<ISession>(session!);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTypeChooser, setShowTypeChooser] = useState(false);
  const [firstAccess, setFirstAccess] = useState<IUser>({
    id: '',
    name: '',
    cpf: '',
    phone: '',
    email: '',
    role: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>();

  const onSubmit: SubmitHandler<IUser> = (data) => {
    setFirstAccess({
      id: '',
      name: data.name,
      role: session?.role!,
      phone: unmask(data.phone),
      email: data.email,
      cpf: session?.cpf!,
    });

    setCurrentSession((prevState) => ({
      ...prevState,
      email: data.email,
      firstAccess: false,
    }));

    if (session?.role !== PerfilType.ADMIN) {
      setShowTypeChooser(true);
    }
  };

  const handleFirstAccessEnd = async () => {
    if (session?.role === '') return;
    await userService
      .createUser({
        cpf: firstAccess.cpf,
        name: firstAccess.name,
        email: firstAccess.email,
        phone: firstAccess.phone,
        role: firstAccess.role,
      })
      .then((response) => {
        setIsModalOpen(false);
        logIn();
        continueTutorial();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRoleSelection = () => {
    setCurrentSession((prevState) => ({
      ...prevState,
      role: PerfilType.COMPANY_MEMBER,
    }));

    setFirstAccess((prevState) => ({
      ...prevState,
      role: PerfilType.COMPANY_MEMBER,
    }));
  };

  useEffect(() => {
    setIsModalOpen(session?.firstAccess!);
  }, []);

  useEffect(() => {
    if (currentSession !== null) setSession(currentSession);
  }, [currentSession]);

  useEffect(() => {
    if (session?.role === PerfilType.ADMIN && firstAccess.cpf !== '') {
      handleFirstAccessEnd();
    }
  }, [firstAccess]);

  return (
    <>
      <ModalAlert visible={isModalOpen}>
        <section className="w-[22rem] md:w-[38.125rem] h-[50rem] md:h-[43.313rem] relative">
          <div className="absolute top-0 left-0 right-0 bottom-0">
            {!showTypeChooser ? (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="pt-10 pl-10 pr-10">
                    <Label size="xl" color="text-black-100">
                      Confira suas informações de contato
                    </Label>
                  </div>
                  <div className="pt-[0.625rem] pl-10 pr-10">
                    <Label color="text-gray-400">
                      Você pode continuar através do botão abaixo.
                    </Label>
                  </div>

                  <div className="h-[0.063rem] mt-10 bg-gray-200"></div>
                  <div className="max-h-[408px] overflow-y-auto">
                    <div className="pt-10 pl-10 pr-10">
                      <Label color="text-gray-400">
                        Você poderá receber notificações da plataforma por
                        e-mail e/ou pelo telefone celular. Se quiser alterá-los,
                        utilize os campos abaixo.
                      </Label>
                    </div>

                    <div className="pt-2.5 px-10">
                      <Label color="text-black-100">Nome completo</Label>
                    </div>
                    <div className="pt-2.5 px-10">
                      <Input
                        placeholder="Digite seu nome completo"
                        registerInput={register('name', {
                          required: true,
                        })}
                        state={errors.name && 'danger'}
                      />
                    </div>
                    <div className="pt-2.5 px-10">
                      <Label color="text-black-100">Telefone de contato</Label>
                    </div>
                    <div className="pt-[0.625rem] px-10">
                      <Input
                        mask="phone"
                        defaultValue={session?.phone!}
                        placeholder="Ex: (11) 9 9999-9999"
                        registerInput={register('phone', {
                          required: true,
                          minLength: 10,
                        })}
                        state={errors.phone && 'danger'}
                      />
                    </div>
                    <div className="pt-2.5 px-10">
                      <Label color="text-black-100">E-mail de contato</Label>
                    </div>
                    <div className="py-[0.625rem] px-10">
                      <Input
                        placeholder="Ex: exemplo@email.com"
                        defaultValue={session?.email!}
                        registerInput={register('email', {
                          required: true,
                          pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                        })}
                        state={errors.email && 'danger'}
                      />
                    </div>
                  </div>

                  <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                  <div className="absolute bottom-10 left-10 right-10 z-10">
                    <Button
                      icon={'arrow-right-button-white-100'}
                      iconPosition="right"
                      label="Continuar"
                      style={'filled'}
                      type="submit"
                    ></Button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div className="pt-10 pl-10 pr-10">
                  <Label size="xl" color="text-black-100">
                    Escolha seu tipo de usuário
                  </Label>
                </div>
                <div className="pt-[0.625rem] pl-10 pr-10">
                  <Label color="text-gray-400">
                    Você pode finalizar e acessar sua plataforma através do
                    botão abaixo.
                  </Label>
                </div>

                <div className="h-[0.063rem] mt-10 bg-gray-200"></div>

                <div className="p-10">
                  <Label color="text-gray-400">
                    Por meio do seu perfil você terá acesso a funcionalidades
                    específicas. Para adicionar ou consultar os inventários de
                    gases de efeito estufa selecione o perfil "Responsável ou
                    relator de Organização Inventariante".
                  </Label>
                </div>
                <div className="pt-[0.625rem] px-10 flex flex-col gap-2.5">
                  <Button
                    style="outlined"
                    label="Especialista convidado"
                    disabled={session?.role === PerfilType.COMPANY_MEMBER}
                  />
                  <Button
                    style="outlined"
                    label="Responsável ou relator de Organização Inventariante"
                    onClick={() => handleRoleSelection()}
                    isSelected={session?.role === PerfilType.COMPANY_MEMBER}
                  />
                </div>

                <div className="absolute bottom-[8.125rem] left-0 right-0 h-[0.063rem] bg-gray-200"></div>

                <div className="absolute bottom-10 left-10 right-10 flex gap-2.5">
                  <Button
                    style="outlined"
                    label="Voltar"
                    icon="arrow-left-blue-200"
                    onClick={() => setShowTypeChooser(false)}
                  />
                  <Button
                    style="filled"
                    label="Finalizar"
                    onClick={() => {
                      handleFirstAccessEnd();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </ModalAlert>

      {session?.role === PerfilType.COMPANY_MEMBER && (
        <GeneralVisionCompanyMember />
      )}
      {session?.role === PerfilType.ADMIN && <GeneralVisionAdmin />}
    </>
  );
};

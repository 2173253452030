import React, { useState, useEffect, useRef } from 'react';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';

interface Option {
  label: string;
  icon: string;
  onClick?: () => void;
}

interface FloatingButtonProps {
  style: 'filled' | 'outlined' | 'none';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isSelected?: boolean;
  options: Option[];
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  style = 'filled',
  disabled = false,
  type = 'button',
  isSelected = false,
  options,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleButtonClick = () => {
    setExpanded(!isExpanded);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const button = buttonRef.current;
    const expandedArea = expandedAreaRef.current;

    if (
      button &&
      expandedArea &&
      !button.contains(event.target as Node) &&
      !expandedArea.contains(event.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const expandedAreaRef = useRef<HTMLDivElement>(null);

  return (
    <div className="fixed bottom-4 right-4">
      <button
        ref={buttonRef}
        type={type}
        onClick={handleButtonClick}
        className={`w-14 h-12 flex flex-row items-center justify-center gap-2.5 rounded-full border-2 ${disabled && 'opacity-50 cursor-not-allowed'
          } ${style === 'filled' || isSelected
            ? `bg-blue-200 border-blue-200 ${!disabled && 'hover:bg-blue-300 hover:border-blue-300'}`
            : `bg-white-100 border-blue-200 ${!disabled && 'hover:bg-blue-warm-100'}`
          }`}
      >
        {isExpanded ? (
          <Icon name='fb-close'></Icon>
        ) : (
          <Icon name='fb-open'></Icon>
        )}
      </button>

      {isExpanded && (
        <div ref={expandedAreaRef} className="fixed bottom-16 right-2 bg-white p-2 rounded shadow-md">
          {options.map((option, index) => (
            <button
              key={index}
              disabled={disabled}
              onClick={option.onClick}
              type={type}
              className={`flex items-center justify-start gap-2.5 rounded-3xl border-2 w-full h-12 mb-2 ${disabled && 'opacity-50 cursor-not-allowed'} 
              ${isSelected ? `bg-blue-warm-100 border-blue-200 ${!disabled && 'hover:none'}` : `bg-white-100 border-blue-200 ${!disabled && 'hover:bg-blue-warm-100'}`}`}
            >
              <div className='p-6 flex flex-row'>
                <Icon margin={false} name={option.icon} size={'sm'}></Icon>
                <Label className='pl-2' color={'text-blue-200'}>
                  {option.label}
                </Label>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FloatingButton;

import { SireneAppRoutes } from './routes/Index.routes';

import './config/awsAmplify/amplify-config'

// import {Amplify} from 'aws-amplify';

// Amplify.configure({
//   Auth: {
//     region: 'sa-east-1',
//     userPoolId: 'sa-east-1_ySBvG6JFF',
//     userPoolWebClientId: '7jgttkkhl2c4g1sn0ngd09j9gk',
//     cookieStorage: {
//       domain: 'localhost',
//       path: '/',
//       expires: 365,
//       secure: false,
//     },
//     authenticationFlowType: 'USER_PASSWORD_AUTH',
//     mandatorySignIn: true,
//   },
// });

function App() {
  return (
    <>
      <SireneAppRoutes />
    </>
  );
}

export default App;

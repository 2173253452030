import React, { useState, InputHTMLAttributes, ChangeEvent } from 'react';

interface AutoCompleteProps extends InputHTMLAttributes<HTMLInputElement> {
  state?: IStateClasses;
  registerInput?: any;
  isValid?: boolean;
  errorLabel?: string;
  size?: number | undefined;
  maxValue?: number | undefined;
  minValue?: number | undefined;
  options: any[];
  updateHasInput: (hasInput: boolean) => void;
  onValueChange: (value: string) => void;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  type = 'text',
  state,
  placeholder,
  onChange,
  value,
  disabled,
  registerInput,
  isValid,
  errorLabel,
  size,
  maxValue,
  minValue,
  options,
  updateHasInput,
  onValueChange,
  ...rest
}) => {
  const [display, setDisplay] = useState(false);
  const [items, setItems] = useState(options);
  const [search, setSearch] = useState('');
  const [hasInput, setHasInput] = useState(false);

  const stateStyle = {
    warning: 'border-yellow-200',
    danger: 'border-red-200',
    primary: '',
    success: '',
  };

  const inputClasses = `
    w-full
    h-[60px]
    p-5
    text-black-300
    border-2
    border-solid
    rounded-base
    text-sm
    !text-black-300
    placeholder:italic
    placeholder:text-gray-300
    placeholder:!font-normal
    disabled:bg-gray-200
    hover:bg-gray-100
    ${
      (!hasInput && search !== '') || state === 'danger'
        ? stateStyle['danger']
        : 'border-gray-200'
    }
    `;

  const autoContainer = `
    w-full
    h-full
    cursor-pointer
    scrolling
    overflow-auto;
    `;

  const itemHoverStyle = `
    hover:bg-blue-200
    hover:text-white-100`;

  const spanStyle = (value: string) => `
    pt-${value.length > 50 ? 10 : value.length > 30 ? 6 : 6}
    pb-${value.length > 50 ? 10 : value.length > 30 ? 6 : 6}
    pl-3
    pr-3
    h-[40px]
    text-sm
    border-gray-200
    border-2
    border-solid
    group
    flex
    items-center
    justify-start
    gap-3
    cursor-pointer
    border-gray-200
    relative
    border-[1px]
    `;

  const selectedItem = (selected: string) => {
    setSearch(selected);
    handleOnChangeValue(selected);
    setDisplay(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setHasInput(event.target.value !== '');
    updateHasInput(event.target.value !== '');
  };

  const handleOnChangeValue = (value: string) => {
    onValueChange(value);
  };

  return (
    <>
      <input
        className={inputClasses}
        type={type}
        placeholder={placeholder ?? 'Digite sua resposta aqui.'}
        onChange={handleInputChange}
        value={search}
        onClick={() => {
          setDisplay(!display);
          setSearch('');
        }}
        disabled={disabled}
        data-testid="input"
        {...registerInput}
        {...rest}
        size={size}
        max={maxValue}
        min={minValue}
      />
      {search && display && (
        <div className={autoContainer}>
          <ul className="max-h-60 overflow-x-auto overflow-y-auto">
            {items
              .filter(
                ({ id, descricao }) =>
                  id.toLowerCase().includes(search.toLowerCase()) ||
                  descricao.toLowerCase().includes(search.toLowerCase()),
              )
              .map((value, i) => {
                return (
                  <li
                    onClick={() =>
                      selectedItem(value.id + ' - ' + value.descricao)
                    }
                    className={itemHoverStyle}
                    key={i}
                  >
                    <span
                      className={spanStyle(value.id + ' - ' + value.descricao)}
                    >
                      {value.id + ' - ' + value.descricao}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
};
